import { PayloadAction } from '@reduxjs/toolkit';

import { GET_ALL_PRODUCTS, GET_ALL_PRODUCTS_ERROR, GET_ALL_PRODUCTS_SUCCESS } from '../actionTypes';
import { GET_PRODUCT, GET_PRODUCT_ERROR, TOGGLE_PRODUCT, TOGGLE_PRODUCT_ERROR } from './../actionTypes';
import { ProductsReducerState } from './reducer.model';

const initialState: ProductsReducerState = {
    isLoading: false,
    products: [],
};

const products = (state = initialState, action: PayloadAction<any>): ProductsReducerState => {
    switch (action.type) {
        case GET_ALL_PRODUCTS:
            return { ...state, isLoading: true };
        case GET_ALL_PRODUCTS_SUCCESS:
            return { ...state, isLoading: false, products: action.payload };
        case GET_ALL_PRODUCTS_ERROR:
            return { ...state, error: 'unable to fetch products ' };
        case TOGGLE_PRODUCT:
            return {
                ...state,
                ...action.payload,
            };
        case TOGGLE_PRODUCT_ERROR:
            return {
                ...state,
                error: 'An error ocurred while toggling product',
            };
        case GET_PRODUCT:
            return {
                ...state,
                product: action.payload,
            };
        case GET_PRODUCT_ERROR:
            return {
                ...state,
                error: 'An error occurred when trying to get product',
            };
        default:
            return state;
    }
};

export default products;
