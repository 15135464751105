import './stock.css';

import { GraphQLResult } from '@aws-amplify/api';
import { Breadcrumb } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import { GetDmastockByIdQuery } from '../../API';
import ProductField from '../../components/Products/ProductField';
import { updateBulkUploadedDmaStock } from '../../graphql/mutations';
import { getDmastockById } from '../../graphql/queries';
import { unitTypes } from './AddDmaStock';

const UpdateDmastock: React.FC = () => {
    const { params } = useRouteMatch();
    const { id, stockId } = params as any;
    const [stockDetails, setStockDetails] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [unitType, setUnitType] = useState('');
    const [unit, setUnit] = useState(0);

    //Fetch DMA stock
    const getOrders = async () => {
        try {
            setLoading(true);
            const q = graphqlOperation(getDmastockById, {
                dmastockId: stockId,
            });

            const { data } = (await API.graphql(q)) as GraphQLResult<GetDmastockByIdQuery>;
            if (data?.getDmastockById) {
                setUnit(Number(data.getDmastockById?.unit?.replace(/[^0-9]/g, '')) || 0);
                setUnitType(data.getDmastockById.unit?.replace(/[^A-Za-z]/g, '').toLowerCase() || 'kg');

                setStockDetails({
                    name: data.getDmastockById.name,
                    unit: Number(data.getDmastockById.unit?.replace(/[^0-9]/g, '')) || 0,
                    quantity: data.getDmastockById.quantity,
                    selling_price: data.getDmastockById?.selling_price as number,
                    rebates: data.getDmastockById.rebates,
                });
            }

            setLoading(false);
        } catch (e: any) {
            console.log(e);
            setLoading(false);
            return {
                message: e.message,
            };
        }
    };

    useEffect(() => {
        getOrders();
    }, [id, stockId]);

    const updateEditedProduct = async () => {
        try {
            if (
                !stockDetails ||
                !unitType ||
                !stockDetails.name ||
                !stockDetails.selling_price ||
                !stockDetails.quantity ||
                !unit
            ) {
                return toast.error('Kindly fill all fields to update a product');
            }
            // if (!/^([0-9]+[\s]*[a-zA-Z]+[\s]*)+$/i.test(unit)) {
            //     return toast.error('Kindly fill a valid quantity type');
            // }
            setLoading(true);
            stockDetails.unit = `${unit} ${unitType}`;
            const query = graphqlOperation(updateBulkUploadedDmaStock, {
                dmastockId: stockId,
                dmastocks: stockDetails,
            });
            await API.graphql(query);
            setLoading(false);
            toast.success('Product updated successfully');
            history.push(`/dma/${id}/stock`);
        } catch (e: any) {
            console.log(e);
            return toast.error(e);
        } finally {
            setLoading(false);
        }
    };

    const history = useHistory();

    const cancelAddProduct = () => {
        history.push(`/dma/${id}/stock`);
    };

    return (
        <div className="content-wrapper p-4">
            <Breadcrumb separator=">">
                <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => history.push('/dma')}>Digital Master Agents</Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => history.goBack()}>View Details</Breadcrumb.Item>
                <Breadcrumb.Item href="">Update Product Details</Breadcrumb.Item>
            </Breadcrumb>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <BlockUi blocking={loading}>
                            <div>
                                <h6 className="products-heading">Edit Product Details</h6>
                                <Card className="products-cardbody">
                                    <Card.Body>
                                        <Form className="p-3">
                                            <Row className="d-flex">
                                                <Col md={6} className="">
                                                    <ProductField
                                                        label="PRODUCT NAME"
                                                        value={stockDetails?.name || ''}
                                                        placeholder="Enter Product name"
                                                        required
                                                        name="name"
                                                        onChange={(ev) => {
                                                            setStockDetails({
                                                                ...stockDetails,
                                                                name: ev.target.value,
                                                            });
                                                        }}
                                                    />
                                                </Col>
                                                <Col md={4} className="">
                                                    <ProductField
                                                        name="quantity"
                                                        label="QUANTITY TYPE"
                                                        type="number"
                                                        placeholder="Enter Quantity Type"
                                                        value={unit}
                                                        onChange={(e) => setUnit(Number(e.target.value))}
                                                    />
                                                </Col>
                                                <Col md={2} className="">
                                                    <Form.Label className="new-product-form-label">Unit</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        value={unitType}
                                                        onChange={(e) => setUnitType(e.target.value)}
                                                    >
                                                        <option value="" key={99}>
                                                            Select Unit Type
                                                        </option>
                                                        {unitTypes.map(
                                                            (unit: { name: string; value: string }, k: any) => {
                                                                return (
                                                                    <option value={unit?.value} key={k}>
                                                                        {unit?.name}
                                                                    </option>
                                                                );
                                                            },
                                                        )}
                                                    </Form.Control>
                                                </Col>
                                            </Row>
                                            <Row className="d-flex ">
                                                <Col md={6} className="">
                                                    <ProductField
                                                        name="price"
                                                        required
                                                        value={stockDetails?.selling_price || ''}
                                                        placeholder="Enter Price (KSH)"
                                                        label="PRICE (KSH)"
                                                        onChange={(ev) => {
                                                            setStockDetails({
                                                                ...stockDetails,
                                                                selling_price: Number(ev.target.value),
                                                            });
                                                        }}
                                                    />
                                                </Col>
                                                <Col md={6} className="">
                                                    <ProductField
                                                        label="STOCK"
                                                        value={stockDetails?.quantity || ''}
                                                        required
                                                        placeholder="Enter Stock"
                                                        name="quantity"
                                                        onChange={(ev) => {
                                                            setStockDetails({
                                                                ...stockDetails,
                                                                quantity: ev.target.value,
                                                            });
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="d-flex">
                                                <Col md={6} className="">
                                                    <ProductField
                                                        label="REBATES"
                                                        placeholder="Enter Rebates (KSH)"
                                                        value={stockDetails?.rebates || ''}
                                                        required
                                                        name="rebates"
                                                        onChange={(ev) => {
                                                            setStockDetails({
                                                                ...stockDetails,
                                                                rebates: Number(ev.target.value),
                                                            });
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Col className="mt-5 d-flex flex-row-reverse">
                                                <Button
                                                    onClick={updateEditedProduct}
                                                    className="primary-bg-color n-btn-style  white-color fw-500 m-3 px-5 btn-width"
                                                    disabled={loading}
                                                >
                                                    {loading ? 'Loading...' : 'Update'}
                                                </Button>
                                                <Button
                                                    onClick={cancelAddProduct}
                                                    className="cancel-btn fw-500 m-3 btn-width muted-bg-color white-color px-5"
                                                >
                                                    Cancel
                                                </Button>
                                            </Col>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateDmastock;
