import React from 'react';
import { Col, Form } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

import awsConfig from '../../aws';
import { ProductUpload } from '../../components/ProductUpload';

type UploadInformationProps = {
    setFiles: (files: File[]) => void;
    files: File[];
    uploadProducts: () => void;
    handleManufacturerChange: (e: React.ChangeEvent<any>) => void;
    handleManufacturerHubChange: (e: React.ChangeEvent<any>) => void;
    manufacturersData: any[];
    manufacturerHubs: any[];
};

const ManufacturerUploadInformation: React.FC<UploadInformationProps> = ({
    files,
    setFiles,
    handleManufacturerChange,
    handleManufacturerHubChange,
    manufacturersData,
    uploadProducts,
    manufacturerHubs,
}) => {
    return (
        <div className="mt-5">
            <Card className="roles-cardbody shadow-lg p-3 mb-5">
                <Card.Body>
                    <Col md={6} className="" style={{ marginBottom: '15px', marginLeft: '7%', width: '86%' }}>
                        <Form.Label style={{ color: 'black', float: 'left' }} className="new-product-form-label">
                            Manufacturer Name
                        </Form.Label>
                        <Form.Control as="select" name="manufacturerUserId" onChange={handleManufacturerChange}>
                            <option value={''} key={''}>
                                Select a Manufacturer
                            </option>
                            {manufacturersData?.map((manufacturer, k) => {
                                return (
                                    <option key={k} value={manufacturer.manufacturerId}>
                                        {manufacturer.companyName}
                                    </option>
                                );
                            })}
                        </Form.Control>
                        {manufacturerHubs && manufacturerHubs.length > 0 && (
                            <Col
                                md={6}
                                className=""
                                style={{
                                    marginBottom: '15px',
                                    whiteSpace: 'nowrap',
                                    marginRight: '100%',
                                    marginTop: '25px',
                                    width: '100%',
                                }}
                            >
                                <Form.Label
                                    style={{ color: 'black', float: 'left' }}
                                    className="new-product-form-label"
                                >
                                    Manufacturer Hubs
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    name="manufacturerHubId"
                                    onChange={handleManufacturerHubChange}
                                >
                                    <option value={''} key={''}>
                                        Select Manufacturer Hub
                                    </option>
                                    {manufacturerHubs?.map((manufacturerHub, k) => {
                                        return (
                                            <option key={k} value={manufacturerHub.hubId}>
                                                {manufacturerHub.hubName}
                                            </option>
                                        );
                                    })}
                                </Form.Control>
                            </Col>
                        )}

                        <Form.Label
                            style={{ color: 'black', whiteSpace: 'nowrap', marginRight: '100%', marginTop: '15px' }}
                            className="new-product-form-label"
                        >
                            Upload Documents
                        </Form.Label>
                        <Col md={6} style={{ color: 'black', width: '100%', textAlign: 'left' }}>
                            The Template can be downloaded from
                            <a
                                style={{ color: '#2cb34a' }}
                                target="_blank"
                                rel="noreferrer"
                                href={`${awsConfig.Storage.asset}/test-bulk-upload-products.csv`}
                            >
                                &nbsp;here
                            </a>
                        </Col>
                    </Col>
                    <Col md={6} className="" style={{ marginBottom: '15px', marginLeft: '5%', width: '90%' }}>
                        <ProductUpload files={files} setFiles={setFiles} handleUpload={uploadProducts} />
                    </Col>
                </Card.Body>
            </Card>
        </div>
    );
};

export default ManufacturerUploadInformation;
