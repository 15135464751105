import { GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';
import React from 'react';
import { ChangeEvent, useState } from 'react';
import ReactJson from 'react-json-view';

import { AuditLog, FilterCriteria, FilterCriteriaOperation, ListAuditLogQuery } from '../../API';
import { listAuditLog } from '../../graphql/queries';
import { AuditTableDataType } from './audit';

export const mapAuditDataToAuditTableData = (auditData: Array<AuditLog | null> | undefined) => {
    const actionSet = new Set();
    const auditTableData = auditData?.map((data) => {
        actionSet.add(data?.action);
        return {
            auditId: data?.auditId,
            username: data?.username,
            action: data?.action,
            event: data?.event,
            date: data?.date,
            key: data?.auditId,
        };
    });
    return _.orderBy(auditTableData, ['date'], ['desc']);
};

export const getColumns = () => [
    {
        title: 'ID',
        dataIndex: 'auditId',
        key: 'auditId',
        render: (_: unknown, data?: any) => {
            return data.auditId.split('-')[0].toUpperCase();
        },
    },
    {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
    },
    {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (_: unknown, data?: any) => {
            return new Date(data.date).toLocaleDateString();
        },
    },
    {
        title: 'Event',
        dataIndex: 'event',
        key: 'event',
        className: 'text-align-left',
        render: (_: unknown, data?: any) => (
            <ReactJson
                src={JSON.parse(data.event)}
                collapsed
                name="event"
                displayDataTypes={false}
                enableClipboard={false}
                quotesOnKeys={false}
            />
        ),
    },
];

const filterKeywordInitialState = { username: '', action: '', event: '' };
const startDateInitialState = new Date('2022-04-21');

export const useAudit = () => {
    const [loading, setLoading] = useState(false);
    const [tableData, setTableDate] = useState<AuditTableDataType[]>([]);
    const [startDate, setStartDate] = useState(startDateInitialState);
    const [endDate, setEndDate] = useState(new Date());
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | null | undefined>(null);
    const [filterKeyword, setFilterKeyword] = useState<Record<string, string>>(filterKeywordInitialState);
    const [filterCriterias, setFilterCriterias] = useState<FilterCriteria[]>([]);

    const handleKeywordChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const filterKeywordCopy = { ...filterKeyword };
        filterKeywordCopy[name] = value;
        setFilterKeyword(filterKeywordCopy);
    };

    const clearFilters = () => {
        setFilterKeyword(filterKeywordInitialState);
        setStartDate(startDateInitialState);
        setEndDate(new Date());
        setFilterCriterias([]);
        fetchAuditLog([], false);
    };

    const applyFilters = () => {
        setLoading(true);
        const filterCriterias: FilterCriteria[] = [];
        addFilterKeywordsToFilterCriterias(filterCriterias);
        addDateRangeToFilterCriterias(filterCriterias);
        fetchAuditLog(filterCriterias, false);
    };

    const addDateRangeToFilterCriterias = (filterCriterias: FilterCriteria[]) => {
        let temp = startDate;
        filterCriterias.push({
            key: 'date',
            operation: FilterCriteriaOperation.GTE,
            value: temp.toISOString().split('T')[0],
        });
        temp = endDate;
        filterCriterias.push({
            key: 'date',
            operation: FilterCriteriaOperation.LTE,
            value: temp.toISOString(),
        });
    };

    const addFilterKeywordsToFilterCriterias = (filterCriterias: FilterCriteria[]) => {
        const keywords = { ...filterKeyword };
        for (const key in keywords) {
            const keyword = keywords[key];
            keyword.split(',').forEach((k) => {
                k = k.trim();
                if (k) {
                    const filterCriteria = {
                        key: `${key}Filter`,
                        operation: FilterCriteriaOperation.IS,
                        value: k,
                    };
                    filterCriterias.push(filterCriteria);
                }
            });
        }
    };

    const handleDateRangeSelection = (range: 'start' | 'end', date: Date | null) => {
        if (range === 'start' && date) {
            setStartDate(date);
            if (date > endDate) {
                setEndDate(date);
            }
        } else if (range === 'end' && date) {
            setEndDate(date);
        }
    };

    const aggregateTableData = (data: AuditTableDataType[]): AuditTableDataType[] => {
        return [...tableData, ...data];
    };

    const fetchAuditLog = async (filterCriterias: FilterCriteria[] = [], aggregateResult: Boolean = true) => {
        try {
            if (!loading) {
                setLoading(true);
            }
            const q = graphqlOperation(listAuditLog, { filterCriterias });
            const { data } = (await API.graphql(q)) as GraphQLResult<ListAuditLogQuery>;
            setLastEvaluatedKey(data?.listAuditLog?.lastEvaluatedKey);
            if (aggregateResult) {
                const aggregateData = aggregateTableData(mapAuditDataToAuditTableData(data?.listAuditLog?.data || []));
                setTableDate(aggregateData);
            } else {
                setTableDate(mapAuditDataToAuditTableData(data?.listAuditLog?.data || []));
            }
            setFilterCriterias(filterCriterias);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        tableData,
        startDate,
        endDate,
        filterKeyword,
        lastEvaluatedKey,
        filterCriterias,
        clearFilters,
        applyFilters,
        handleKeywordChange,
        handleDateRangeSelection,
        fetchAuditLog,
    };
};
