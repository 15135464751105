import './wards.css';

import { Breadcrumb } from 'antd';
import { Auth } from 'aws-amplify';
import axios, { AxiosRequestConfig } from 'axios';
import React, { useState } from 'react';
import BlockUi from 'react-block-ui';
import Card from 'react-bootstrap/Card';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import awsConfig from '../../aws';
import { BulkUpload } from './components/BulkUpload';
// import { useWards } from './useWards';

interface Iprops {
    setKey: (key: string) => void;
}

export const BulkUploadWard: React.FC<Iprops> = () => {
    const [uploading, setUploading] = useState(false);

    // const { getAllWards } = useWards();
    const [files, setFiles] = useState<File[]>([]);

    const readFileDataAsBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                if (event.target?.result) resolve(event.target.result);
            };

            reader.onerror = (err) => {
                reject(err);
            };

            reader.readAsText(file);
        });
    };

    const handleUpload = async () => {
        try {
            if (files.length < 1) {
                return toast.warning('Please upload a file');
            }

            setUploading(true);

            // const dma = JSON.parse(localStorage.getItem('dma') || '{}');
            const jwt = (await Auth.currentSession()).getAccessToken().getJwtToken();

            const fileContents = await readFileDataAsBase64(files[0]);
            const config: AxiosRequestConfig = {
                method: 'post',
                url: `${process.env.REACT_APP_DOCUMENT_MANAGEMENT_HTTP_SRC}/v1/wards/bulkupload-wards`,
                headers: {
                    'Content-Type': files[0].type,
                    Authorization: jwt,
                },
                data: fileContents,
            };
            const res = await axios(config);
            const { data } = res;
            console.log(data);
            if (data.code === 200) {
                return history.push('/wards');
            } else if (data.code === 207) {
                return toast.warning(data.message);
            } else {
                return toast.error(data.message);
            }
        } catch (e: any) {
            console.log('ERROR: ', e);
            return toast.error(
                e?.response?.data?.message || 'We could not do an upload at this time, try again later!',
            );
        } finally {
            setUploading(false);
        }
    };

    const history = useHistory();

    return (
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <BlockUi blocking={uploading}>
                            <ToastContainer />
                            <div>
                                <Breadcrumb separator=">">
                                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item>Wards</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>

                            <h6 className="roles-heading">Bulk Upload Ward</h6>

                            <div>
                                <Card className="roles-cardbody shadow-lg p-3 mb-5">
                                    <Card.Body>
                                        <div className="fw-400 pb-4">
                                            <div className="fw-500">
                                                <div>The requirement for the upload should the listed items.</div>
                                                <div>They include:</div>

                                                <div className="bkl-ml-3">
                                                    <ul>
                                                        <li>COUNTY</li>
                                                        <li>SUB COUNTY</li>
                                                        <li>WARD</li>
                                                        <li>REGION</li>
                                                    </ul>
                                                </div>
                                                <div style={{ fontSize: '16px' }}>
                                                    The Template can be downloaded from
                                                    <a
                                                        style={{ color: '#2cb34a' }}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={`${awsConfig.Storage.asset}/ward_bulk_upload.csv`}
                                                    >
                                                        &nbsp;here
                                                    </a>
                                                </div>
                                                <div className="mt-5 mb-3">UPLOAD DOCUMENT</div>
                                            </div>
                                            <div className="Avenir">
                                                <BulkUpload
                                                    setFiles={setFiles}
                                                    files={files}
                                                    loading={uploading}
                                                    handleUpload={handleUpload}
                                                />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div>
        </div>
    );
};
