import { Auth } from 'aws-amplify';
import { get } from 'lodash';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { User } from '../../shared-types/user.model';
import { setLoggedIn } from '../../utils';
import {
    GET_CURRENT_SESSION,
    GET_CURRENT_SESSION_ERROR,
    LOGIN_MANUFACTURER,
    LOGIN_MANUFACTURER_ERROR,
    LOGIN_MANUFACTURER_SUCCESS,
    LOGOUT,
    RESET_NEW_MANUFACTURER_PASSWORD_ERROR,
    RESET_NEW_MANUFACTURER_PASSWORD_SUCCESS,
} from '../actionTypes';
import { ActionTypes, DONE_LOGGING_IN, IS_LOGGING_IN, LOGOUT_USER, SET_USER } from '../actionTypes';

type AuthDispatch<T = unknown, S = unknown> = ThunkDispatch<T, S, Action<ActionTypes>>;

export const setCurrentUser = (user: User) => (dispatch: AuthDispatch) => {
    dispatch({ type: SET_USER, payload: user });
};

export const loginUser = (email: string, password: string) => async (dispatch: AuthDispatch) => {
    dispatch({ type: IS_LOGGING_IN });
    //login logic here.
    try {
        const user = await Auth.signIn({ username: email, password });

        dispatch({ type: DONE_LOGGING_IN });
        dispatch(setCurrentUser(user));
    } catch (e: any) {
        // todo
        dispatch({
            type: LOGIN_MANUFACTURER_ERROR,
            payload: e,
        });
        console.log(e);
    }
};

export const loginManufacturer = (email: string, password: string) => async (dispatch: AuthDispatch) => {
    try {
        dispatch({ type: LOGIN_MANUFACTURER });
        const cognitoUser = await Auth.signIn({ username: email, password });

        const user: User = {
            username: cognitoUser.username,
            ...cognitoUser.attributes,
            accessToken: cognitoUser.signInUserSession?.accessToken?.jwtToken,
            idToken: cognitoUser?.signInUserSession?.idToken.jwtToken,
        };
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('jwt', cognitoUser.signInUserSession?.accessToken?.jwtToken);

        dispatch(setCurrentUser(user));

        dispatch({
            type: LOGIN_MANUFACTURER_SUCCESS,
            payload: {
                user,
                challengeName: cognitoUser.challengeName,
                challengeParam: cognitoUser.challengeParam,
            },
        });
        return;
    } catch (e: any) {
        dispatch({ type: LOGIN_MANUFACTURER_ERROR, payload: e });
    }
};

export const getCurrentSession = () => async (dispatch: AuthDispatch) => {
    try {
        await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });
        const session = await Auth.currentSession();

        const user = session.getIdToken().payload;
        const cognitoUser = await Auth.currentUserInfo();
        const groups = get(user, 'cognito:groups');
        setLoggedIn(true);
        localStorage.setItem('isAuthenticated', JSON.stringify(true));
        const myUser = {
            username: cognitoUser.username,
            groups,
            ...cognitoUser.attributes,
        };

        dispatch({ type: GET_CURRENT_SESSION });
        dispatch(setCurrentUser(myUser));
    } catch (e: any) {
        // todo
        console.log(e);
        dispatch({ type: GET_CURRENT_SESSION_ERROR });
        if (e == 'No current user') {
            setLoggedIn(false);

            localStorage.setItem('isAuthenticated', JSON.stringify(false));
            dispatch(logoutUser());
        }
    }
};

export const changeNewManufacturerPassword =
    (
        oldPassword: string,
        newPassword: string,
        username: string,
        givenName: string,
        setIsResettingPassword: any,
        setLoading: any,
    ) =>
    async (dispatch: AuthDispatch) => {
        try {
            const user = await Auth.signIn(username, oldPassword);
            await Auth.completeNewPassword(user, newPassword, {
                given_name: givenName,
            });

            dispatch({ type: RESET_NEW_MANUFACTURER_PASSWORD_SUCCESS });
            setLoading(false);
            setIsResettingPassword(false);
        } catch (e: any) {
            dispatch({ type: RESET_NEW_MANUFACTURER_PASSWORD_ERROR });
        }
    };

export const logoutUser = () => (dispatch: AuthDispatch) => {
    dispatch({ type: LOGOUT_USER });
};

export const logout = (history: any) => async (dispatch: AuthDispatch) => {
    try {
        await Auth.signOut();
        localStorage.clear();
        dispatch({ type: LOGOUT });
        setLoggedIn(false);
        history.push('/auth/login');
    } catch (e: any) {
        //  todo: an error occurred
    }
};

export const sendResetPasswordInstruction = (email: string) => async () => {
    try {
        await Auth.forgotPassword(email);
    } catch (e: any) {}
};
