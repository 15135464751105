import React from 'react';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface DataPoints {
    name: string;
    dma: string | number;
    manufacturer: string | number;
}

type Props = {
    dmaGrowth: DataPoints[];
};

const GrowthChart: React.FC<Props> = ({ dmaGrowth }) => {
    return (
        <ResponsiveContainer width="100%" height="100%" minHeight="200px">
            <BarChart width={150} height={40} data={dmaGrowth}>
                <YAxis />
                <XAxis dataKey="label" name="Month" />
                <Tooltip />
                <Legend />
                <Bar dataKey="manufacturer" stackId="a" fill="#f7a73e" name="Manufacturers" />
                <Bar dataKey="dma" stackId="a" fill="#1f06fe" name="DMA" />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default GrowthChart;
