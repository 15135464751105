import { DeleteFilled, MoreOutlined } from '@ant-design/icons';
import { Card, Divider, Popover } from 'antd';
import React from 'react';
import { Table } from 'react-bootstrap';
import { _DeepReadonlyObject } from 'utility-types/dist/mapped-types';

import { ManufacturerDetailResult } from '../../../API';

export const Profile: React.FC<{
    profile?: _DeepReadonlyObject<ManufacturerDetailResult>;
    onToggleManufacturer: (profile: _DeepReadonlyObject<ManufacturerDetailResult>) => void;
    isManufacturerActive: (status: String | undefined | null) => boolean;
}> = ({ profile, onToggleManufacturer, isManufacturerActive }) => {
    const content = profile && (
        <div className="p-3">
            <div onClick={() => onToggleManufacturer && onToggleManufacturer(profile)}>
                <DeleteFilled /> {isManufacturerActive(profile?.status) ? 'Deactivate' : 'Activate'}
            </div>
        </div>
    );

    return (
        <div className="d-flex flex-column justify-content-between fullwidth p-4">
            <Card className="profile-card fullwidth mb-5">
                <Popover placement="leftTop" content={content} trigger="click">
                    <div className="pull-right">
                        <MoreOutlined size={23} style={{ fontSize: '18px', color: '#000000' }} />
                    </div>
                </Popover>

                <div className="fullwidth d-flex">
                    <div className="profile-initials-manufacturers d-flex align-items-center justify-content-center">
                        {profile?.name &&
                            profile.name.split(' ').map((namePart, k) => <div key={k}>{namePart[0]}</div>)}
                    </div>
                    <div className="fs-20 fw-500 mt-3 primary-color ml-5">{profile?.name}</div>
                </div>
                <Divider />
                <Table borderless hover>
                    <thead className="text-muted">
                        <tr>
                            <th>EMAIL</th>
                            <th>PHONE</th>
                            <th>CONTACT PERSON</th>
                            <th>LOCATION</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{profile?.email}</td>
                            <td>{profile?.phoneNumber}</td>
                            <td>{profile?.name}</td>
                            <td>{profile?.location}</td>
                        </tr>
                    </tbody>
                </Table>
            </Card>
        </div>
    );
};
