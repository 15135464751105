import 'react-datepicker/dist/react-datepicker.css';

import { EyeOutlined, MoreOutlined, PrinterOutlined } from '@ant-design/icons';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { Breadcrumb, DatePicker, Pagination, Popover, Select, Table } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { ExportFarmerOrdersAdminQuery, NestedFarmerOrders, SearchFarmerOrdersAdminQuery } from '../../../API';
import { exportFarmerOrdersAdmin, searchFarmerOrdersAdmin } from '../../../graphql/queries';
import CustomPopoverExportButton from '../../dma/components/CustomPopoverExportButton';
import { PageTitle } from '../../products/PageTitle';

const csvHeaders = [
    { label: 'ID', key: 'id' },
    { label: 'DMA Name', key: 'dmaName' },
    { label: 'Farmer Name', key: 'farmerName' },
    { label: 'Date', key: 'creationDate' },
    { label: 'Total Cost', key: 'totalCost' },
    { label: 'Rebates', key: 'rebates' },
    { label: 'Payment Method', key: 'paymentMethod' },
    { label: 'Payment Status', key: 'paymentStatus' },
    { label: 'Status', key: 'farmerOrderStatus' },
];

const content = (id: any) => (
    <NavLink to={`/sales-farmer/${id}`} style={{ backgroundColor: 'white', color: 'black' }}>
        <EyeOutlined /> &nbsp; View More
    </NavLink>
);

const { Option } = Select;

const columns = [
    {
        title: 'Id',
        dataIndex: 'farmerOrderId',
        key: 'idNumber',
        render: (action: any, record: any) => {
            return record.farmerOrderId?.slice(-6).toUpperCase() as any;
        },
    },
    {
        title: 'Farmer Name',
        dataIndex: 'farmerName',
        key: 'name',
    },
    {
        title: 'Dma Name',
        dataIndex: 'dmaName',
        key: 'dmaName',
    },
    {
        title: 'Rebates',
        dataIndex: 'rebates',
        key: 'rebates',
    },

    {
        title: 'Total Cost',
        dataIndex: 'totalCost',
        key: 'totalCost',
    },

    {
        title: 'Payment Method',
        dataIndex: 'paymentMethod',
        key: 'phoneNumber',
    },
    {
        title: 'Payment Status',
        dataIndex: 'paymentStatus',
        key: 'paymentStatus',
    },
    {
        title: 'Origin',
        dataIndex: 'origin',
        key: 'origin',
        render: (_: any, record: any) => {
            return record?.origin ? record?.origin : '-';
        },
    },
    {
        title: 'Delivery Type',
        dataIndex: 'deliveryType',
        key: 'deliveryType',
        render: (_: any, record: any) => {
            return record?.deliveryType ? record?.deliveryType : '-';
        },
    },
    {
        title: 'Date',
        dataIndex: 'creationDate',
        key: 'date',
        render: (action: any, record: any) => {
            return moment(record.creationDate).format('DD/MM/YYYY');
        },
    },
    {
        title: 'Status',
        dataIndex: 'orderStatus',
        key: 'status',
        render: (action: any, record: any) => {
            const pending = record.farmerOrderStatus === 'ORDERED';
            const accepted = record.farmerOrderStatus === 'COMPLETE';
            const rejected = record.farmerOrderStatus === 'REJECTED';
            return (
                <div
                    className={
                        pending ? 'pending' : accepted ? 'active-status' : rejected ? 'rejected' : 'disabled-status'
                    }
                >
                    {record.farmerOrderStatus}
                </div>
            );
        },
    },
    {
        title: '',
        dataIndex: '',
        key: 'viewMore',
        render: (action: any, record: any) => {
            return (
                <div onClick={() => localStorage.setItem('dmaSale', JSON.stringify(record))}>
                    <Popover placement="leftTop" content={content(record.id)} trigger="click">
                        <MoreOutlined />
                    </Popover>
                </div>
            );
        },
    },
];

type FilterType = {
    limit?: number;
    page?: number;
    q?: string;
    paymentStatus: string;
    orderStatus: string;
    paymentMethod: string;
    origin: string;
    deliveryType: string;
    fromDate: string | null;
    toDate: string | null;
    farmerName: string;
    dmaName: string;
};

const mapData = (arr: Array<NestedFarmerOrders | null> | undefined | null) => {
    return arr?.map((item) => ({
        key: item?.id,
        farmerOrderId: item?.farmerOrderId,
        memberNumber: item?.memberNumber,
        dmaId: item?.dmaId,
        dmaName: item?.dmaName,
        rebates: item?.rebates,
        branchId: item?.rebates,
        paymentMethod: item?.paymentMethod,
        farmerOrderStatus: item?.farmerOrderStatus,
        creationDate: item?.creationDate,
        farmerName: item?.farmerName,
        farmerNumber: item?.farmerNumber,
        paymentStatus: item?.paymentStatus,
        totalCost: item?.totalCost,
        id: item?.id,
        mpesaTransactionId: item?.mpesaTransactionId,
        product: item?.product,
        // origin: item?.origin,
        deliveryType: item?.deliveryType,
    }));
};

const FarmerSales = () => {
    const [tableData, setTableData] = useState<SearchFarmerOrdersAdminQuery['searchFarmerOrdersAdmin']>(
        {} as SearchFarmerOrdersAdminQuery['searchFarmerOrdersAdmin'],
    );
    const [loading, setLoading] = useState(false);
    const [perPage] = useState(10);
    const [data, setData] = useState([] as any);
    const [aggregration, setAggregration] = useState<any>([]);

    const [filters, setFilters] = useState<FilterType>({
        limit: 20,
        page: 1,
        paymentStatus: 'Select Payment Status',
        orderStatus: 'Select Order Status',
        paymentMethod: 'Select Payment Method',
        origin: 'Select Order Origin',
        deliveryType: 'Select Delivery Type',
        fromDate: null,
        toDate: null,
        farmerName: 'Select Farmer',
        dmaName: 'Select Dma',
    });
    const [exportData, setExportData] = useState<any[]>([]);

    const [popoverVisible, setPopoverVisible] = useState(false);

    const handlePopoverVisibleChange = (visible: boolean) => setPopoverVisible(visible);

    const handleExportClick = async (dateRange: { startDate: Date; endDate: Date }, csvLinkRef: any) => {
        try {
            setPopoverVisible(false);
            const gte = new Date(dateRange.startDate.getTime());
            gte.setHours(0, 0, 0, 0);
            const query = graphqlOperation(exportFarmerOrdersAdmin, { gte, lte: dateRange.endDate });
            const { data } = (await API.graphql(query)) as GraphQLResult<ExportFarmerOrdersAdminQuery>;
            setExportData(data?.exportFarmerOrdersAdmin?.result || []);
            csvLinkRef?.current?.link.click();
        } catch (err: any) {
            console.log(err);
        }
    };

    //func to handle filter change event for farmer
    const handleFarmerChange = (value: string) => {
        setFilters({ ...filters, farmerName: value });
    };

    //func to handle filter change event for farmer
    const handleDmaChange = (value: string) => {
        setFilters({ ...filters, dmaName: value });
    };

    //func to handle filter change event for farmer order delivery type
    const handlePaymentStatusChange = (value: string) => {
        setFilters({ ...filters, paymentStatus: value });
    };

    const handleStatusChange = async (value: string) => {
        setFilters({ ...filters, orderStatus: value });
    };

    //func to handle filter change event for farmer order origin
    const handleOriginChange = (value: string) => {
        setFilters({ ...filters, origin: value });
    };

    //func to handle filter change event for farmer order delivery type
    const handleDeliveryTypeChange = (value: string) => {
        setFilters({ ...filters, deliveryType: value });
    };

    //func to handle filter change event for farmer order delivery type
    const handleFromDateChange = (date: Moment | null) => {
        setFilters({ ...filters, fromDate: date && moment(date).format('YYYY-MM-DD') });
    };

    //func to handle filter change event for farmer order delivery type
    const handleToDateChange = (date: Moment | null) => {
        setFilters({ ...filters, toDate: date && moment(date).format('YYYY-MM-DD') });
    };

    // const handleDateChange = async (date: any, dateString: string) => {
    //     if (!dateString) {
    //         setTableData(data?.searchFarmerOrdersAdmin);
    //         setData(mapData(data?.searchFarmerOrdersAdmin?.result));
    //         return;
    //     }
    //     setStatusFilter('');
    //     setDateFilter(date);
    //     setLoading(true);
    //     await fetchOrders(filters.page, filters.limit, '', '');
    //     setLoading(false);
    // };

    const fetchOrders = async () => {
        setLoading(true);
        const params: Record<string, any> = {};
        Object.entries(filters).forEach(([key, val]) => {
            if (val && val !== '' && !val.toString().includes('Select')) {
                params[key] = val;
            }
        });
        try {
            const query = graphqlOperation(searchFarmerOrdersAdmin, { ...params });
            const { data } = (await API.graphql(query)) as GraphQLResult<SearchFarmerOrdersAdminQuery>;
            setTableData(data?.searchFarmerOrdersAdmin);
            setData(mapData(data?.searchFarmerOrdersAdmin?.result));
            const agg = JSON.parse(data?.searchFarmerOrdersAdmin?.aggregations || '{}');
            if (aggregration.length === 0) {
                setAggregration(agg);
            }
            setLoading(false);
        } catch (err: any) {
            console.log(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, [filters]);

    const handlePageChange = (page: number) => {
        setFilters({ ...filters, page: page });
    };

    const clearFilters = () => {
        setFilters({
            limit: 10,
            page: 1,
            paymentStatus: 'Select Status',
            paymentMethod: 'Select Payment Method',
            orderStatus: 'Select Order Method',
            origin: 'Select Order Origin',
            deliveryType: 'Select Delivery Type',
            fromDate: null,
            toDate: null,
            farmerName: 'Select Farmer',
            dmaName: 'Select Dma',
        });
    };

    return (
        <div className="content-wrapper" id="wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="">Sales</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className="row mt-4 mb-3">
                            <PageTitle title="DMA Sales" />
                        </div>
                        <div className="row">
                            <div className="d-flex justify-content-end">
                                <div className="p-2 bd-highlight">
                                    <div className="border-btn text-center" onClick={() => window.print()}>
                                        Print <PrinterOutlined style={{ color: '#2cb34a' }} />
                                    </div>
                                </div>
                                <div className="p-2 bd-highlight">
                                    <CustomPopoverExportButton
                                        popoverVisible={popoverVisible}
                                        csvHeaders={csvHeaders}
                                        csvData={exportData}
                                        handleExportClick={handleExportClick}
                                        csvFileName={`DMA Sales Data`}
                                        handlePopoverVisibleChange={handlePopoverVisibleChange}
                                    />
                                    {/* <Popover
                                        placement="leftTop"
                                        content={dateRangeSelector(
                                            dateRange.startDate,
                                            dateRange.endDate,
                                            handleDateRangeSelection,
                                        )}
                                        trigger="click"
                                        visible={popoverVisible}
                                        onVisibleChange={handlePopoverVisibleChange}
                                    >
                                        <div className="border-btn text-center">
                                            Export <DownloadOutlined style={{ color: '#2cb34a' }} />
                                        </div>
                                    </Popover> */}
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="row">
                                <div className="col-md-3 mb-5 mt-3">
                                    <DatePicker
                                        style={{ height: '100%', backgroundColor: '#fff' }}
                                        onChange={handleFromDateChange}
                                        value={filters.fromDate ? moment(filters.fromDate) : null}
                                        placeholder="Select Start Date"
                                    />
                                </div>

                                <div className="col-md-3 mb-5 mt-3">
                                    <DatePicker
                                        style={{ height: '100%', backgroundColor: '#fff' }}
                                        onChange={handleToDateChange}
                                        value={filters.toDate ? moment(filters.toDate) : null}
                                        placeholder="Select End Date"
                                    />
                                </div>

                                <div className="col-md-3 mb-5 mt-3">
                                    <Select
                                        showSearch
                                        className="w-100 dma-location-select"
                                        value={filters.orderStatus}
                                        onChange={handleStatusChange}
                                        filterOption={(input, option: any) =>
                                            option.props.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        <Option value="order-status" disabled>
                                            filter by order status
                                        </Option>
                                        {aggregration.farmerOrderStatus?.buckets.map(
                                            (p: Record<string, string>, index: number) => {
                                                return (
                                                    <Option key={`status-${index}`} value={p.key}>
                                                        {p.key}
                                                    </Option>
                                                );
                                            },
                                        )}
                                    </Select>
                                </div>
                                <div className="col-md-3 mb-5 mt-3">
                                    <Select
                                        showSearch
                                        className="w-100 dma-location-select"
                                        value={filters.paymentStatus}
                                        onChange={handlePaymentStatusChange}
                                        filterOption={(input, option: any) =>
                                            option.props.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        <Option value="payment-status" disabled>
                                            filter by payment status
                                        </Option>
                                        {aggregration.paymentStatus?.buckets.map(
                                            (p: Record<string, string>, index: number) => {
                                                return (
                                                    <Option key={`payment-status-${index}`} value={p.key}>
                                                        {p.key}
                                                    </Option>
                                                );
                                            },
                                        )}
                                    </Select>
                                </div>
                                <div className="col-md-3 mb-5 mt-3">
                                    <Select
                                        showSearch
                                        className="w-100 dma-location-select"
                                        value={filters.origin}
                                        onChange={handleOriginChange}
                                        filterOption={(input, option: any) =>
                                            option.props.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        <Option value="origin" disabled>
                                            filter by Order Origin
                                        </Option>
                                        {aggregration.origin?.buckets.map(
                                            (p: Record<string, string>, index: number) => {
                                                return (
                                                    <Option key={`origin-${index}`} value={p.key}>
                                                        {p.key}
                                                    </Option>
                                                );
                                            },
                                        )}
                                    </Select>
                                </div>
                                <div className="col-md-3 mb-5 mt-3">
                                    <Select
                                        showSearch
                                        className="w-100 dma-location-select"
                                        value={filters.deliveryType}
                                        onChange={handleDeliveryTypeChange}
                                        filterOption={(input, option: any) =>
                                            option.props.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        <Option value="deliveryTyoe" disabled>
                                            filter by Order Delivery Type
                                        </Option>
                                        {aggregration.deliveryType?.buckets.map(
                                            (p: Record<string, string>, index: number) => {
                                                return (
                                                    <Option key={`deliveryType-${index}`} value={p.key}>
                                                        {p.key}
                                                    </Option>
                                                );
                                            },
                                        )}
                                    </Select>
                                </div>
                                <div className="col-md-3 mb-5 mt-3">
                                    <Select
                                        showSearch
                                        className="w-100 dma-location-select"
                                        value={filters.dmaName}
                                        onChange={handleDmaChange}
                                        filterOption={(input, option: any) =>
                                            option.props.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        <Option value="dmaName" disabled>
                                            filter by DMA Name
                                        </Option>
                                        {aggregration.dmaName?.buckets.map(
                                            (p: Record<string, string>, index: number) => {
                                                return (
                                                    <Option key={`dmaName-${index}`} value={p.key}>
                                                        {p.key}
                                                    </Option>
                                                );
                                            },
                                        )}
                                    </Select>
                                </div>
                                <div className="col-md-3 mb-5 mt-3">
                                    <Select
                                        showSearch
                                        className="w-100 dma-location-select"
                                        value={filters.farmerName}
                                        onChange={handleFarmerChange}
                                        filterOption={(input, option: any) =>
                                            option.props.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        <Option value="farmerName" disabled>
                                            filter by Farmer Name
                                        </Option>
                                        {aggregration.farmerName?.buckets.map(
                                            (p: Record<string, string>, index: number) => {
                                                return (
                                                    <Option key={`farmerName-${index}`} value={p.key}>
                                                        {p.key}
                                                    </Option>
                                                );
                                            },
                                        )}
                                    </Select>
                                </div>
                            </div>
                            <div className=" d-flex justify-content-end">
                                <div className="primary-color fw-500 p-3 pointer" onClick={clearFilters}>
                                    Clear All Filters
                                </div>
                            </div>
                        </div>
                        <div style={{ overflowX: 'auto' }}>
                            <Table
                                className={'shadow-lg p'}
                                loading={loading}
                                dataSource={data}
                                columns={columns}
                                pagination={false}
                            />
                        </div>
                        <div className="pagination-wrapper">
                            <Pagination
                                current={filters.page || 1}
                                total={tableData?.totalCount || 0}
                                pageSize={perPage}
                                showSizeChanger={false}
                                disabled={(tableData?.totalCount || 0) < perPage}
                                onChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FarmerSales;
