import './ProductDetails.css';

import { Breadcrumb } from 'antd';
import { FC } from 'react';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useRouteMatch } from 'react-router-dom';

import { IDProductDetails } from '../../../components/IDProductDetails';
import { PageTitle } from '../../../components/PageTitle';
import { useProducts } from '../useProducts';

const ProductDetailsPage: FC = () => {
    const { params } = useRouteMatch();
    const { id } = params as any;
    const { product, getSingleProduct } = useProducts();

    useEffect(() => {
        getSingleProduct(id);
    }, [getSingleProduct, id]);

    return (
        <>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="/products">Products</Breadcrumb.Item>
                                <Breadcrumb.Item>View More</Breadcrumb.Item>
                            </Breadcrumb>
                            <div>
                                <PageTitle title={product?.name || ''} />
                                <Row style={{ marginTop: -30 }}>
                                    <Col className="mt-1 product-details d-flex flex-direction-column align-items-center justify-content-center">
                                        {product && <IDProductDetails details={product} />}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductDetailsPage;
