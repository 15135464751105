import React from 'react';

import logo from '../../../assets/LOGO/drawable-hdpi/digifarm_logo_approved.png';
import { LeftPanelTypes } from '../../../models';

const style = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
};
export const LeftPanel: React.FC<LeftPanelTypes> = (props) => {
    const {
        image,
        header,
        // headerStyle,
        headerClassName,
        activeText,
        breakText,
        subText,
    } = props;
    return (
        <div className={`${image} col-md-12 fullheight d-flex flex-column justify-content-center`} style={style}>
            <div className={'img-overlay padding-20percent'}>
                <div className="m-display-none">
                    <img alt="logo" src={logo} className={'mb-5'} width={150} />
                    <div className={`register-big-header white-color ${headerClassName}`}>
                        {header}
                        <span className="primary-color"> {activeText}</span> {breakText}
                    </div>
                    <div className="white-color fs-16 mt-5">{subText}</div>
                </div>
            </div>
        </div>
    );
};
