import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { RootState } from '../redux/store';
import SignIn from '../views/auth/SignIn/SignIn';

const AuthRoute = () => {
    return (
        <main className="" id="auth-route-layout">
            <Switch>
                <Route path="/auth/login" component={SignIn} />
            </Switch>
        </main>
    );
};

const mapStateToProps = ({ auth }: RootState) => ({
    auth,
});

export default connect(mapStateToProps)(AuthRoute);
