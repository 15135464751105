import { EyeOutlined, MoreOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import moment from 'moment';
import React from 'react';
import { NavLink } from 'react-router-dom';

enum Status {
    Pending = 'PENDING',
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
}

export const companyPermsColumns = () => [
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Full Name',
        dataIndex: 'fullName',
        key: 'fullName',
    },
    {
        title: 'Email Address',
        dataIndex: 'emailAddress',
        key: 'emailAddress',
    },
    {
        title: 'Phone Number',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
    },
    {
        title: 'Role/Permission',
        dataIndex: 'rolePermission',
        key: 'rolePermission',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: () => {
            return <div className="pending">Active</div>;
        },
    },
    // {
    //   dataIndex: 'action',
    //   key: 'action',
    //   render: (action: any, record: any) => {
    //     const content = (
    //         <div className="p-3">
    //           <div onClick={() => cb && cb()}>
    //             <DeleteFilled /> {profile?.enabled ? 'Deactivate' : 'Activate'}
    //           </div>
    //         </div>
    //       );

    //     return (
    //       <Popover placement="leftTop" content={content} trigger="click">
    //         <MoreOutlined />
    //       </Popover>
    //     );
    //   },
    // },
];

export const salesColumns = [
    {
        title: 'Order Id',
        dataIndex: 'orderId',
        key: 'orderId',
    },
    {
        title: 'DMA Name',
        dataIndex: 'dmaName',
        key: 'dmaName',
    },

    {
        title: 'Location',
        dataIndex: 'dmaLocation',
        key: 'dmaLocation',
    },
    {
        title: 'Number of Orders',
        dataIndex: 'orderItems',
        key: 'orderItems',
        render: (record: any) => {
            return <div>{record.orderItems.length || 0}</div>;
        },
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (record: any) => {
            return <div className={Status.Approved ? 'approved' : 'pending'}>{record.orderStatus}</div>;
        },
    },
];

export const sales = [
    {
        orderId: '1000',
        dmaName: 'Christine Wangui',
        quantity: '100kg',
        price: '2,000',
    },
    {
        orderId: '10200',
        dmaName: 'Victor Musa',
        quantity: '102kg',
        price: '3,000',
    },
];

export const csvHeaders = [
    { label: 'Id', key: 'id' },
    { label: 'Company Name', key: 'companyName' },
    { label: 'Contact Person', key: 'contactPerson' },
    { label: 'Email Address', key: 'emailAddress' },
    { label: 'Phone Number', key: 'phoneNumber' },
    { label: 'Location', key: 'location' },
    { label: 'Enabled', key: 'enabled' },
];

export const manufacturerHubColumns = (manufacturerId: string | null | undefined) => [
    {
        title: 'Id',
        dataIndex: 'hubId',
        key: 'hubId',
    },
    {
        title: 'Name',
        dataIndex: 'hubName',
        key: 'hubName',
    },
    {
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Phone Number',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
    },
    {
        title: 'Date Created',
        dataIndex: 'creationDate',
        key: 'creationDate',
        render: (_action: any, record: any) => {
            return <div>{moment(record.creationDate).format('Do MMM YYYY') as any}</div>;
        },
    },
    {
        dataIndex: 'action',
        key: 'action',
        render: (action: any, data: any) => {
            const content = (
                <NavLink to={`/manufacturer/${manufacturerId}/hub/${data.hubId}`}>
                    <EyeOutlined /> &nbsp; View More
                </NavLink>
            );
            return (
                <Popover placement="leftTop" content={content} trigger="click">
                    <MoreOutlined />
                </Popover>
            );
        },
    },
];
