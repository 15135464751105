import './categories.css';

import { Breadcrumb } from 'antd';
import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { NavLink } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

import { PageTitle } from '../dma/PageTitle';
import { useCategories } from './useCategories';

function CategoryDetails() {
    const { params } = useRouteMatch();
    const { id } = params as any;
    const [selectedCategory, setSelectedCategory] = useState(id);
    // const history = useHistory();

    const { categories, getAllCategories, getSubCategoriesList, subCategories } = useCategories();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async function () {
            // setSelectedCategory();
            await getAllCategories();
            await getSubCategoriesList(id);
            localStorage.setItem('categoryId', id);
            setLoading(false);
        })();
    }, []);

    const handleCategoryChange = async (e: any) => {
        const categoryId = e.target.value;
        setSelectedCategory(categoryId);
        setLoading(true);
        await getSubCategoriesList(categoryId);
        setLoading(false);
    };

    return (
        <BlockUi blocking={loading}>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="/categories">Categories</Breadcrumb.Item>
                                <Breadcrumb.Item>Categories Details</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <div className="row mt-4 mb-3">
                            <div className="col-md-10">
                                <PageTitle title="Categories Details" />
                            </div>
                            <div className="col-md-2">
                                <NavLink to={'/subcategories/create'}>
                                    <div className="border-btn text-center"> Add Sub Category</div>
                                </NavLink>
                            </div>
                        </div>

                        <div>
                            <Card className="categories-cardbody shadow-lg p-3 mb-5">
                                <Card.Body>
                                    <h6>CHECKLIST ITEMS</h6>
                                    <Form>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                            <div className="row">
                                                <div className="col-md-1 mt-2">
                                                    <Form.Label>Select Category</Form.Label>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <Form.Control
                                                        onChange={(e) => handleCategoryChange(e)}
                                                        value={selectedCategory}
                                                        as="select"
                                                        className="w-100 mb-4"
                                                    >
                                                        {categories.map((category) => {
                                                            if (category.categoryId === selectedCategory) {
                                                                return (
                                                                    <option
                                                                        selected={true}
                                                                        value={category.categoryId}
                                                                        key={category.categoryId}
                                                                    >
                                                                        {category.name}
                                                                    </option>
                                                                );
                                                            }
                                                            return (
                                                                <option
                                                                    value={category.categoryId}
                                                                    key={category.categoryId}
                                                                >
                                                                    {category.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </Form.Control>
                                                </div>
                                            </div>

                                            <h6>Sub Categories</h6>
                                            <Form.Group
                                                className="categories-permission-checkbox-group"
                                                controlId="formBasicCheckbox"
                                            >
                                                <ul>
                                                    {subCategories.map((subcategory: any) => (
                                                        <li
                                                            style={{ margin: '20px', fontSize: '18px' }}
                                                            key={subcategory.subCategoryId}
                                                        >
                                                            {subcategory.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Form.Group>
                                        </Form.Group>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </BlockUi>
    );
}

export default CategoryDetails;
