import React from 'react';
import { Card, Col } from 'react-bootstrap';

import { FileUpload } from '../../components/fileUpload/FileUpload';

interface Iprops {
    setKey: (key: string) => void;
    files: File[];
    setFiles: (value: File[]) => void;
    handleSubmit: () => void;
    message: string;
}

export const KycForm: React.FC<Iprops> = ({ files, handleSubmit, setFiles }) => {
    return (
        <Card className="shadow-lg p-3 mb-5">
            <div className="grey-color p-4">
                The following documents are required for the new manufacturer to be onboarded.
                <br />
                They include:
                <ul className="m-3">
                    <li>KEPHIS AND CERTIFICATION</li>
                    <li>CERTIFICATE OF INCORPERATION</li>
                    <li>TAX COMPLIANCE CERTIFICATE</li>
                    <li>SHAREHOLDING STRUCTURE (CR1)</li>
                    <li>FILLED BANK DETAILS</li>
                </ul>
                <Col md={7}>
                    <label className="auth-label mt-4">Upload Documents</label>
                    <FileUpload files={files} setFiles={setFiles} />
                    <button className="primary-btn col-12 fw-500 mt-5" type="submit" onClick={() => handleSubmit()}>
                        Upload
                    </button>
                </Col>
            </div>
        </Card>
    );
};
