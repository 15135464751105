import { Col, Row } from 'antd';
import React from 'react';
interface titleProps {
    empty: string;
    name: string | undefined;
}

export const EmptyUserData: React.FC<titleProps> = ({ empty, name }) => {
    return (
        <Row>
            <Col span={18}>{name} has no data to present as no order has been requested from a Manufacturer</Col>
            <Col span={6}>
                <img alt="empty-data" className="fullwidth" src={empty} />
            </Col>
        </Row>
    );
};
