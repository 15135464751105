import { GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { useState } from 'react';

import { GetDMARolesQuery, GetPermissionsByUserLevelQuery } from '../../../API';
import { getDMARoles, getPermissionsByUserLevel } from '../../../graphql/queries';

export const useDmaRoles = () => {
    const [rolesData, setRolesData] = useState<any[]>([]);
    const [permissionsData, setPermissionsData] = useState<any[] | undefined | null>([]);

    const getAllRoles = async () => {
        try {
            const query = graphqlOperation(getDMARoles);
            const { data } = (await API.graphql(query)) as GraphQLResult<GetDMARolesQuery>;

            const roles = data?.getDMARoles;
            if (roles) {
                setRolesData(roles);
            }
            return roles;
        } catch (e: any) {
            console.log(e);
        }
    };

    const getAllPermissions = async (permissionsId = '') => {
        try {
            const query = graphqlOperation(getPermissionsByUserLevel, { permissionsId, userLevel: 'DMA_USER' });
            const { data } = (await API.graphql(query)) as GraphQLResult<GetPermissionsByUserLevelQuery>;

            const permissions = data?.getPermissionsByUserLevel?.data;

            if (permissions) {
                setPermissionsData(permissions);
            }
            return permissions;
        } catch (e: any) {
            console.log(e);
        }
    };

    return {
        rolesData,
        getAllRoles,
        permissionsData,
        getAllPermissions,
    };
};
