import React from 'react';

enum Status {
    Pending = 'PENDING',
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
}

export const companyPermsColumns = () => [
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Full Name',
        dataIndex: 'fullName',
        key: 'fullName',
    },
    {
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Phone Number',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
    },
    {
        title: 'Role/Permission',
        dataIndex: 'rolePermission',
        key: 'rolePermission',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: () => {
            return <div className="pending">Active</div>;
        },
    },
    // {
    //   dataIndex: 'action',
    //   key: 'action',
    //   render: (action: any, record: any) => {
    //     const content = (
    //         <div className="p-3">
    //           <div onClick={() => cb && cb()}>
    //             <DeleteFilled /> {profile?.enabled ? 'Deactivate' : 'Activate'}
    //           </div>
    //         </div>
    //       );

    //     return (
    //       <Popover placement="leftTop" content={content} trigger="click">
    //         <MoreOutlined />
    //       </Popover>
    //     );
    //   },
    // },
];

export const salesColumns = () => [
    {
        title: 'Order Id',
        dataIndex: 'orderId',
        key: 'orderId',
    },
    {
        title: 'DMA Name',
        dataIndex: 'dmaName',
        key: 'dmaName',
    },

    {
        title: 'Location',
        dataIndex: 'dmaLocation',
        key: 'dmaLocation',
    },
    {
        title: 'Price',
        dataIndex: 'orderItems',
        key: 'orderItems',
        render: (record: any) => {
            const reducedPrice = record?.reduce(
                (sum: any, price: any) => (sum += price?.product?.price * price.quantity),
                0,
            );
            return <div>{`KSh ${reducedPrice || 0}`}</div>;
        },
    },
    {
        title: 'Status',
        dataIndex: 'orderStatus',
        key: 'orderStatus',
        render: (record: any) => {
            return (
                <div
                    className={
                        record.toLowerCase() === Status.Approved.toLowerCase()
                            ? 'active-status'
                            : record.toLowerCase() === Status.Pending.toLowerCase()
                            ? 'pending'
                            : 'rejected'
                    }
                >
                    {record}
                </div>
            );
        },
    },
];

export const sales = [
    {
        orderId: '1000',
        dmaName: 'Christine Wangui',
        quantity: '100kg',
        price: '2,000',
    },
    {
        orderId: '10200',
        dmaName: 'Victor Musa',
        quantity: '102kg',
        price: '3,000',
    },
];

export const csvHeaders = [
    { label: 'Id', key: 'id' },
    { label: 'Company Name', key: 'companyName' },
    { label: 'Contact Person', key: 'contactPerson' },
    { label: 'Email Address', key: 'emailAddress' },
    { label: 'Phone Number', key: 'phoneNumber' },
    { label: 'Location', key: 'location' },
    { label: 'Enabled', key: 'enabled' },
];
