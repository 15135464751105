import { LoadingOutlined } from '@ant-design/icons';
import { Breadcrumb, Card, Divider } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useManufacturer } from '../useManufacturer';

export const HubDetails: React.FC<{}> = () => {
    const { hubId, manufacturerId } = useParams<{ hubId: string; manufacturerId: string }>();

    const { manufacturerHubDetail, fetchManufacturerHubDetail } = useManufacturer();

    useEffect(() => {
        fetchManufacturerHubDetail(manufacturerId, hubId);
    }, []);

    if (!manufacturerHubDetail) {
        return (
            <div className="d-flex justify-content-center align-items-center height-100vh">
                <LoadingOutlined style={{ fontSize: 34 }} spin />
            </div>
        );
    }

    return (
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 mt-3">
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href={`/manufacturer/profile/${manufacturerId}`}>Hubs</Breadcrumb.Item>
                            <Breadcrumb.Item>Hub Details</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>

                    <div className="d-flex flex-column justify-content-between fullwidth p-2 mt-3">
                        <Card className="profile-card fullwidth mb-5">
                            <div className="fullwidth d-flex">
                                <div className="profile-initials-manufacturers d-flex align-items-center justify-content-center">
                                    {manufacturerHubDetail?.hubName &&
                                        manufacturerHubDetail.hubName
                                            .split(' ')
                                            .map((namePart: string, k: any) => <div key={k}>{namePart[0]}</div>)}
                                </div>
                                <div className="fs-20 fw-500 primary-color ml-5 d-flex align-items-center">
                                    {manufacturerHubDetail?.hubName}
                                </div>
                            </div>
                            <Divider />
                            <Table borderless hover responsive>
                                <thead className="text-muted">
                                    <tr>
                                        <th>EMAIL</th>
                                        <th>PHONE</th>
                                        <th>LOCATION</th>
                                        <th>ADDRESS</th>
                                        <th>DATE CREATED</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{manufacturerHubDetail?.email}</td>
                                        <td>{manufacturerHubDetail?.phoneNumber}</td>
                                        <td>{manufacturerHubDetail?.location}</td>
                                        <td>{manufacturerHubDetail?.address}</td>
                                        <td>
                                            {moment(manufacturerHubDetail?.creationDate).format('Do MMM YYYY') as any}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};
