import React from 'react';

import { Attributes, UserAttributes } from '../API';
import { Manufacturer } from '../shared-types/manufacturer.model';

export const onChange: Function = (
    e: React.ChangeEvent<HTMLInputElement>,
    values: Record<string, unknown>,
    setValues: Function,
) => {
    values[e.target.name] = e.target.value;
    setValues({ ...values });

    return values;
};

export const formatNumber = (number: string, omitPlus = false): string => {
    const getLastDigits = number.slice(number.length - 9);
    return `${omitPlus ? '' : '+'}254${getLastDigits}`;
};

export const mapAttributeToManufacturer = (
    manufacturer: Record<string, any> = {},
    data: Attributes | UserAttributes | null,
) => {
    if (!data) {
        return manufacturer;
    }

    switch (data.Name) {
        case 'custom:company_name':
            manufacturer['name'] = data.Value;
            return manufacturer;
        case 'name':
            manufacturer['contactPerson'] = data.Value;
            return manufacturer;
        case 'phone_number':
            manufacturer['phoneNumber'] = data.Value;
            return manufacturer;
        case 'email':
            manufacturer['emailAddress'] = data.Value;
            return manufacturer;
        case 'custom:location':
            manufacturer['location'] = data.Value;
            return manufacturer;
        default:
            return manufacturer;
    }
};

export const mapAttributesToManufacturer = (
    record: {
        Username?: string | null;
        Enabled?: boolean | null;
        Attributes?: Array<(Attributes | UserAttributes) | null> | null;
    } | null,
) => {
    return record?.Attributes?.reduce(mapAttributeToManufacturer, {
        username: record.Username,
        enabled: record.Enabled,
        isActive: record.Enabled,
    } as Manufacturer);
};

// export const mapAttributesToSingleAdmin = ( record: {
//         Username?: string | null;
//         Enabled?: boolean | null;
//         UserStatus?: string | null;
//         UserCreateDate?: string | null | undefined;
//         Attributes?: Array<(Attributes | UserAttributes) | null> | null;
//         __typename: any;
//     } | null) => {

//     }

export const mapAttributesToAdmin = (
    record: {
        Username?: string | null;
        Enabled?: boolean | null;
        UserStatus?: string | null;
        UserCreateDate?: string | null | undefined;
        Attributes?: Array<(Attributes | UserAttributes) | null> | null;
        __typename: any;
    } | null,
) => {
    const data = record?.Attributes?.reduce(mapAttributeToAdmin, {
        username: record.Username,
        enabled: record.Enabled,
        status: record.UserStatus,
        isActive: record.Enabled,
    });
    return data;
};

export const mapAttributeToAdmin = (admin: Record<string, any> = {}, data: Attributes | UserAttributes | null) => {
    if (!data) {
        return admin;
    }

    switch (data.Name) {
        case 'given_name':
            admin['given_name'] = data.Value;
            return admin;
        case 'phone_number':
            admin['phoneNumber'] = data.Value;
            return admin;
        case 'email':
            admin['emailAddress'] = data.Value;
            return admin;
        default:
            return admin;
    }
};

export const reduceDmaList = (arr: {}[]) => {
    type DMA = Record<string, any>;
    const userAttr = arr.reduce((prev: DMA, curr: DMA): DMA => {
        switch (curr.Name) {
            case 'name':
                prev['name'] = curr.Value;
                return prev;
            case 'custom:national_id':
                prev['idNumber'] = curr.Value;
                return prev;
            case 'phone_number':
                prev['phoneNumber'] = curr.Value;
                return prev;
            case 'email':
                prev['email'] = curr.Value;
                return prev;
            case 'custom:location':
                prev['location'] = curr.Value;
                return prev;
            case 'custom:kyc_flow_state':
                prev['status'] = curr.Value;
                return prev;
            default:
                return prev;
        }
    }, {} as DMA);
    return userAttr;
};

export const getInitials = (name?: string) => {
    if (name === undefined) return 'N/A';
    if (name.includes(' ')) {
        const nameArr = name.split(' ');
        return `${nameArr[0].charAt(0)}${nameArr[1].charAt(0)}`;
    } else {
        return name.charAt(0);
    }
};

export const defaultCurrency = 'KES';

export const formatAmountWithCurrency = (amount?: string | number | null, hideValues = false) => {
    if (hideValues) return 'N/A';
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: defaultCurrency,

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(parseInt(`${amount}`));
};
