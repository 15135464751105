import './audit.css';
import 'react-datepicker/dist/react-datepicker.css';

import { Breadcrumb, Table } from 'antd';
import React, { useEffect } from 'react';
import BlockUi from 'react-block-ui';
import DatePicker from 'react-datepicker';

import { FormInput } from '../../components/inputs/FormInput';
import { PageTitle } from '../products/PageTitle';
import { getColumns, useAudit } from './helpers';

export type AuditTableDataType = {
    auditId: string | null | undefined;
    username: string | null | undefined;
    action: string | null | undefined;
    event: string | null | undefined;
    date: string | null | undefined;
};

const PAGE_SIZE = 10;

export const Audit: React.FC = () => {
    const {
        loading,
        tableData,
        fetchAuditLog,
        handleDateRangeSelection,
        startDate,
        endDate,
        filterKeyword,
        lastEvaluatedKey,
        filterCriterias,
        handleKeywordChange,
        applyFilters,
        clearFilters,
    } = useAudit();

    const { username, action, event } = filterKeyword;

    useEffect(() => {
        fetchAuditLog();
    }, []);

    return (
        <BlockUi blocking={loading}>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="">Audit</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                    <div className="row mt-4 mb-3">
                        <PageTitle title="Audit" />
                    </div>
                    <div className="row mt-4 mb-3">
                        <div className="col-md-4 mb-2">
                            <label>
                                Username
                                <FormInput
                                    className="audit-filter-input w-100"
                                    placeholder="username keyword"
                                    name="username"
                                    value={username}
                                    onChange={handleKeywordChange}
                                />
                                <small>Multiple keywords can be comma seperated</small>
                            </label>
                        </div>
                        <div className="col-md-4 mb-2">
                            <label>
                                Action
                                <FormInput
                                    className="audit-filter-input w-100"
                                    placeholder="action keyword"
                                    name="action"
                                    value={action}
                                    onChange={handleKeywordChange}
                                />
                                <small>Multiple keywords can be comma seperated</small>
                            </label>
                        </div>
                        <div className="col-md-4 mb-2">
                            <label>
                                Event
                                <FormInput
                                    className="audit-filter-input w-100"
                                    placeholder="event keyword"
                                    name="event"
                                    value={event}
                                    onChange={handleKeywordChange}
                                />
                                <small>Multiple keywords can be comma seperated</small>
                            </label>
                        </div>
                        <div className="col-md-5 flex-row d-flex justify-content-between">
                            <label>
                                From
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => handleDateRangeSelection('start', date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxDate={new Date()}
                                    dateFormat="dd/MM/yyyy"
                                    className="audit-filter-input"
                                />
                            </label>
                            <label>
                                To
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => handleDateRangeSelection('end', date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    maxDate={new Date()}
                                    dateFormat="dd/MM/yyyy"
                                    className="audit-filter-input"
                                />
                            </label>
                        </div>
                        <div className="col-md-3 ml-auto d-flex align-items-center justify-content-between">
                            <div className="primary-color fw-500 m-1 pointer" onClick={applyFilters}>
                                Apply Filters
                            </div>
                            <div className="primary-color fw-500 m-1 pointer" onClick={clearFilters}>
                                Clear Filters
                            </div>
                        </div>
                    </div>

                    <div style={{ overflowX: 'auto' }}>
                        <Table
                            className="audit-header audit-table"
                            dataSource={tableData}
                            columns={getColumns()}
                            pagination={{
                                total: lastEvaluatedKey ? tableData.length + PAGE_SIZE : tableData.length,
                                showSizeChanger: false,
                                hideOnSinglePage: true,
                                disabled: false,
                                onChange: () => {
                                    fetchAuditLog(filterCriterias);
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        </BlockUi>
    );
};
