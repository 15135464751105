/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type WardInput = {
  name: string,
  county?: string | null,
  subCounty?: string | null,
  region?: string | null,
};

export type PermissionInput = {
  fieldName?: string | null,
  alias?: string | null,
  description?: string | null,
  userLevel?: UserLevel | null,
};

export enum UserLevel {
  DMA_USER = "DMA_USER",
  MANUFACTURER_USER = "MANUFACTURER_USER",
  ADMIN_USER = "ADMIN_USER",
}


export type RoleInput = {
  rolename?: string | null,
  alias?: string | null,
  creationDate?: string | null,
  description?: string | null,
  permissions?: Array< string | null > | null,
  modificationDate?: string | null,
};

export type RespManufacturer = {
  __typename: "RespManufacturer",
  User?: UserManufacturer | null,
  code?: string | null,
  message?: string | null,
};

export type UserManufacturer = {
  __typename: "UserManufacturer",
  Username?: string | null,
  UserCreateDate?: string | null,
  UserLastModifiedDate?: string | null,
  Enabled?: boolean | null,
  UserStatus?: string | null,
  Attributes?:  Array<Attributes | null > | null,
};

export type Attributes = {
  __typename: "Attributes",
  Name?: string | null,
  Value?: string | null,
};

export type CreateAdminResponse = {
  __typename: "CreateAdminResponse",
  User?: SingleUserResponse | null,
  message?: string | null,
  code?: string | null,
};

export type SingleUserResponse = {
  __typename: "SingleUserResponse",
  Username?: string | null,
  UserCreateDate?: string | null,
  UserLastModifiedDate?: string | null,
  Enabled?: boolean | null,
  UserStatus?: string | null,
  UserAttributes?:  Array<UserAttributes | null > | null,
};

export type UserAttributes = {
  __typename: "UserAttributes",
  Name?: string | null,
  Value?: string | null,
};

export type RespCollectionCentreUser = {
  __typename: "RespCollectionCentreUser",
  User?: CollectionCentreUser | null,
  code?: string | null,
  message?: string | null,
};

export type CollectionCentreUser = {
  __typename: "CollectionCentreUser",
  Username?: string | null,
  UserCreateDate?: string | null,
  UserLastModifiedDate?: string | null,
  Enabled?: boolean | null,
  UserStatus?: string | null,
  Attributes?:  Array<CollectionCenterAttributes | null > | null,
};

export type CollectionCenterAttributes = {
  __typename: "CollectionCenterAttributes",
  Name?: string | null,
  Value?: string | null,
};

export type TempDMAInput = {
  userId: string,
  dmaType?: DmaType | null,
  businessName: string,
  phoneNumber?: string | null,
  mail: string,
  location?: string | null,
  ward?: string | null,
};

export enum DmaType {
  INDIVIDUAL = "INDIVIDUAL",
  MULTI = "MULTI",
  COOPERATIVE = "COOPERATIVE",
}


export type createTempDmaResponse = {
  __typename: "createTempDmaResponse",
  data?: string | null,
  code?: number | null,
  message?: string | null,
};

export type AuditLogInput = {
  username: string,
  action: string,
  event?: string | null,
};

export type CreateAuditResponse = {
  __typename: "CreateAuditResponse",
  code: number,
  status: ResponseStatus,
  data?: AuditLog | null,
  message?: string | null,
};

export enum ResponseStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  SUCCESSED = "SUCCESSED",
  SUCCESSFUL = "SUCCESSFUL",
}


export type AuditLog = {
  __typename: "AuditLog",
  auditId: string,
  username?: string | null,
  action?: string | null,
  date?: string | null,
  event?: string | null,
};

export type AdvertInput = {
  adcategoryId?: string | null,
  creationDate?: string | null,
  description?: string | null,
  advertId: string,
  modificationDate?: string | null,
  name: string,
  time?: string | null,
  action?: string | null,
  image?: string | null,
};

export type ResponseMessage = {
  __typename: "ResponseMessage",
  status?: ResponseStatus | null,
  code?: number | null,
  data?: string | null,
};

export type Advert = {
  __typename: "Advert",
  adcategory?: AdCategory | null,
  creationDate?: string | null,
  description?: string | null,
  advertId: string,
  modificationDate?: string | null,
  name: string,
  time?: string | null,
  action?: string | null,
  image?: string | null,
};

export type AdCategory = {
  __typename: "AdCategory",
  adcategoryId: string,
  name?: string | null,
  creationDate?: string | null,
  modificationDate?: string | null,
};

export type AdCategoryInput = {
  name?: string | null,
  creationDate?: string | null,
  modificationDate?: string | null,
};

export type AdCompanyInput = {
  adcompanyId?: string | null,
  creationDate?: string | null,
  description?: string | null,
  modificationDate?: string | null,
  name: string,
  time?: string | null,
  action?: string | null,
  image: string,
};

export type CartInput = {
  cartItems?: Array< CartItemInput | null > | null,
  dmaId?: string | null,
  userId?: string | null,
  branchId?: string | null,
  cartStatus?: CartStatus | null,
  paymentStatus?: string | null,
  paymentMethod?: string | null,
  mpesaTransactionId?: string | null,
};

export type CartItemInput = {
  product?: CartProductInput | null,
  quantity?: number | null,
};

export type CartProductInput = {
  productId?: string | null,
};

export enum CartStatus {
  PENDING = "PENDING",
  ORDERDED = "ORDERDED",
}


export type Cart = {
  __typename: "Cart",
  cartId?: string | null,
  cartItems?:  Array<CartItem | null > | null,
  dmaId?: string | null,
  userId?: string | null,
  branchId?: string | null,
  cartStatus?: CartStatus | null,
  creationDate?: string | null,
  modificationDate?: string | null,
  paymentStatus?: string | null,
  paymentMethod?: string | null,
  mpesaTransactionId?: string | null,
  total?: number | null,
};

export type CartItem = {
  __typename: "CartItem",
  product?: CartProduct | null,
  quantity?: number | null,
  total?: number | null,
};

export type CartProduct = {
  __typename: "CartProduct",
  manufacturerUserId?: string | null,
  description?: string | null,
  productId?: string | null,
  name?: string | null,
  price?: number | null,
  unit?: string | null,
  type?: string | null,
  image?: Array< string | null > | null,
  weight?: number | null,
  quantity?: number | null,
  company_name?: string | null,
  manufacturerId?: string | null,
};

export type TransporterAddFarmerDeliveryInput = {
  farmerId: string,
  collectionCenterId: string,
  containerNumber: number,
  quantity: number,
  product: string,
  unitPrice: number,
  deliveryDate: string,
};

export type TransporterAddFarmerDeliveryResponse = {
  __typename: "TransporterAddFarmerDeliveryResponse",
  data?: FarmerDelivery | null,
  code?: string | null,
  message?: string | null,
};

export type FarmerDelivery = {
  __typename: "FarmerDelivery",
  farmerId?: string | null,
  collectionCenterId?: string | null,
  transporterId?: string | null,
  deliveryDate?: string | null,
  containerNumber?: number | null,
  product?: string | null,
  quantity?: number | null,
  unitPrice?: number | null,
  status?: FarmerDeliveryStatus | null,
};

export enum FarmerDeliveryStatus {
  PICKED = "PICKED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}


export type FarmerTransactionInput = {
  farmerId?: string | null,
  phone?: string | null,
  amount?: number | null,
  transactionType?: TransactionType | null,
  paymentMethod?: PaymentMethod | null,
  transactionId?: string | null,
};

export enum TransactionType {
  CASH_ADVANCE = "CASH_ADVANCE",
  CREDIT = "CREDIT",
  PAYMENT = "PAYMENT",
}


export enum PaymentMethod {
  MPESA = "MPESA",
  CASH = "CASH",
  BANK = "BANK",
  CREDIT = "CREDIT",
}


export type AddTransactionResponse = {
  __typename: "AddTransactionResponse",
  data?: string | null,
  code?: string | null,
  message?: string | null,
};

export type DMAInput = {
  userId: string,
  dmaType?: DmaType | null,
  businessName: string,
  phoneNumber?: string | null,
  mail: string,
  location?: string | null,
  ward?: string | null,
  wardObj?: string | null,
};

export type createDmaAndMainBranchResponse = {
  __typename: "createDmaAndMainBranchResponse",
  data?: string | null,
  code?: number | null,
  message?: string | null,
};

export type BranchInput = {
  dmaId: string,
  branchName: string,
  location: string,
  isHQ: string,
  phoneNumber?: string | null,
  mail?: string | null,
  ward?: string | null,
};

export type CollectionCentreInput = {
  dmaId: string,
  centreId: string,
  centreName: string,
  location?: string | null,
};

export type RespMessage = {
  __typename: "RespMessage",
  data?: string | null,
  code?: number | null,
  message?: string | null,
};

export type AddFarmerInput = {
  dmaId?: string | null,
  national_id?: string | null,
  member_number?: string | null,
  phone: string,
  first_name?: string | null,
  middle_name?: string | null,
  last_name?: string | null,
  route?: string | null,
  supply_category?: string | null,
  MCC?: string | null,
  gender?: string | null,
  village?: string | null,
};

export type AddFarmerResponse = {
  __typename: "AddFarmerResponse",
  code?: string | null,
  message?: string | null,
  data?: Farmer | null,
};

export type Farmer = {
  __typename: "Farmer",
  farmerId?: string | null,
  dmaId?: string | null,
  national_id?: string | null,
  member_number?: string | null,
  phone?: string | null,
  first_name?: string | null,
  middle_name?: string | null,
  last_name?: string | null,
  route?: string | null,
  supply_category?: string | null,
  MCC?: string | null,
  gender?: string | null,
  village?: string | null,
};

export type CollectionCenterAddMilkDeliveryInput = {
  farmerId: string,
  collectionCenterId: string,
  transporterId?: string | null,
  containerNumber: number,
  quantity: number,
  product: string,
  unitPrice: number,
  deliveryDate: string,
};

export type CollectionCenterAddMilkDeliveryResponse = {
  __typename: "CollectionCenterAddMilkDeliveryResponse",
  data?: MilkDelivery | null,
  code?: string | null,
  message?: string | null,
};

export type MilkDelivery = {
  __typename: "MilkDelivery",
  farmerId?: string | null,
  collectionCenterId?: string | null,
  transporterId?: string | null,
  deliveryDate?: string | null,
  containerNumber?: number | null,
  product?: string | null,
  quantity?: number | null,
  unitPrice?: number | null,
  status?: MilkDeliveryStatus | null,
};

export enum MilkDeliveryStatus {
  PICKED = "PICKED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}


export type ApproveMilkDeliveryInput = {
  collectionCenterId: string,
  transporterId: string,
  deliveryTime?: string | null,
  totalQuantity?: number | null,
  approvedQuantity?: number | null,
  rejectedQuantity?: number | null,
  varianceQuantity?: number | null,
  deliveries?: Array< ApproveMilkDeliveryItem | null > | null,
};

export type ApproveMilkDeliveryItem = {
  deliveryId: string,
  deliveryDate?: string | null,
  status?: MilkDeliveryStatus | null,
};

export type ApproveMilkDeliveryResponse = {
  __typename: "ApproveMilkDeliveryResponse",
  data?: string | null,
  code?: number | null,
  message?: string | null,
};

export type UpdateMilkDeliveryInput = {
  deliveryId: string,
  deliveryDate?: string | null,
  product?: string | null,
  quantity?: number | null,
  unitPrice?: number | null,
};

export type UpdateMilkDeliveryResponse = {
  __typename: "UpdateMilkDeliveryResponse",
  code?: string | null,
  message?: string | null,
};

export type DMAPartnershipResponse = {
  __typename: "DMAPartnershipResponse",
  data?: string | null,
  code?: number | null,
  message?: string | null,
};

export enum KYCSTATUS {
  NOT_UPLOADED = "NOT_UPLOADED",
  APPROVAL_PENDING = "APPROVAL_PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}


export type updateKycStatusResponse = {
  __typename: "updateKycStatusResponse",
  data?: string | null,
  code?: number | null,
  message?: string | null,
};

export type UpdateDmaProfileInput = {
  dmaId: string,
  creditLimit?: number | null,
  paymentAccounts?: PaymentAccount | null,
  digiFarmHub?: Array< DigiFarmHubInput | null > | null,
  updateType?: UpdateType | null,
};

export type PaymentAccount = {
  payBillNumber?: string | null,
  tillNumber?: string | null,
  mpesaNumber?: string | null,
  accountNumber?: string | null,
};

export type DigiFarmHubInput = {
  manufacturerId?: string | null,
  manufacturerName?: string | null,
  hubs?: Array< HubInput | null > | null,
};

export type HubInput = {
  hubId?: string | null,
  hubName?: string | null,
};

export enum UpdateType {
  CREDITLIMIT = "CREDITLIMIT",
  PAYMENTACCOUNTS = "PAYMENTACCOUNTS",
  DIGIFARMHUB = "DIGIFARMHUB",
}


export type deleteDmaResponse = {
  __typename: "deleteDmaResponse",
  status?: ResponseStatus | null,
  code?: number | null,
  message?: string | null,
};

export type DmastockInput = {
  manufacturerUserId: string,
  productId: string,
  manufacturerName?: string | null,
  description?: string | null,
  dmaUserId?: string | null,
  dmaId?: string | null,
  branchId?: string | null,
  name: string,
  buying_price: number,
  selling_price: number,
  stockSubCategoryId: string,
  stockcategoryId: string,
  unit?: string | null,
  type?: string | null,
  image?: Array< string | null > | null,
  quantity?: number | null,
  status?: string | null,
  weight?: string | null,
};

export type StockResponseMessage = {
  __typename: "StockResponseMessage",
  status?: ResponseStatus | null,
  code?: number | null,
  data?: StockRequest | null,
  message?: string | null,
};

export type StockRequest = {
  __typename: "StockRequest",
  stockRequestId: string,
  branchId?: string | null,
  productId?: string | null,
  dmaId?: string | null,
  name?: string | null,
  type?: string | null,
  unit?: string | null,
  quantity?: number | null,
  status?: string | null,
  creationDate?: string | null,
  product?: RequestProduct | null,
  branch?: RequestBranch | null,
};

export type RequestProduct = {
  __typename: "RequestProduct",
  description?: string | null,
  productId: string,
  name: string,
  price: number,
  subCategoryName?: string | null,
  unit?: string | null,
  type?: string | null,
  image?: Array< string | null > | null,
  quantity?: number | null,
  status?: string | null,
  weight?: number | null,
  manufacturerUserId: string,
  creationDate?: string | null,
  modificationDate?: string | null,
  subCategoryId: string,
  rebates?: number | null,
  dmaPrice?: number | null,
  company_name?: string | null,
};

export type RequestBranch = {
  __typename: "RequestBranch",
  branchId: string,
  dmaId: string,
  branchName?: string | null,
  location?: string | null,
  isHQ?: string | null,
  phoneNumber?: string | null,
  mail?: string | null,
  ward?: string | null,
  creationDate?: string | null,
};

export type Dmastock = {
  __typename: "Dmastock",
  dmastockId?: string | null,
  manufacturerUserId?: string | null,
  manufacturerName?: string | null,
  dmaUserId?: string | null,
  dmaId?: string | null,
  branchId?: string | null,
  creationDate?: string | null,
  description?: string | null,
  modificationDate?: string | null,
  name: string,
  buying_price?: number | null,
  selling_price?: number | null,
  stockSubCategoryId?: string | null,
  unit?: string | null,
  type?: string | null,
  image?: Array< string | null > | null,
  quantity?: number | null,
  status?: string | null,
  weight?: string | null,
  bulkUploadProcessStatus?: string | null,
  bulkUploadProcessId?: string | null,
  rebates?: number | null,
};

export type StockRequestInput = {
  productId: string,
  dmaId?: string | null,
  branchId?: string | null,
  quantity?: number | null,
  product?: string | null,
  branch?: string | null,
};

export type FarmerOrderCreateInput = {
  farmerOrderId?: string | null,
  farmerNumber?: string | null,
  memberNumber: string,
  totalCost?: number | null,
  farmerName?: string | null,
  paymentMethods?: Array< PaymentMethod | null > | null,
  currentMethod: PaymentMethod,
  checkedItem?: Array< PaymentMethod | null > | null,
  product?: Array< FarmerProductInput | null > | null,
  dmaId?: string | null,
  dmaUserId?: string | null,
  branchId?: string | null,
  isComplete?: boolean | null,
  deliveryType?: DeliveryType | null,
  origin?: OrderOrigin | null,
};

export type FarmerProductInput = {
  dmastockId?: string | null,
  dmaUserId: string,
  dmaId?: string | null,
  productId?: string | null,
  price?: number | null,
  quantity?: number | null,
};

export enum DeliveryType {
  DELIVERY = "DELIVERY",
  PICKUP = "PICKUP",
}


export enum OrderOrigin {
  ONSITE = "ONSITE",
  OFFSITE = "OFFSITE",
}


export type FarmerOrderResponse = {
  __typename: "FarmerOrderResponse",
  status?: ResponseStatus | null,
  code?: number | null,
  data?: FarmerOrder | null,
  message?: string | null,
};

export type FarmerOrder = {
  __typename: "FarmerOrder",
  farmerOrderId?: string | null,
  memberNumber?: string | null,
  farmerName?: string | null,
  totalCost?: string | null,
  paymentMethod?: PaymentMethod | null,
  product?:  Array<FarmerOrderProductDetailed | null > | null,
  creationDate?: string | null,
  paymentStatus?: PaymentStatus | null,
  farmerOrderStatus?: string | null,
  dmaName?: string | null,
  dmaUserId?: string | null,
  dmaId?: string | null,
  branchId?: string | null,
  mpesaTransactionId?: string | null,
};

export type FarmerOrderProductDetailed = {
  __typename: "FarmerOrderProductDetailed",
  quantity?: number | null,
  product?: Dmastock | null,
  price?: number | null,
  selling_price?: number | null,
};

export enum PaymentStatus {
  PAID = "PAID",
  PENDING = "PENDING",
  FAILED = "FAILED",
  UNKNOWN = "UNKNOWN",
}


export type FarmerOrderUpdateInput = {
  farmerNumber?: string | null,
  memberNumber?: string | null,
  totalCost?: number | null,
  farmerName?: string | null,
  paymentMethod?: PaymentMethod | null,
  paymentStatus?: PaymentStatus | null,
  product?: Array< FarmerProductInput | null > | null,
  dmaId?: string | null,
  dmaUserId?: string | null,
  branchId?: string | null,
};

export type DmaBulkUploadStockInput = {
  name: string,
  selling_price: number,
  unit?: string | null,
  quantity?: number | null,
  rebates?: number | null,
  status?: string | null,
};

export type AddDmaBulkUploadStockInput = {
  dmaId: string,
  branchId: string,
  name: string,
  selling_price: number,
  unit?: string | null,
  quantity?: number | null,
  rebates?: number | null,
  status?: string | null,
};

export type DmaStockReonciliationRecordInput = {
  dmaStockId: string,
  dmaId: string,
  dmaBranchId: string,
  creditedCount: number,
  verifiedCount: number,
  purchasedCount: number,
  collectedCount: number,
};

export type ManufacturerInput = {
  company_name: string,
  location: string,
  name: string,
  phoneNumber?: string | null,
  address?: string | null,
  email: string,
};

export type CreateManufacturerAndMainHubResponseDTO = {
  __typename: "CreateManufacturerAndMainHubResponseDTO",
  data?: ManufacturerResponse | null,
  code?: number | null,
  message?: string | null,
};

export type ManufacturerResponse = {
  __typename: "ManufacturerResponse",
  User?: UserManufacturer | null,
  companyName?: string | null,
  location?: string | null,
  name?: string | null,
  phoneNumber?: string | null,
  address?: string | null,
  email?: string | null,
};

export type CreateHubInput = {
  manufacturerId: string,
  location: string,
  name: string,
  phoneNumber?: string | null,
  emailAddress: string,
  address?: string | null,
};

export type CreateHubResponseDTO = {
  __typename: "CreateHubResponseDTO",
  data?: HubResponse | null,
  code?: number | null,
  message?: string | null,
};

export type HubResponse = {
  __typename: "HubResponse",
  location?: string | null,
  hubName?: string | null,
  phoneNumber?: string | null,
  email?: string | null,
  hubId?: string | null,
  manufacturerId?: string | null,
  address?: string | null,
  creationDate?: string | null,
  modificationDate?: string | null,
};

export type OrderInput = {
  manufacturerId?: string | null,
  dmaId?: string | null,
  branchId?: string | null,
  orderStatus?: string | null,
  paymentStatus?: string | null,
  paymentMethod?: string | null,
  orderItems?: Array< OrderItemInput | null > | null,
};

export type OrderItemInput = {
  product?: OrderProductInput | null,
  quantity?: number | null,
  availableQuantity?: number | null,
  receivedQuantity?: number | null,
  total?: number | null,
};

export type OrderProductInput = {
  productId: string,
};

export type UpdateOrderInput = {
  orderStatus?: string | null,
  paymentStatus?: string | null,
  paymentMethod?: string | null,
  orderItems?: Array< OrderItemInput | null > | null,
};

export type Order = {
  __typename: "Order",
  orderId?: string | null,
  manufacturerId?: string | null,
  dmaId?: string | null,
  branchId?: string | null,
  orderItems?:  Array<OrderItem | null > | null,
  orderStatus?: string | null,
  paymentStatus?: string | null,
  paymentMethod?: string | null,
  creationDate?: string | null,
  modificationDate?: string | null,
  rebates?: number | null,
};

export type OrderItem = {
  __typename: "OrderItem",
  product?: OrderProduct | null,
  quantity?: number | null,
  availableQuantity?: number | null,
  receivedQuantity?: number | null,
  total?: number | null,
};

export type OrderProduct = {
  __typename: "OrderProduct",
  productId?: string | null,
  manufacturerUserId?: string | null,
  description?: string | null,
  name?: string | null,
  company_name?: string | null,
  price?: number | null,
  unit?: string | null,
  type?: string | null,
  image?: Array< string | null > | null,
  weight?: number | null,
  quantity?: number | null,
};

export type PushStkInput = {
  dmaId: string,
  branchId: string,
  userAccountId: string,
  mobileNumber?: string | null,
  amount: number,
  cartId?: string | null,
  farmerNumber?: string | null,
  farmerOrderId?: string | null,
};

export type MpesaResponse = {
  __typename: "MpesaResponse",
  MerchantRequestID?: string | null,
  CheckoutRequestID?: string | null,
  ResponseCode?: string | null,
  ResponseDescription?: string | null,
  CustomerMessage?: string | null,
  requestId?: string | null,
  errorCode?: string | null,
  errorMessage?: string | null,
};

export type B2BInput = {
  deliveryId: string,
  businessShortCode: string,
  amount: number,
  buyerId: string,
};

export type ProductInput = {
  manufacturerUserId?: string | null,
  manufacturerId: string,
  hubId: string,
  description?: string | null,
  name: string,
  price: number,
  subCategoryId: string,
  unit?: string | null,
  type?: string | null,
  image?: Array< string | null > | null,
  quantity?: number | null,
  status?: string | null,
  weight?: number | null,
  rebates?: number | null,
  dmaPrice?: number | null,
  company_name?: string | null,
};

export type Product = {
  __typename: "Product",
  manufacturerUserId?: string | null,
  creationDate?: string | null,
  description?: string | null,
  productId: string,
  modificationDate?: string | null,
  name: string,
  price: number,
  subCategoryId: string,
  subCategoryName?: string | null,
  unit?: string | null,
  type?: string | null,
  image?: Array< string | null > | null,
  quantity?: number | null,
  status?: string | null,
  weight?: number | null,
  rebates?: number | null,
  dmaPrice?: number | null,
  company_name?: string | null,
  manufacturerId: string,
  hubId: string,
};

export type CategoryInput = {
  name: string,
  image?: string | null,
};

export type Category = {
  __typename: "Category",
  categoryId: string,
  name?: string | null,
  subCategory?:  Array<SubCategory | null > | null,
  creationDate?: string | null,
  modificationDate?: string | null,
  image?: string | null,
};

export type SubCategory = {
  __typename: "SubCategory",
  subCategoryId: string,
  categoryId: string,
  name?: string | null,
  creationDate?: string | null,
  modificationDate?: string | null,
  image?: string | null,
};

export type SubCategoryInput = {
  categoryId: string,
  name: string,
  image?: string | null,
};

export type Ward = {
  __typename: "Ward",
  name?: string | null,
  wardId?: string | null,
  county?: string | null,
  subcounty?: string | null,
  region?: string | null,
  creationDate?: string | null,
  description?: string | null,
  modificationDate?: string | null,
};

export type Role = {
  __typename: "Role",
  rolename?: string | null,
  alias?: string | null,
  rolesId?: string | null,
  permissions?:  Array<Permission | null > | null,
  creationDate?: string | null,
  description?: string | null,
  modificationDate?: string | null,
};

export type Permission = {
  __typename: "Permission",
  fieldName?: string | null,
  alias?: string | null,
  permissionId?: string | null,
  creationDate?: string | null,
  description?: string | null,
  modificationDate?: string | null,
  userLevel?: UserLevel | null,
};

export type DMARole = {
  __typename: "DMARole",
  rolename?: string | null,
  alias?: string | null,
  dmaRolesId?: string | null,
  permissions?:  Array<Permission | null > | null,
  creationDate?: string | null,
  description?: string | null,
  modificationDate?: string | null,
};

export type PermissionResponse = {
  __typename: "PermissionResponse",
  data?:  Array<Permission | null > | null,
  code?: string | null,
  message?: string | null,
};

export type UsersResponse = {
  __typename: "UsersResponse",
  Locations?: Array< string | null > | null,
  Users?:  Array<Users | null > | null,
  message?: string | null,
};

export type Users = {
  __typename: "Users",
  Username?: string | null,
  UserCreateDate?: string | null,
  UserLastModifiedDate?: string | null,
  Enabled?: boolean | null,
  UserStatus?: string | null,
  Attributes?:  Array<Attributes | null > | null,
};

export type WriteDocumentResponse = {
  __typename: "WriteDocumentResponse",
  url?: string | null,
  expires_in?: number | null,
  fields?: string | null,
};

export type AdminsListResponse = {
  __typename: "AdminsListResponse",
  Users?:  Array<Users | null > | null,
  PaginationToken?: string | null,
};

export type AdminsInGroupResponse = {
  __typename: "AdminsInGroupResponse",
  Users?:  Array<Users | null > | null,
  NextToken?: string | null,
};

export type GetAdminAccountResp = {
  __typename: "GetAdminAccountResp",
  Username?: string | null,
  UserCreateDate?: string | null,
  UserLastModifiedDate?: string | null,
  Enabled?: boolean | null,
  UserStatus?: string | null,
  UserAttributes?:  Array<UserAttributes | null > | null,
  roles?: Array< string | null > | null,
  permissions?:  Array<Permission | null > | null,
};

export type FarmerDetailsResponse = {
  __typename: "FarmerDetailsResponse",
  farmer_id?: string | null,
  member_number?: string | null,
  first_name?: string | null,
  middle_name?: string | null,
  last_name?: string | null,
  phone_number?: string | null,
};

export type AccountDashboard = {
  __typename: "AccountDashboard",
  dma?: number | null,
  manufacturer?: number | null,
  unassigned?: number | null,
  name?: string | null,
};

export type manufacturerStatisticsResponse = {
  __typename: "manufacturerStatisticsResponse",
  manToday?: string | null,
  manMonthly?:  Array<StatsMonthly | null > | null,
};

export type StatsMonthly = {
  __typename: "StatsMonthly",
  monthLong?: string | null,
  monthShort?: string | null,
  creationDateFrom?: string | null,
  creationDateTo?: string | null,
  manStats?: string | null,
  monthStats?: number | null,
};

export type GetTempDmaResponse = {
  __typename: "GetTempDmaResponse",
  data?: string | null,
  code?: number | null,
  message?: string | null,
};

export type FilterCriteria = {
  key: string,
  operation: FilterCriteriaOperation,
  value: string,
};

export enum FilterCriteriaOperation {
  IS = "IS",
  GTE = "GTE",
  LTE = "LTE",
}


export type ListAuditResponse = {
  __typename: "ListAuditResponse",
  code: number,
  message?: string | null,
  data?:  Array<AuditLog | null > | null,
  lastEvaluatedKey?: string | null,
};

export type PaginatedAdCategories = {
  __typename: "PaginatedAdCategories",
  adcategories?:  Array<AdCategory | null > | null,
  nextToken?: string | null,
};

export type AdCompany = {
  __typename: "AdCompany",
  adcompanyId: string,
  adcategory?: AdCategory | null,
  creationDate?: string | null,
  description?: string | null,
  modificationDate?: string | null,
  name: string,
  time?: string | null,
  action?: string | null,
  image?: string | null,
};

export type DMAResponse = {
  __typename: "DMAResponse",
  data?: DMA | null,
  code?: string | null,
  message?: string | null,
};

export type DMA = {
  __typename: "DMA",
  userId?: string | null,
  dmaId?: string | null,
  dmaType: DmaType,
  businessName: string,
  phoneNumber?: string | null,
  mail: string,
  location?: string | null,
  ward?: string | null,
  wardObj?: string | null,
  kyc_flow_state?: string | null,
  paymentAccounts?: PaymentAccounts | null,
  digiFarmHub?:  Array<DigiFarmHub | null > | null,
  creditLimit?: number | null,
  creationDate?: string | null,
  modificationDate?: string | null,
};

export type PaymentAccounts = {
  __typename: "PaymentAccounts",
  payBillNumber?: string | null,
  tillNumber?: string | null,
  mpesaNumber?: string | null,
  accountNumber?: string | null,
};

export type DigiFarmHub = {
  __typename: "DigiFarmHub",
  manufacturerId?: string | null,
  manufacturerName?: string | null,
  hubs?:  Array<Hub | null > | null,
};

export type Hub = {
  __typename: "Hub",
  hubId?: string | null,
  hubName?: string | null,
};

export type SingleBranchResponse = {
  __typename: "SingleBranchResponse",
  data?: Branch | null,
  code?: string | null,
  message?: string | null,
};

export type Branch = {
  __typename: "Branch",
  branchId: string,
  dmaId: string,
  branchName?: string | null,
  location?: string | null,
  isHQ?: string | null,
  phoneNumber?: string | null,
  mail?: string | null,
  ward?: string | null,
  creationDate?: string | null,
};

export type BranchResponse = {
  __typename: "BranchResponse",
  data?:  Array<Branch | null > | null,
  code?: string | null,
  message?: string | null,
};

export type BranchUsersResponse = {
  __typename: "BranchUsersResponse",
  data?:  Array<User | null > | null,
  code?: string | null,
  message?: string | null,
};

export type User = {
  __typename: "User",
  dmaId: string,
  branchId?: string | null,
  collectionCentreId?: string | null,
  national_id?: string | null,
  username?: string | null,
  email_verified?: boolean | null,
  phone_number_verified?: boolean | null,
  group?: string | null,
  kyc_flow_state?: string | null,
  location?: string | null,
  name?: string | null,
  phone_number?: string | null,
  email?: string | null,
  roles?:  Array<Group | null > | null,
  enabled?: string | null,
  userStatus?: string | null,
};

export type Group = {
  __typename: "Group",
  name: string,
  description?: string | null,
  creationDate?: string | null,
  modificationDate?: string | null,
};

export type FarmerTransaction = {
  __typename: "FarmerTransaction",
  farmerId?: string | null,
  amount?: number | null,
  transactionType?: TransactionType | null,
  paymentMethod?: PaymentMethod | null,
  transactionId?: string | null,
  transactionDate?: string | null,
};

export type UserDetailsResponse = {
  __typename: "UserDetailsResponse",
  data?: UserDetail | null,
  code?: string | null,
  message?: string | null,
};

export type UserDetail = {
  __typename: "UserDetail",
  userDetail?: User | null,
  dmaDetail?: DMA | null,
  dmaBranchDetail?:  Array<Branch | null > | null,
};

export type GetFarmerResponse = {
  __typename: "GetFarmerResponse",
  farmerDetails?: Farmer | null,
  farmerFinancialDetails?: FarmerFinancial | null,
  todayFarmerDeliveryDetails?: CollectedMilkQuantity | null,
  currentMonthFarmerTransactions?:  Array<FarmerTransaction | null > | null,
  currentMonthFarmerDelivery?:  Array<FarmerDelivery | null > | null,
};

export type FarmerFinancial = {
  __typename: "FarmerFinancial",
  farmerId?: string | null,
  avg_income?: string | null,
  limit?: string | null,
  df_exclusions?: string | null,
  segment?: string | null,
  score?: string | null,
  income_proxy_variable?: string | null,
  df_score_band?: string | null,
  df_income_proxy?: string | null,
  currentMonthRemainingCashAdvanceLimit?: number | null,
  currentMonthRemainingCreditLimit?: number | null,
  repayment?: number | null,
  currentMonthTotalCashEntitlement?: number | null,
};

export type CollectedMilkQuantity = {
  __typename: "CollectedMilkQuantity",
  morning?: number | null,
  evening?: number | null,
};

export type GetFarmerCashAdvanceLimitResponse = {
  __typename: "GetFarmerCashAdvanceLimitResponse",
  first_name?: string | null,
  middle_name?: string | null,
  last_name?: string | null,
  member_number?: string | null,
  phone?: string | null,
  cooperative?: string | null,
  cashAdvanceEntitlement?: number | null,
  code?: string | null,
  message?: string | null,
};

export type GetFarmerCreditLimitResponse = {
  __typename: "GetFarmerCreditLimitResponse",
  first_name?: string | null,
  middle_name?: string | null,
  last_name?: string | null,
  member_number?: string | null,
  phone?: string | null,
  cooperative?: string | null,
  limit?: number | null,
  code?: string | null,
  message?: string | null,
};

export type CollectionCentresResponse = {
  __typename: "CollectionCentresResponse",
  data?:  Array<CollectionCentre | null > | null,
  code?: number | null,
  message?: string | null,
};

export type CollectionCentre = {
  __typename: "CollectionCentre",
  collectionCentreId: string,
  centreId: string,
  dmaId: string,
  centreName?: string | null,
  location?: string | null,
  creationDate?: string | null,
};

export type CollectionCentreResponse = {
  __typename: "CollectionCentreResponse",
  data?: CollectionCentre | null,
  code?: number | null,
  message?: string | null,
};

export type BranchUserResponse = {
  __typename: "BranchUserResponse",
  data?: User | null,
  code?: string | null,
  message?: string | null,
};

export type TransporterGetTodayCollectedMilkQuantityResponse = {
  __typename: "TransporterGetTodayCollectedMilkQuantityResponse",
  data?: CollectedMilkQuantity | null,
  code?: string | null,
  message?: string | null,
};

export type VerifyFarmerInput = {
  phone?: string | null,
  member_number?: string | null,
  dmaId?: string | null,
};

export type VerifyFarmerResponse = {
  __typename: "VerifyFarmerResponse",
  code?: string | null,
  message?: string | null,
};

export type GetFarmerByPhone = {
  __typename: "GetFarmerByPhone",
  code?: string | null,
  message?: string | null,
  data?: Farmer | null,
};

export type FarmerGetTodayCollectedMilkQuantityResponse = {
  __typename: "FarmerGetTodayCollectedMilkQuantityResponse",
  data?: CollectedMilkQuantity | null,
  code?: string | null,
  message?: string | null,
};

export type getDeliveryApprovalsResponse = {
  __typename: "getDeliveryApprovalsResponse",
  data?: string | null,
  code?: number | null,
  message?: string | null,
};

export type PaginatedDmaResponse = {
  __typename: "PaginatedDmaResponse",
  data?:  Array<DMA | null > | null,
  code?: string | null,
  message?: string | null,
  lastKey?: string | null,
};

export type DmaDashbaord = {
  __typename: "DmaDashbaord",
  dmasales?: number | null,
};

export enum OrderType {
  PREORDER = "PREORDER",
  ORDER = "ORDER",
}


export type USSDFarmerOrderResponse = {
  __typename: "USSDFarmerOrderResponse",
  header?: USSDFarmerOrderHeader | null,
  body?: USSDFarmerOrderBody | null,
  responseID?: string | null,
  responseCode?: string | null,
  responseDesc?: string | null,
  status?: string | null,
  version?: string | null,
  lastModified?: string | null,
};

export type USSDFarmerOrderHeader = {
  __typename: "USSDFarmerOrderHeader",
  requestRefId?: string | null,
  responseCode?: number | null,
  responseMessage?: string | null,
  customerMessage?: string | null,
  timestamp?: string | null,
};

export type USSDFarmerOrderBody = {
  __typename: "USSDFarmerOrderBody",
  numberOfPages?: number | null,
  hasNextPage?: boolean | null,
  hasPreviousPage?: boolean | null,
  totalNumberOfElements?: number | null,
  data?:  Array<USSDFarmerOrderData | null > | null,
};

export type USSDFarmerOrderData = {
  __typename: "USSDFarmerOrderData",
  orderId?: string | null,
  farmerMsisdn?: string | null,
  sessionId?: string | null,
  dmaShopName?: string | null,
  dmaFirstName?: string | null,
  dmaLastName?: string | null,
  dmaMsisdn?: string | null,
  region?: string | null,
  county?: string | null,
  subCounty?: string | null,
  ward?: string | null,
  orderType?: string | null,
  preOrderDuration?: string | null,
  preOrderDeliveryDate?: string | null,
  orderTotalCost?: number | null,
  orderPaymentStatus?: string | null,
  orderPaymentTransactionId?: string | null,
  orderTotalRebateCost?: number | null,
  rebatePaymentStatus?: string | null,
  rebatePaymentTransactionId?: string | null,
  createdDateTime?: string | null,
  updatedDateTime?: string | null,
  farmerModel?: USSDFarmerOrderFarmerModel | null,
  orderInputs?:  Array<USSDFarmerOrderOrderInputs | null > | null,
};

export type USSDFarmerOrderFarmerModel = {
  __typename: "USSDFarmerOrderFarmerModel",
  farmerId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  memberNumber?: string | null,
};

export type USSDFarmerOrderOrderInputs = {
  __typename: "USSDFarmerOrderOrderInputs",
  orderInputId?: number | null,
  inputId?: number | null,
  inputName?: string | null,
  packageQuantity?: string | null,
  price?: number | null,
  rebate?: number | null,
  inputCategory?: string | null,
  valueChain?: string | null,
  orderedQty?: number | null,
  totalInputCost?: number | null,
  totalRebateCost?: number | null,
};

export type DmastockUpdateHistory = {
  __typename: "DmastockUpdateHistory",
  dmastockId: string,
  name: string,
  bulkUploadProcessStatus: string,
  updateHistory?:  Array<UpdateHistory | null > | null,
};

export type UpdateHistory = {
  __typename: "UpdateHistory",
  user?: string | null,
  modificationDate?: string | null,
  newVersion?: UpdateHistoryVersion | null,
  oldVersion?: UpdateHistoryVersion | null,
};

export type UpdateHistoryVersion = {
  __typename: "UpdateHistoryVersion",
  name?: string | null,
  quantity?: number | null,
  rebates?: number | null,
  selling_price?: number | null,
  unit?: string | null,
  buying_price?: number | null,
  bulkUploadProcessStatus?: string | null,
  bulkUploadProcessId?: string | null,
};

export type FetchDmaStockReconciliationRecordResponse = {
  __typename: "FetchDmaStockReconciliationRecordResponse",
  data?:  Array<DmaStockReconciliationRecord > | null,
  code: number,
  message?: string | null,
};

export type DmaStockReconciliationRecord = {
  __typename: "DmaStockReconciliationRecord",
  dmastockId: string,
  dmaId: string,
  branchId: string,
  creditedCount: number,
  verifiedCount: number,
  purchasedCount: number,
  collectedCount: number,
  dma: string,
  branch: string,
  product: string,
  date: string,
  user: string,
};

export type HubDetailsResponseDTO = {
  __typename: "HubDetailsResponseDTO",
  data?: HubResponse | null,
  code?: number | null,
  message?: string | null,
};

export type HubsListResponseDTO = {
  __typename: "HubsListResponseDTO",
  data?:  Array<HubResponse | null > | null,
  code?: number | null,
  message?: string | null,
};

export type DashboardReport = {
  __typename: "DashboardReport",
  manufacturersales?: number | null,
};

export type MpesaQueryResponse = {
  __typename: "MpesaQueryResponse",
  MerchantRequestID?: string | null,
  CheckoutRequestID?: string | null,
  ResponseCode?: string | null,
  ResultDesc?: string | null,
  ResponseDescription?: string | null,
  ResultCode?: string | null,
  requestId?: string | null,
  errorCode?: string | null,
  errorMessage?: string | null,
};

export type Payment = {
  __typename: "Payment",
  CheckoutRequestID?: string | null,
  MerchantRequestID?: string | null,
  ResultCode?: number | null,
  ResultDesc?: string | null,
  buyerId?: string | null,
  creationDate?: string | null,
  deliveryId?: string | null,
  CallbackMetadata?: MetaData | null,
};

export type MetaData = {
  __typename: "MetaData",
  Item?:  Array<MetaDataList | null > | null,
};

export type MetaDataList = {
  __typename: "MetaDataList",
  Name?: string | null,
  Value?: string | null,
};

export type SearchOrderResult = {
  __typename: "SearchOrderResult",
  totalCount?: number | null,
  page?: number | null,
  result?:  Array<NestedOrders | null > | null,
  aggregations?: string | null,
};

export type NestedOrders = {
  __typename: "NestedOrders",
  orderId?: string | null,
  dmaId?: string | null,
  userId?: string | null,
  dmaName?: string | null,
  dmaLocation?: string | null,
  dmaPhone?: string | null,
  manufacturerId?: string | null,
  orderStatus?: string | null,
  paymentStatus?: string | null,
  paymentMethod?: string | null,
  mpesaTransactionId?: string | null,
  creationDate?: string | null,
  orderItems?:  Array<NestedOrderItem | null > | null,
  company_name?: string | null,
  rebates?: number | null,
  dma?: NestedOrderDmaDetailResult | null,
  hub?: NestedOrderHubDetailResult | null,
};

export type NestedOrderItem = {
  __typename: "NestedOrderItem",
  product?: NestedOrderProduct | null,
  quantity?: number | null,
  availableQuantity?: number | null,
  receivedQuantity?: number | null,
  total?: number | null,
};

export type NestedOrderProduct = {
  __typename: "NestedOrderProduct",
  productId?: string | null,
  manufacturerUserId?: string | null,
  description?: string | null,
  company_name?: string | null,
  name?: string | null,
  price?: number | null,
  unit?: string | null,
  type?: string | null,
  image?: Array< string | null > | null,
  weight?: number | null,
  quantity?: number | null,
};

export type NestedOrderDmaDetailResult = {
  __typename: "NestedOrderDmaDetailResult",
  businessName?: string | null,
  dmaType?: string | null,
  location?: string | null,
  phoneNumber?: string | null,
  ward?: string | null,
  creationDate?: string | null,
};

export type NestedOrderHubDetailResult = {
  __typename: "NestedOrderHubDetailResult",
  hubName?: string | null,
  hubId?: string | null,
};

export type SearchProductResult = {
  __typename: "SearchProductResult",
  totalCount?: number | null,
  page?: number | null,
  result?:  Array<NestedProducts | null > | null,
  aggregations?: string | null,
};

export type NestedProducts = {
  __typename: "NestedProducts",
  creationDate?: string | null,
  description?: string | null,
  productId: string,
  modificationDate?: string | null,
  name: string,
  price: number,
  subCategoryId: string,
  unit?: string | null,
  type?: string | null,
  image?: Array< string | null > | null,
  quantity?: number | null,
  status?: string | null,
  manufacturerUserId?: string | null,
  weight?: number | null,
  category?: string | null,
  subCategory?: string | null,
  company_name?: string | null,
  dmaPrice?: number | null,
  rebates?: number | null,
  manufacturerId?: string | null,
  hubId?: string | null,
};

export type SearchDmastockResult = {
  __typename: "SearchDmastockResult",
  totalCount?: number | null,
  page?: number | null,
  result?:  Array<DmastockResult | null > | null,
  aggregations?: string | null,
};

export type DmastockResult = {
  __typename: "DmastockResult",
  selling_price?: number | null,
  dmaUserId?: string | null,
  dmastockId?: string | null,
  buying_price?: number | null,
  productId?: string | null,
  name?: string | null,
  quantity?: string | null,
  image?: Array< string | null > | null,
  description?: string | null,
  manufacturerUserId?: string | null,
  manufacturerName?: string | null,
  creationDate?: string | null,
  stockSubCategoryId?: string | null,
  stockcategoryId?: string | null,
  id?: string | null,
  weight?: string | null,
  type?: string | null,
  unit?: string | null,
  bulkUploadProcessStatus?: string | null,
  bulkUploadProcessId?: string | null,
};

export enum BulkUploadStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}


export type SearchFarmerOrderResult = {
  __typename: "SearchFarmerOrderResult",
  totalCount?: number | null,
  page?: number | null,
  result?:  Array<NestedFarmerOrders | null > | null,
  aggregations?: string | null,
};

export type NestedFarmerOrders = {
  __typename: "NestedFarmerOrders",
  farmerOrderId?: string | null,
  memberNumber?: string | null,
  dmaId?: string | null,
  dmaName?: string | null,
  rebates?: number | null,
  branchId?: string | null,
  paymentMethod?: string | null,
  farmerOrderStatus?: string | null,
  deliveryType?: string | null,
  origin?: string | null,
  creationDate?: string | null,
  farmerName?: string | null,
  farmerNumber?: string | null,
  paymentStatus?: string | null,
  totalCost?: string | null,
  id?: string | null,
  mpesaTransactionId?: string | null,
  product?:  Array<ResultFarmerOrderProduct | null > | null,
};

export type ResultFarmerOrderProduct = {
  __typename: "ResultFarmerOrderProduct",
  quantity?: number | null,
  total?: string | null,
  product?: FarmerOrderProduct | null,
};

export type FarmerOrderProduct = {
  __typename: "FarmerOrderProduct",
  quantity?: number | null,
  dmastockId?: string | null,
  price?: number | null,
  selling_price?: number | null,
  productId?: string | null,
  manufacturerName?: string | null,
  buying_price?: number | null,
  weight?: string | null,
  manufacturerUserId?: string | null,
  description?: string | null,
  creationDate?: string | null,
  type?: string | null,
  unit?: string | null,
  total?: string | null,
  dmaUserId?: string | null,
  company_name?: string | null,
  name?: string | null,
  status?: string | null,
  image?: Array< string | null > | null,
};

export type SearchFarmerDeliveryResult = {
  __typename: "SearchFarmerDeliveryResult",
  totalCount?: number | null,
  page?: number | null,
  result?:  Array<FarmerDeliveryResult | null > | null,
  aggregations?: string | null,
};

export type FarmerDeliveryResult = {
  __typename: "FarmerDeliveryResult",
  product?: string | null,
  collectionCenterId?: string | null,
  containerNumber?: number | null,
  deliveryDate?: string | null,
  deliveryId?: string | null,
  status?: string | null,
  unitPrice?: number | null,
  quantity?: number | null,
  transporterId?: string | null,
  farmerId?: string | null,
  transporter?: TransporterResult | null,
  farmer?: FarmerResult | null,
  collectionCentre?: CollectionCentreResult | null,
};

export type TransporterResult = {
  __typename: "TransporterResult",
  name?: string | null,
  location?: string | null,
  phone?: string | null,
};

export type FarmerResult = {
  __typename: "FarmerResult",
  member_number?: string | null,
  route?: string | null,
  supply_category?: string | null,
  MCC?: string | null,
  gender?: string | null,
  farmerId?: string | null,
  last_name?: string | null,
  first_name?: string | null,
  village?: string | null,
  dmaId?: string | null,
  national_id?: string | null,
  phone?: string | null,
  creationDate?: string | null,
};

export type CollectionCentreResult = {
  __typename: "CollectionCentreResult",
  location?: string | null,
  collectionCentreId?: string | null,
  creationDate?: string | null,
  centreName?: string | null,
  dmaId?: string | null,
  centreId?: string | null,
};

export type SearchFarmerResult = {
  __typename: "SearchFarmerResult",
  totalCount?: number | null,
  page?: number | null,
  result?:  Array<FarmerResult | null > | null,
  aggregations?: string | null,
  code?: number | null,
  message?: string | null,
};

export type SearchRequeststockResult = {
  __typename: "SearchRequeststockResult",
  totalCount?: number | null,
  page?: number | null,
  result?:  Array<RequestStockResult | null > | null,
  aggregations?: string | null,
};

export type RequestStockResult = {
  __typename: "RequestStockResult",
  stockRequestId?: string | null,
  branchId?: string | null,
  productId?: string | null,
  dmaId?: string | null,
  name?: string | null,
  type?: string | null,
  unit?: string | null,
  quantity?: number | null,
  status?: string | null,
  creationDate?: string | null,
  product?: RequestProduct | null,
  branch?: RequestBranch | null,
};

export type dmaSalesStatisticsResponse = {
  __typename: "dmaSalesStatisticsResponse",
  dmaSales?: string | null,
  topDmaSellers?:  Array<TopDmaSeller | null > | null,
  monthlyDmaSales?:  Array<SalesMonthly | null > | null,
};

export type TopDmaSeller = {
  __typename: "TopDmaSeller",
  dmaId?: string | null,
  dmaName?: string | null,
  totalSalesAmount?: number | null,
  totalItemsSold?: number | null,
};

export type SalesMonthly = {
  __typename: "SalesMonthly",
  monthLong?: string | null,
  monthShort?: string | null,
  creationDateFrom?: string | null,
  creationDateTo?: string | null,
  monthSales?: number | null,
};

export type manSalesStatisticsResponse = {
  __typename: "manSalesStatisticsResponse",
  manSales?: string | null,
  monthlyManufacturerSales?:  Array<SalesMonthly | null > | null,
};

export type dmaStatisticsResponse = {
  __typename: "dmaStatisticsResponse",
  dmaStats?: string | null,
  monthlyDmaStats?:  Array<StatsMonthly | null > | null,
};

export type AdminDmaMonthlyStatisticsResponse = {
  __typename: "AdminDmaMonthlyStatisticsResponse",
  monthlySales?:  Array<MonthlySales | null > | null,
};

export type MonthlySales = {
  __typename: "MonthlySales",
  monthLong?: string | null,
  monthShort?: string | null,
  creationDateFrom?: string | null,
  creationDateTo?: string | null,
  monthSales?: number | null,
};

export type DmaMonthlyStatisticsResponse = {
  __typename: "DmaMonthlyStatisticsResponse",
  monthlySales?:  Array<MonthlySales | null > | null,
  salesAnalysis?: SumSummary | null,
  purchaseAnalysis?:  Array<MonthlySales | null > | null,
  bestSellingProducts?:  Array<BestSellingProducts | null > | null,
  monthlyInputPerformance?:  Array<MonthlyInputPerformance | null > | null,
};

export type SumSummary = {
  __typename: "SumSummary",
  total?: number | null,
  approved?: number | null,
  rejected?: number | null,
  pending?: number | null,
};

export type BestSellingProducts = {
  __typename: "BestSellingProducts",
  key?: string | null,
  doc_count?: number | null,
  product?: Dmastock | null,
};

export type MonthlyInputPerformance = {
  __typename: "MonthlyInputPerformance",
  monthLong?: string | null,
  monthShort?: string | null,
  creationDateFrom?: string | null,
  creationDateTo?: string | null,
  monthPerformance?:  Array<MonthPerformance | null > | null,
};

export type MonthPerformance = {
  __typename: "MonthPerformance",
  key?: string | null,
  doc_count?: number | null,
};

export type DmaDailyStatisticsResponse = {
  __typename: "DmaDailyStatisticsResponse",
  paidOrdersCountToday?: number | null,
  newOrdersCountToday?: SumSummary | null,
  totalSalesToday?: number | null,
  totalSalesCurrentWeek?:  Array<TotalSalesCurrentWeek | null > | null,
};

export type TotalSalesCurrentWeek = {
  __typename: "TotalSalesCurrentWeek",
  date?: string | null,
  dayName?: string | null,
  daySales?: number | null,
};

export type ManufacturerMonthlyStatisticsResponse = {
  __typename: "ManufacturerMonthlyStatisticsResponse",
  monthlySales?:  Array<MonthlySales | null > | null,
  salesAnalysis?: SumSummary | null,
  bestSellingProducts?:  Array<BestSellingProducts | null > | null,
  monthlyInputPerformance?:  Array<MonthlyInputPerformance | null > | null,
};

export type ManufacturerDailyStatisticsResponse = {
  __typename: "ManufacturerDailyStatisticsResponse",
  paidOrdersCountToday?: number | null,
  newOrdersCountToday?: SumSummary | null,
  totalSalesToday?: number | null,
  totalSalesCurrentWeek?:  Array<TotalSalesCurrentWeek | null > | null,
};

export type SearchManufacturerResult = {
  __typename: "SearchManufacturerResult",
  data?: ManufacturerResult | null,
  code?: number | null,
  message?: string | null,
};

export type ManufacturerResult = {
  __typename: "ManufacturerResult",
  totalCount?: number | null,
  page?: number | null,
  result?:  Array<ManufacturerDetailResult | null > | null,
  aggregations?: string | null,
};

export type ManufacturerDetailResult = {
  __typename: "ManufacturerDetailResult",
  companyName?: string | null,
  location?: string | null,
  name?: string | null,
  phoneNumber?: string | null,
  address?: string | null,
  email?: string | null,
  manufacturerId?: string | null,
  enabled?: boolean | null,
  status?: string | null,
};

export type SearchHubResult = {
  __typename: "SearchHubResult",
  data?: HubResult | null,
  code?: number | null,
  message?: string | null,
};

export type HubResult = {
  __typename: "HubResult",
  totalCount?: number | null,
  page?: number | null,
  result?:  Array<HubDetailResult | null > | null,
  aggregations?: string | null,
};

export type HubDetailResult = {
  __typename: "HubDetailResult",
  location?: string | null,
  hubName?: string | null,
  phoneNumber?: string | null,
  address?: string | null,
  email?: string | null,
  hubId?: string | null,
  creationDate?: string | null,
};

export type SearchUserResult = {
  __typename: "SearchUserResult",
  totalCount?: number | null,
  page?: number | null,
  result?:  Array<UserResult | null > | null,
};

export type UserResult = {
  __typename: "UserResult",
  approvedAt?: string | null,
  created?: string | null,
  dmaId?: string | null,
  branchId?: string | null,
  manufacturerId?: string | null,
  collectionCentreId?: string | null,
  hubId?: string | null,
  name?: string | null,
  username?: string | null,
  phoneNumber?: string | null,
  email?: string | null,
  companyName?: string | null,
  group?: string | null,
  kycFlowState?: string | null,
  location?: string | null,
  nationalId?: string | null,
  ward?: string | null,
  enabled?: boolean | null,
};

export type SearchDmaResult = {
  __typename: "SearchDmaResult",
  totalCount?: number | null,
  page?: number | null,
  result?:  Array<DmaResult | null > | null,
  aggregations?: string | null,
};

export type DmaResult = {
  __typename: "DmaResult",
  dmaId?: string | null,
  businessName?: string | null,
  creationDate?: string | null,
  dmaType?: string | null,
  location?: string | null,
  kyc_flow_state?: string | null,
  mail?: string | null,
  phoneNumber?: string | null,
  ward?: string | null,
  wardRegion?: string | null,
  wardCounty?: string | null,
  wardSubcounty?: string | null,
};

export type CreateWardMutationVariables = {
  ward?: WardInput | null,
};

export type CreateWardMutation = {
  createWard?: string | null,
};

export type DeleteWardMutationVariables = {
  wardId: string,
};

export type DeleteWardMutation = {
  deleteWard?: string | null,
};

export type DeleteMultipleWardsMutationVariables = {
  wardIds?: Array< string | null > | null,
};

export type DeleteMultipleWardsMutation = {
  deleteMultipleWards?: string | null,
};

export type CreatePermissionMutationVariables = {
  permission?: PermissionInput | null,
};

export type CreatePermissionMutation = {
  createPermission?: string | null,
};

export type CreateRoleMutationVariables = {
  role?: RoleInput | null,
};

export type CreateRoleMutation = {
  createRole?: string | null,
};

export type CreateDMARoleMutationVariables = {
  role?: RoleInput | null,
};

export type CreateDMARoleMutation = {
  createDMARole?: string | null,
};

export type UpdateRoleMutationVariables = {
  rolesId: string,
  role?: RoleInput | null,
};

export type UpdateRoleMutation = {
  updateRole?: string | null,
};

export type UpdateDMARoleMutationVariables = {
  dmaRolesId: string,
  role?: RoleInput | null,
};

export type UpdateDMARoleMutation = {
  updateDMARole?: string | null,
};

export type UpdatePermissionMutationVariables = {
  permissionId: string,
  permission?: PermissionInput | null,
};

export type UpdatePermissionMutation = {
  updatePermission?: string | null,
};

export type CreateManufacturerMutationVariables = {
  email?: string | null,
  company_name?: string | null,
  phoneNumber?: string | null,
  name?: string | null,
  location?: string | null,
  group?: string | null,
};

export type CreateManufacturerMutation = {
  createManufacturer?:  {
    __typename: "RespManufacturer",
    User?:  {
      __typename: "UserManufacturer",
      Username?: string | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: string | null,
      Attributes?:  Array< {
        __typename: "Attributes",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
    } | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type CreateUserWithRoleMutationVariables = {
  email?: string | null,
  rolename?: string | null,
  phoneNumber?: string | null,
  name?: string | null,
  location?: string | null,
};

export type CreateUserWithRoleMutation = {
  createUserWithRole?:  {
    __typename: "RespManufacturer",
    User?:  {
      __typename: "UserManufacturer",
      Username?: string | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: string | null,
      Attributes?:  Array< {
        __typename: "Attributes",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
    } | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type DmaCreateBranchUserMutationVariables = {
  email: string,
  rolename?: string | null,
  phoneNumber: string,
  name: string,
  dmaId: string,
  branchId: string,
  location?: string | null,
};

export type DmaCreateBranchUserMutation = {
  dmaCreateBranchUser?:  {
    __typename: "RespManufacturer",
    User?:  {
      __typename: "UserManufacturer",
      Username?: string | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: string | null,
      Attributes?:  Array< {
        __typename: "Attributes",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
    } | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type DmaCreateTransporterMutationVariables = {
  email?: string | null,
  phoneNumber: string,
  name: string,
  dmaId: string,
  location?: string | null,
  nationalId: string,
};

export type DmaCreateTransporterMutation = {
  dmaCreateTransporter?:  {
    __typename: "RespManufacturer",
    User?:  {
      __typename: "UserManufacturer",
      Username?: string | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: string | null,
      Attributes?:  Array< {
        __typename: "Attributes",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
    } | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type EnableAdminMutationVariables = {
  Username?: string | null,
};

export type EnableAdminMutation = {
  enableAdmin?: string | null,
};

export type DisableAdminMutationVariables = {
  Username?: string | null,
};

export type DisableAdminMutation = {
  disableAdmin?: string | null,
};

export type CreateAdminMutationVariables = {
  email: string,
  phoneNumber: string,
  group?: string | null,
  name?: string | null,
};

export type CreateAdminMutation = {
  createAdmin?:  {
    __typename: "CreateAdminResponse",
    User?:  {
      __typename: "SingleUserResponse",
      Username?: string | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: string | null,
      UserAttributes?:  Array< {
        __typename: "UserAttributes",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
    } | null,
    message?: string | null,
    code?: string | null,
  } | null,
};

export type UpdateAdminRoleMutationVariables = {
  Username: string,
  group: string,
};

export type UpdateAdminRoleMutation = {
  updateAdminRole?: string | null,
};

export type DmaCreateCollectionCentreUserMutationVariables = {
  email: string,
  rolename?: string | null,
  phoneNumber: string,
  name: string,
  dmaId: string,
  collectionCentreId: string,
  location?: string | null,
};

export type DmaCreateCollectionCentreUserMutation = {
  dmaCreateCollectionCentreUser?:  {
    __typename: "RespCollectionCentreUser",
    User?:  {
      __typename: "CollectionCentreUser",
      Username?: string | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: string | null,
      Attributes?:  Array< {
        __typename: "CollectionCenterAttributes",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
    } | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type DmaDisableTransporterMutationVariables = {
  username?: string | null,
};

export type DmaDisableTransporterMutation = {
  dmaDisableTransporter?: string | null,
};

export type DmaEnableTransporterMutationVariables = {
  username?: string | null,
};

export type DmaEnableTransporterMutation = {
  dmaEnableTransporter?: string | null,
};

export type CreateTempDmaMutationVariables = {
  dma?: TempDMAInput | null,
};

export type CreateTempDmaMutation = {
  createTempDma?:  {
    __typename: "createTempDmaResponse",
    data?: string | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type CreateAuditLogMutationVariables = {
  auditLog: AuditLogInput,
};

export type CreateAuditLogMutation = {
  createAuditLog?:  {
    __typename: "CreateAuditResponse",
    code: number,
    status: ResponseStatus,
    data?:  {
      __typename: "AuditLog",
      auditId: string,
      username?: string | null,
      action?: string | null,
      date?: string | null,
      event?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type CheckDmaPhoneNumberMutationVariables = {
  phoneNumber: string,
};

export type CheckDmaPhoneNumberMutation = {
  checkDmaPhoneNumber?: string | null,
};

export type CreateAdvertMutationVariables = {
  advert?: AdvertInput | null,
};

export type CreateAdvertMutation = {
  createAdvert?:  {
    __typename: "ResponseMessage",
    status?: ResponseStatus | null,
    code?: number | null,
    data?: string | null,
  } | null,
};

export type UpdateAdvertMutationVariables = {
  advertId: string,
  advertss?: AdvertInput | null,
};

export type UpdateAdvertMutation = {
  updateAdvert?:  {
    __typename: "Advert",
    adcategory?:  {
      __typename: "AdCategory",
      adcategoryId: string,
      name?: string | null,
      creationDate?: string | null,
      modificationDate?: string | null,
    } | null,
    creationDate?: string | null,
    description?: string | null,
    advertId: string,
    modificationDate?: string | null,
    name: string,
    time?: string | null,
    action?: string | null,
    image?: string | null,
  } | null,
};

export type DeleteAdvertByIdMutationVariables = {
  advertId: string,
};

export type DeleteAdvertByIdMutation = {
  deleteAdvertById?:  {
    __typename: "Advert",
    adcategory?:  {
      __typename: "AdCategory",
      adcategoryId: string,
      name?: string | null,
      creationDate?: string | null,
      modificationDate?: string | null,
    } | null,
    creationDate?: string | null,
    description?: string | null,
    advertId: string,
    modificationDate?: string | null,
    name: string,
    time?: string | null,
    action?: string | null,
    image?: string | null,
  } | null,
};

export type CreateAdCategoryMutationVariables = {
  adcategory?: AdCategoryInput | null,
};

export type CreateAdCategoryMutation = {
  createAdCategory?:  {
    __typename: "ResponseMessage",
    status?: ResponseStatus | null,
    code?: number | null,
    data?: string | null,
  } | null,
};

export type UpdateAdCategoryMutationVariables = {
  adcategoryId: string,
  adcategory?: AdCategoryInput | null,
};

export type UpdateAdCategoryMutation = {
  updateAdCategory?:  {
    __typename: "AdCategory",
    adcategoryId: string,
    name?: string | null,
    creationDate?: string | null,
    modificationDate?: string | null,
  } | null,
};

export type DeleteAdCategoryByIdMutationVariables = {
  adcategoryId: string,
};

export type DeleteAdCategoryByIdMutation = {
  deleteAdCategoryById?:  {
    __typename: "AdCategory",
    adcategoryId: string,
    name?: string | null,
    creationDate?: string | null,
    modificationDate?: string | null,
  } | null,
};

export type CreateAdCompanyMutationVariables = {
  adcompany?: AdCompanyInput | null,
};

export type CreateAdCompanyMutation = {
  createAdCompany?:  {
    __typename: "ResponseMessage",
    status?: ResponseStatus | null,
    code?: number | null,
    data?: string | null,
  } | null,
};

export type CreateCartMutationVariables = {
  carts?: CartInput | null,
};

export type CreateCartMutation = {
  createCart?:  {
    __typename: "ResponseMessage",
    status?: ResponseStatus | null,
    code?: number | null,
    data?: string | null,
  } | null,
};

export type UpdateCartMutationVariables = {
  cartId: string,
  carts?: CartInput | null,
};

export type UpdateCartMutation = {
  updateCart?:  {
    __typename: "Cart",
    cartId?: string | null,
    cartItems?:  Array< {
      __typename: "CartItem",
      product?:  {
        __typename: "CartProduct",
        manufacturerUserId?: string | null,
        description?: string | null,
        productId?: string | null,
        name?: string | null,
        price?: number | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        weight?: number | null,
        quantity?: number | null,
        company_name?: string | null,
        manufacturerId?: string | null,
      } | null,
      quantity?: number | null,
      total?: number | null,
    } | null > | null,
    dmaId?: string | null,
    userId?: string | null,
    branchId?: string | null,
    cartStatus?: CartStatus | null,
    creationDate?: string | null,
    modificationDate?: string | null,
    paymentStatus?: string | null,
    paymentMethod?: string | null,
    mpesaTransactionId?: string | null,
    total?: number | null,
  } | null,
};

export type DeleteCartMutationVariables = {
  cartId: string,
  dmaId: string,
};

export type DeleteCartMutation = {
  deleteCart?:  {
    __typename: "Cart",
    cartId?: string | null,
    cartItems?:  Array< {
      __typename: "CartItem",
      product?:  {
        __typename: "CartProduct",
        manufacturerUserId?: string | null,
        description?: string | null,
        productId?: string | null,
        name?: string | null,
        price?: number | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        weight?: number | null,
        quantity?: number | null,
        company_name?: string | null,
        manufacturerId?: string | null,
      } | null,
      quantity?: number | null,
      total?: number | null,
    } | null > | null,
    dmaId?: string | null,
    userId?: string | null,
    branchId?: string | null,
    cartStatus?: CartStatus | null,
    creationDate?: string | null,
    modificationDate?: string | null,
    paymentStatus?: string | null,
    paymentMethod?: string | null,
    mpesaTransactionId?: string | null,
    total?: number | null,
  } | null,
};

export type PublishCartUpdateMutationVariables = {
  cartId?: string | null,
};

export type PublishCartUpdateMutation = {
  publishCartUpdate?:  {
    __typename: "Cart",
    cartId?: string | null,
    cartItems?:  Array< {
      __typename: "CartItem",
      product?:  {
        __typename: "CartProduct",
        manufacturerUserId?: string | null,
        description?: string | null,
        productId?: string | null,
        name?: string | null,
        price?: number | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        weight?: number | null,
        quantity?: number | null,
        company_name?: string | null,
        manufacturerId?: string | null,
      } | null,
      quantity?: number | null,
      total?: number | null,
    } | null > | null,
    dmaId?: string | null,
    userId?: string | null,
    branchId?: string | null,
    cartStatus?: CartStatus | null,
    creationDate?: string | null,
    modificationDate?: string | null,
    paymentStatus?: string | null,
    paymentMethod?: string | null,
    mpesaTransactionId?: string | null,
    total?: number | null,
  } | null,
};

export type TransporterAddFarmerDeliveryMutationVariables = {
  farmerDelivery?: TransporterAddFarmerDeliveryInput | null,
};

export type TransporterAddFarmerDeliveryMutation = {
  transporterAddFarmerDelivery?:  {
    __typename: "TransporterAddFarmerDeliveryResponse",
    data?:  {
      __typename: "FarmerDelivery",
      farmerId?: string | null,
      collectionCenterId?: string | null,
      transporterId?: string | null,
      deliveryDate?: string | null,
      containerNumber?: number | null,
      product?: string | null,
      quantity?: number | null,
      unitPrice?: number | null,
      status?: FarmerDeliveryStatus | null,
    } | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type AddFarmerTransactionMutationVariables = {
  farmerTransaction?: FarmerTransactionInput | null,
};

export type AddFarmerTransactionMutation = {
  addFarmerTransaction?:  {
    __typename: "AddTransactionResponse",
    data?: string | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type CreateDmaAndMainBranchMutationVariables = {
  dma?: DMAInput | null,
};

export type CreateDmaAndMainBranchMutation = {
  createDmaAndMainBranch?:  {
    __typename: "createDmaAndMainBranchResponse",
    data?: string | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type CreateBranchMutationVariables = {
  branch?: BranchInput | null,
};

export type CreateBranchMutation = {
  createBranch?:  {
    __typename: "ResponseMessage",
    status?: ResponseStatus | null,
    code?: number | null,
    data?: string | null,
  } | null,
};

export type CreateCollectionCentreMutationVariables = {
  CollectionCentre?: CollectionCentreInput | null,
};

export type CreateCollectionCentreMutation = {
  createCollectionCentre?:  {
    __typename: "RespMessage",
    data?: string | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type AddFarmerMutationVariables = {
  farmer?: AddFarmerInput | null,
};

export type AddFarmerMutation = {
  addFarmer?:  {
    __typename: "AddFarmerResponse",
    code?: string | null,
    message?: string | null,
    data?:  {
      __typename: "Farmer",
      farmerId?: string | null,
      dmaId?: string | null,
      national_id?: string | null,
      member_number?: string | null,
      phone?: string | null,
      first_name?: string | null,
      middle_name?: string | null,
      last_name?: string | null,
      route?: string | null,
      supply_category?: string | null,
      MCC?: string | null,
      gender?: string | null,
      village?: string | null,
    } | null,
  } | null,
};

export type CollectionCentreAddMilkDeliveryMutationVariables = {
  milkDelivery?: CollectionCenterAddMilkDeliveryInput | null,
};

export type CollectionCentreAddMilkDeliveryMutation = {
  collectionCentreAddMilkDelivery?:  {
    __typename: "CollectionCenterAddMilkDeliveryResponse",
    data?:  {
      __typename: "MilkDelivery",
      farmerId?: string | null,
      collectionCenterId?: string | null,
      transporterId?: string | null,
      deliveryDate?: string | null,
      containerNumber?: number | null,
      product?: string | null,
      quantity?: number | null,
      unitPrice?: number | null,
      status?: MilkDeliveryStatus | null,
    } | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type ApproveMilkDeliveryMutationVariables = {
  milkDeliveries?: ApproveMilkDeliveryInput | null,
};

export type ApproveMilkDeliveryMutation = {
  approveMilkDelivery?:  {
    __typename: "ApproveMilkDeliveryResponse",
    data?: string | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type UpdateMilkDeliveryMutationVariables = {
  delivery?: UpdateMilkDeliveryInput | null,
};

export type UpdateMilkDeliveryMutation = {
  updateMilkDelivery?:  {
    __typename: "UpdateMilkDeliveryResponse",
    code?: string | null,
    message?: string | null,
  } | null,
};

export type DeleteMilkDeliveryMutationVariables = {
  deliveryId: string,
  deliveryDate?: string | null,
};

export type DeleteMilkDeliveryMutation = {
  deleteMilkDelivery?:  {
    __typename: "UpdateMilkDeliveryResponse",
    code?: string | null,
    message?: string | null,
  } | null,
};

export type CreateDmaPartnershipMutationVariables = {
  cooperativeId: string,
  agrovetId: string,
};

export type CreateDmaPartnershipMutation = {
  createDmaPartnership?:  {
    __typename: "DMAPartnershipResponse",
    data?: string | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type UpdateKycStatusMutationVariables = {
  dmaId: string,
  status?: KYCSTATUS | null,
};

export type UpdateKycStatusMutation = {
  updateKycStatus?:  {
    __typename: "updateKycStatusResponse",
    data?: string | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type RemoveDmaPartnershipMutationVariables = {
  cooperativeId: string,
  agrovetId: string,
};

export type RemoveDmaPartnershipMutation = {
  removeDmaPartnership?:  {
    __typename: "DMAPartnershipResponse",
    data?: string | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type UpdateDmaProfileDetailsByDmaIdMutationVariables = {
  dmaProfile?: UpdateDmaProfileInput | null,
};

export type UpdateDmaProfileDetailsByDmaIdMutation = {
  updateDmaProfileDetailsByDmaId?:  {
    __typename: "updateKycStatusResponse",
    data?: string | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type DeleteDmaMutationVariables = {
  dmaId: string,
};

export type DeleteDmaMutation = {
  deleteDma?:  {
    __typename: "deleteDmaResponse",
    status?: ResponseStatus | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type CreateDmastockMutationVariables = {
  dmastock?: DmastockInput | null,
};

export type CreateDmastockMutation = {
  createDmastock?:  {
    __typename: "StockResponseMessage",
    status?: ResponseStatus | null,
    code?: number | null,
    data?:  {
      __typename: "StockRequest",
      stockRequestId: string,
      branchId?: string | null,
      productId?: string | null,
      dmaId?: string | null,
      name?: string | null,
      type?: string | null,
      unit?: string | null,
      quantity?: number | null,
      status?: string | null,
      creationDate?: string | null,
      product?:  {
        __typename: "RequestProduct",
        description?: string | null,
        productId: string,
        name: string,
        price: number,
        subCategoryName?: string | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        quantity?: number | null,
        status?: string | null,
        weight?: number | null,
        manufacturerUserId: string,
        creationDate?: string | null,
        modificationDate?: string | null,
        subCategoryId: string,
        rebates?: number | null,
        dmaPrice?: number | null,
        company_name?: string | null,
      } | null,
      branch?:  {
        __typename: "RequestBranch",
        branchId: string,
        dmaId: string,
        branchName?: string | null,
        location?: string | null,
        isHQ?: string | null,
        phoneNumber?: string | null,
        mail?: string | null,
        ward?: string | null,
        creationDate?: string | null,
      } | null,
    } | null,
    message?: string | null,
  } | null,
};

export type UpdateDmastockMutationVariables = {
  dmastockId: string,
  dmastocks?: DmastockInput | null,
};

export type UpdateDmastockMutation = {
  updateDmastock?:  {
    __typename: "Dmastock",
    dmastockId?: string | null,
    manufacturerUserId?: string | null,
    manufacturerName?: string | null,
    dmaUserId?: string | null,
    dmaId?: string | null,
    branchId?: string | null,
    creationDate?: string | null,
    description?: string | null,
    modificationDate?: string | null,
    name: string,
    buying_price?: number | null,
    selling_price?: number | null,
    stockSubCategoryId?: string | null,
    unit?: string | null,
    type?: string | null,
    image?: Array< string | null > | null,
    quantity?: number | null,
    status?: string | null,
    weight?: string | null,
    bulkUploadProcessStatus?: string | null,
    bulkUploadProcessId?: string | null,
    rebates?: number | null,
  } | null,
};

export type DeleteDmastockByIdMutationVariables = {
  dmastockId: string,
};

export type DeleteDmastockByIdMutation = {
  deleteDmastockById?:  {
    __typename: "Dmastock",
    dmastockId?: string | null,
    manufacturerUserId?: string | null,
    manufacturerName?: string | null,
    dmaUserId?: string | null,
    dmaId?: string | null,
    branchId?: string | null,
    creationDate?: string | null,
    description?: string | null,
    modificationDate?: string | null,
    name: string,
    buying_price?: number | null,
    selling_price?: number | null,
    stockSubCategoryId?: string | null,
    unit?: string | null,
    type?: string | null,
    image?: Array< string | null > | null,
    quantity?: number | null,
    status?: string | null,
    weight?: string | null,
    bulkUploadProcessStatus?: string | null,
    bulkUploadProcessId?: string | null,
    rebates?: number | null,
  } | null,
};

export type UpdateListDmastockMutationVariables = {
  branchId: string,
  bulkAction?: string | null,
};

export type UpdateListDmastockMutation = {
  updateListDmastock?: string | null,
};

export type ApproveDmastockMutationVariables = {
  bulkUploadId?: Array< string > | null,
  bulkAction?: string | null,
};

export type ApproveDmastockMutation = {
  approveDmastock?: string | null,
};

export type CreateStockRequestMutationVariables = {
  stockRequest?: StockRequestInput | null,
};

export type CreateStockRequestMutation = {
  createStockRequest?:  {
    __typename: "StockResponseMessage",
    status?: ResponseStatus | null,
    code?: number | null,
    data?:  {
      __typename: "StockRequest",
      stockRequestId: string,
      branchId?: string | null,
      productId?: string | null,
      dmaId?: string | null,
      name?: string | null,
      type?: string | null,
      unit?: string | null,
      quantity?: number | null,
      status?: string | null,
      creationDate?: string | null,
      product?:  {
        __typename: "RequestProduct",
        description?: string | null,
        productId: string,
        name: string,
        price: number,
        subCategoryName?: string | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        quantity?: number | null,
        status?: string | null,
        weight?: number | null,
        manufacturerUserId: string,
        creationDate?: string | null,
        modificationDate?: string | null,
        subCategoryId: string,
        rebates?: number | null,
        dmaPrice?: number | null,
        company_name?: string | null,
      } | null,
      branch?:  {
        __typename: "RequestBranch",
        branchId: string,
        dmaId: string,
        branchName?: string | null,
        location?: string | null,
        isHQ?: string | null,
        phoneNumber?: string | null,
        mail?: string | null,
        ward?: string | null,
        creationDate?: string | null,
      } | null,
    } | null,
    message?: string | null,
  } | null,
};

export type ApproveStockRequestMutationVariables = {
  stockRequestId: string,
};

export type ApproveStockRequestMutation = {
  approveStockRequest?:  {
    __typename: "StockResponseMessage",
    status?: ResponseStatus | null,
    code?: number | null,
    data?:  {
      __typename: "StockRequest",
      stockRequestId: string,
      branchId?: string | null,
      productId?: string | null,
      dmaId?: string | null,
      name?: string | null,
      type?: string | null,
      unit?: string | null,
      quantity?: number | null,
      status?: string | null,
      creationDate?: string | null,
      product?:  {
        __typename: "RequestProduct",
        description?: string | null,
        productId: string,
        name: string,
        price: number,
        subCategoryName?: string | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        quantity?: number | null,
        status?: string | null,
        weight?: number | null,
        manufacturerUserId: string,
        creationDate?: string | null,
        modificationDate?: string | null,
        subCategoryId: string,
        rebates?: number | null,
        dmaPrice?: number | null,
        company_name?: string | null,
      } | null,
      branch?:  {
        __typename: "RequestBranch",
        branchId: string,
        dmaId: string,
        branchName?: string | null,
        location?: string | null,
        isHQ?: string | null,
        phoneNumber?: string | null,
        mail?: string | null,
        ward?: string | null,
        creationDate?: string | null,
      } | null,
    } | null,
    message?: string | null,
  } | null,
};

export type RejectStockRequestMutationVariables = {
  stockRequestId: string,
};

export type RejectStockRequestMutation = {
  rejectStockRequest?:  {
    __typename: "StockResponseMessage",
    status?: ResponseStatus | null,
    code?: number | null,
    data?:  {
      __typename: "StockRequest",
      stockRequestId: string,
      branchId?: string | null,
      productId?: string | null,
      dmaId?: string | null,
      name?: string | null,
      type?: string | null,
      unit?: string | null,
      quantity?: number | null,
      status?: string | null,
      creationDate?: string | null,
      product?:  {
        __typename: "RequestProduct",
        description?: string | null,
        productId: string,
        name: string,
        price: number,
        subCategoryName?: string | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        quantity?: number | null,
        status?: string | null,
        weight?: number | null,
        manufacturerUserId: string,
        creationDate?: string | null,
        modificationDate?: string | null,
        subCategoryId: string,
        rebates?: number | null,
        dmaPrice?: number | null,
        company_name?: string | null,
      } | null,
      branch?:  {
        __typename: "RequestBranch",
        branchId: string,
        dmaId: string,
        branchName?: string | null,
        location?: string | null,
        isHQ?: string | null,
        phoneNumber?: string | null,
        mail?: string | null,
        ward?: string | null,
        creationDate?: string | null,
      } | null,
    } | null,
    message?: string | null,
  } | null,
};

export type CreateFarmerOrderMutationVariables = {
  farmerOrderInput?: FarmerOrderCreateInput | null,
};

export type CreateFarmerOrderMutation = {
  createFarmerOrder?:  {
    __typename: "FarmerOrderResponse",
    status?: ResponseStatus | null,
    code?: number | null,
    data?:  {
      __typename: "FarmerOrder",
      farmerOrderId?: string | null,
      memberNumber?: string | null,
      farmerName?: string | null,
      totalCost?: string | null,
      paymentMethod?: PaymentMethod | null,
      product?:  Array< {
        __typename: "FarmerOrderProductDetailed",
        quantity?: number | null,
        product?:  {
          __typename: "Dmastock",
          dmastockId?: string | null,
          manufacturerUserId?: string | null,
          manufacturerName?: string | null,
          dmaUserId?: string | null,
          dmaId?: string | null,
          branchId?: string | null,
          creationDate?: string | null,
          description?: string | null,
          modificationDate?: string | null,
          name: string,
          buying_price?: number | null,
          selling_price?: number | null,
          stockSubCategoryId?: string | null,
          unit?: string | null,
          type?: string | null,
          image?: Array< string | null > | null,
          quantity?: number | null,
          status?: string | null,
          weight?: string | null,
          bulkUploadProcessStatus?: string | null,
          bulkUploadProcessId?: string | null,
          rebates?: number | null,
        } | null,
        price?: number | null,
        selling_price?: number | null,
      } | null > | null,
      creationDate?: string | null,
      paymentStatus?: PaymentStatus | null,
      farmerOrderStatus?: string | null,
      dmaName?: string | null,
      dmaUserId?: string | null,
      dmaId?: string | null,
      branchId?: string | null,
      mpesaTransactionId?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type UpdateFarmerOrderMutationVariables = {
  farmerOrderId: string,
  farmerOrder?: FarmerOrderUpdateInput | null,
};

export type UpdateFarmerOrderMutation = {
  updateFarmerOrder?:  {
    __typename: "FarmerOrder",
    farmerOrderId?: string | null,
    memberNumber?: string | null,
    farmerName?: string | null,
    totalCost?: string | null,
    paymentMethod?: PaymentMethod | null,
    product?:  Array< {
      __typename: "FarmerOrderProductDetailed",
      quantity?: number | null,
      product?:  {
        __typename: "Dmastock",
        dmastockId?: string | null,
        manufacturerUserId?: string | null,
        manufacturerName?: string | null,
        dmaUserId?: string | null,
        dmaId?: string | null,
        branchId?: string | null,
        creationDate?: string | null,
        description?: string | null,
        modificationDate?: string | null,
        name: string,
        buying_price?: number | null,
        selling_price?: number | null,
        stockSubCategoryId?: string | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        quantity?: number | null,
        status?: string | null,
        weight?: string | null,
        bulkUploadProcessStatus?: string | null,
        bulkUploadProcessId?: string | null,
        rebates?: number | null,
      } | null,
      price?: number | null,
      selling_price?: number | null,
    } | null > | null,
    creationDate?: string | null,
    paymentStatus?: PaymentStatus | null,
    farmerOrderStatus?: string | null,
    dmaName?: string | null,
    dmaUserId?: string | null,
    dmaId?: string | null,
    branchId?: string | null,
    mpesaTransactionId?: string | null,
  } | null,
};

export type DeleteFarmerOrderByIdMutationVariables = {
  farmerOrderId: string,
};

export type DeleteFarmerOrderByIdMutation = {
  deleteFarmerOrderById?:  {
    __typename: "FarmerOrder",
    farmerOrderId?: string | null,
    memberNumber?: string | null,
    farmerName?: string | null,
    totalCost?: string | null,
    paymentMethod?: PaymentMethod | null,
    product?:  Array< {
      __typename: "FarmerOrderProductDetailed",
      quantity?: number | null,
      product?:  {
        __typename: "Dmastock",
        dmastockId?: string | null,
        manufacturerUserId?: string | null,
        manufacturerName?: string | null,
        dmaUserId?: string | null,
        dmaId?: string | null,
        branchId?: string | null,
        creationDate?: string | null,
        description?: string | null,
        modificationDate?: string | null,
        name: string,
        buying_price?: number | null,
        selling_price?: number | null,
        stockSubCategoryId?: string | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        quantity?: number | null,
        status?: string | null,
        weight?: string | null,
        bulkUploadProcessStatus?: string | null,
        bulkUploadProcessId?: string | null,
        rebates?: number | null,
      } | null,
      price?: number | null,
      selling_price?: number | null,
    } | null > | null,
    creationDate?: string | null,
    paymentStatus?: PaymentStatus | null,
    farmerOrderStatus?: string | null,
    dmaName?: string | null,
    dmaUserId?: string | null,
    dmaId?: string | null,
    branchId?: string | null,
    mpesaTransactionId?: string | null,
  } | null,
};

export type DeleteDmaStockListMutationVariables = {
  stockIds: Array< string | null >,
};

export type DeleteDmaStockListMutation = {
  deleteDmaStockList?: string | null,
};

export type UpdateBulkUploadedDmaStockMutationVariables = {
  dmastockId: string,
  dmastocks?: DmaBulkUploadStockInput | null,
};

export type UpdateBulkUploadedDmaStockMutation = {
  updateBulkUploadedDmaStock?:  {
    __typename: "Dmastock",
    dmastockId?: string | null,
    manufacturerUserId?: string | null,
    manufacturerName?: string | null,
    dmaUserId?: string | null,
    dmaId?: string | null,
    branchId?: string | null,
    creationDate?: string | null,
    description?: string | null,
    modificationDate?: string | null,
    name: string,
    buying_price?: number | null,
    selling_price?: number | null,
    stockSubCategoryId?: string | null,
    unit?: string | null,
    type?: string | null,
    image?: Array< string | null > | null,
    quantity?: number | null,
    status?: string | null,
    weight?: string | null,
    bulkUploadProcessStatus?: string | null,
    bulkUploadProcessId?: string | null,
    rebates?: number | null,
  } | null,
};

export type AddDmastockMutationVariables = {
  dmastock?: AddDmaBulkUploadStockInput | null,
};

export type AddDmastockMutation = {
  addDmastock?: string | null,
};

export type DeleteAllDmaStockMutationVariables = {
  branchId: string,
};

export type DeleteAllDmaStockMutation = {
  deleteAllDmaStock?: string | null,
};

export type CreateDmaStockReconciliationRecordMutationVariables = {
  record: DmaStockReonciliationRecordInput,
};

export type CreateDmaStockReconciliationRecordMutation = {
  createDmaStockReconciliationRecord?: string | null,
};

export type UpdateDmaStockReconciliationRecordMutationVariables = {
  record: DmaStockReonciliationRecordInput,
};

export type UpdateDmaStockReconciliationRecordMutation = {
  updateDmaStockReconciliationRecord?: string | null,
};

export type CreateManufacturerAndMainHubMutationVariables = {
  manufacturerInput?: ManufacturerInput | null,
};

export type CreateManufacturerAndMainHubMutation = {
  createManufacturerAndMainHub?:  {
    __typename: "CreateManufacturerAndMainHubResponseDTO",
    data?:  {
      __typename: "ManufacturerResponse",
      User?:  {
        __typename: "UserManufacturer",
        Username?: string | null,
        UserCreateDate?: string | null,
        UserLastModifiedDate?: string | null,
        Enabled?: boolean | null,
        UserStatus?: string | null,
        Attributes?:  Array< {
          __typename: "Attributes",
          Name?: string | null,
          Value?: string | null,
        } | null > | null,
      } | null,
      companyName?: string | null,
      location?: string | null,
      name?: string | null,
      phoneNumber?: string | null,
      address?: string | null,
      email?: string | null,
    } | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type CreateHubMutationVariables = {
  hubInput?: CreateHubInput | null,
};

export type CreateHubMutation = {
  createHub?:  {
    __typename: "CreateHubResponseDTO",
    data?:  {
      __typename: "HubResponse",
      location?: string | null,
      hubName?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      hubId?: string | null,
      manufacturerId?: string | null,
      address?: string | null,
      creationDate?: string | null,
      modificationDate?: string | null,
    } | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type CreateOrderMutationVariables = {
  order?: OrderInput | null,
};

export type CreateOrderMutation = {
  createOrder?:  {
    __typename: "ResponseMessage",
    status?: ResponseStatus | null,
    code?: number | null,
    data?: string | null,
  } | null,
};

export type UpdateOrderMutationVariables = {
  orderId: string,
  manufacturerId: string,
  orders?: UpdateOrderInput | null,
};

export type UpdateOrderMutation = {
  updateOrder?:  {
    __typename: "Order",
    orderId?: string | null,
    manufacturerId?: string | null,
    dmaId?: string | null,
    branchId?: string | null,
    orderItems?:  Array< {
      __typename: "OrderItem",
      product?:  {
        __typename: "OrderProduct",
        productId?: string | null,
        manufacturerUserId?: string | null,
        description?: string | null,
        name?: string | null,
        company_name?: string | null,
        price?: number | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        weight?: number | null,
        quantity?: number | null,
      } | null,
      quantity?: number | null,
      availableQuantity?: number | null,
      receivedQuantity?: number | null,
      total?: number | null,
    } | null > | null,
    orderStatus?: string | null,
    paymentStatus?: string | null,
    paymentMethod?: string | null,
    creationDate?: string | null,
    modificationDate?: string | null,
    rebates?: number | null,
  } | null,
};

export type PaymentPushMutationVariables = {
  request?: PushStkInput | null,
};

export type PaymentPushMutation = {
  paymentPush?:  {
    __typename: "MpesaResponse",
    MerchantRequestID?: string | null,
    CheckoutRequestID?: string | null,
    ResponseCode?: string | null,
    ResponseDescription?: string | null,
    CustomerMessage?: string | null,
    requestId?: string | null,
    errorCode?: string | null,
    errorMessage?: string | null,
  } | null,
};

export type PaymentB2BMutationVariables = {
  request?: B2BInput | null,
};

export type PaymentB2BMutation = {
  paymentB2B?:  {
    __typename: "ResponseMessage",
    status?: ResponseStatus | null,
    code?: number | null,
    data?: string | null,
  } | null,
};

export type PaymentLipaNaBongaMutationVariables = {
  request?: B2BInput | null,
};

export type PaymentLipaNaBongaMutation = {
  paymentLipaNaBonga?:  {
    __typename: "ResponseMessage",
    status?: ResponseStatus | null,
    code?: number | null,
    data?: string | null,
  } | null,
};

export type CreateProductMutationVariables = {
  product?: ProductInput | null,
};

export type CreateProductMutation = {
  createProduct?:  {
    __typename: "ResponseMessage",
    status?: ResponseStatus | null,
    code?: number | null,
    data?: string | null,
  } | null,
};

export type UpdateProductMutationVariables = {
  productId: string,
  produces?: ProductInput | null,
};

export type UpdateProductMutation = {
  updateProduct?:  {
    __typename: "Product",
    manufacturerUserId?: string | null,
    creationDate?: string | null,
    description?: string | null,
    productId: string,
    modificationDate?: string | null,
    name: string,
    price: number,
    subCategoryId: string,
    subCategoryName?: string | null,
    unit?: string | null,
    type?: string | null,
    image?: Array< string | null > | null,
    quantity?: number | null,
    status?: string | null,
    weight?: number | null,
    rebates?: number | null,
    dmaPrice?: number | null,
    company_name?: string | null,
    manufacturerId: string,
    hubId: string,
  } | null,
};

export type UpdateMultipleProductsMutationVariables = {
  productIds?: Array< string > | null,
  status?: string | null,
};

export type UpdateMultipleProductsMutation = {
  updateMultipleProducts?:  Array< {
    __typename: "Product",
    manufacturerUserId?: string | null,
    creationDate?: string | null,
    description?: string | null,
    productId: string,
    modificationDate?: string | null,
    name: string,
    price: number,
    subCategoryId: string,
    subCategoryName?: string | null,
    unit?: string | null,
    type?: string | null,
    image?: Array< string | null > | null,
    quantity?: number | null,
    status?: string | null,
    weight?: number | null,
    rebates?: number | null,
    dmaPrice?: number | null,
    company_name?: string | null,
    manufacturerId: string,
    hubId: string,
  } | null > | null,
};

export type DeleteProductByIdMutationVariables = {
  productId: string,
};

export type DeleteProductByIdMutation = {
  deleteProductById?:  {
    __typename: "Product",
    manufacturerUserId?: string | null,
    creationDate?: string | null,
    description?: string | null,
    productId: string,
    modificationDate?: string | null,
    name: string,
    price: number,
    subCategoryId: string,
    subCategoryName?: string | null,
    unit?: string | null,
    type?: string | null,
    image?: Array< string | null > | null,
    quantity?: number | null,
    status?: string | null,
    weight?: number | null,
    rebates?: number | null,
    dmaPrice?: number | null,
    company_name?: string | null,
    manufacturerId: string,
    hubId: string,
  } | null,
};

export type CreateCategoryMutationVariables = {
  category: CategoryInput,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "ResponseMessage",
    status?: ResponseStatus | null,
    code?: number | null,
    data?: string | null,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  categoryId: string,
  category: CategoryInput,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    categoryId: string,
    name?: string | null,
    subCategory?:  Array< {
      __typename: "SubCategory",
      subCategoryId: string,
      categoryId: string,
      name?: string | null,
      creationDate?: string | null,
      modificationDate?: string | null,
      image?: string | null,
    } | null > | null,
    creationDate?: string | null,
    modificationDate?: string | null,
    image?: string | null,
  } | null,
};

export type DeleteCategoryByIdMutationVariables = {
  categoryId: string,
};

export type DeleteCategoryByIdMutation = {
  deleteCategoryById?:  {
    __typename: "Category",
    categoryId: string,
    name?: string | null,
    subCategory?:  Array< {
      __typename: "SubCategory",
      subCategoryId: string,
      categoryId: string,
      name?: string | null,
      creationDate?: string | null,
      modificationDate?: string | null,
      image?: string | null,
    } | null > | null,
    creationDate?: string | null,
    modificationDate?: string | null,
    image?: string | null,
  } | null,
};

export type CreateSubCategoryMutationVariables = {
  subCategory: SubCategoryInput,
};

export type CreateSubCategoryMutation = {
  createSubCategory?:  {
    __typename: "ResponseMessage",
    status?: ResponseStatus | null,
    code?: number | null,
    data?: string | null,
  } | null,
};

export type UpdateSubCategoryMutationVariables = {
  subCategoryId: string,
  subCategory: SubCategoryInput,
};

export type UpdateSubCategoryMutation = {
  updateSubCategory?:  {
    __typename: "SubCategory",
    subCategoryId: string,
    categoryId: string,
    name?: string | null,
    creationDate?: string | null,
    modificationDate?: string | null,
    image?: string | null,
  } | null,
};

export type DeleteSubCategoryByIdMutationVariables = {
  subCategoryId: string,
};

export type DeleteSubCategoryByIdMutation = {
  deleteSubCategoryById?:  {
    __typename: "SubCategory",
    subCategoryId: string,
    categoryId: string,
    name?: string | null,
    creationDate?: string | null,
    modificationDate?: string | null,
    image?: string | null,
  } | null,
};

export type GetWardsQueryVariables = {
  wardId?: string | null,
  limit?: number | null,
};

export type GetWardsQuery = {
  getWards?:  Array< {
    __typename: "Ward",
    name?: string | null,
    wardId?: string | null,
    county?: string | null,
    subcounty?: string | null,
    region?: string | null,
    creationDate?: string | null,
    description?: string | null,
    modificationDate?: string | null,
  } | null > | null,
};

export type GetRolesQueryVariables = {
  rolesId?: string | null,
};

export type GetRolesQuery = {
  getRoles?:  Array< {
    __typename: "Role",
    rolename?: string | null,
    alias?: string | null,
    rolesId?: string | null,
    permissions?:  Array< {
      __typename: "Permission",
      fieldName?: string | null,
      alias?: string | null,
      permissionId?: string | null,
      creationDate?: string | null,
      description?: string | null,
      modificationDate?: string | null,
      userLevel?: UserLevel | null,
    } | null > | null,
    creationDate?: string | null,
    description?: string | null,
    modificationDate?: string | null,
  } | null > | null,
};

export type GetDMARolesQuery = {
  getDMARoles?:  Array< {
    __typename: "DMARole",
    rolename?: string | null,
    alias?: string | null,
    dmaRolesId?: string | null,
    permissions?:  Array< {
      __typename: "Permission",
      fieldName?: string | null,
      alias?: string | null,
      permissionId?: string | null,
      creationDate?: string | null,
      description?: string | null,
      modificationDate?: string | null,
      userLevel?: UserLevel | null,
    } | null > | null,
    creationDate?: string | null,
    description?: string | null,
    modificationDate?: string | null,
  } | null > | null,
};

export type GetDMARolesByIdQueryVariables = {
  dmaRolesId?: string | null,
};

export type GetDMARolesByIdQuery = {
  getDMARolesById?:  Array< {
    __typename: "DMARole",
    rolename?: string | null,
    alias?: string | null,
    dmaRolesId?: string | null,
    permissions?:  Array< {
      __typename: "Permission",
      fieldName?: string | null,
      alias?: string | null,
      permissionId?: string | null,
      creationDate?: string | null,
      description?: string | null,
      modificationDate?: string | null,
      userLevel?: UserLevel | null,
    } | null > | null,
    creationDate?: string | null,
    description?: string | null,
    modificationDate?: string | null,
  } | null > | null,
};

export type GetPermissionsQueryVariables = {
  permissionId?: string | null,
};

export type GetPermissionsQuery = {
  getPermissions?:  Array< {
    __typename: "Permission",
    fieldName?: string | null,
    alias?: string | null,
    permissionId?: string | null,
    creationDate?: string | null,
    description?: string | null,
    modificationDate?: string | null,
    userLevel?: UserLevel | null,
  } | null > | null,
};

export type GetPermissionsByUserLevelQueryVariables = {
  userLevel?: string | null,
};

export type GetPermissionsByUserLevelQuery = {
  getPermissionsByUserLevel?:  {
    __typename: "PermissionResponse",
    data?:  Array< {
      __typename: "Permission",
      fieldName?: string | null,
      alias?: string | null,
      permissionId?: string | null,
      creationDate?: string | null,
      description?: string | null,
      modificationDate?: string | null,
      userLevel?: UserLevel | null,
    } | null > | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  limit?: string | null,
  group?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "UsersResponse",
    Locations?: Array< string | null > | null,
    Users?:  Array< {
      __typename: "Users",
      Username?: string | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: string | null,
      Attributes?:  Array< {
        __typename: "Attributes",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
    } | null > | null,
    message?: string | null,
  } | null,
};

export type ListUsersInGroupQueryVariables = {
  limit?: string | null,
  group: string,
  filters?: string | null,
  next_page_token?: string | null,
  company_name?: string | null,
  location?: string | null,
  kyc_flow_state?: string | null,
  enabled?: string | null,
  search_text?: string | null,
};

export type ListUsersInGroupQuery = {
  listUsersInGroup?:  {
    __typename: "UsersResponse",
    Locations?: Array< string | null > | null,
    Users?:  Array< {
      __typename: "Users",
      Username?: string | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: string | null,
      Attributes?:  Array< {
        __typename: "Attributes",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
    } | null > | null,
    message?: string | null,
  } | null,
};

export type KeyListUsersInGroupQueryVariables = {
  limit?: string | null,
  group: string,
  filters?: string | null,
  next_page_token?: string | null,
  company_name?: string | null,
  location?: string | null,
  kyc_flow_state?: string | null,
  enabled?: string | null,
  search_text?: string | null,
};

export type KeyListUsersInGroupQuery = {
  keyListUsersInGroup?:  {
    __typename: "UsersResponse",
    Locations?: Array< string | null > | null,
    Users?:  Array< {
      __typename: "Users",
      Username?: string | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: string | null,
      Attributes?:  Array< {
        __typename: "Attributes",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
    } | null > | null,
    message?: string | null,
  } | null,
};

export type AdminGetDmaReadPresignedUrlQueryVariables = {
  file_key?: string | null,
};

export type AdminGetDmaReadPresignedUrlQuery = {
  adminGetDmaReadPresignedUrl?: string | null,
};

export type AdminGetManufacturerPresignedUrlQueryVariables = {
  username?: string | null,
};

export type AdminGetManufacturerPresignedUrlQuery = {
  adminGetManufacturerPresignedUrl?:  {
    __typename: "WriteDocumentResponse",
    url?: string | null,
    expires_in?: number | null,
    fields?: string | null,
  } | null,
};

export type GetAccountQueryVariables = {
  username?: string | null,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "SingleUserResponse",
    Username?: string | null,
    UserCreateDate?: string | null,
    UserLastModifiedDate?: string | null,
    Enabled?: boolean | null,
    UserStatus?: string | null,
    UserAttributes?:  Array< {
      __typename: "UserAttributes",
      Name?: string | null,
      Value?: string | null,
    } | null > | null,
  } | null,
};

export type UserGetAccountQueryVariables = {
  username?: string | null,
};

export type UserGetAccountQuery = {
  userGetAccount?: string | null,
};

export type ApproveDmaQueryVariables = {
  username?: string | null,
};

export type ApproveDmaQuery = {
  approveDma?: string | null,
};

export type RejectDmaQueryVariables = {
  username?: string | null,
};

export type RejectDmaQuery = {
  rejectDma?: string | null,
};

export type DisableManufacturerQueryVariables = {
  company_name?: string | null,
};

export type DisableManufacturerQuery = {
  disableManufacturer?: string | null,
};

export type EnableManufacturerQueryVariables = {
  company_name?: string | null,
};

export type EnableManufacturerQuery = {
  enableManufacturer?: string | null,
};

export type EnableUserQueryVariables = {
  username?: string | null,
};

export type EnableUserQuery = {
  enableUser?: string | null,
};

export type DisableUserQueryVariables = {
  username?: string | null,
};

export type DisableUserQuery = {
  disableUser?: string | null,
};

export type ListAdminsQueryVariables = {
  limit?: number | null,
  filter?: string | null,
  attributes?: Array< string | null > | null,
  nextPageToken?: string | null,
};

export type ListAdminsQuery = {
  listAdmins?:  {
    __typename: "AdminsListResponse",
    Users?:  Array< {
      __typename: "Users",
      Username?: string | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: string | null,
      Attributes?:  Array< {
        __typename: "Attributes",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
    } | null > | null,
    PaginationToken?: string | null,
  } | null,
};

export type ListAdminsInGroupQueryVariables = {
  limit?: number | null,
  group?: string | null,
  nextPageToken?: string | null,
};

export type ListAdminsInGroupQuery = {
  listAdminsInGroup?:  {
    __typename: "AdminsInGroupResponse",
    Users?:  Array< {
      __typename: "Users",
      Username?: string | null,
      UserCreateDate?: string | null,
      UserLastModifiedDate?: string | null,
      Enabled?: boolean | null,
      UserStatus?: string | null,
      Attributes?:  Array< {
        __typename: "Attributes",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
    } | null > | null,
    NextToken?: string | null,
  } | null,
};

export type GetAdminAccountQueryVariables = {
  Username?: string | null,
};

export type GetAdminAccountQuery = {
  getAdminAccount?:  {
    __typename: "GetAdminAccountResp",
    Username?: string | null,
    UserCreateDate?: string | null,
    UserLastModifiedDate?: string | null,
    Enabled?: boolean | null,
    UserStatus?: string | null,
    UserAttributes?:  Array< {
      __typename: "UserAttributes",
      Name?: string | null,
      Value?: string | null,
    } | null > | null,
    roles?: Array< string | null > | null,
    permissions?:  Array< {
      __typename: "Permission",
      fieldName?: string | null,
      alias?: string | null,
      permissionId?: string | null,
      creationDate?: string | null,
      description?: string | null,
      modificationDate?: string | null,
      userLevel?: UserLevel | null,
    } | null > | null,
  } | null,
};

export type DmaGetWritePresignedUrlQuery = {
  dmaGetWritePresignedUrl?: string | null,
};

export type UserGetFarmerDetailsQueryVariables = {
  phone_number?: string | null,
};

export type UserGetFarmerDetailsQuery = {
  userGetFarmerDetails?:  {
    __typename: "FarmerDetailsResponse",
    farmer_id?: string | null,
    member_number?: string | null,
    first_name?: string | null,
    middle_name?: string | null,
    last_name?: string | null,
    phone_number?: string | null,
  } | null,
};

export type GetAccountDashboardQuery = {
  getAccountDashboard?:  {
    __typename: "AccountDashboard",
    dma?: number | null,
    manufacturer?: number | null,
    unassigned?: number | null,
    name?: string | null,
  } | null,
};

export type GetAccountDashboardChartQuery = {
  getAccountDashboardChart?:  Array< {
    __typename: "AccountDashboard",
    dma?: number | null,
    manufacturer?: number | null,
    unassigned?: number | null,
    name?: string | null,
  } | null > | null,
};

export type ManufacturerStatisticsQuery = {
  manufacturerStatistics?:  {
    __typename: "manufacturerStatisticsResponse",
    manToday?: string | null,
    manMonthly?:  Array< {
      __typename: "StatsMonthly",
      monthLong?: string | null,
      monthShort?: string | null,
      creationDateFrom?: string | null,
      creationDateTo?: string | null,
      manStats?: string | null,
      monthStats?: number | null,
    } | null > | null,
  } | null,
};

export type GetTempDmaQueryVariables = {
  email: string,
};

export type GetTempDmaQuery = {
  getTempDma?:  {
    __typename: "GetTempDmaResponse",
    data?: string | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type ListAuditLogQueryVariables = {
  filterCriterias?: Array< FilterCriteria > | null,
  exclusiveStartKey?: string | null,
};

export type ListAuditLogQuery = {
  listAuditLog?:  {
    __typename: "ListAuditResponse",
    code: number,
    message?: string | null,
    data?:  Array< {
      __typename: "AuditLog",
      auditId: string,
      username?: string | null,
      action?: string | null,
      date?: string | null,
      event?: string | null,
    } | null > | null,
    lastEvaluatedKey?: string | null,
  } | null,
};

export type DmaGetReadPresignedUrlQuery = {
  dmaGetReadPresignedUrl?: string | null,
};

export type GetAdvertsQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  advertIds?: Array< string | null > | null,
};

export type GetAdvertsQuery = {
  getAdverts?:  Array< {
    __typename: "Advert",
    adcategory?:  {
      __typename: "AdCategory",
      adcategoryId: string,
      name?: string | null,
      creationDate?: string | null,
      modificationDate?: string | null,
    } | null,
    creationDate?: string | null,
    description?: string | null,
    advertId: string,
    modificationDate?: string | null,
    name: string,
    time?: string | null,
    action?: string | null,
    image?: string | null,
  } | null > | null,
};

export type GetAdvertByIdQueryVariables = {
  advertId: string,
};

export type GetAdvertByIdQuery = {
  getAdvertById?:  {
    __typename: "Advert",
    adcategory?:  {
      __typename: "AdCategory",
      adcategoryId: string,
      name?: string | null,
      creationDate?: string | null,
      modificationDate?: string | null,
    } | null,
    creationDate?: string | null,
    description?: string | null,
    advertId: string,
    modificationDate?: string | null,
    name: string,
    time?: string | null,
    action?: string | null,
    image?: string | null,
  } | null,
};

export type GetAdCategoriesQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAdCategoriesQuery = {
  getAdCategories?:  {
    __typename: "PaginatedAdCategories",
    adcategories?:  Array< {
      __typename: "AdCategory",
      adcategoryId: string,
      name?: string | null,
      creationDate?: string | null,
      modificationDate?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAdCategoryByIdQueryVariables = {
  adcategoryId: string,
};

export type GetAdCategoryByIdQuery = {
  getAdCategoryById?:  {
    __typename: "AdCategory",
    adcategoryId: string,
    name?: string | null,
    creationDate?: string | null,
    modificationDate?: string | null,
  } | null,
};

export type GetAdCompanyQueryVariables = {
  limit?: number | null,
};

export type GetAdCompanyQuery = {
  getAdCompany?:  Array< {
    __typename: "AdCompany",
    adcompanyId: string,
    adcategory?:  {
      __typename: "AdCategory",
      adcategoryId: string,
      name?: string | null,
      creationDate?: string | null,
      modificationDate?: string | null,
    } | null,
    creationDate?: string | null,
    description?: string | null,
    modificationDate?: string | null,
    name: string,
    time?: string | null,
    action?: string | null,
    image?: string | null,
  } | null > | null,
};

export type FetchCartByUserIdQueryVariables = {
  userId: string,
};

export type FetchCartByUserIdQuery = {
  fetchCartByUserId?:  {
    __typename: "Cart",
    cartId?: string | null,
    cartItems?:  Array< {
      __typename: "CartItem",
      product?:  {
        __typename: "CartProduct",
        manufacturerUserId?: string | null,
        description?: string | null,
        productId?: string | null,
        name?: string | null,
        price?: number | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        weight?: number | null,
        quantity?: number | null,
        company_name?: string | null,
        manufacturerId?: string | null,
      } | null,
      quantity?: number | null,
      total?: number | null,
    } | null > | null,
    dmaId?: string | null,
    userId?: string | null,
    branchId?: string | null,
    cartStatus?: CartStatus | null,
    creationDate?: string | null,
    modificationDate?: string | null,
    paymentStatus?: string | null,
    paymentMethod?: string | null,
    mpesaTransactionId?: string | null,
    total?: number | null,
  } | null,
};

export type GetDmaByDmaIDQueryVariables = {
  dmaId: string,
};

export type GetDmaByDmaIDQuery = {
  getDmaByDmaID?:  {
    __typename: "DMAResponse",
    data?:  {
      __typename: "DMA",
      userId?: string | null,
      dmaId?: string | null,
      dmaType: DmaType,
      businessName: string,
      phoneNumber?: string | null,
      mail: string,
      location?: string | null,
      ward?: string | null,
      wardObj?: string | null,
      kyc_flow_state?: string | null,
      paymentAccounts?:  {
        __typename: "PaymentAccounts",
        payBillNumber?: string | null,
        tillNumber?: string | null,
        mpesaNumber?: string | null,
        accountNumber?: string | null,
      } | null,
      digiFarmHub?:  Array< {
        __typename: "DigiFarmHub",
        manufacturerId?: string | null,
        manufacturerName?: string | null,
        hubs?:  Array< {
          __typename: "Hub",
          hubId?: string | null,
          hubName?: string | null,
        } | null > | null,
      } | null > | null,
      creditLimit?: number | null,
      creationDate?: string | null,
      modificationDate?: string | null,
    } | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type GetBranchByIDQueryVariables = {
  branchId: string,
  dmaId: string,
};

export type GetBranchByIDQuery = {
  getBranchByID?:  {
    __typename: "SingleBranchResponse",
    data?:  {
      __typename: "Branch",
      branchId: string,
      dmaId: string,
      branchName?: string | null,
      location?: string | null,
      isHQ?: string | null,
      phoneNumber?: string | null,
      mail?: string | null,
      ward?: string | null,
      creationDate?: string | null,
    } | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type GetBranchesQueryVariables = {
  dmaId: string,
};

export type GetBranchesQuery = {
  getBranches?:  {
    __typename: "BranchResponse",
    data?:  Array< {
      __typename: "Branch",
      branchId: string,
      dmaId: string,
      branchName?: string | null,
      location?: string | null,
      isHQ?: string | null,
      phoneNumber?: string | null,
      mail?: string | null,
      ward?: string | null,
      creationDate?: string | null,
    } | null > | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type GetBranchUsersQueryVariables = {
  branchId: string,
};

export type GetBranchUsersQuery = {
  getBranchUsers?:  {
    __typename: "BranchUsersResponse",
    data?:  Array< {
      __typename: "User",
      dmaId: string,
      branchId?: string | null,
      collectionCentreId?: string | null,
      national_id?: string | null,
      username?: string | null,
      email_verified?: boolean | null,
      phone_number_verified?: boolean | null,
      group?: string | null,
      kyc_flow_state?: string | null,
      location?: string | null,
      name?: string | null,
      phone_number?: string | null,
      email?: string | null,
      roles?:  Array< {
        __typename: "Group",
        name: string,
        description?: string | null,
        creationDate?: string | null,
        modificationDate?: string | null,
      } | null > | null,
      enabled?: string | null,
      userStatus?: string | null,
    } | null > | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type GetFarmerTransactionQueryVariables = {
  farmerId: string,
};

export type GetFarmerTransactionQuery = {
  getFarmerTransaction?:  Array< {
    __typename: "FarmerTransaction",
    farmerId?: string | null,
    amount?: number | null,
    transactionType?: TransactionType | null,
    paymentMethod?: PaymentMethod | null,
    transactionId?: string | null,
    transactionDate?: string | null,
  } | null > | null,
};

export type GetDmaUserDetailsQueryVariables = {
  userId: string,
};

export type GetDmaUserDetailsQuery = {
  getDmaUserDetails?:  {
    __typename: "UserDetailsResponse",
    data?:  {
      __typename: "UserDetail",
      userDetail?:  {
        __typename: "User",
        dmaId: string,
        branchId?: string | null,
        collectionCentreId?: string | null,
        national_id?: string | null,
        username?: string | null,
        email_verified?: boolean | null,
        phone_number_verified?: boolean | null,
        group?: string | null,
        kyc_flow_state?: string | null,
        location?: string | null,
        name?: string | null,
        phone_number?: string | null,
        email?: string | null,
        roles?:  Array< {
          __typename: "Group",
          name: string,
          description?: string | null,
          creationDate?: string | null,
          modificationDate?: string | null,
        } | null > | null,
        enabled?: string | null,
        userStatus?: string | null,
      } | null,
      dmaDetail?:  {
        __typename: "DMA",
        userId?: string | null,
        dmaId?: string | null,
        dmaType: DmaType,
        businessName: string,
        phoneNumber?: string | null,
        mail: string,
        location?: string | null,
        ward?: string | null,
        wardObj?: string | null,
        kyc_flow_state?: string | null,
        paymentAccounts?:  {
          __typename: "PaymentAccounts",
          payBillNumber?: string | null,
          tillNumber?: string | null,
          mpesaNumber?: string | null,
          accountNumber?: string | null,
        } | null,
        digiFarmHub?:  Array< {
          __typename: "DigiFarmHub",
          manufacturerId?: string | null,
          manufacturerName?: string | null,
        } | null > | null,
        creditLimit?: number | null,
        creationDate?: string | null,
        modificationDate?: string | null,
      } | null,
      dmaBranchDetail?:  Array< {
        __typename: "Branch",
        branchId: string,
        dmaId: string,
        branchName?: string | null,
        location?: string | null,
        isHQ?: string | null,
        phoneNumber?: string | null,
        mail?: string | null,
        ward?: string | null,
        creationDate?: string | null,
      } | null > | null,
    } | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type GetFarmerQueryVariables = {
  farmerId: string,
};

export type GetFarmerQuery = {
  getFarmer?:  {
    __typename: "GetFarmerResponse",
    farmerDetails?:  {
      __typename: "Farmer",
      farmerId?: string | null,
      dmaId?: string | null,
      national_id?: string | null,
      member_number?: string | null,
      phone?: string | null,
      first_name?: string | null,
      middle_name?: string | null,
      last_name?: string | null,
      route?: string | null,
      supply_category?: string | null,
      MCC?: string | null,
      gender?: string | null,
      village?: string | null,
    } | null,
    farmerFinancialDetails?:  {
      __typename: "FarmerFinancial",
      farmerId?: string | null,
      avg_income?: string | null,
      limit?: string | null,
      df_exclusions?: string | null,
      segment?: string | null,
      score?: string | null,
      income_proxy_variable?: string | null,
      df_score_band?: string | null,
      df_income_proxy?: string | null,
      currentMonthRemainingCashAdvanceLimit?: number | null,
      currentMonthRemainingCreditLimit?: number | null,
      repayment?: number | null,
      currentMonthTotalCashEntitlement?: number | null,
    } | null,
    todayFarmerDeliveryDetails?:  {
      __typename: "CollectedMilkQuantity",
      morning?: number | null,
      evening?: number | null,
    } | null,
    currentMonthFarmerTransactions?:  Array< {
      __typename: "FarmerTransaction",
      farmerId?: string | null,
      amount?: number | null,
      transactionType?: TransactionType | null,
      paymentMethod?: PaymentMethod | null,
      transactionId?: string | null,
      transactionDate?: string | null,
    } | null > | null,
    currentMonthFarmerDelivery?:  Array< {
      __typename: "FarmerDelivery",
      farmerId?: string | null,
      collectionCenterId?: string | null,
      transporterId?: string | null,
      deliveryDate?: string | null,
      containerNumber?: number | null,
      product?: string | null,
      quantity?: number | null,
      unitPrice?: number | null,
      status?: FarmerDeliveryStatus | null,
    } | null > | null,
  } | null,
};

export type GetFarmerCashAdvanceLimitQueryVariables = {
  farmerId?: string | null,
  phone?: string | null,
};

export type GetFarmerCashAdvanceLimitQuery = {
  getFarmerCashAdvanceLimit?:  {
    __typename: "GetFarmerCashAdvanceLimitResponse",
    first_name?: string | null,
    middle_name?: string | null,
    last_name?: string | null,
    member_number?: string | null,
    phone?: string | null,
    cooperative?: string | null,
    cashAdvanceEntitlement?: number | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type GetFarmerCreditLimitQueryVariables = {
  farmerId?: string | null,
  phone?: string | null,
};

export type GetFarmerCreditLimitQuery = {
  getFarmerCreditLimit?:  {
    __typename: "GetFarmerCreditLimitResponse",
    first_name?: string | null,
    middle_name?: string | null,
    last_name?: string | null,
    member_number?: string | null,
    phone?: string | null,
    cooperative?: string | null,
    limit?: number | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type GetCollectionCentresQueryVariables = {
  dmaId: string,
};

export type GetCollectionCentresQuery = {
  getCollectionCentres?:  {
    __typename: "CollectionCentresResponse",
    data?:  Array< {
      __typename: "CollectionCentre",
      collectionCentreId: string,
      centreId: string,
      dmaId: string,
      centreName?: string | null,
      location?: string | null,
      creationDate?: string | null,
    } | null > | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type GetCollectionCentreByIDQueryVariables = {
  collectionCentreId: string,
};

export type GetCollectionCentreByIDQuery = {
  getCollectionCentreByID?:  {
    __typename: "CollectionCentreResponse",
    data?:  {
      __typename: "CollectionCentre",
      collectionCentreId: string,
      centreId: string,
      dmaId: string,
      centreName?: string | null,
      location?: string | null,
      creationDate?: string | null,
    } | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type GetDmaTransportersQueryVariables = {
  dmaId: string,
};

export type GetDmaTransportersQuery = {
  getDmaTransporters?:  {
    __typename: "BranchUsersResponse",
    data?:  Array< {
      __typename: "User",
      dmaId: string,
      branchId?: string | null,
      collectionCentreId?: string | null,
      national_id?: string | null,
      username?: string | null,
      email_verified?: boolean | null,
      phone_number_verified?: boolean | null,
      group?: string | null,
      kyc_flow_state?: string | null,
      location?: string | null,
      name?: string | null,
      phone_number?: string | null,
      email?: string | null,
      roles?:  Array< {
        __typename: "Group",
        name: string,
        description?: string | null,
        creationDate?: string | null,
        modificationDate?: string | null,
      } | null > | null,
      enabled?: string | null,
      userStatus?: string | null,
    } | null > | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type GetTransporterByIdQueryVariables = {
  transporterId: string,
};

export type GetTransporterByIdQuery = {
  getTransporterById?:  {
    __typename: "BranchUserResponse",
    data?:  {
      __typename: "User",
      dmaId: string,
      branchId?: string | null,
      collectionCentreId?: string | null,
      national_id?: string | null,
      username?: string | null,
      email_verified?: boolean | null,
      phone_number_verified?: boolean | null,
      group?: string | null,
      kyc_flow_state?: string | null,
      location?: string | null,
      name?: string | null,
      phone_number?: string | null,
      email?: string | null,
      roles?:  Array< {
        __typename: "Group",
        name: string,
        description?: string | null,
        creationDate?: string | null,
        modificationDate?: string | null,
      } | null > | null,
      enabled?: string | null,
      userStatus?: string | null,
    } | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type GetCollectionCentreUsersQueryVariables = {
  collectionCentreId: string,
};

export type GetCollectionCentreUsersQuery = {
  getCollectionCentreUsers?:  {
    __typename: "BranchUsersResponse",
    data?:  Array< {
      __typename: "User",
      dmaId: string,
      branchId?: string | null,
      collectionCentreId?: string | null,
      national_id?: string | null,
      username?: string | null,
      email_verified?: boolean | null,
      phone_number_verified?: boolean | null,
      group?: string | null,
      kyc_flow_state?: string | null,
      location?: string | null,
      name?: string | null,
      phone_number?: string | null,
      email?: string | null,
      roles?:  Array< {
        __typename: "Group",
        name: string,
        description?: string | null,
        creationDate?: string | null,
        modificationDate?: string | null,
      } | null > | null,
      enabled?: string | null,
      userStatus?: string | null,
    } | null > | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type TransporterGetTodayCollectedMilkQuantityQuery = {
  transporterGetTodayCollectedMilkQuantity?:  {
    __typename: "TransporterGetTodayCollectedMilkQuantityResponse",
    data?:  {
      __typename: "CollectedMilkQuantity",
      morning?: number | null,
      evening?: number | null,
    } | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type VerifyFarmerCooperativeAssociationQueryVariables = {
  verifyFarmer?: VerifyFarmerInput | null,
};

export type VerifyFarmerCooperativeAssociationQuery = {
  verifyFarmerCooperativeAssociation?:  {
    __typename: "VerifyFarmerResponse",
    code?: string | null,
    message?: string | null,
  } | null,
};

export type GetFarmerByPhoneQueryVariables = {
  phone?: string | null,
};

export type GetFarmerByPhoneQuery = {
  getFarmerByPhone?:  {
    __typename: "GetFarmerByPhone",
    code?: string | null,
    message?: string | null,
    data?:  {
      __typename: "Farmer",
      farmerId?: string | null,
      dmaId?: string | null,
      national_id?: string | null,
      member_number?: string | null,
      phone?: string | null,
      first_name?: string | null,
      middle_name?: string | null,
      last_name?: string | null,
      route?: string | null,
      supply_category?: string | null,
      MCC?: string | null,
      gender?: string | null,
      village?: string | null,
    } | null,
  } | null,
};

export type FarmerGetTodayCollectedMilkQuantityQueryVariables = {
  farmerId?: string | null,
};

export type FarmerGetTodayCollectedMilkQuantityQuery = {
  farmerGetTodayCollectedMilkQuantity?:  {
    __typename: "FarmerGetTodayCollectedMilkQuantityResponse",
    data?:  {
      __typename: "CollectedMilkQuantity",
      morning?: number | null,
      evening?: number | null,
    } | null,
    code?: string | null,
    message?: string | null,
  } | null,
};

export type GetDeliveryApprovalsQueryVariables = {
  collectionCenterId?: string | null,
  transporterId?: string | null,
};

export type GetDeliveryApprovalsQuery = {
  getDeliveryApprovals?:  {
    __typename: "getDeliveryApprovalsResponse",
    data?: string | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type GetAgrovetListQueryVariables = {
  businessName?: string | null,
  limit?: number | null,
  startKey?: string | null,
};

export type GetAgrovetListQuery = {
  getAgrovetList?:  {
    __typename: "PaginatedDmaResponse",
    data?:  Array< {
      __typename: "DMA",
      userId?: string | null,
      dmaId?: string | null,
      dmaType: DmaType,
      businessName: string,
      phoneNumber?: string | null,
      mail: string,
      location?: string | null,
      ward?: string | null,
      wardObj?: string | null,
      kyc_flow_state?: string | null,
      paymentAccounts?:  {
        __typename: "PaymentAccounts",
        payBillNumber?: string | null,
        tillNumber?: string | null,
        mpesaNumber?: string | null,
        accountNumber?: string | null,
      } | null,
      digiFarmHub?:  Array< {
        __typename: "DigiFarmHub",
        manufacturerId?: string | null,
        manufacturerName?: string | null,
        hubs?:  Array< {
          __typename: "Hub",
          hubId?: string | null,
          hubName?: string | null,
        } | null > | null,
      } | null > | null,
      creditLimit?: number | null,
      creationDate?: string | null,
      modificationDate?: string | null,
    } | null > | null,
    code?: string | null,
    message?: string | null,
    lastKey?: string | null,
  } | null,
};

export type GetCooperativeDmaPartnersQueryVariables = {
  cooperativeId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCooperativeDmaPartnersQuery = {
  getCooperativeDmaPartners?:  {
    __typename: "PaginatedDmaResponse",
    data?:  Array< {
      __typename: "DMA",
      userId?: string | null,
      dmaId?: string | null,
      dmaType: DmaType,
      businessName: string,
      phoneNumber?: string | null,
      mail: string,
      location?: string | null,
      ward?: string | null,
      wardObj?: string | null,
      kyc_flow_state?: string | null,
      paymentAccounts?:  {
        __typename: "PaymentAccounts",
        payBillNumber?: string | null,
        tillNumber?: string | null,
        mpesaNumber?: string | null,
        accountNumber?: string | null,
      } | null,
      digiFarmHub?:  Array< {
        __typename: "DigiFarmHub",
        manufacturerId?: string | null,
        manufacturerName?: string | null,
        hubs?:  Array< {
          __typename: "Hub",
          hubId?: string | null,
          hubName?: string | null,
        } | null > | null,
      } | null > | null,
      creditLimit?: number | null,
      creationDate?: string | null,
      modificationDate?: string | null,
    } | null > | null,
    code?: string | null,
    message?: string | null,
    lastKey?: string | null,
  } | null,
};

export type GetAgrovetDmaPartnersQueryVariables = {
  agrovetId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAgrovetDmaPartnersQuery = {
  getAgrovetDmaPartners?:  {
    __typename: "PaginatedDmaResponse",
    data?:  Array< {
      __typename: "DMA",
      userId?: string | null,
      dmaId?: string | null,
      dmaType: DmaType,
      businessName: string,
      phoneNumber?: string | null,
      mail: string,
      location?: string | null,
      ward?: string | null,
      wardObj?: string | null,
      kyc_flow_state?: string | null,
      paymentAccounts?:  {
        __typename: "PaymentAccounts",
        payBillNumber?: string | null,
        tillNumber?: string | null,
        mpesaNumber?: string | null,
        accountNumber?: string | null,
      } | null,
      digiFarmHub?:  Array< {
        __typename: "DigiFarmHub",
        manufacturerId?: string | null,
        manufacturerName?: string | null,
        hubs?:  Array< {
          __typename: "Hub",
          hubId?: string | null,
          hubName?: string | null,
        } | null > | null,
      } | null > | null,
      creditLimit?: number | null,
      creationDate?: string | null,
      modificationDate?: string | null,
    } | null > | null,
    code?: string | null,
    message?: string | null,
    lastKey?: string | null,
  } | null,
};

export type VerifyDmaPartnerShipQueryVariables = {
  cooperativeId: string,
  agrovetId: string,
};

export type VerifyDmaPartnerShipQuery = {
  verifyDmaPartnerShip?:  {
    __typename: "DMAPartnershipResponse",
    data?: string | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type GetDmastocksQuery = {
  getDmastocks?:  Array< {
    __typename: "Dmastock",
    dmastockId?: string | null,
    manufacturerUserId?: string | null,
    manufacturerName?: string | null,
    dmaUserId?: string | null,
    dmaId?: string | null,
    branchId?: string | null,
    creationDate?: string | null,
    description?: string | null,
    modificationDate?: string | null,
    name: string,
    buying_price?: number | null,
    selling_price?: number | null,
    stockSubCategoryId?: string | null,
    unit?: string | null,
    type?: string | null,
    image?: Array< string | null > | null,
    quantity?: number | null,
    status?: string | null,
    weight?: string | null,
    bulkUploadProcessStatus?: string | null,
    bulkUploadProcessId?: string | null,
    rebates?: number | null,
  } | null > | null,
};

export type GetDmastockByIdQueryVariables = {
  dmastockId: string,
};

export type GetDmastockByIdQuery = {
  getDmastockById?:  {
    __typename: "Dmastock",
    dmastockId?: string | null,
    manufacturerUserId?: string | null,
    manufacturerName?: string | null,
    dmaUserId?: string | null,
    dmaId?: string | null,
    branchId?: string | null,
    creationDate?: string | null,
    description?: string | null,
    modificationDate?: string | null,
    name: string,
    buying_price?: number | null,
    selling_price?: number | null,
    stockSubCategoryId?: string | null,
    unit?: string | null,
    type?: string | null,
    image?: Array< string | null > | null,
    quantity?: number | null,
    status?: string | null,
    weight?: string | null,
    bulkUploadProcessStatus?: string | null,
    bulkUploadProcessId?: string | null,
    rebates?: number | null,
  } | null,
};

export type GetStockRequestByIdQueryVariables = {
  stockRequestId: string,
};

export type GetStockRequestByIdQuery = {
  getStockRequestById?:  {
    __typename: "StockResponseMessage",
    status?: ResponseStatus | null,
    code?: number | null,
    data?:  {
      __typename: "StockRequest",
      stockRequestId: string,
      branchId?: string | null,
      productId?: string | null,
      dmaId?: string | null,
      name?: string | null,
      type?: string | null,
      unit?: string | null,
      quantity?: number | null,
      status?: string | null,
      creationDate?: string | null,
      product?:  {
        __typename: "RequestProduct",
        description?: string | null,
        productId: string,
        name: string,
        price: number,
        subCategoryName?: string | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        quantity?: number | null,
        status?: string | null,
        weight?: number | null,
        manufacturerUserId: string,
        creationDate?: string | null,
        modificationDate?: string | null,
        subCategoryId: string,
        rebates?: number | null,
        dmaPrice?: number | null,
        company_name?: string | null,
      } | null,
      branch?:  {
        __typename: "RequestBranch",
        branchId: string,
        dmaId: string,
        branchName?: string | null,
        location?: string | null,
        isHQ?: string | null,
        phoneNumber?: string | null,
        mail?: string | null,
        ward?: string | null,
        creationDate?: string | null,
      } | null,
    } | null,
    message?: string | null,
  } | null,
};

export type GetFarmerOrdersQuery = {
  getFarmerOrders?:  Array< {
    __typename: "FarmerOrder",
    farmerOrderId?: string | null,
    memberNumber?: string | null,
    farmerName?: string | null,
    totalCost?: string | null,
    paymentMethod?: PaymentMethod | null,
    product?:  Array< {
      __typename: "FarmerOrderProductDetailed",
      quantity?: number | null,
      product?:  {
        __typename: "Dmastock",
        dmastockId?: string | null,
        manufacturerUserId?: string | null,
        manufacturerName?: string | null,
        dmaUserId?: string | null,
        dmaId?: string | null,
        branchId?: string | null,
        creationDate?: string | null,
        description?: string | null,
        modificationDate?: string | null,
        name: string,
        buying_price?: number | null,
        selling_price?: number | null,
        stockSubCategoryId?: string | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        quantity?: number | null,
        status?: string | null,
        weight?: string | null,
        bulkUploadProcessStatus?: string | null,
        bulkUploadProcessId?: string | null,
        rebates?: number | null,
      } | null,
      price?: number | null,
      selling_price?: number | null,
    } | null > | null,
    creationDate?: string | null,
    paymentStatus?: PaymentStatus | null,
    farmerOrderStatus?: string | null,
    dmaName?: string | null,
    dmaUserId?: string | null,
    dmaId?: string | null,
    branchId?: string | null,
    mpesaTransactionId?: string | null,
  } | null > | null,
};

export type GetFarmerOrdersByFarmerMemberNameQueryVariables = {
  memberName?: string | null,
};

export type GetFarmerOrdersByFarmerMemberNameQuery = {
  getFarmerOrdersByFarmerMemberName?:  Array< {
    __typename: "FarmerOrder",
    farmerOrderId?: string | null,
    memberNumber?: string | null,
    farmerName?: string | null,
    totalCost?: string | null,
    paymentMethod?: PaymentMethod | null,
    product?:  Array< {
      __typename: "FarmerOrderProductDetailed",
      quantity?: number | null,
      product?:  {
        __typename: "Dmastock",
        dmastockId?: string | null,
        manufacturerUserId?: string | null,
        manufacturerName?: string | null,
        dmaUserId?: string | null,
        dmaId?: string | null,
        branchId?: string | null,
        creationDate?: string | null,
        description?: string | null,
        modificationDate?: string | null,
        name: string,
        buying_price?: number | null,
        selling_price?: number | null,
        stockSubCategoryId?: string | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        quantity?: number | null,
        status?: string | null,
        weight?: string | null,
        bulkUploadProcessStatus?: string | null,
        bulkUploadProcessId?: string | null,
        rebates?: number | null,
      } | null,
      price?: number | null,
      selling_price?: number | null,
    } | null > | null,
    creationDate?: string | null,
    paymentStatus?: PaymentStatus | null,
    farmerOrderStatus?: string | null,
    dmaName?: string | null,
    dmaUserId?: string | null,
    dmaId?: string | null,
    branchId?: string | null,
    mpesaTransactionId?: string | null,
  } | null > | null,
};

export type GetFarmerOrderByIdQueryVariables = {
  farmerOrderId: string,
};

export type GetFarmerOrderByIdQuery = {
  getFarmerOrderById?:  {
    __typename: "FarmerOrder",
    farmerOrderId?: string | null,
    memberNumber?: string | null,
    farmerName?: string | null,
    totalCost?: string | null,
    paymentMethod?: PaymentMethod | null,
    product?:  Array< {
      __typename: "FarmerOrderProductDetailed",
      quantity?: number | null,
      product?:  {
        __typename: "Dmastock",
        dmastockId?: string | null,
        manufacturerUserId?: string | null,
        manufacturerName?: string | null,
        dmaUserId?: string | null,
        dmaId?: string | null,
        branchId?: string | null,
        creationDate?: string | null,
        description?: string | null,
        modificationDate?: string | null,
        name: string,
        buying_price?: number | null,
        selling_price?: number | null,
        stockSubCategoryId?: string | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        quantity?: number | null,
        status?: string | null,
        weight?: string | null,
        bulkUploadProcessStatus?: string | null,
        bulkUploadProcessId?: string | null,
        rebates?: number | null,
      } | null,
      price?: number | null,
      selling_price?: number | null,
    } | null > | null,
    creationDate?: string | null,
    paymentStatus?: PaymentStatus | null,
    farmerOrderStatus?: string | null,
    dmaName?: string | null,
    dmaUserId?: string | null,
    dmaId?: string | null,
    branchId?: string | null,
    mpesaTransactionId?: string | null,
  } | null,
};

export type GetDmaStockDashboardQuery = {
  getDmaStockDashboard?:  {
    __typename: "DmaDashbaord",
    dmasales?: number | null,
  } | null,
};

export type GetDmaStockDashboardChartQuery = {
  getDmaStockDashboardChart?: Array< string | null > | null,
};

export type GetUSSDFarmerOrderQueryVariables = {
  startDate?: string | null,
  endDate?: string | null,
  page?: number | null,
  size?: number | null,
  orderType?: OrderType | null,
};

export type GetUSSDFarmerOrderQuery = {
  getUSSDFarmerOrder?:  {
    __typename: "USSDFarmerOrderResponse",
    header?:  {
      __typename: "USSDFarmerOrderHeader",
      requestRefId?: string | null,
      responseCode?: number | null,
      responseMessage?: string | null,
      customerMessage?: string | null,
      timestamp?: string | null,
    } | null,
    body?:  {
      __typename: "USSDFarmerOrderBody",
      numberOfPages?: number | null,
      hasNextPage?: boolean | null,
      hasPreviousPage?: boolean | null,
      totalNumberOfElements?: number | null,
      data?:  Array< {
        __typename: "USSDFarmerOrderData",
        orderId?: string | null,
        farmerMsisdn?: string | null,
        sessionId?: string | null,
        dmaShopName?: string | null,
        dmaFirstName?: string | null,
        dmaLastName?: string | null,
        dmaMsisdn?: string | null,
        region?: string | null,
        county?: string | null,
        subCounty?: string | null,
        ward?: string | null,
        orderType?: string | null,
        preOrderDuration?: string | null,
        preOrderDeliveryDate?: string | null,
        orderTotalCost?: number | null,
        orderPaymentStatus?: string | null,
        orderPaymentTransactionId?: string | null,
        orderTotalRebateCost?: number | null,
        rebatePaymentStatus?: string | null,
        rebatePaymentTransactionId?: string | null,
        createdDateTime?: string | null,
        updatedDateTime?: string | null,
        farmerModel?:  {
          __typename: "USSDFarmerOrderFarmerModel",
          farmerId?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          memberNumber?: string | null,
        } | null,
        orderInputs?:  Array< {
          __typename: "USSDFarmerOrderOrderInputs",
          orderInputId?: number | null,
          inputId?: number | null,
          inputName?: string | null,
          packageQuantity?: string | null,
          price?: number | null,
          rebate?: number | null,
          inputCategory?: string | null,
          valueChain?: string | null,
          orderedQty?: number | null,
          totalInputCost?: number | null,
          totalRebateCost?: number | null,
        } | null > | null,
      } | null > | null,
    } | null,
    responseID?: string | null,
    responseCode?: string | null,
    responseDesc?: string | null,
    status?: string | null,
    version?: string | null,
    lastModified?: string | null,
  } | null,
};

export type GetDmastockUpdateHistoryByIdQueryVariables = {
  dmastockId: string,
};

export type GetDmastockUpdateHistoryByIdQuery = {
  getDmastockUpdateHistoryById?:  {
    __typename: "DmastockUpdateHistory",
    dmastockId: string,
    name: string,
    bulkUploadProcessStatus: string,
    updateHistory?:  Array< {
      __typename: "UpdateHistory",
      user?: string | null,
      modificationDate?: string | null,
      newVersion?:  {
        __typename: "UpdateHistoryVersion",
        name?: string | null,
        quantity?: number | null,
        rebates?: number | null,
        selling_price?: number | null,
        unit?: string | null,
        buying_price?: number | null,
        bulkUploadProcessStatus?: string | null,
        bulkUploadProcessId?: string | null,
      } | null,
      oldVersion?:  {
        __typename: "UpdateHistoryVersion",
        name?: string | null,
        quantity?: number | null,
        rebates?: number | null,
        selling_price?: number | null,
        unit?: string | null,
        buying_price?: number | null,
        bulkUploadProcessStatus?: string | null,
        bulkUploadProcessId?: string | null,
      } | null,
    } | null > | null,
  } | null,
};

export type FetchDmaStockReconciliationRecordsQueryVariables = {
  from: string,
  to: string,
  dmaId?: string | null,
  dmaBranchId?: string | null,
};

export type FetchDmaStockReconciliationRecordsQuery = {
  fetchDmaStockReconciliationRecords?:  {
    __typename: "FetchDmaStockReconciliationRecordResponse",
    data?:  Array< {
      __typename: "DmaStockReconciliationRecord",
      dmastockId: string,
      dmaId: string,
      branchId: string,
      creditedCount: number,
      verifiedCount: number,
      purchasedCount: number,
      collectedCount: number,
      dma: string,
      branch: string,
      product: string,
      date: string,
      user: string,
    } > | null,
    code: number,
    message?: string | null,
  } | null,
};

export type FetchDmaStockReconciliationRecordByIdQueryVariables = {
  dmaStockId: string,
};

export type FetchDmaStockReconciliationRecordByIdQuery = {
  fetchDmaStockReconciliationRecordById?:  {
    __typename: "DmaStockReconciliationRecord",
    dmastockId: string,
    dmaId: string,
    branchId: string,
    creditedCount: number,
    verifiedCount: number,
    purchasedCount: number,
    collectedCount: number,
    dma: string,
    branch: string,
    product: string,
    date: string,
    user: string,
  } | null,
};

export type GetManufacturerHubDetailsQueryVariables = {
  hubId: string,
  manufacturerId: string,
};

export type GetManufacturerHubDetailsQuery = {
  getManufacturerHubDetails?:  {
    __typename: "HubDetailsResponseDTO",
    data?:  {
      __typename: "HubResponse",
      location?: string | null,
      hubName?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      hubId?: string | null,
      manufacturerId?: string | null,
      address?: string | null,
      creationDate?: string | null,
      modificationDate?: string | null,
    } | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type GetManufacturerHubsQueryVariables = {
  manufacturerId: string,
};

export type GetManufacturerHubsQuery = {
  getManufacturerHubs?:  {
    __typename: "HubsListResponseDTO",
    data?:  Array< {
      __typename: "HubResponse",
      location?: string | null,
      hubName?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      hubId?: string | null,
      manufacturerId?: string | null,
      address?: string | null,
      creationDate?: string | null,
      modificationDate?: string | null,
    } | null > | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type GetOrderByIdQueryVariables = {
  orderId: string,
  manufacturerId: string,
};

export type GetOrderByIdQuery = {
  getOrderById?:  {
    __typename: "Order",
    orderId?: string | null,
    manufacturerId?: string | null,
    dmaId?: string | null,
    branchId?: string | null,
    orderItems?:  Array< {
      __typename: "OrderItem",
      product?:  {
        __typename: "OrderProduct",
        productId?: string | null,
        manufacturerUserId?: string | null,
        description?: string | null,
        name?: string | null,
        company_name?: string | null,
        price?: number | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        weight?: number | null,
        quantity?: number | null,
      } | null,
      quantity?: number | null,
      availableQuantity?: number | null,
      receivedQuantity?: number | null,
      total?: number | null,
    } | null > | null,
    orderStatus?: string | null,
    paymentStatus?: string | null,
    paymentMethod?: string | null,
    creationDate?: string | null,
    modificationDate?: string | null,
    rebates?: number | null,
  } | null,
};

export type FetchManufacturerPlacardQuery = {
  fetchManufacturerPlacard?:  {
    __typename: "DashboardReport",
    manufacturersales?: number | null,
  } | null,
};

export type FetchManufacturerChartQuery = {
  fetchManufacturerChart?: Array< string | null > | null,
};

export type PushQueryQueryVariables = {
  CheckoutRequestID?: string | null,
};

export type PushQueryQuery = {
  pushQuery:  {
    __typename: "MpesaQueryResponse",
    MerchantRequestID?: string | null,
    CheckoutRequestID?: string | null,
    ResponseCode?: string | null,
    ResultDesc?: string | null,
    ResponseDescription?: string | null,
    ResultCode?: string | null,
    requestId?: string | null,
    errorCode?: string | null,
    errorMessage?: string | null,
  },
};

export type GetPaymentDetailsQueryVariables = {
  CheckoutRequestID: string,
};

export type GetPaymentDetailsQuery = {
  getPaymentDetails?:  Array< {
    __typename: "Payment",
    CheckoutRequestID?: string | null,
    MerchantRequestID?: string | null,
    ResultCode?: number | null,
    ResultDesc?: string | null,
    buyerId?: string | null,
    creationDate?: string | null,
    deliveryId?: string | null,
    CallbackMetadata?:  {
      __typename: "MetaData",
      Item?:  Array< {
        __typename: "MetaDataList",
        Name?: string | null,
        Value?: string | null,
      } | null > | null,
    } | null,
  } | null > | null,
};

export type GetProductsQuery = {
  getProducts:  Array< {
    __typename: "Product",
    manufacturerUserId?: string | null,
    creationDate?: string | null,
    description?: string | null,
    productId: string,
    modificationDate?: string | null,
    name: string,
    price: number,
    subCategoryId: string,
    subCategoryName?: string | null,
    unit?: string | null,
    type?: string | null,
    image?: Array< string | null > | null,
    quantity?: number | null,
    status?: string | null,
    weight?: number | null,
    rebates?: number | null,
    dmaPrice?: number | null,
    company_name?: string | null,
    manufacturerId: string,
    hubId: string,
  } >,
};

export type GetProductByIdQueryVariables = {
  productId: string,
};

export type GetProductByIdQuery = {
  getProductById?:  {
    __typename: "Product",
    manufacturerUserId?: string | null,
    creationDate?: string | null,
    description?: string | null,
    productId: string,
    modificationDate?: string | null,
    name: string,
    price: number,
    subCategoryId: string,
    subCategoryName?: string | null,
    unit?: string | null,
    type?: string | null,
    image?: Array< string | null > | null,
    quantity?: number | null,
    status?: string | null,
    weight?: number | null,
    rebates?: number | null,
    dmaPrice?: number | null,
    company_name?: string | null,
    manufacturerId: string,
    hubId: string,
  } | null,
};

export type GetCategoriesQueryVariables = {
  ids?: Array< string > | null,
};

export type GetCategoriesQuery = {
  getCategories:  Array< {
    __typename: "Category",
    categoryId: string,
    name?: string | null,
    subCategory?:  Array< {
      __typename: "SubCategory",
      subCategoryId: string,
      categoryId: string,
      name?: string | null,
      creationDate?: string | null,
      modificationDate?: string | null,
      image?: string | null,
    } | null > | null,
    creationDate?: string | null,
    modificationDate?: string | null,
    image?: string | null,
  } | null >,
};

export type GetCategoryByIdQueryVariables = {
  categoryId: string,
};

export type GetCategoryByIdQuery = {
  getCategoryById?:  {
    __typename: "Category",
    categoryId: string,
    name?: string | null,
    subCategory?:  Array< {
      __typename: "SubCategory",
      subCategoryId: string,
      categoryId: string,
      name?: string | null,
      creationDate?: string | null,
      modificationDate?: string | null,
      image?: string | null,
    } | null > | null,
    creationDate?: string | null,
    modificationDate?: string | null,
    image?: string | null,
  } | null,
};

export type GetSubCategoriesQueryVariables = {
  ids?: Array< string > | null,
};

export type GetSubCategoriesQuery = {
  getSubCategories:  Array< {
    __typename: "SubCategory",
    subCategoryId: string,
    categoryId: string,
    name?: string | null,
    creationDate?: string | null,
    modificationDate?: string | null,
    image?: string | null,
  } | null >,
};

export type GetSubCategoryByIdQueryVariables = {
  subCategoryId: string,
};

export type GetSubCategoryByIdQuery = {
  getSubCategoryById?:  {
    __typename: "SubCategory",
    subCategoryId: string,
    categoryId: string,
    name?: string | null,
    creationDate?: string | null,
    modificationDate?: string | null,
    image?: string | null,
  } | null,
};

export type SearchOrdersQueryVariables = {
  q?: string | null,
  limit?: number | null,
  page?: number | null,
  manufacturerId?: string | null,
  dmaId?: string | null,
  orderId?: string | null,
  paymentStatus?: string | null,
  orderStatus?: string | null,
  creationDate?: string | null,
  userId?: string | null,
  hubId?: string | null,
  gte?: string | null,
  lte?: string | null,
};

export type SearchOrdersQuery = {
  searchOrders?:  {
    __typename: "SearchOrderResult",
    totalCount?: number | null,
    page?: number | null,
    result?:  Array< {
      __typename: "NestedOrders",
      orderId?: string | null,
      dmaId?: string | null,
      userId?: string | null,
      dmaName?: string | null,
      dmaLocation?: string | null,
      dmaPhone?: string | null,
      manufacturerId?: string | null,
      orderStatus?: string | null,
      paymentStatus?: string | null,
      paymentMethod?: string | null,
      mpesaTransactionId?: string | null,
      creationDate?: string | null,
      orderItems?:  Array< {
        __typename: "NestedOrderItem",
        product?:  {
          __typename: "NestedOrderProduct",
          productId?: string | null,
          manufacturerUserId?: string | null,
          description?: string | null,
          company_name?: string | null,
          name?: string | null,
          price?: number | null,
          unit?: string | null,
          type?: string | null,
          image?: Array< string | null > | null,
          weight?: number | null,
          quantity?: number | null,
        } | null,
        quantity?: number | null,
        availableQuantity?: number | null,
        receivedQuantity?: number | null,
        total?: number | null,
      } | null > | null,
      company_name?: string | null,
      rebates?: number | null,
      dma?:  {
        __typename: "NestedOrderDmaDetailResult",
        businessName?: string | null,
        dmaType?: string | null,
        location?: string | null,
        phoneNumber?: string | null,
        ward?: string | null,
        creationDate?: string | null,
      } | null,
      hub?:  {
        __typename: "NestedOrderHubDetailResult",
        hubName?: string | null,
        hubId?: string | null,
      } | null,
    } | null > | null,
    aggregations?: string | null,
  } | null,
};

export type SearchOrdersAdminQueryVariables = {
  q?: string | null,
  limit?: number | null,
  page?: number | null,
  manufacturerId?: string | null,
  dmaId?: string | null,
  orderId?: string | null,
  paymentStatus?: string | null,
  orderStatus?: string | null,
  creationDate?: string | null,
  userId?: string | null,
  hubId?: string | null,
  gte?: string | null,
  lte?: string | null,
};

export type SearchOrdersAdminQuery = {
  searchOrdersAdmin?:  {
    __typename: "SearchOrderResult",
    totalCount?: number | null,
    page?: number | null,
    result?:  Array< {
      __typename: "NestedOrders",
      orderId?: string | null,
      dmaId?: string | null,
      userId?: string | null,
      dmaName?: string | null,
      dmaLocation?: string | null,
      dmaPhone?: string | null,
      manufacturerId?: string | null,
      orderStatus?: string | null,
      paymentStatus?: string | null,
      paymentMethod?: string | null,
      mpesaTransactionId?: string | null,
      creationDate?: string | null,
      orderItems?:  Array< {
        __typename: "NestedOrderItem",
        product?:  {
          __typename: "NestedOrderProduct",
          productId?: string | null,
          manufacturerUserId?: string | null,
          description?: string | null,
          company_name?: string | null,
          name?: string | null,
          price?: number | null,
          unit?: string | null,
          type?: string | null,
          image?: Array< string | null > | null,
          weight?: number | null,
          quantity?: number | null,
        } | null,
        quantity?: number | null,
        availableQuantity?: number | null,
        receivedQuantity?: number | null,
        total?: number | null,
      } | null > | null,
      company_name?: string | null,
      rebates?: number | null,
      dma?:  {
        __typename: "NestedOrderDmaDetailResult",
        businessName?: string | null,
        dmaType?: string | null,
        location?: string | null,
        phoneNumber?: string | null,
        ward?: string | null,
        creationDate?: string | null,
      } | null,
      hub?:  {
        __typename: "NestedOrderHubDetailResult",
        hubName?: string | null,
        hubId?: string | null,
      } | null,
    } | null > | null,
    aggregations?: string | null,
  } | null,
};

export type SearchProductsQueryVariables = {
  q?: string | null,
  limit?: number | null,
  page?: number | null,
  upper?: string | null,
  lower?: string | null,
  status?: string | null,
  type?: string | null,
  company_name?: Array< string | null > | null,
  manufacturerUserId?: string | null,
  category?: Array< string | null > | null,
  hubId?: Array< string | null > | null,
};

export type SearchProductsQuery = {
  searchProducts?:  {
    __typename: "SearchProductResult",
    totalCount?: number | null,
    page?: number | null,
    result?:  Array< {
      __typename: "NestedProducts",
      creationDate?: string | null,
      description?: string | null,
      productId: string,
      modificationDate?: string | null,
      name: string,
      price: number,
      subCategoryId: string,
      unit?: string | null,
      type?: string | null,
      image?: Array< string | null > | null,
      quantity?: number | null,
      status?: string | null,
      manufacturerUserId?: string | null,
      weight?: number | null,
      category?: string | null,
      subCategory?: string | null,
      company_name?: string | null,
      dmaPrice?: number | null,
      rebates?: number | null,
      manufacturerId?: string | null,
      hubId?: string | null,
    } | null > | null,
    aggregations?: string | null,
  } | null,
};

export type SearchProductsAdminQueryVariables = {
  q?: string | null,
  limit?: number | null,
  page?: number | null,
  upper?: string | null,
  lower?: string | null,
  status?: string | null,
  type?: string | null,
  company_name?: Array< string | null > | null,
  manufacturerUserId?: string | null,
  category?: Array< string | null > | null,
  manufacturerId?: string | null,
  hubId?: Array< string | null > | null,
};

export type SearchProductsAdminQuery = {
  searchProductsAdmin?:  {
    __typename: "SearchProductResult",
    totalCount?: number | null,
    page?: number | null,
    result?:  Array< {
      __typename: "NestedProducts",
      creationDate?: string | null,
      description?: string | null,
      productId: string,
      modificationDate?: string | null,
      name: string,
      price: number,
      subCategoryId: string,
      unit?: string | null,
      type?: string | null,
      image?: Array< string | null > | null,
      quantity?: number | null,
      status?: string | null,
      manufacturerUserId?: string | null,
      weight?: number | null,
      category?: string | null,
      subCategory?: string | null,
      company_name?: string | null,
      dmaPrice?: number | null,
      rebates?: number | null,
      manufacturerId?: string | null,
      hubId?: string | null,
    } | null > | null,
    aggregations?: string | null,
  } | null,
};

export type SearchProductsCatQueryVariables = {
  category?: Array< string | null > | null,
  limit?: number | null,
  page?: number | null,
  upper?: string | null,
  lower?: string | null,
};

export type SearchProductsCatQuery = {
  searchProductsCat?:  {
    __typename: "SearchProductResult",
    totalCount?: number | null,
    page?: number | null,
    result?:  Array< {
      __typename: "NestedProducts",
      creationDate?: string | null,
      description?: string | null,
      productId: string,
      modificationDate?: string | null,
      name: string,
      price: number,
      subCategoryId: string,
      unit?: string | null,
      type?: string | null,
      image?: Array< string | null > | null,
      quantity?: number | null,
      status?: string | null,
      manufacturerUserId?: string | null,
      weight?: number | null,
      category?: string | null,
      subCategory?: string | null,
      company_name?: string | null,
      dmaPrice?: number | null,
      rebates?: number | null,
      manufacturerId?: string | null,
      hubId?: string | null,
    } | null > | null,
    aggregations?: string | null,
  } | null,
};

export type SearchDmastockQueryVariables = {
  q?: string | null,
  limit?: number | null,
  page?: number | null,
  upper?: string | null,
  lower?: string | null,
  status?: string | null,
  type?: string | null,
  company_name?: string | null,
  dmaId?: string | null,
  branchId?: string | null,
  userId?: string | null,
  category?: Array< string | null > | null,
  name?: string | null,
  unit?: string | null,
};

export type SearchDmastockQuery = {
  searchDmastock?:  {
    __typename: "SearchDmastockResult",
    totalCount?: number | null,
    page?: number | null,
    result?:  Array< {
      __typename: "DmastockResult",
      selling_price?: number | null,
      dmaUserId?: string | null,
      dmastockId?: string | null,
      buying_price?: number | null,
      productId?: string | null,
      name?: string | null,
      quantity?: string | null,
      image?: Array< string | null > | null,
      description?: string | null,
      manufacturerUserId?: string | null,
      manufacturerName?: string | null,
      creationDate?: string | null,
      stockSubCategoryId?: string | null,
      stockcategoryId?: string | null,
      id?: string | null,
      weight?: string | null,
      type?: string | null,
      unit?: string | null,
      bulkUploadProcessStatus?: string | null,
      bulkUploadProcessId?: string | null,
    } | null > | null,
    aggregations?: string | null,
  } | null,
};

export type SearchDmastockAdminQueryVariables = {
  q?: string | null,
  limit?: number | null,
  page?: number | null,
  upper?: string | null,
  lower?: string | null,
  status?: string | null,
  type?: string | null,
  company_name?: string | null,
  dmaId?: string | null,
  branchId?: string | null,
  userId?: string | null,
  category?: Array< string | null > | null,
  bulkUploadProcessId?: string | null,
  bulkUploadProcessStatus?: BulkUploadStatus | null,
};

export type SearchDmastockAdminQuery = {
  searchDmastockAdmin?:  {
    __typename: "SearchDmastockResult",
    totalCount?: number | null,
    page?: number | null,
    result?:  Array< {
      __typename: "DmastockResult",
      selling_price?: number | null,
      dmaUserId?: string | null,
      dmastockId?: string | null,
      buying_price?: number | null,
      productId?: string | null,
      name?: string | null,
      quantity?: string | null,
      image?: Array< string | null > | null,
      description?: string | null,
      manufacturerUserId?: string | null,
      manufacturerName?: string | null,
      creationDate?: string | null,
      stockSubCategoryId?: string | null,
      stockcategoryId?: string | null,
      id?: string | null,
      weight?: string | null,
      type?: string | null,
      unit?: string | null,
      bulkUploadProcessStatus?: string | null,
      bulkUploadProcessId?: string | null,
    } | null > | null,
    aggregations?: string | null,
  } | null,
};

export type SearchStockMobileQueryVariables = {
  query?: string | null,
  limit?: number | null,
  page?: number | null,
  upper?: string | null,
  lower?: string | null,
  type?: string | null,
  company_name?: string | null,
  dmaId?: string | null,
  category?: Array< string | null > | null,
};

export type SearchStockMobileQuery = {
  searchStockMobile?:  {
    __typename: "SearchDmastockResult",
    totalCount?: number | null,
    page?: number | null,
    result?:  Array< {
      __typename: "DmastockResult",
      selling_price?: number | null,
      dmaUserId?: string | null,
      dmastockId?: string | null,
      buying_price?: number | null,
      productId?: string | null,
      name?: string | null,
      quantity?: string | null,
      image?: Array< string | null > | null,
      description?: string | null,
      manufacturerUserId?: string | null,
      manufacturerName?: string | null,
      creationDate?: string | null,
      stockSubCategoryId?: string | null,
      stockcategoryId?: string | null,
      id?: string | null,
      weight?: string | null,
      type?: string | null,
      unit?: string | null,
      bulkUploadProcessStatus?: string | null,
      bulkUploadProcessId?: string | null,
    } | null > | null,
    aggregations?: string | null,
  } | null,
};

export type SearchFarmerOrdersQueryVariables = {
  q?: string | null,
  limit?: number | null,
  page?: number | null,
  dmaId?: string | null,
  branchId?: string | null,
  farmerNumber?: string | null,
  farmerorderId?: string | null,
  paymentStatus?: string | null,
  orderStatus?: string | null,
  dmaName?: string | null,
  origin?: string | null,
  deliveryType?: string | null,
  fromDate?: string | null,
  toDate?: string | null,
  farmerName?: string | null,
};

export type SearchFarmerOrdersQuery = {
  searchFarmerOrders?:  {
    __typename: "SearchFarmerOrderResult",
    totalCount?: number | null,
    page?: number | null,
    result?:  Array< {
      __typename: "NestedFarmerOrders",
      farmerOrderId?: string | null,
      memberNumber?: string | null,
      dmaId?: string | null,
      dmaName?: string | null,
      rebates?: number | null,
      branchId?: string | null,
      paymentMethod?: string | null,
      farmerOrderStatus?: string | null,
      deliveryType?: string | null,
      origin?: string | null,
      creationDate?: string | null,
      farmerName?: string | null,
      farmerNumber?: string | null,
      paymentStatus?: string | null,
      totalCost?: string | null,
      id?: string | null,
      mpesaTransactionId?: string | null,
      product?:  Array< {
        __typename: "ResultFarmerOrderProduct",
        quantity?: number | null,
        total?: string | null,
        product?:  {
          __typename: "FarmerOrderProduct",
          quantity?: number | null,
          dmastockId?: string | null,
          price?: number | null,
          selling_price?: number | null,
          productId?: string | null,
          manufacturerName?: string | null,
          buying_price?: number | null,
          weight?: string | null,
          manufacturerUserId?: string | null,
          description?: string | null,
          creationDate?: string | null,
          type?: string | null,
          unit?: string | null,
          total?: string | null,
          dmaUserId?: string | null,
          company_name?: string | null,
          name?: string | null,
          status?: string | null,
          image?: Array< string | null > | null,
        } | null,
      } | null > | null,
    } | null > | null,
    aggregations?: string | null,
  } | null,
};

export type SearchFarmerOrdersAdminQueryVariables = {
  q?: string | null,
  limit?: number | null,
  page?: number | null,
  dmaId?: string | null,
  branchId?: string | null,
  farmerNumber?: string | null,
  farmerorderId?: string | null,
  paymentStatus?: string | null,
  orderStatus?: string | null,
  dmaName?: string | null,
  origin?: string | null,
  deliveryType?: string | null,
  fromDate?: string | null,
  toDate?: string | null,
  farmerName?: string | null,
};

export type SearchFarmerOrdersAdminQuery = {
  searchFarmerOrdersAdmin?:  {
    __typename: "SearchFarmerOrderResult",
    totalCount?: number | null,
    page?: number | null,
    result?:  Array< {
      __typename: "NestedFarmerOrders",
      farmerOrderId?: string | null,
      memberNumber?: string | null,
      dmaId?: string | null,
      dmaName?: string | null,
      rebates?: number | null,
      branchId?: string | null,
      paymentMethod?: string | null,
      farmerOrderStatus?: string | null,
      deliveryType?: string | null,
      origin?: string | null,
      creationDate?: string | null,
      farmerName?: string | null,
      farmerNumber?: string | null,
      paymentStatus?: string | null,
      totalCost?: string | null,
      id?: string | null,
      mpesaTransactionId?: string | null,
      product?:  Array< {
        __typename: "ResultFarmerOrderProduct",
        quantity?: number | null,
        total?: string | null,
        product?:  {
          __typename: "FarmerOrderProduct",
          quantity?: number | null,
          dmastockId?: string | null,
          price?: number | null,
          selling_price?: number | null,
          productId?: string | null,
          manufacturerName?: string | null,
          buying_price?: number | null,
          weight?: string | null,
          manufacturerUserId?: string | null,
          description?: string | null,
          creationDate?: string | null,
          type?: string | null,
          unit?: string | null,
          total?: string | null,
          dmaUserId?: string | null,
          company_name?: string | null,
          name?: string | null,
          status?: string | null,
          image?: Array< string | null > | null,
        } | null,
      } | null > | null,
    } | null > | null,
    aggregations?: string | null,
  } | null,
};

export type ExportFarmerOrdersAdminQueryVariables = {
  gte: string,
  lte: string,
};

export type ExportFarmerOrdersAdminQuery = {
  exportFarmerOrdersAdmin?:  {
    __typename: "SearchFarmerOrderResult",
    totalCount?: number | null,
    page?: number | null,
    result?:  Array< {
      __typename: "NestedFarmerOrders",
      farmerOrderId?: string | null,
      memberNumber?: string | null,
      dmaId?: string | null,
      dmaName?: string | null,
      rebates?: number | null,
      branchId?: string | null,
      paymentMethod?: string | null,
      farmerOrderStatus?: string | null,
      deliveryType?: string | null,
      origin?: string | null,
      creationDate?: string | null,
      farmerName?: string | null,
      farmerNumber?: string | null,
      paymentStatus?: string | null,
      totalCost?: string | null,
      id?: string | null,
      mpesaTransactionId?: string | null,
      product?:  Array< {
        __typename: "ResultFarmerOrderProduct",
        quantity?: number | null,
        total?: string | null,
        product?:  {
          __typename: "FarmerOrderProduct",
          quantity?: number | null,
          dmastockId?: string | null,
          price?: number | null,
          selling_price?: number | null,
          productId?: string | null,
          manufacturerName?: string | null,
          buying_price?: number | null,
          weight?: string | null,
          manufacturerUserId?: string | null,
          description?: string | null,
          creationDate?: string | null,
          type?: string | null,
          unit?: string | null,
          total?: string | null,
          dmaUserId?: string | null,
          company_name?: string | null,
          name?: string | null,
          status?: string | null,
          image?: Array< string | null > | null,
        } | null,
      } | null > | null,
    } | null > | null,
    aggregations?: string | null,
  } | null,
};

export type SearchFarmerDeliveryQueryVariables = {
  q?: string | null,
  deliveryId?: string | null,
  farmerId?: string | null,
  kyc_flow_state?: string | null,
  collectionCenterId?: string | null,
  transporterId?: string | null,
  containerNumber?: string | null,
  status?: string | null,
  deliveryDate?: string | null,
  page?: number | null,
  limit?: number | null,
};

export type SearchFarmerDeliveryQuery = {
  searchFarmerDelivery?:  {
    __typename: "SearchFarmerDeliveryResult",
    totalCount?: number | null,
    page?: number | null,
    result?:  Array< {
      __typename: "FarmerDeliveryResult",
      product?: string | null,
      collectionCenterId?: string | null,
      containerNumber?: number | null,
      deliveryDate?: string | null,
      deliveryId?: string | null,
      status?: string | null,
      unitPrice?: number | null,
      quantity?: number | null,
      transporterId?: string | null,
      farmerId?: string | null,
      transporter?:  {
        __typename: "TransporterResult",
        name?: string | null,
        location?: string | null,
        phone?: string | null,
      } | null,
      farmer?:  {
        __typename: "FarmerResult",
        member_number?: string | null,
        route?: string | null,
        supply_category?: string | null,
        MCC?: string | null,
        gender?: string | null,
        farmerId?: string | null,
        last_name?: string | null,
        first_name?: string | null,
        village?: string | null,
        dmaId?: string | null,
        national_id?: string | null,
        phone?: string | null,
        creationDate?: string | null,
      } | null,
      collectionCentre?:  {
        __typename: "CollectionCentreResult",
        location?: string | null,
        collectionCentreId?: string | null,
        creationDate?: string | null,
        centreName?: string | null,
        dmaId?: string | null,
        centreId?: string | null,
      } | null,
    } | null > | null,
    aggregations?: string | null,
  } | null,
};

export type SearchFarmerDeliveryApikeyQueryVariables = {
  q?: string | null,
  deliveryId?: string | null,
  farmerId?: string | null,
  kyc_flow_state?: string | null,
  collectionCenterId?: string | null,
  transporterId?: string | null,
  containerNumber?: string | null,
  status?: string | null,
  deliveryDate?: string | null,
  page?: number | null,
  limit?: number | null,
};

export type SearchFarmerDeliveryApikeyQuery = {
  searchFarmerDeliveryApikey?:  {
    __typename: "SearchFarmerDeliveryResult",
    totalCount?: number | null,
    page?: number | null,
    result?:  Array< {
      __typename: "FarmerDeliveryResult",
      product?: string | null,
      collectionCenterId?: string | null,
      containerNumber?: number | null,
      deliveryDate?: string | null,
      deliveryId?: string | null,
      status?: string | null,
      unitPrice?: number | null,
      quantity?: number | null,
      transporterId?: string | null,
      farmerId?: string | null,
      transporter?:  {
        __typename: "TransporterResult",
        name?: string | null,
        location?: string | null,
        phone?: string | null,
      } | null,
      farmer?:  {
        __typename: "FarmerResult",
        member_number?: string | null,
        route?: string | null,
        supply_category?: string | null,
        MCC?: string | null,
        gender?: string | null,
        farmerId?: string | null,
        last_name?: string | null,
        first_name?: string | null,
        village?: string | null,
        dmaId?: string | null,
        national_id?: string | null,
        phone?: string | null,
        creationDate?: string | null,
      } | null,
      collectionCentre?:  {
        __typename: "CollectionCentreResult",
        location?: string | null,
        collectionCentreId?: string | null,
        creationDate?: string | null,
        centreName?: string | null,
        dmaId?: string | null,
        centreId?: string | null,
      } | null,
    } | null > | null,
    aggregations?: string | null,
  } | null,
};

export type SearchFarmerQueryVariables = {
  q?: string | null,
  dmaId?: string | null,
  page?: number | null,
  limit?: number | null,
  gte?: string | null,
  lte?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  middleName?: string | null,
  gender?: string | null,
  village?: string | null,
};

export type SearchFarmerQuery = {
  searchFarmer?:  {
    __typename: "SearchFarmerResult",
    totalCount?: number | null,
    page?: number | null,
    result?:  Array< {
      __typename: "FarmerResult",
      member_number?: string | null,
      route?: string | null,
      supply_category?: string | null,
      MCC?: string | null,
      gender?: string | null,
      farmerId?: string | null,
      last_name?: string | null,
      first_name?: string | null,
      village?: string | null,
      dmaId?: string | null,
      national_id?: string | null,
      phone?: string | null,
      creationDate?: string | null,
    } | null > | null,
    aggregations?: string | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type SearchRequestStockQueryVariables = {
  q?: string | null,
  limit?: number | null,
  page?: number | null,
  dateFrom?: string | null,
  dateTo?: string | null,
  status?: string | null,
  type?: string | null,
  dmaId?: string | null,
  branchId?: string | null,
};

export type SearchRequestStockQuery = {
  searchRequestStock?:  {
    __typename: "SearchRequeststockResult",
    totalCount?: number | null,
    page?: number | null,
    result?:  Array< {
      __typename: "RequestStockResult",
      stockRequestId?: string | null,
      branchId?: string | null,
      productId?: string | null,
      dmaId?: string | null,
      name?: string | null,
      type?: string | null,
      unit?: string | null,
      quantity?: number | null,
      status?: string | null,
      creationDate?: string | null,
      product?:  {
        __typename: "RequestProduct",
        description?: string | null,
        productId: string,
        name: string,
        price: number,
        subCategoryName?: string | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        quantity?: number | null,
        status?: string | null,
        weight?: number | null,
        manufacturerUserId: string,
        creationDate?: string | null,
        modificationDate?: string | null,
        subCategoryId: string,
        rebates?: number | null,
        dmaPrice?: number | null,
        company_name?: string | null,
      } | null,
      branch?:  {
        __typename: "RequestBranch",
        branchId: string,
        dmaId: string,
        branchName?: string | null,
        location?: string | null,
        isHQ?: string | null,
        phoneNumber?: string | null,
        mail?: string | null,
        ward?: string | null,
        creationDate?: string | null,
      } | null,
    } | null > | null,
    aggregations?: string | null,
  } | null,
};

export type DmaSalesStatisticsQuery = {
  dmaSalesStatistics?:  {
    __typename: "dmaSalesStatisticsResponse",
    dmaSales?: string | null,
    topDmaSellers?:  Array< {
      __typename: "TopDmaSeller",
      dmaId?: string | null,
      dmaName?: string | null,
      totalSalesAmount?: number | null,
      totalItemsSold?: number | null,
    } | null > | null,
    monthlyDmaSales?:  Array< {
      __typename: "SalesMonthly",
      monthLong?: string | null,
      monthShort?: string | null,
      creationDateFrom?: string | null,
      creationDateTo?: string | null,
      monthSales?: number | null,
    } | null > | null,
  } | null,
};

export type ManufacturerSalesStatisticsQuery = {
  manufacturerSalesStatistics?:  {
    __typename: "manSalesStatisticsResponse",
    manSales?: string | null,
    monthlyManufacturerSales?:  Array< {
      __typename: "SalesMonthly",
      monthLong?: string | null,
      monthShort?: string | null,
      creationDateFrom?: string | null,
      creationDateTo?: string | null,
      monthSales?: number | null,
    } | null > | null,
  } | null,
};

export type DmaStatisticsQuery = {
  dmaStatistics?:  {
    __typename: "dmaStatisticsResponse",
    dmaStats?: string | null,
    monthlyDmaStats?:  Array< {
      __typename: "StatsMonthly",
      monthLong?: string | null,
      monthShort?: string | null,
      creationDateFrom?: string | null,
      creationDateTo?: string | null,
      manStats?: string | null,
      monthStats?: number | null,
    } | null > | null,
  } | null,
};

export type AdminDmaMonthlyStatisticsQueryVariables = {
  dmaId: string,
};

export type AdminDmaMonthlyStatisticsQuery = {
  adminDmaMonthlyStatistics?:  {
    __typename: "AdminDmaMonthlyStatisticsResponse",
    monthlySales?:  Array< {
      __typename: "MonthlySales",
      monthLong?: string | null,
      monthShort?: string | null,
      creationDateFrom?: string | null,
      creationDateTo?: string | null,
      monthSales?: number | null,
    } | null > | null,
  } | null,
};

export type DmaMonthlyStatisticsQueryVariables = {
  dmaId?: string | null,
  branchId?: string | null,
};

export type DmaMonthlyStatisticsQuery = {
  dmaMonthlyStatistics?:  {
    __typename: "DmaMonthlyStatisticsResponse",
    monthlySales?:  Array< {
      __typename: "MonthlySales",
      monthLong?: string | null,
      monthShort?: string | null,
      creationDateFrom?: string | null,
      creationDateTo?: string | null,
      monthSales?: number | null,
    } | null > | null,
    salesAnalysis?:  {
      __typename: "SumSummary",
      total?: number | null,
      approved?: number | null,
      rejected?: number | null,
      pending?: number | null,
    } | null,
    purchaseAnalysis?:  Array< {
      __typename: "MonthlySales",
      monthLong?: string | null,
      monthShort?: string | null,
      creationDateFrom?: string | null,
      creationDateTo?: string | null,
      monthSales?: number | null,
    } | null > | null,
    bestSellingProducts?:  Array< {
      __typename: "BestSellingProducts",
      key?: string | null,
      doc_count?: number | null,
      product?:  {
        __typename: "Dmastock",
        dmastockId?: string | null,
        manufacturerUserId?: string | null,
        manufacturerName?: string | null,
        dmaUserId?: string | null,
        dmaId?: string | null,
        branchId?: string | null,
        creationDate?: string | null,
        description?: string | null,
        modificationDate?: string | null,
        name: string,
        buying_price?: number | null,
        selling_price?: number | null,
        stockSubCategoryId?: string | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        quantity?: number | null,
        status?: string | null,
        weight?: string | null,
        bulkUploadProcessStatus?: string | null,
        bulkUploadProcessId?: string | null,
        rebates?: number | null,
      } | null,
    } | null > | null,
    monthlyInputPerformance?:  Array< {
      __typename: "MonthlyInputPerformance",
      monthLong?: string | null,
      monthShort?: string | null,
      creationDateFrom?: string | null,
      creationDateTo?: string | null,
      monthPerformance?:  Array< {
        __typename: "MonthPerformance",
        key?: string | null,
        doc_count?: number | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type DmaDailyStatisticsQueryVariables = {
  dmaId?: string | null,
  branchId?: string | null,
};

export type DmaDailyStatisticsQuery = {
  dmaDailyStatistics?:  {
    __typename: "DmaDailyStatisticsResponse",
    paidOrdersCountToday?: number | null,
    newOrdersCountToday?:  {
      __typename: "SumSummary",
      total?: number | null,
      approved?: number | null,
      rejected?: number | null,
      pending?: number | null,
    } | null,
    totalSalesToday?: number | null,
    totalSalesCurrentWeek?:  Array< {
      __typename: "TotalSalesCurrentWeek",
      date?: string | null,
      dayName?: string | null,
      daySales?: number | null,
    } | null > | null,
  } | null,
};

export type AdminManufacturerMonthlyStatisticsQueryVariables = {
  manufacturerId: string,
};

export type AdminManufacturerMonthlyStatisticsQuery = {
  adminManufacturerMonthlyStatistics?:  {
    __typename: "ManufacturerMonthlyStatisticsResponse",
    monthlySales?:  Array< {
      __typename: "MonthlySales",
      monthLong?: string | null,
      monthShort?: string | null,
      creationDateFrom?: string | null,
      creationDateTo?: string | null,
      monthSales?: number | null,
    } | null > | null,
    salesAnalysis?:  {
      __typename: "SumSummary",
      total?: number | null,
      approved?: number | null,
      rejected?: number | null,
      pending?: number | null,
    } | null,
    bestSellingProducts?:  Array< {
      __typename: "BestSellingProducts",
      key?: string | null,
      doc_count?: number | null,
      product?:  {
        __typename: "Dmastock",
        dmastockId?: string | null,
        manufacturerUserId?: string | null,
        manufacturerName?: string | null,
        dmaUserId?: string | null,
        dmaId?: string | null,
        branchId?: string | null,
        creationDate?: string | null,
        description?: string | null,
        modificationDate?: string | null,
        name: string,
        buying_price?: number | null,
        selling_price?: number | null,
        stockSubCategoryId?: string | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        quantity?: number | null,
        status?: string | null,
        weight?: string | null,
        bulkUploadProcessStatus?: string | null,
        bulkUploadProcessId?: string | null,
        rebates?: number | null,
      } | null,
    } | null > | null,
    monthlyInputPerformance?:  Array< {
      __typename: "MonthlyInputPerformance",
      monthLong?: string | null,
      monthShort?: string | null,
      creationDateFrom?: string | null,
      creationDateTo?: string | null,
      monthPerformance?:  Array< {
        __typename: "MonthPerformance",
        key?: string | null,
        doc_count?: number | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type ManufacturerMonthlyStatisticsQueryVariables = {
  manufacturerId?: string | null,
};

export type ManufacturerMonthlyStatisticsQuery = {
  manufacturerMonthlyStatistics?:  {
    __typename: "ManufacturerMonthlyStatisticsResponse",
    monthlySales?:  Array< {
      __typename: "MonthlySales",
      monthLong?: string | null,
      monthShort?: string | null,
      creationDateFrom?: string | null,
      creationDateTo?: string | null,
      monthSales?: number | null,
    } | null > | null,
    salesAnalysis?:  {
      __typename: "SumSummary",
      total?: number | null,
      approved?: number | null,
      rejected?: number | null,
      pending?: number | null,
    } | null,
    bestSellingProducts?:  Array< {
      __typename: "BestSellingProducts",
      key?: string | null,
      doc_count?: number | null,
      product?:  {
        __typename: "Dmastock",
        dmastockId?: string | null,
        manufacturerUserId?: string | null,
        manufacturerName?: string | null,
        dmaUserId?: string | null,
        dmaId?: string | null,
        branchId?: string | null,
        creationDate?: string | null,
        description?: string | null,
        modificationDate?: string | null,
        name: string,
        buying_price?: number | null,
        selling_price?: number | null,
        stockSubCategoryId?: string | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        quantity?: number | null,
        status?: string | null,
        weight?: string | null,
        bulkUploadProcessStatus?: string | null,
        bulkUploadProcessId?: string | null,
        rebates?: number | null,
      } | null,
    } | null > | null,
    monthlyInputPerformance?:  Array< {
      __typename: "MonthlyInputPerformance",
      monthLong?: string | null,
      monthShort?: string | null,
      creationDateFrom?: string | null,
      creationDateTo?: string | null,
      monthPerformance?:  Array< {
        __typename: "MonthPerformance",
        key?: string | null,
        doc_count?: number | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type ManufacturerDailyStatisticsQueryVariables = {
  manufacturerId?: string | null,
};

export type ManufacturerDailyStatisticsQuery = {
  manufacturerDailyStatistics?:  {
    __typename: "ManufacturerDailyStatisticsResponse",
    paidOrdersCountToday?: number | null,
    newOrdersCountToday?:  {
      __typename: "SumSummary",
      total?: number | null,
      approved?: number | null,
      rejected?: number | null,
      pending?: number | null,
    } | null,
    totalSalesToday?: number | null,
    totalSalesCurrentWeek?:  Array< {
      __typename: "TotalSalesCurrentWeek",
      date?: string | null,
      dayName?: string | null,
      daySales?: number | null,
    } | null > | null,
  } | null,
};

export type SearchManufacturerQueryVariables = {
  q?: string | null,
  location?: string | null,
  status?: string | null,
  manufacturerId?: string | null,
  emailAddress?: string | null,
  page?: number | null,
  limit?: number | null,
};

export type SearchManufacturerQuery = {
  searchManufacturer?:  {
    __typename: "SearchManufacturerResult",
    data?:  {
      __typename: "ManufacturerResult",
      totalCount?: number | null,
      page?: number | null,
      result?:  Array< {
        __typename: "ManufacturerDetailResult",
        companyName?: string | null,
        location?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        address?: string | null,
        email?: string | null,
        manufacturerId?: string | null,
        enabled?: boolean | null,
        status?: string | null,
      } | null > | null,
      aggregations?: string | null,
    } | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type SearchHubQueryVariables = {
  q?: string | null,
  manufacturerId?: string | null,
  page?: number | null,
  limit?: number | null,
};

export type SearchHubQuery = {
  searchHub?:  {
    __typename: "SearchHubResult",
    data?:  {
      __typename: "HubResult",
      totalCount?: number | null,
      page?: number | null,
      result?:  Array< {
        __typename: "HubDetailResult",
        location?: string | null,
        hubName?: string | null,
        phoneNumber?: string | null,
        address?: string | null,
        email?: string | null,
        hubId?: string | null,
        creationDate?: string | null,
      } | null > | null,
      aggregations?: string | null,
    } | null,
    code?: number | null,
    message?: string | null,
  } | null,
};

export type SearchUserQueryVariables = {
  dmaId?: string | null,
  branchId?: string | null,
  collectionCentreId?: string | null,
  manufacturerId?: string | null,
  hubId?: string | null,
  group?: string | null,
  page?: number | null,
  limit?: number | null,
};

export type SearchUserQuery = {
  searchUser?:  {
    __typename: "SearchUserResult",
    totalCount?: number | null,
    page?: number | null,
    result?:  Array< {
      __typename: "UserResult",
      approvedAt?: string | null,
      created?: string | null,
      dmaId?: string | null,
      branchId?: string | null,
      manufacturerId?: string | null,
      collectionCentreId?: string | null,
      hubId?: string | null,
      name?: string | null,
      username?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      companyName?: string | null,
      group?: string | null,
      kycFlowState?: string | null,
      location?: string | null,
      nationalId?: string | null,
      ward?: string | null,
      enabled?: boolean | null,
    } | null > | null,
  } | null,
};

export type SearchDmaAdminQueryVariables = {
  q?: string | null,
  ward?: string | null,
  businessName?: string | null,
  kyc_flow_state?: string | null,
  dmaType?: string | null,
  dmaId?: string | null,
  page?: number | null,
  limit?: number | null,
  regions?: Array< string | null > | null,
  counties?: Array< string | null > | null,
  subCounties?: Array< string | null > | null,
};

export type SearchDmaAdminQuery = {
  searchDmaAdmin?:  {
    __typename: "SearchDmaResult",
    totalCount?: number | null,
    page?: number | null,
    result?:  Array< {
      __typename: "DmaResult",
      dmaId?: string | null,
      businessName?: string | null,
      creationDate?: string | null,
      dmaType?: string | null,
      location?: string | null,
      kyc_flow_state?: string | null,
      mail?: string | null,
      phoneNumber?: string | null,
      ward?: string | null,
      wardRegion?: string | null,
      wardCounty?: string | null,
      wardSubcounty?: string | null,
    } | null > | null,
    aggregations?: string | null,
  } | null,
};

export type SearchDmaApikeyQueryVariables = {
  q?: string | null,
  businessName?: string | null,
  kyc_flow_state?: string | null,
  dmaType?: string | null,
  dmaId?: string | null,
  wards?: Array< string | null > | null,
  regions?: Array< string | null > | null,
  counties?: Array< string | null > | null,
  subCounties?: Array< string | null > | null,
  locations?: Array< string | null > | null,
  page?: number | null,
  limit?: number | null,
};

export type SearchDmaApikeyQuery = {
  searchDmaApikey?:  {
    __typename: "SearchDmaResult",
    totalCount?: number | null,
    page?: number | null,
    result?:  Array< {
      __typename: "DmaResult",
      dmaId?: string | null,
      businessName?: string | null,
      creationDate?: string | null,
      dmaType?: string | null,
      location?: string | null,
      kyc_flow_state?: string | null,
      mail?: string | null,
      phoneNumber?: string | null,
      ward?: string | null,
      wardRegion?: string | null,
      wardCounty?: string | null,
      wardSubcounty?: string | null,
    } | null > | null,
    aggregations?: string | null,
  } | null,
};

export type ExportDmaAdminQueryVariables = {
  gte: string,
  lte: string,
};

export type ExportDmaAdminQuery = {
  exportDmaAdmin?:  {
    __typename: "SearchDmaResult",
    totalCount?: number | null,
    page?: number | null,
    result?:  Array< {
      __typename: "DmaResult",
      dmaId?: string | null,
      businessName?: string | null,
      creationDate?: string | null,
      dmaType?: string | null,
      location?: string | null,
      kyc_flow_state?: string | null,
      mail?: string | null,
      phoneNumber?: string | null,
      ward?: string | null,
      wardRegion?: string | null,
      wardCounty?: string | null,
      wardSubcounty?: string | null,
    } | null > | null,
    aggregations?: string | null,
  } | null,
};

export type UpdatedCartSubscriptionVariables = {
  id?: string | null,
};

export type UpdatedCartSubscription = {
  updatedCart?:  {
    __typename: "Cart",
    cartId?: string | null,
    cartItems?:  Array< {
      __typename: "CartItem",
      product?:  {
        __typename: "CartProduct",
        manufacturerUserId?: string | null,
        description?: string | null,
        productId?: string | null,
        name?: string | null,
        price?: number | null,
        unit?: string | null,
        type?: string | null,
        image?: Array< string | null > | null,
        weight?: number | null,
        quantity?: number | null,
        company_name?: string | null,
        manufacturerId?: string | null,
      } | null,
      quantity?: number | null,
      total?: number | null,
    } | null > | null,
    dmaId?: string | null,
    userId?: string | null,
    branchId?: string | null,
    cartStatus?: CartStatus | null,
    creationDate?: string | null,
    modificationDate?: string | null,
    paymentStatus?: string | null,
    paymentMethod?: string | null,
    mpesaTransactionId?: string | null,
    total?: number | null,
  } | null,
};
