import './wards.css';

import { DeleteOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Pagination, Tooltip } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { NavLink } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';

import { PageTitle } from '../dma/PageTitle';
import { useWards } from './useWards';

function Wards() {
    const { getAllWards, wardsData, loading, deleteWardById } = useWards();
    const [paginatedWard, setPaginatedWard] = useState<any[]>([]);
    const [wardIds, setWardIds] = useState<string[]>([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        getAllWards();
    }, []);

    useEffect(() => {
        console.log(wardIds);
    }, [wardIds]);

    useEffect(() => {
        if (wardsData && wardsData.length > 0) {
            const newArray = _(wardsData).orderBy(['name'], ['asc']).value();
            const paginated = _(newArray).take(10).value();
            setPaginatedWard(paginated);
        }
    }, [wardsData]);

    const handlePageChange = (value: number) => {
        console.log({ value });
        setPage(value);
        const mainArr = _(wardsData).orderBy(['name'], ['asc']).value();
        // setPaginatedWard(mainArr.splice(value * 10, value * 10));
        const paginated = _(mainArr)
            .slice(page * 10)
            .take(10)
            .value();
        console.log({ paginated });
        setPaginatedWard(paginated);
    };

    const handleOnCheck = (e: any, id: any) => {
        let arr = [...wardIds];
        if (e.target.checked) {
            if (!arr.includes(id)) {
                arr.push(id);
                setWardIds(arr);
            }
        } else {
            arr = arr.filter((a: any) => a !== id);
            setWardIds(arr);
        }
    };

    const handleDelete = () => {
        Swal.fire({
            title: `Delete ${wardIds.length > 1 ? 'Wards' : 'Ward'}`,
            text: `Are you sure you want to the following ${wardIds.length > 1 ? wardIds.length + ' Wards' : 'Ward'}?`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#2cb34a',
        }).then(async (results: any) => {
            if (results.value) {
                Swal.fire({
                    title: 'Processing Request ...',
                    allowOutsideClick: false,
                });
                Swal.showLoading();
                const resp = await deleteWardById(wardIds);
                if (resp.code === 200) {
                    setWardIds([]);
                    Swal.fire('Deleted!', `Wards has been deleted successfully`, 'success');
                } else {
                    Swal.fire('Error!', resp.message as string, 'error');
                }
                getAllWards();
            }
        });
    };

    return (
        <BlockUi blocking={loading}>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Wards</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <div className="row mt-4 mb-3">
                            <ToastContainer />
                            <div className="col-md-8">
                                <PageTitle title="All Wards" />
                            </div>
                            <div className="col-md-2">
                                <NavLink to={'/wards/bulk-upload'}>
                                    <div className="border-btn text-center"> Upload Wards</div>
                                </NavLink>
                            </div>
                            <div className="col-md-2">
                                <NavLink to={'/wards/create'}>
                                    <div className="border-btn text-center"> Add Ward</div>
                                </NavLink>
                            </div>
                            {wardIds.length > 0 && (
                                <div className="pull-left">
                                    <Tooltip title={`Delete ${wardIds.length > 1 ? 'Wards' : 'Ward'}`}>
                                        <Button
                                            type="primary"
                                            icon={<DeleteOutlined />}
                                            danger
                                            size="large"
                                            onClick={() => handleDelete()}
                                        />
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                        <div>
                            <Card className="roles-cardbody shadow-lg p-3 mb-5">
                                <Card.Body>
                                    <h6 className="mb-4">Wards</h6>
                                    <Form>
                                        {paginatedWard.map((ward: any) => (
                                            <Form.Group
                                                key={ward.name + ward.wardId}
                                                className="roles-permission-checkbox-group"
                                                controlId={ward.wardId}
                                            >
                                                <Form.Check
                                                    key={ward.wardId}
                                                    onChange={(e) => {
                                                        if (e.target.checked && wardIds.length >= 25) {
                                                            return toast.warning(
                                                                'Maximum wards that can be selected for delete is 25',
                                                            );
                                                        }
                                                        handleOnCheck(e, ward.wardId);
                                                    }}
                                                    type="checkbox"
                                                    label={`${ward.name}`}
                                                    disabled={!wardIds.includes(ward.wardId) && wardIds.length >= 25}
                                                />
                                            </Form.Group>
                                        ))}
                                    </Form>
                                    <div className="pagination-wrapper">
                                        <Pagination
                                            // defaultCurrent={paginatedProducts?.page || 1}
                                            current={page || 1}
                                            total={wardsData.length}
                                            pageSize={10}
                                            showSizeChanger={false}
                                            disabled={wardsData.length < 10}
                                            onChange={handlePageChange}
                                        />
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </BlockUi>
    );
}

export default Wards;
