import { DownloadOutlined } from '@ant-design/icons';
import { GraphQLResult } from '@aws-amplify/api';
import { Popover } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import React, { CSSProperties, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import DatePicker from 'react-datepicker';

import { Branch, DmaStockReconciliationRecord, FetchDmaStockReconciliationRecordsQuery } from '../../../API';
import { fetchDmaStockReconciliationRecords } from '../../../graphql/queries';
import { addMonths, subtractMonths } from '../../../utils/dateUtil';

interface DmaStockReconciliationExportProps {
    dmaBranchList: Branch[];
    dmaId: string;
    csvFileName: string;
    className?: string;
    style?: CSSProperties;
}

const csvHeaders = [
    { label: 'Date', key: 'date' },
    { label: 'DMA Name', key: 'dmaName' },
    { label: 'DMA Branch Name', key: 'dmaBranchName' },
    { label: 'Product Name', key: 'productName' },
    { label: 'Product Quantity', key: 'productQuantity' },
    { label: 'Product Unit', key: 'productUnit' },
    { label: 'Credited Count', key: 'creditedCount' },
    { label: 'Verified Count', key: 'verifiedCount' },
    { label: 'Collected Count', key: 'collectedCount' },
    { label: 'Purchased Count', key: 'purchasedCount' },
    { label: 'Regional Anchor', key: 'regionalAnchor' },
];

const mapStockReconciliationToCsvRecord = (records: DmaStockReconciliationRecord[] | undefined | null) => {
    if (!records) {
        return [];
    }

    return records.map((record) => {
        const product = JSON.parse(record.product);
        return {
            date: new Date(record.date).toLocaleDateString(),
            dmaName: JSON.parse(record.dma).businessName,
            dmaBranchName: JSON.parse(record.branch).branchName,
            productName: product.name,
            productQuantity: product.quantity,
            productUnit: product.unit,
            creditedCount: record.creditedCount,
            verifiedCount: record.verifiedCount,
            collectedCount: record.collectedCount,
            purchasedCount: record.purchasedCount,
            regionalAnchor: record.user,
        };
    });
};

const DmaStockReconciliationExport: React.FC<DmaStockReconciliationExportProps> = ({
    csvFileName,
    dmaBranchList,
    dmaId,
    className,
    style,
}) => {
    const [dateRange, setDateRange] = useState(() => ({
        startDate: subtractMonths(1, new Date()),
        endDate: new Date(),
    }));
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [stockReconciliationExportRecord, setStockReconciliationExportRecord] = useState<any>([]);

    const csvLinkRef = useRef<any>(null);

    const getCsvFileName = (csvFileName: string) =>
        `${csvFileName}  ${dateRange.startDate.toLocaleDateString('en-GB', {
            dateStyle: 'medium',
        })} - ${dateRange.endDate.toLocaleDateString('en-GB', { dateStyle: 'medium' })}`;

    const handleDateRangeSelection = (range: 'start' | 'end', date: Date | null) => {
        if (!date) return;
        if (range === 'start') {
            if (date >= dateRange.endDate) {
                setDateRange({ startDate: date, endDate: date });
            } else if (date < subtractMonths(1, dateRange.endDate)) {
                const newEndDate = addMonths(1, date);
                setDateRange({ startDate: date, endDate: newEndDate });
            } else {
                setDateRange({ startDate: date, endDate: dateRange.endDate });
            }
        } else {
            if (date > addMonths(1, dateRange.startDate)) {
                const newStartDate = subtractMonths(1, date);
                setDateRange({ startDate: newStartDate, endDate: date });
            } else {
                setDateRange({ startDate: dateRange.startDate, endDate: date });
            }
        }
    };

    const handleExportClick = async () => {
        try {
            setPopoverVisible(false);
            const from = new Date(dateRange.startDate.getTime());
            from.setHours(0, 0, 0, 0);
            const to = new Date(dateRange.endDate.getTime());
            const query = graphqlOperation(fetchDmaStockReconciliationRecords, {
                from: from.toISOString(),
                to: to.toISOString(),
                dmaId,
                dmaBranchId: selectedBranch,
            });
            const { data } = (await API.graphql(query)) as GraphQLResult<FetchDmaStockReconciliationRecordsQuery>;
            setStockReconciliationExportRecord(
                mapStockReconciliationToCsvRecord(data?.fetchDmaStockReconciliationRecords?.data),
            );
            csvLinkRef?.current?.link.click();
        } catch (err: any) {
            console.log(err);
        }
    };

    const dateRangeAndBranchSelector = (
        startDate: Date,
        endDate: Date,
        handleDateRangeSelection: (range: 'start' | 'end', date: Date | null) => void,
    ) => (
        <div>
            <div className="d-flex align-items-end">
                <label>
                    From
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => handleDateRangeSelection('start', date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        className="audit-filter-input"
                    />
                </label>
                <label>
                    To
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => handleDateRangeSelection('end', date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        className="audit-filter-input"
                    />
                </label>
            </div>
            {dmaBranchList && dmaBranchList.length > 1 && (
                // <div className="d-flex align-items-end">
                <select
                    value={selectedBranch}
                    onChange={(e) => setSelectedBranch(e.target.value)}
                    className="w-100 mt-2 p-2"
                >
                    {dmaBranchList.map((branch) => (
                        <>
                            <option selected>Select branch</option>
                            <option key={branch.branchId} value={branch.branchId}>
                                {branch.branchName}
                            </option>
                        </>
                    ))}
                </select>
                // </div>
            )}
            <div className="border-btn text-center mt-2" onClick={handleExportClick}>
                Submit
            </div>
            <CSVLink
                data={stockReconciliationExportRecord}
                headers={csvHeaders}
                filename={getCsvFileName(csvFileName)}
                ref={csvLinkRef}
                className="d-none"
            />
        </div>
    );

    return (
        <Popover
            placement="leftTop"
            content={dateRangeAndBranchSelector(dateRange.startDate, dateRange.endDate, handleDateRangeSelection)}
            trigger="click"
            visible={popoverVisible}
            onVisibleChange={(visible) => setPopoverVisible(visible)}
            className={className}
            style={style}
        >
            <div className="border-btn text-center">
                Export Stock Reconciliation <DownloadOutlined style={{ color: '#2cb34a' }} />
            </div>
        </Popover>
    );
};

export default DmaStockReconciliationExport;
