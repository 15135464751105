import '../roles.css';

import { CheckOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { Breadcrumb } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { NavLink, useHistory } from 'react-router-dom';

import { PageTitle } from '../../dma/PageTitle';
import { useDmaRoles } from './useDmaRoles';

function DmaRoles() {
    const [selectedRole, setSelectedRole] = useState('');
    const [permissions, setPermissions] = useState([]);
    const { getAllRoles, rolesData } = useDmaRoles();
    const history = useHistory();

    useEffect(() => {
        const getData = async () => {
            const roles = await getAllRoles();
            if (roles && roles[0]) {
                setSelectedRole(roles[0].dmaRolesId as string);
            }
        };
        getData();
    }, []);

    useEffect(() => {
        const selectedRoleObject = rolesData.find((p) => p.dmaRolesId === selectedRole);
        if (!_.isEmpty(selectedRoleObject)) {
            const selectedRolePermission = selectedRoleObject.permissions;
            setPermissions(selectedRolePermission || []);
        }
    }, [selectedRole]);

    console.log({ selectedRole });

    const menu = (
        <Menu>
            <Menu.Item onClick={() => history.push('/dma-roles/edit')} key="1">
                <p onClick={() => history.push('/dma-roles/edit')} className="status-approval">
                    <CheckOutlined /> Edit
                </p>
            </Menu.Item>
        </Menu>
    );

    console.log({ rolesData });
    return (
        <>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Dma Roles and Permissions</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <div className="d-flex justify-content-between mt-4 mb-3">
                            <div className="col-md-6">
                                <PageTitle title="Roles and Permissions" />
                            </div>
                            <div className="pull-right width-200">
                                <NavLink to={'/dma-roles/create'}>
                                    <div className="primary-btn text-center"> Create Role</div>
                                </NavLink>
                            </div>
                        </div>

                        <div>
                            <Card className="roles-cardbody shadow-lg p-3 mb-5">
                                <Card.Body>
                                    <h6>CHECKLIST ITEMS</h6>
                                    <Dropdown.Button overlay={menu} />
                                    <Form>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                            <div className="row">
                                                <div className="col-md-2 mt-2">
                                                    <Form.Label>Select Type</Form.Label>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <Form.Control
                                                        onChange={(e) => setSelectedRole(e.target.value)}
                                                        as="select"
                                                        className="w-100 mb-4"
                                                    >
                                                        {rolesData?.map((role) => {
                                                            return (
                                                                <option value={role.dmaRolesId} key={role.dmaRolesId}>
                                                                    {role.rolename}
                                                                </option>
                                                            );
                                                        })}
                                                    </Form.Control>
                                                </div>
                                            </div>

                                            <Form.Group
                                                className="roles-permission-checkbox-group"
                                                controlId="formBasicCheckbox"
                                            >
                                                {permissions.map((permission: any) => (
                                                    <Form.Check
                                                        key={permission.permissionId}
                                                        checked
                                                        type="checkbox"
                                                        label={`${permission.alias} (${permission.description})`}
                                                    />
                                                ))}
                                            </Form.Group>
                                        </Form.Group>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DmaRoles;
