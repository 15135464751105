import './SideNavItem.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FC } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { NavLink } from '../../routes';

export const SideNavItem: FC<{
    item: NavLink;
    onSelect: (item: NavLink) => void;
}> = ({ item, onSelect }) => {
    return (
        <Link
            className={`d-block  my-3 mx-auto d-flex align-items-center py-2 pl-4 w-100 side-nav-item-link  ${
                item.isSelected ? 'selected' : ''
            } ${item.disabled ? 'disabledSideNav' : ''}`}
            to={item.path}
            onClick={(event) => (item.disabled ? event?.preventDefault() : onSelect(item))}
        >
            <Nav.Item className="side-nav-item col-md-10" key={item.key}>
                {item.icon && <FontAwesomeIcon icon={item.icon} />}&nbsp;&nbsp;
                <span className="sideNav-title">{item.title}</span>
            </Nav.Item>
        </Link>
    );
};
