import React from 'react';
import { Col } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { SideNav } from '../layouts/SideNav';
import Advertise from '../views/advertise/Advertise';
import { Audit } from '../views/audit/audit';
import AddCategory from '../views/categories/AddCategory';
import AddSubCategory from '../views/categories/AddSubCategory';
import Categories from '../views/categories/Categories';
import CategoryDetails from '../views/categories/CategoryDetails';
import AddDmaStock from '../views/dma/AddDmaStock';
import BulkUpdateDma from '../views/dma/BulkUpdateDma';
import DMA from '../views/dma/Dma';
import DmaProfile from '../views/dma/DmaProfile';
import DmaStock from '../views/dma/DmaStock';
import DmaStockReconciliation from '../views/dma/DmaStockReconciliation';
import DmaStockUpdateHistory from '../views/dma/DmaStockUpdateHistory';
import UpdateDmaStock from '../views/dma/UpdateDmaStock';
import FarmerRequests from '../views/farmerRequests/FarmerRequests';
import Dashboard from '../views/home/Dashboard';
import CreateManufacturer from '../views/manufacturer/CreateManufacturer';
import CreateHub from '../views/manufacturers/hubs/CreateHub';
import { HubDetails } from '../views/manufacturers/hubs/HubDetails';
import ManufacturerProfile from '../views/manufacturers/ManufacturerProfile';
import Manufacturers from '../views/manufacturers/Manufacturers';
import AddProduct from '../views/products/AddProduct';
import EditProduct from '../views/products/EditProduct';
import ProductDetailsPage from '../views/products/ProductDetails/ProductDetails';
import Products from '../views/products/Products';
import ProductsUpload from '../views/products/ProductsUpload';
import { CreateRoles } from '../views/roles/CreateRoles';
import CreateDMARoles from '../views/roles/dmaRoles/CreateDMARoles';
import DMARoles from '../views/roles/dmaRoles/DmaRoles';
import DmaRoles from '../views/roles/dmaRoles/DmaRoles';
import EditDMARoles from '../views/roles/dmaRoles/EditDMARole';
import EditRoles from '../views/roles/EditRoles';
import Roles from '../views/roles/Roles';
import FarmerSales from '../views/sales/farmerSales/FarmerSales';
import { FarmerSalesDetails } from '../views/sales/farmerSales/FarmerSalesDetails';
import { ManufacturerSalesDetails } from '../views/sales/manfacturerSales/ManufacturerSalesDetails';
import Sales from '../views/sales/Sales';
import CreateUser from '../views/users/createUser/CreateUser';
import UserDetails from '../views/users/userDetails/UserDetails';
import Users from '../views/users/Users';
import { BulkUploadWard } from '../views/wards/BulkUploadWard';
import { CreateWard } from '../views/wards/CreateWard';
import Wards from '../views/wards/Wards';

const AdminRoutes = () => {
    return (
        <div className="" id="private-route-layout">
            <div className="p-0">
                <SideNav />
            </div>
            <Col className="innerContent-main">
                <div className="innerContent">
                    <ToastContainer />
                    <Switch>
                        <Route exact path="/dashboard" component={Dashboard} />
                        <Route exact path="/advertise" component={Advertise} />
                        <Route exact path="/manufacturer/new" component={CreateManufacturer} />
                        <Route exact path="/manufacturer/profile/:id" component={ManufacturerProfile} />
                        <Route exact path="/manufacturer/:id/hub/new" component={CreateHub} />
                        <Route exact path="/manufacturer/:manufacturerId/hub/:hubId" component={HubDetails} />
                        <Route exact path="/dma/profile" component={DmaProfile} />
                        <Route exact path="/manufacturers" component={Manufacturers} />
                        <Route exact path="/products" component={Products} />
                        <Route exact path="/sales" component={Sales} />
                        <Route exact path="/manufacturer-sales/:id" component={ManufacturerSalesDetails} />
                        <Route exact path="/sales-farmer" component={FarmerSales} />
                        <Route exact path="/sales-farmer/:id" component={FarmerSalesDetails} />
                        <Route exact path="/products/upload" component={ProductsUpload} />
                        <Route exact path="/products/:id" component={ProductDetailsPage} />
                        <Route exact path="/products/:id/edit" component={EditProduct} />
                        <Route exact path="/users" component={Users} />
                        <Route exact path="/product/new" component={AddProduct} />
                        <Route path="/dma/profile/:id" component={DmaProfile} />
                        <Route exact path="/dma" component={DMA} />
                        <Route exact path="/dma/bulk-update" component={BulkUpdateDma} />
                        <Route exact path="/roles/edit" component={EditRoles} />
                        <Route exact path="/dma-roles/edit" component={EditDMARoles} />
                        <Route exact path="/roles" component={Roles} />
                        <Route exact path="/dma-roles" component={DmaRoles} />
                        <Route exact path="/roles/create" component={CreateRoles} />
                        <Route exact path="/dma-roles/edit" component={EditDMARoles} />
                        <Route exact path="/dma-roles" component={DMARoles} />
                        <Route exact path="/dma-roles/create" component={CreateDMARoles} />
                        <Route exact path="/farmer/requests" component={FarmerRequests} />
                        <Route exact path="/users/:id" component={UserDetails} />
                        <Route exact path="/dma/:id/stock" component={DmaStock} />
                        <Route exact path="/dma/:id/stock/add" component={AddDmaStock} />
                        <Route exact path="/dma/:id/stock/:stockId" component={UpdateDmaStock} />
                        <Route exact path="/create-user" component={CreateUser} />
                        <Route exact path="/wards" component={Wards} />
                        <Route exact path="/wards/create" component={CreateWard} />
                        <Route exact path="/wards/bulk-upload" component={BulkUploadWard} />
                        <Route exact path="/categories" component={Categories} />
                        <Route exact path="/categories/create" component={AddCategory} />
                        <Route exact path="/categories/:id/details" component={CategoryDetails} />
                        <Route exact path="/subcategories/create" component={AddSubCategory} />
                        <Route exact path="/audit" component={Audit} />
                        <Route exact path="/dma/:id/stock/:stockId/history" component={DmaStockUpdateHistory} />
                        <Route exact path="/dma/stock/:stockId/reconciliation" component={DmaStockReconciliation} />
                        <Redirect to="/dashboard" />
                    </Switch>
                </div>
            </Col>
        </div>
    );
};

export default AdminRoutes;
