import classNames from 'classnames';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import styles from './stats.module.scss';

interface DashboardStats {
    dma: number;
    manufacturer: number;
    manufacturersales: number;
    dmasales: number;
}

type Props = {
    stats: DashboardStats;
};

const DashboardStats: React.FC<Props> = ({ stats }) => {
    return (
        <Row className="mb-1">
            <Col xs="12" sm="6" md="3">
                <Card className="shadow-lg p-3 mb-5 height-150" body>
                    <div className={classNames(styles.bgGreen, styles.icon)}>
                        <i className="bi bi-receipt" />
                    </div>
                    <p className={styles.value}>{stats.manufacturersales}</p>
                    <h5 className={styles.label}>Daily Manufacturer Sales</h5>
                </Card>
            </Col>
            <Col xs="12" sm="6" md="3">
                <Card className="shadow-lg p-3 mb-5 height-150" body>
                    <div className={classNames(styles.bgOrange, styles.icon)}>
                        <i className="bi bi-person" />
                    </div>
                    <p className={styles.value}>{stats.dma}</p>
                    <h5 className={styles.label}>Daily Digifarm Master Agent</h5>
                </Card>
            </Col>
            <Col xs="12" sm="6" md="3">
                <Card className="shadow-lg p-3 mb-5 height-150" body>
                    <div className={classNames(styles.bgBlue, styles.icon)}>
                        <i className="bi bi-people-fill" />
                    </div>

                    <p className={styles.value}>{stats.manufacturer}</p>
                    <h5 className={styles.label}>Daily Manufacturers</h5>
                </Card>
            </Col>
            <Col xs="12" sm="6" md="3">
                <Card className="shadow-lg p-3 mb-5 height-150" body>
                    <div className={classNames(styles.bgRed, styles.icon)}>
                        <i className="bi bi-currency-exchange" />
                    </div>
                    <p className={styles.value}>{stats.dmasales}</p>
                    <h5 className={styles.label}>Daily DMA Sales</h5>
                </Card>
            </Col>
        </Row>
    );
};

export default DashboardStats;
