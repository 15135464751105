import './categories.css';

import { GraphQLResult } from '@aws-amplify/api';
import { Breadcrumb } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useState } from 'react';
import BlockUi from 'react-block-ui';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { Input } from '../../components/inputs/Input';
import { createCategory } from '../../graphql/mutations';

function AddCategory() {
    const [name, setName] = useState('');
    const [creating, setCreating] = useState(false);
    const history = useHistory();

    const saveCategory = async (e: any) => {
        e.preventDefault();
        if (!name) {
            return toast.error('Please specify a Category name!');
        }
        setCreating(true);
        const query = graphqlOperation(createCategory, {
            category: {
                name,
            },
        });
        const res = (await API.graphql(query)) as GraphQLResult<any>;
        // const results = JSON.parse(res.data.createCategory);
        if (res.data.createCategory.status === 'SUCCESS') {
            return history.push('/categories');
        }
        toast.error(res.data.createCategory.message);
    };

    return (
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <BlockUi blocking={creating}>
                            <ToastContainer />
                            <div>
                                <Breadcrumb separator=">">
                                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/categories">Categories</Breadcrumb.Item>
                                    <Breadcrumb.Item>Add Category</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>

                            <h6 className="categories-heading">Create Category</h6>

                            <div>
                                <Card className="categories-cardbody shadow-lg p-3 mb-5">
                                    <Card.Body>
                                        <Form>
                                            <div className="d-flex">
                                                <Col md={12} className="p-3">
                                                    <div className="form-group">
                                                        <label className="auth-label mb-2">Category Name</label>
                                                        <Input
                                                            className=""
                                                            placeholder="Category Name"
                                                            value={name}
                                                            type="text"
                                                            required
                                                            onChange={(e) => setName(e)}
                                                            name="category_name"
                                                            disabled={false}
                                                        />
                                                    </div>
                                                </Col>
                                            </div>

                                            <div className="d-flex justify-content-flex-end fullwidth mt-5">
                                                <div
                                                    className="cancel-btn fw-500 m-3 btn-width pointer"
                                                    onClick={() => history.push('/categories')}
                                                >
                                                    Cancel
                                                </div>
                                                <button
                                                    onClick={(e) => saveCategory(e)}
                                                    className="primary-btn fw-500 m-3 btn-width"
                                                    disabled={creating}
                                                >
                                                    {creating ? 'Loading...' : 'Add Category'}
                                                </button>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddCategory;
