import {
    CheckOutlined,
    CloseOutlined,
    DeleteFilled,
    EyeOutlined,
    FileOutlined,
    LoadingOutlined,
    MoreOutlined,
} from '@ant-design/icons';
import { Card, Col, Divider, Popover, Row } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';

import { DeleteDmaMutation, DMAResponse } from '../../../API';
import { Status } from '../../../models';
import { getInitials } from '../../../utils';

interface ProfileType {
    user: DMAResponse['data'] | null | undefined;
    updateDmaStatus: (status: string, action: string) => void;
    updatingStatus: boolean;
    downloadUrl: string;
    id: string;
    getReadUrl: (id: string, download: boolean) => void;
    checkingCanDeleteDma: boolean;
    canDeleteDma: boolean;
    deleteDmaFn: (dmaId: string) => Promise<DeleteDmaMutation | undefined>;
}
export const Profile: React.FC<ProfileType> = ({
    user,
    updateDmaStatus,
    updatingStatus,
    downloadUrl,
    id,
    getReadUrl,
    checkingCanDeleteDma,
    canDeleteDma,
    deleteDmaFn,
}) => {
    const pending = user?.kyc_flow_state && user?.kyc_flow_state === Status.Pending;
    const noDocument = user?.kyc_flow_state && user?.kyc_flow_state === Status.noDocument;
    const approvalPending = user?.kyc_flow_state && user?.kyc_flow_state === Status.approvalPending;
    const accepted = user?.kyc_flow_state && user?.kyc_flow_state === Status.Approved;
    const rejected = user?.kyc_flow_state && user?.kyc_flow_state === Status.Rejected;

    const [approvedState, setApprovedState] = useState<string | null>(null);
    const [rejectedState, setRejectedState] = useState<string | null>(null);

    const name = user?.businessName;

    const deleteDma = () => {
        if (!user?.dmaId) return;

        deleteDmaFn(user.dmaId)
            .then((resp) => {
                const statusCode = resp?.deleteDma?.code;
                if (statusCode == 200) {
                    toast.success('DMA successfully deleted');
                    setTimeout(() => history.push('/dma'), 3000);
                } else if (statusCode == 405) {
                    toast.info('Dma cannot be deleted');
                } else {
                    toast.error("Couldn't complete request");
                }
            })
            .catch(() => toast.error("Couldn't complete request"));
    };

    const getStatusContent = () => {
        if (updatingStatus) {
            return <LoadingOutlined spin />;
        }

        if (
            user?.kyc_flow_state === Status.Pending ||
            user?.kyc_flow_state === Status.approvalPending ||
            user?.kyc_flow_state === Status.noDocument
        ) {
            return (
                <div className="p-0">
                    {updatingStatus ? (
                        <LoadingOutlined spin />
                    ) : (
                        <>
                            <div
                                className=" pointer mb-2 d-flex pt-0 align-items-center"
                                onClick={async () => {
                                    const results = await Swal.fire({
                                        title: '<strong style="color: #2cb34a">Approve Digifarm Master Agent</strong>',
                                        text: `Are you sure you want to approve ${user?.businessName}?`,
                                        icon: 'success',
                                        confirmButtonText: `APPROVE`,
                                        confirmButtonColor: '#2cb34a',
                                        showCancelButton: true,
                                    });
                                    if (results.value) {
                                        updateDmaStatus(id, 'APPROVED');
                                        setApprovedState('Approved');
                                    }
                                }}
                            >
                                <CheckOutlined /> &nbsp; Accept
                            </div>
                            <div
                                className={'pointer d-flex pt-0 align-items-center'}
                                onClick={() => {
                                    return Swal.fire({
                                        title: '<strong style="color: #f01d1d">Reject Digifarm Master Agent</strong>',
                                        text: `Are you sure you want to reject ${user?.businessName}?`,
                                        icon: 'error',
                                        confirmButtonColor: '#f01d1d',
                                        confirmButtonText: `OKAY`,
                                        showCancelButton: true,
                                    }).then(async (results: any) => {
                                        if (results.value) {
                                            updateDmaStatus(id, 'REJECTED');
                                            setRejectedState('Rejected');
                                        }
                                    });
                                }}
                            >
                                <CloseOutlined /> &nbsp; Reject
                            </div>
                        </>
                    )}
                </div>
            );
        } else if (user?.kyc_flow_state === Status.Approved) {
            return (
                <div
                    className="d-flex pt-0 align-items-center pointer"
                    onClick={() => {
                        history.push(`/dma/${user?.dmaId || ''}/stock`);
                    }}
                >
                    <EyeOutlined /> &nbsp; View Stock
                </div>
            );
        } else {
        }
    };

    const getDeleteDmaContent = () => {
        if (checkingCanDeleteDma) {
            return <LoadingOutlined spin />;
        }

        return (
            <button
                disabled={!canDeleteDma}
                className={'pointer p-0 d-flex align-items-center mt-2 w-100'}
                style={{
                    color: `${canDeleteDma ? 'black' : 'gray'}`,
                    border: 'none',
                    outline: 'none',
                    backgroundColor: 'white',
                }}
                onClick={() => {
                    return Swal.fire({
                        title: '<strong style="color: #f01d1d">Delete Digifarm Master Agent</strong>',
                        text: `Are you sure you want to delete ${user?.businessName}?`,
                        icon: 'question',
                        confirmButtonColor: '#f01d1d',
                        confirmButtonText: `OKAY`,
                        showCancelButton: true,
                    }).then(async (results: any) => {
                        if (results.value) {
                            deleteDma();
                        }
                    });
                }}
            >
                <DeleteFilled /> &nbsp; Delete
            </button>
        );
    };

    const getContent = () => {
        return (
            <div>
                {getStatusContent()}
                {getDeleteDmaContent()}
            </div>
        );
    };

    const history = useHistory();

    const getStatus = () => {
        if (pending) return 'Pending';
        if (approvalPending) return 'Pending';
        if (accepted || approvedState === 'Approved') return 'Approved';
        if (rejected || rejectedState === 'Rejected') return 'Rejected';
        if (noDocument) return 'No document uploaded';
    };

    return (
        <div className="d-flex justify-content-between fullwidth min-height-100vh p-4">
            <ToastContainer />
            <Card className="profile-card fullwidth mb-5">
                <Popover placement="leftTop" content={getContent()} trigger="click">
                    <div className="pull-right pointer">
                        <MoreOutlined size={23} style={{ fontSize: '18px', color: '#000000' }} />
                    </div>
                </Popover>

                <div className="flex-column fullwidth d-flex align-items-center">
                    <div
                        className={`${
                            pending || rejected || Status.noDocument || !user?.kyc_flow_state
                                ? 'grey-bg-color light-grey-color'
                                : 'primary-bg-color white-color'
                        } profile-initials d-flex align-items-center justify-content-center`}
                    >
                        <div>{getInitials(name)}</div>
                    </div>
                    <div className="black-color fs-20 fw-500 mt-3">{user?.businessName}</div>
                    {/* <div className="grey-color fs-16">ID Number: {user.}</div> */}
                </div>
                <Divider />
                <div className=" mb-3">
                    <div className="profile-header">Status</div>
                    <div
                        className={accepted ? 'primary-color' : !user?.kyc_flow_state ? 'grey-color' : 'pending-color'}
                        style={{ fontWeight: 500 }}
                    >
                        {getStatus()}
                    </div>
                </div>
                <div className="mb-3">
                    <div className="profile-header">Email</div>
                    <div className="black-color">{user?.mail}</div>
                </div>
                <div className="mb-3">
                    <div className="profile-header">Phone</div>
                    <div className="black-color">{user?.phoneNumber}</div>
                </div>
                <div className="mt-3 mb-3">
                    <div className="profile-header">Location</div>
                    <div className="black-color">{user?.location}</div>
                </div>
                <div className="mt-3 mb-3">
                    <div className="profile-header">Ward</div>
                    <div className="black-color">{user?.ward}</div>
                </div>
                <div className="mt-3 mb-3">
                    <div className="profile-header">Attachments</div>
                    {/*{downloadUrl.map((data, index) => {*/}
                    {/*  const documentName = data.fileName?.split("/");*/}
                    {/*  return (*/}
                    {downloadUrl ? (
                        <Row className="mb-2 mt-2">
                            <Col span={4} className="text-align-center">
                                <FileOutlined style={{ fontSize: '20px', color: '#cccccc' }} />
                            </Col>
                            <Col span={20}>
                                <a
                                    className="grey-color line-break-anywhere"
                                    href="#"
                                    onClick={async (ev) => {
                                        ev.preventDefault();
                                        getReadUrl(id, true);
                                    }}
                                >
                                    {downloadUrl?.split('/').pop()?.split('?')[0]}
                                </a>
                            </Col>
                        </Row>
                    ) : (
                        'No documents attached'
                    )}
                </div>
            </Card>
        </div>
    );
};
