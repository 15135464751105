import { Breadcrumb } from 'antd';
import { Auth } from 'aws-amplify';
import axios, { AxiosRequestConfig } from 'axios';
import React, { useState } from 'react';
import BlockUi from 'react-block-ui';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { NewProductUpload } from '../../components/NewProductUpload';

const BulkUpdatDma: React.FC = () => {
    const [files, setFiles] = useState<File[]>([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const history = useHistory();

    const readFileDataAsBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                if (event.target?.result) resolve(event.target.result);
            };

            reader.onerror = (err) => {
                reject(err);
            };

            reader.readAsText(file);
        });
    };

    const upload = async () => {
        try {
            if (files.length < 1) {
                return setMessage('Please upload a file');
            }
            setLoading(true);
            const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
            const fileContents = await readFileDataAsBase64(files[0]);
            const config: AxiosRequestConfig = {
                method: 'post',
                onUploadProgress: (progressEvent: any) => console.log({ progressEvent }),
                url: `${process.env.REACT_APP_DMA_API_ENDPOINT}/v1/dma/admin-bulk-update-dmas`,
                headers: {
                    'Content-Type': files[0].type,
                    Authorization: jwt,
                },
                data: fileContents,
            };
            const { data } = await axios(config);
            console.log(data);
            setMessage(data?.message);
            if (data?.code === 'SUCCESS') {
                setTimeout(() => {
                    history.push('/dma');
                }, 1500);
            }
            setLoading(false);
        } catch (e: any) {
            setMessage(e.message as string);
            setLoading(false);
        }
    };
    return (
        <BlockUi blocking={loading}>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
                                <Breadcrumb.Item onClick={() => history.push('/dma')}>
                                    DigiFarm Master Agents
                                </Breadcrumb.Item>
                                <Breadcrumb.Item href="">Bulk Update</Breadcrumb.Item>
                            </Breadcrumb>
                            <div className="fw-400 mt-5 pb-5">
                                <div className="fw-500">
                                    <div>The requirement for the upload should be the listed items.</div>
                                    <div>They include:</div>
                                    <div className="bkl-ml-3">
                                        <ul>
                                            <li>ID</li>
                                            <li>Phone Number</li>
                                            <li>Ward</li>
                                            <li>Credit Limit</li>
                                            <li>Digifarm Hub</li>
                                        </ul>
                                    </div>
                                    {/* <div>
                                        The Template can be downloaded from
                                        <a
                                            style={{ color: '#2cb34a' }}
                                            target="_blank"
                                            rel="noreferrer"
                                            // href={`${awsConfig.Storage.asset}/test-bulk-upload-farmers.csv`}
                                        >
                                            &nbsp;here
                                        </a>
                                    </div> */}
                                    <div className="mt-5 mb-3">UPLOAD DOCUMENT</div>
                                </div>
                                {message && (
                                    <Alert key="alert-key" variant="warning">
                                        {message}
                                    </Alert>
                                )}
                                <NewProductUpload files={files} setFiles={setFiles} handleUpload={upload} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BlockUi>
    );
};

export default BulkUpdatDma;
