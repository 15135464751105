import React from 'react';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { formatTick } from './util';

interface DataPoints {
    label: string;
    sales: string | number;
}

type Props = {
    manufactureSales: DataPoints[];
};

const ManufactureSales: React.FC<Props> = ({ manufactureSales }) => {
    return (
        <ResponsiveContainer width="100%" height="100%" minHeight="300px">
            <BarChart width={150} height={40} data={manufactureSales}>
                <YAxis tickFormatter={formatTick} />
                <XAxis dataKey="label" name="Month" />
                <Tooltip />
                <Bar dataKey="sales" fill="#f7a73e" name="Sales" />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default ManufactureSales;
