import './input.css';

import React from 'react';

interface CustomInputProps extends Partial<HTMLInputElement> {
    onChange?: (arg: string) => void;
}

export const Input: React.FC<CustomInputProps> = ({
    className,
    type,
    placeholder,
    value,
    onChange,
    required = false,
}) => {
    return (
        <input
            className={`custom-input ${className || ''}`}
            placeholder={placeholder}
            value={value}
            required={required}
            type={type}
            onChange={(e) => onChange && onChange(e.target.value)}
            name="name"
        />
    );
};
