import { GraphQLResult } from '@aws-amplify/api';
import { Card, Col, Row, Table } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';

import { DMAResponse, SearchFarmerOrdersAdminQuery } from '../../../API';
import { searchFarmerOrdersAdmin } from '../../../graphql/queries';
import { Status } from '../../../models';
import { EmptyUserData } from './EmptyUserData';
interface OrderProps {
    title: string;
    userId: string;
    empty: string;
    status: string | null | undefined;
    name: string | undefined;
    user: DMAResponse['data'] | null | undefined;
}

const columns = [
    {
        title: 'Order ID',
        dataIndex: 'orderId',
        key: 'orderId',
    },
    {
        title: 'Date of order',
        dataIndex: 'dateOfOrder',
        key: 'dateOfOrder',
        // render: (_: any, data: any) => {
        //     console.log(data);
        //     return <div className="active-status">{data}</div>;
        // },
    },
    {
        title: 'Amount(Ksh)',
        dataIndex: 'amount',
        key: 'amount',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: () => {
            return <div className="active-status">Successful</div>;
        },
    },
];
export const Orders: React.FC<OrderProps> = ({ title, status, empty, user, name, userId }) => {
    // const pending = status === Status.Pending && user.account_status;
    const accepted = status === Status.Approved && user?.kyc_flow_state;
    const [dataSource, setDataSource] = useState<any>([]);
    // const rejected = status === Status.Rejected && user.account_status;

    const getOrders = async () => {
        try {
            const q = graphqlOperation(searchFarmerOrdersAdmin, { dmaId: userId });

            const { data } = (await API.graphql(q)) as GraphQLResult<SearchFarmerOrdersAdminQuery>;
            const mappedData = data?.searchFarmerOrdersAdmin?.result?.map((data) => {
                return {
                    id: data?.id,
                    dateOfOrder: new Date(data?.creationDate as string).toDateString(),
                    orderId: `${data?.farmerOrderId?.slice(0, 7)}...`,
                    amount: data?.totalCost,
                    status: data?.farmerOrderStatus,
                };
            });
            if (mappedData) {
                setDataSource(mappedData);
            }
        } catch (e: any) {
            console.log(e);
            return {
                message: e.message,
            };
        }
    };

    useEffect(() => {
        getOrders();
    }, []);

    return (
        <Row>
            <Col span={24}>
                <div className="primary-color mb-2 fw-500 fs-18">{title}</div>
                <Card className="sales-orders-card overflow-scroll">
                    {accepted ? (
                        <Table className={'dma-header'} dataSource={dataSource} columns={columns} />
                    ) : (
                        <EmptyUserData empty={empty} name={name} />
                    )}
                </Card>
            </Col>
        </Row>
    );
};
