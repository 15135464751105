import './IDProductDetails.css';

import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { Product } from '../../API';
import { useProducts } from '../../views/products/useProducts';
import { ProductImage } from '../ProductImage/ProductImage';
import { IDProductImages } from './IDProductImages';
import { IDProductInfo } from './IDProductInfo';

/**
 * In order to be highly reusable, this component assumes its
 * parent's size
 */
export const IDProductDetails: FC<{ details: Product }> = ({ details }) => {
    const { image, ...info } = details;
    const images = Array.isArray(image) ? image : [image, image, image];
    const { updateProduct, removeProduct } = useProducts();
    const history = useHistory();

    const acceptProduct = () => {
        return Swal.fire({
            title: 'Update Product Status',
            text: 'Update Product Status to approved?',
            icon: 'success',
            showCancelButton: true,
        }).then(async (results: any) => {
            if (results.value) {
                await updateProduct(info.productId, { ...info, status: 'Approved' });
                window.location.href = '/products';
            }
        });
    };

    const deleteProduct = () => {
        return Swal.fire({
            title: 'Delete Product',
            text: `Are you sure you want to delete ${info.name}?`,
            confirmButtonColor: '#f01d1d',
            icon: 'error',
            showCancelButton: true,
        }).then(async (results: any) => {
            if (results.value) {
                await removeProduct(info.productId);
                window.location.href = '/products';
            }
        });
    };

    const rejectProduct = () => {
        return Swal.fire({
            title: 'Update Product Status',
            text: 'Update Product Status to rejected?',
            confirmButtonColor: '#f01d1d',
            icon: 'error',
            showCancelButton: true,
        }).then(async (results: any) => {
            if (results.value) {
                await updateProduct(info.productId, { ...info, status: 'rejected' });
                window.location.href = '/products';
            }
        });
    };

    const approvedProductMenu = (
        <Menu>
            <Menu.Item onClick={() => history.push(`/products/${info.productId}/edit`)} key="1">
                <p onClick={() => history.push(`/products/${info.productId}/edit`)} className="status-approval">
                    {' '}
                    <EditOutlined /> Edit
                </p>
            </Menu.Item>
            <Menu.Item onClick={deleteProduct} key="2">
                <p onClick={deleteProduct} className="status-approval">
                    {' '}
                    <DeleteOutlined /> Delete
                </p>
            </Menu.Item>
        </Menu>
    );

    const rejectedProductMenu = (
        <Menu>
            <Menu.Item onClick={deleteProduct} key="2">
                <p onClick={deleteProduct} className="status-approval">
                    {' '}
                    <DeleteOutlined /> Delete
                </p>
            </Menu.Item>
        </Menu>
    );

    const menu = (
        <Menu>
            <Menu.Item onClick={() => acceptProduct()} key="1">
                <p onClick={() => acceptProduct()} className="status-approval">
                    {' '}
                    <CheckOutlined /> Approve
                </p>
            </Menu.Item>
            <Menu.Item onClick={rejectProduct} key="2">
                <p onClick={rejectProduct} className="status-approval">
                    {' '}
                    <CloseOutlined /> Reject
                </p>
            </Menu.Item>
        </Menu>
    );

    return (
        <Row className="id-product-details">
            <Dropdown.Button
                overlay={
                    info.status?.toLocaleLowerCase() === 'approved'
                        ? approvedProductMenu
                        : info.status?.toLocaleLowerCase() === 'rejected'
                        ? rejectedProductMenu
                        : menu
                }
            ></Dropdown.Button>
            <Col>
                <div className="main-image-wrapper">
                    <ProductImage imgUrl={images[0] || ''} />
                </div>
                <div className="mt-3">
                    <IDProductImages images={images} />
                </div>
            </Col>
            <Col>
                <IDProductInfo info={info} />
            </Col>
        </Row>
    );
};
