import React from 'react';
import { Alert, Card, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { Input } from '../../components/inputs/Input';
import { fieldsType } from './types';

interface Iprops {
    setKey: (key: string) => void;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    values: fieldsType;
    creating: boolean;
    companyName: string;
    setCompanyName: (value: string) => void;
    location: string;
    setLocation: (value: string) => void;
    phoneNumber: string;
    setPhoneNumber: (value: string) => void;
    email: string;
    setEmail: (value: string) => void;
    name: string;
    setName: (value: string) => void;
    handleSubmit: () => void;
    message: string;
}

export const PersonalInfo: React.FC<Iprops> = ({
    creating,
    companyName,
    setCompanyName,
    location,
    setLocation,
    phoneNumber,
    setPhoneNumber,
    email,
    setEmail,
    name,
    setName,
    handleSubmit,
    message,
}) => {
    const history = useHistory();

    return (
        <div className="mt-5">
            <Card className="shadow-lg p-3 mb-5">
                {message && (
                    <Alert key="alert-key" variant="warning">
                        {message}
                    </Alert>
                )}
                <form
                    onSubmit={(e) => {
                        handleSubmit();
                        e.preventDefault();
                    }}
                >
                    <div className="d-flex">
                        <Col md={6} className="p-3">
                            <div className="form-group">
                                <label className="auth-label mb-2">Company Name</label>
                                <Input
                                    className=""
                                    placeholder="Company Name"
                                    value={companyName}
                                    type="text"
                                    required
                                    onChange={(e) => setCompanyName(e)}
                                    name="company_name"
                                    disabled={false}
                                />
                            </div>
                        </Col>
                        <Col md={6} className="p-3">
                            <div className="form-group">
                                <label className="auth-label mb-2">Location</label>
                                <Input
                                    className=""
                                    placeholder="Location"
                                    value={location}
                                    type="text"
                                    required
                                    onChange={(e) => setLocation(e)}
                                    maxLength={11}
                                    name="location"
                                    disabled={false}
                                />
                            </div>
                        </Col>
                    </div>
                    <div className="d-flex">
                        <Col md={6} className="p-3">
                            <div className="form-group ">
                                <label className="auth-label mb-2">Contact Person</label>
                                <Input
                                    className=""
                                    placeholder="John Doe"
                                    value={name}
                                    type="text"
                                    required
                                    onChange={(e) => setName(e)}
                                    name="contactPerson"
                                    disabled={false}
                                />
                            </div>
                        </Col>
                        <Col md={6} className="p-3">
                            <div className="form-group ">
                                <label className="auth-label mb-2">Email Address</label>
                                <Input
                                    className=""
                                    placeholder="johndoe@gmail.com"
                                    value={email}
                                    type="email"
                                    required
                                    onChange={(e) => setEmail(e)}
                                    maxLength={11}
                                    name="email"
                                    disabled={false}
                                />
                            </div>
                        </Col>
                    </div>
                    <div className="d-flex">
                        <Col md={6} className="p-3">
                            <div className="form-group ">
                                <label className="auth-label mb-2">Phone Number</label>
                                <Input
                                    className=""
                                    placeholder="0712345678"
                                    value={phoneNumber}
                                    type="number"
                                    required
                                    onChange={(e) => setPhoneNumber(e)}
                                    maxLength={11}
                                    name="phoneNumber"
                                    disabled={false}
                                />
                            </div>
                        </Col>
                    </div>

                    <div className="d-flex justify-content-flex-end fullwidth mt-5">
                        <div
                            className="cancel-btn fw-500 m-3 btn-width pointer"
                            onClick={() => history.push('/manufacturers')}
                        >
                            Cancel
                        </div>
                        <button className="primary-btn fw-500 m-3 btn-width" disabled={creating}>
                            {creating ? 'Loading...' : 'Next'}
                        </button>
                    </div>
                </form>
            </Card>
        </div>
    );
};
