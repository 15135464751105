import './createUser.css';

import { Breadcrumb, Select } from 'antd';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';

import { Input } from '../../../components/inputs/Input';
import { PageTitle } from '../PageTitle';
import { useCreateUser } from './useCreate';

const { Option } = Select;
const CreateUser = () => {
    //hooks
    const {
        setName,
        name,
        email,
        setEmail,
        handleSelectRole,
        role,
        roles,
        getUserRoles,
        phoneNumber,
        setPhoneNumber,
        createUser,
        isCreating,
    } = useCreateUser();
    const history = useHistory();

    //useEffect hook to load roles
    useEffect(() => {
        getUserRoles();
    }, []);

    return (
        <div>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="/users">Users</Breadcrumb.Item>
                                <Breadcrumb.Item>Add New</Breadcrumb.Item>
                            </Breadcrumb>
                            <PageTitle title={'Create User'} />
                            <div className={'user-permission'}>
                                <Col md={11} sm={12}>
                                    <Row>
                                        <Col md={6} sm={12} xs={12}>
                                            <div className="form-group mb-4">
                                                <label className="auth-label mb-2"> Name</label>
                                                <Input
                                                    placeholder={'John Doe'}
                                                    value={name}
                                                    type="text"
                                                    required
                                                    onChange={(e) => setName(e)}
                                                    name="given_name"
                                                    disabled={false}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6} sm={12} xs={12}>
                                            <div className="form-group mb-4">
                                                <label className="auth-label mb-2">Email</label>
                                                <Input
                                                    placeholder={'johndoe@email.com'}
                                                    className=""
                                                    value={email}
                                                    type="email"
                                                    required
                                                    onChange={(e) => setEmail(e)}
                                                    name="company_name"
                                                    disabled={false}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={11} sm={12}>
                                    <Row className={'mb-4'}>
                                        <Col md={6} sm={12} xs={12}>
                                            <div className="form-group">
                                                <label className="auth-label mb-2">Role</label>
                                                <Select
                                                    value={role}
                                                    style={{ width: '100%' }}
                                                    className="custom-select"
                                                    onChange={handleSelectRole}
                                                >
                                                    <Option value="disabled" disabled>
                                                        Select a Role
                                                    </Option>
                                                    {roles?.map((role, index) => (
                                                        <Option
                                                            key={role?.rolesId || index}
                                                            value={role?.rolename || ''}
                                                        >
                                                            {role?.rolename}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </Col>
                                        <Col md={6} sm={12} xs={12}>
                                            <div className="form-group mb-4">
                                                <label className="auth-label mb-2">Phone Number</label>
                                                <Input
                                                    placeholder={'0710937839'}
                                                    className=""
                                                    value={phoneNumber}
                                                    type="number"
                                                    required
                                                    onChange={(e) => setPhoneNumber(e)}
                                                    name="phone_number"
                                                    disabled={false}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="d-flex justify-content-flex-end fullwidth mt-5 uppercase">
                                        <div
                                            className="cancel-btn fw-500 m-3 btn-width pointer mt-5"
                                            onClick={() => history.push('/users')}
                                        >
                                            Cancel
                                        </div>
                                        <button
                                            className="primary-btn fw-500 m-3 btn-width uppercase mt-5"
                                            onClick={createUser}
                                            disabled={isCreating}
                                        >
                                            {isCreating ? 'Sending...' : 'Send invite'}
                                        </button>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateUser;
