import { GraphQLResult } from '@aws-amplify/api';
import { message } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import { useState } from 'react';

import {
    DisableAdminMutation,
    EnableAdminMutation,
    GetAdminAccountQuery,
    ListAdminsQuery,
    UpdateAdminRoleMutation,
} from '../../API';
import { disableAdmin, enableAdmin, updateAdminRole } from '../../graphql/mutations';
import { getAdminAccount, listAdmins } from '../../graphql/queries';
import { mapAttributesToAdmin } from '../../utils';

export const useAdmin = () => {
    const [adminsData, setAdminsData] = useState<any[]>([]);
    const [initialData, setInitialData] = useState<any[]>([]);
    const [adminData, setAdminData] = useState<any>({});
    const [isCreating, setCreating] = useState(false);
    const [selectedRole, setSelectedRoles] = useState('Select a role');
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };
    //function to handle select role.
    const handleSelectRole = (e: string) => {
        setSelectedRoles(e);
    };

    //function to edit user role
    const editUserRole = async (id: string) => {
        setCreating(true);
        try {
            const mutation = graphqlOperation(updateAdminRole, {
                Username: id,
                group: selectedRole,
            });
            (await API.graphql(mutation)) as GraphQLResult<UpdateAdminRoleMutation>;
            getAdmin(id);
            message.success('successfully updated');
            setIsModalVisible(false);
            setCreating(false);
        } catch (e: any) {
            console.log(e);
            setCreating(false);
        }
    };

    const getAdmin = async (id: string) => {
        try {
            const query = graphqlOperation(getAdminAccount, {
                Username: id,
            });
            const { data } = (await API.graphql(query)) as GraphQLResult<GetAdminAccountQuery>;
            const user = data?.getAdminAccount;
            if (user) {
                setAdminData(mapSingleAdmin(user));
            }
        } catch (e: any) {
            console.log(e);
        }
    };

    const activateUser = async (id: string) => {
        try {
            const query = graphqlOperation(enableAdmin, {
                Username: id,
            });
            const { data } = (await API.graphql(query)) as GraphQLResult<EnableAdminMutation>;
            const user = data?.enableAdmin;
            if (user) {
                getAdmin(id);
            }
        } catch (e: any) {
            console.log(e);
        }
    };

    const deActivateUser = async (id: string) => {
        try {
            const query = graphqlOperation(disableAdmin, {
                Username: id,
            });
            const { data } = (await API.graphql(query)) as GraphQLResult<DisableAdminMutation>;
            const user = data?.disableAdmin;
            if (user) {
                getAdmin(id);
            }
        } catch (e: any) {
            console.log(e);
        }
    };

    const mapSingleAdmin = (record: any) => {
        const data = {
            username: record.Username,
            roles: record.roles,
            permissions: record.permissions,
            status: record.UserStatus,
            enabled: record.Enabled,
        };
        const attributes = record.UserAttributes.reduce(
            (obj: any, item: any) => ((obj[item.Name] = item.Value), obj),
            {},
        );
        return { ...data, ...attributes };
    };

    const fetchAdmins = async (filters: { limit: number; nextPageToken?: string }) => {
        try {
            const query = graphqlOperation(listAdmins, { ...filters });
            const { data } = (await API.graphql(query)) as GraphQLResult<ListAdminsQuery>;

            const users = data?.listAdmins?.Users || [];
            const paginationToken = data?.listAdmins?.PaginationToken;

            if (!users.length) {
                throw new Error('No users found');
            }

            const mapped = users.map(mapAttributesToAdmin);
            setAdminsData(mapped);
            setInitialData(mapped);

            return {
                users: mapped,
                paginationToken,
            };
        } catch (e: any) {}
    };

    return {
        adminsData,
        adminData,
        setAdminsData,
        initialData,
        fetchAdmins,
        getAdmin,
        deActivateUser,
        activateUser,
        handleSelectRole,
        selectedRole,
        editUserRole,
        isCreating,
        showModal,
        isModalVisible,
        handleOk,
        setIsModalVisible,
    };
};
