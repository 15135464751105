import '../../../components/inputs/input.css';
import '../auth.css';
import '../../../components/button/button.css';

import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { Alert, Spinner } from 'react-bootstrap';

import { Input } from '../../../components/inputs/Input';
import { Views } from '../auth-constants';
import { AuthHeading } from '../components/AuthHeading';
import useBreakpoint from '../hooks/useBreakpoint';

type ForgotPasswordProps = {
    sendResetInfo: (password: string) => void;
    isSubmitting?: boolean;
    setView: (view: Views) => void;
    setUsername: (username: string) => void;
    setDeliveryMethod: (method: string) => void;
};

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({ setView, setUsername, setDeliveryMethod }) => {
    const [email, setEmail] = useState('');

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const bp = useBreakpoint();
    const isSmallScreen = bp === 'xs' || bp === 'sm';

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            const result = await Auth.forgotPassword(email);
            console.log({ result });
            setUsername(email);
            setDeliveryMethod(result?.CodeDeliveryDetails?.Destination);
            setView(Views.VerifyResetPassword);
        } catch (err: any) {
            console.log({ err });
            if (err.code === 'UserNotFoundException') {
                setMessage('The provided email or phone number does not exist');
            } else if (err.code === 'NetworkError') {
                setMessage('Network Error - Please check internet connection');
            }
        }
        setLoading(false);
    };

    // return (
    //   <div
    //     className="mt-4 position-relative"
    //     style={{ height: isSmallScreen ? '70vh' : '100%' }}
    //   >
    //     <AuthHeading title="Forgot Password" subTitle="" />
    //     <div style={{ width: 189, height: 106 }}></div>
    //     <form onSubmit={handleSubmit}>
    //       <div className="form-group mt-5 mb-5">
    //         <label className="auth-label mb-2">email</label>
    //         <Input
    //           placeholder="Johndoeltd@gmail.com"
    //           value={email}
    //           type="text"
    //           onChange={setEmail}
    //           name="email"
    //           disabled={false}
    //         />
    //       </div>
    //       <button className="primary-btn col-12 fw-500" type="submit">
    //         {!isSubmitting ? (
    //           'Reset Password'
    //         ) : (
    //           <Spinner animation="grow" className="mt-1" />
    //         )}
    //       </button>
    //     </form>
    //   </div>
    // );

    return (
        <div className="mt-4 position-relative" style={{ height: isSmallScreen ? '70vh' : '100%' }}>
            {message ? <Alert variant="warning">{message}</Alert> : ''}
            <AuthHeading title="Forgot Password" subTitle="Enter the email address you registered with." />
            <form onSubmit={handleSubmit}>
                <div className="form-group mt-5 mb-5">
                    <label className="auth-label mb-2">email</label>
                    <Input
                        placeholder="Johndoeltd@gmail.com"
                        value={email}
                        type="text"
                        onChange={setEmail}
                        name="email"
                        disabled={false}
                    />
                </div>
                <button className="primary-btn col-12 fw-500" type="submit">
                    {!loading ? 'Reset Password' : <Spinner animation="grow" className="mt-1" />}
                </button>
            </form>
        </div>
    );
};
