import { combineReducers } from 'redux';

import auth from './authReducer';
import categories from './categoriesReducer';
import manufacturersList from './manufacturerReducer';
import manufacturers from './manufacturersReducers';
import products from './productsReducers';
import {
    AuthReducerState,
    CategoriesReducerState,
    ManufacturerReducerState,
    ManufacturersReducerState,
    ProductsReducerState,
} from './reducer.model';

export type AppState = {
    auth: AuthReducerState;
    products: ProductsReducerState;
    manufacturers: ManufacturersReducerState;
    manufacturersList: ManufacturerReducerState;
    categories: CategoriesReducerState;
};

export type UploadAppState = {
    auth?: AuthReducerState;
    products?: ProductsReducerState;
    manufacturers?: ManufacturersReducerState;
    categories: CategoriesReducerState;
};

export default combineReducers({
    auth,
    manufacturers,
    products,
    categories,
    manufacturersList,
});
