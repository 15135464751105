import '../../../components/inputs/input.css';
import '../auth.css';
import '../../../components/button/button.css';

import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';

import { Input } from '../../../components/inputs/Input';
import { AuthHeading } from '../components/AuthHeading';
// import useBreakpoint from '../hooks/useBreakpoint';

type SignInFormProps = {
    isSubmitting?: boolean;
    signIn: (userId: string, password: string) => void;
    resetPassword: () => void;
};

export const SignInForm: React.FC<SignInFormProps> = ({ signIn, resetPassword, isSubmitting }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // const bp = useBreakpoint();
    // const isSmallScreen = bp === 'xs' || bp === 'sm';

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        signIn(email, password);
    };

    return (
        <div className="mt-4 position-relative">
            <AuthHeading title="Login to your account" subTitle="Enter your details to log in to your account" />
            <form onSubmit={handleSubmit}>
                <div className="form-group mt-5 mb-5">
                    <label className="auth-label mb-2">email address</label>
                    <Input
                        placeholder="Johndoeltd@gmail.com"
                        value={email}
                        type="email"
                        onChange={setEmail}
                        name="idNumber"
                        disabled={false}
                    />
                </div>
                <div className="form-group mt-4 mb-4">
                    <label className="auth-label mb-2">Password</label>
                    <Input
                        placeholder="XXXXXXX"
                        value={password}
                        type="password"
                        onChange={setPassword}
                        name="password"
                        disabled={false}
                    />
                </div>
                <div className="form-group mt-4 mb-4 text-align-right pointer" onClick={() => resetPassword()}>
                    Forgot Password?
                </div>
                <button className="primary-btn col-12 uppercase fw-500" type="submit" disabled={isSubmitting}>
                    {!isSubmitting ? 'Log In' : <Spinner animation="grow" className="mt-1" />}
                </button>
            </form>
            <div className="text-align-center terms mt-3">
                By continuing you accept our&nbsp;
                <span className="primary-color pointer">Terms of Use</span> and{' '}
                <span className="primary-color pointer">Privacy Policy</span>
            </div>
        </div>
    );
};
