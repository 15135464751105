import { MinusOutlined, PlusOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { Button, Card, Col, Divider, Row, Select, Tree } from 'antd';
import type { DataNode } from 'antd/lib/tree';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { toast } from 'react-toastify';

import { DMA, UpdateType } from '../../../API';
import { updateDmaProfileDetailsByDmaId } from '../../../graphql/mutations';
import { Manufacturer } from '../../../shared-types/manufacturer.model';
import { useManufacturer } from '../../manufacturers/useManufacturer';
const { Option } = Select;

interface DigiFarmHubCardType {
    id: string;
    user: DMA;
}
type Hub = {
    hubId: string;
    hubName: string;
};
type DigiFarmHub = {
    manufacturerId: string;
    manufacturerName: string;
    hubs: Hub[];
};
export const DigiFarmHubCard: React.FC<DigiFarmHubCardType> = ({ user, id }) => {
    const [digiFarmHub, setDigiFarmHub] = useState<DigiFarmHub[] | any>([
        ...(user?.digiFarmHub && user?.digiFarmHub.length > 0
            ? user?.digiFarmHub
            : [
                  {
                      manufacturerId: '',
                      manufacturerName: '',
                      hubs: [
                          {
                              hubId: '',
                              hubName: '',
                          },
                      ],
                  },
              ]),
    ]);
    const [edit, setEdit] = useState(false);
    const [updating, setUpdating] = useState(false);
    const { fetchManufacturers, manufacturersData, fetchManufacturerHubs, manufacturerHubs } = useManufacturer();

    useEffect(() => {
        fetchManufacturers();
    }, []);

    const save = async () => {
        try {
            console.log('save', digiFarmHub);
            setUpdating(true);
            const query = graphqlOperation(updateDmaProfileDetailsByDmaId, {
                dmaProfile: {
                    dmaId: id,
                    digiFarmHub,
                    updateType: UpdateType.DIGIFARMHUB,
                },
            });
            const { data } = (await API.graphql(query)) as GraphQLResult<any>;
            console.log(data);
            if (data?.updateDmaProfileDetailsByDmaId?.code === 200) {
                setUpdating(false);
                setEdit(false);
                toast.success('DMA Profile Updated successfully!');
                return;
            } else {
                setUpdating(false);
                setEdit(false);
                setDigiFarmHub([
                    {
                        manufacturerId: '',
                        manufacturerName: '',
                        hubs: [
                            {
                                hubId: '',
                                hubName: '',
                            },
                        ],
                    },
                ]);
                toast.error(data?.message || 'Failed to update dma profile');
                return;
            }
        } catch (e: any) {
            setDigiFarmHub([
                {
                    manufacturerId: '',
                    manufacturerName: '',
                    hubs: [
                        {
                            hubId: '',
                            hubName: '',
                        },
                    ],
                },
            ]);
            toast.error('Error updating profile', e);
            return;
        } finally {
            setUpdating(false);
            setEdit(false);
        }
    };

    const handleChange = (index: number, value: string[]) => {
        const data = [...digiFarmHub];
        if (value.length > 0) {
            data[index].hubs = value.map((manufacturerHub) => {
                return {
                    hubId: manufacturerHubs.find((hub) => hub.hubName === manufacturerHub)?.hubId,
                    hubName: manufacturerHubs.find((hub) => hub.hubName === manufacturerHub)?.hubName,
                };
            });
            setDigiFarmHub(data);
        } else {
            if (index > 0) {
                const arr = data.splice(index, 1);
                setDigiFarmHub(arr);
            } else {
                setDigiFarmHub([]);
            }
        }
    };

    const handleChangeManufacturer = (index: number, manufacturer: Manufacturer | any) => {
        const data = [...digiFarmHub];
        data[index].manufacturerId = manufacturer?.manufacturerId;
        data[index].manufacturerName = manufacturer?.companyName;
        setDigiFarmHub(data);
    };

    const formatArrayToNode = (items: DigiFarmHub[]): DataNode[] => {
        return items.map((i) => {
            return {
                title: i.manufacturerName,
                key: i.manufacturerId,
                icon: <UserOutlined />,
                children: i.hubs.map((hub) => {
                    return {
                        title: hub.hubName,
                        key: hub.hubId,
                        icon: <ShopOutlined />,
                    };
                }),
            };
        });
    };

    const addFormFields = () => {
        setDigiFarmHub([
            ...digiFarmHub,
            {
                manufacturerId: '',
                manufacturerName: '',
                hubs: [
                    {
                        hubId: '',
                        hubName: '',
                    },
                ],
            },
        ]);
    };

    const removeFields = (index: number) => {
        const data = [...digiFarmHub];
        data.splice(index, 1);
        setDigiFarmHub(data);
    };

    const isDigiFarmHubAvailable = () => {
        return digiFarmHub.map((hub: DigiFarmHub) => hub.manufacturerId).filter((hub: DigiFarmHub) => hub).length > 0;
    };

    return (
        <BlockUi blocking={updating}>
            <Row>
                <Col span={12}>
                    <div className="primary-color fs-20 fw-500 mb-4">DMA Digifarm Hub</div>
                </Col>
                <Col span={12}>
                    <button
                        className="primary-btn-2 fw-500 pull-right mb-2"
                        onClick={() => {
                            edit ? save() : setEdit(true);
                        }}
                    >
                        {edit ? 'SAVE CHANGES' : 'UPDATE DIGIFARM HUB'}
                    </button>
                </Col>
            </Row>
            <div className="d-flex justify-content-between fullwidth">
                <Card className="profile-card fullwidth mb-5">
                    <div className="flex-column fullwidth d-flex fw-500">Digifarm Hub</div>
                    <hr />
                    {!edit ? (
                        <div className="mb-3">
                            {isDigiFarmHubAvailable() ? (
                                <Tree
                                    className="fs-24"
                                    showIcon
                                    selectable={false}
                                    treeData={formatArrayToNode(digiFarmHub)}
                                />
                            ) : (
                                <>No Digifarm Hub available for this DMA</>
                            )}
                        </div>
                    ) : (
                        <div className="mb-3">
                            {digiFarmHub.map((input: DigiFarmHub, index: number) => {
                                return (
                                    <div className="row mb-4 mt-2" key={index}>
                                        <div className="col-md-10">
                                            <Row>
                                                <Col span={6}>Manufacturers:</Col>
                                                <Col span={10}>
                                                    <Select
                                                        value={input.manufacturerName}
                                                        style={{ width: '100%' }}
                                                        className="custom-select"
                                                        onChange={async (value) => {
                                                            const manufacturer = manufacturersData.find(
                                                                (man) => man?.companyName === value,
                                                            );
                                                            handleChangeManufacturer(index, manufacturer);
                                                            await fetchManufacturerHubs(
                                                                manufacturer?.manufacturerId as string,
                                                            );
                                                        }}
                                                    >
                                                        <Option value="disabled" disabled>
                                                            Select a Manufacturer
                                                        </Option>
                                                        {manufacturersData
                                                            ?.filter(
                                                                (man) =>
                                                                    !digiFarmHub
                                                                        .map((dig: DigiFarmHub, ind: number) => {
                                                                            if (ind !== index)
                                                                                return dig.manufacturerId;
                                                                        })
                                                                        .includes(man.manufacturerId),
                                                            )
                                                            ?.map((manufacturer, _index) => (
                                                                <Option
                                                                    key={manufacturer?.manufacturerId || _index}
                                                                    value={manufacturer?.companyName || {}}
                                                                >
                                                                    {manufacturer?.companyName}
                                                                </Option>
                                                            ))}
                                                    </Select>
                                                </Col>
                                            </Row>
                                            {input.manufacturerId && (
                                                <div className="mt-3">
                                                    <Row>
                                                        <Col span={6}>Manufacturer Hubs:</Col>
                                                        <Col span={10}>
                                                            <Select
                                                                value={input.hubs
                                                                    .map((hub) => hub.hubName)
                                                                    .filter((hub) => hub)}
                                                                allowClear
                                                                mode="multiple"
                                                                style={{ width: '100%' }}
                                                                className="custom-select"
                                                                onChange={(value) => {
                                                                    handleChange(index, value);
                                                                }}
                                                            >
                                                                <Option value="disabled" disabled>
                                                                    Select a Manufacturer Hubs
                                                                </Option>
                                                                {manufacturerHubs?.map((manufacturerHub, index) => (
                                                                    <Option
                                                                        key={manufacturerHub?.hubName || index}
                                                                        value={manufacturerHub?.hubName || ''}
                                                                    >
                                                                        {manufacturerHub?.hubName}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-2">
                                            <Button
                                                type="primary"
                                                danger
                                                disabled={digiFarmHub.length === 1}
                                                className="delete-btn"
                                                onClick={() => removeFields(index)}
                                                icon={<MinusOutlined />}
                                                size="small"
                                            />
                                            &nbsp;
                                            <Button
                                                type="primary"
                                                disabled={
                                                    manufacturersData.filter(
                                                        (contain: any) =>
                                                            !digiFarmHub
                                                                .map((i: DigiFarmHub) => i.manufacturerId)
                                                                .includes(contain.manufacturerId),
                                                    ).length < 1
                                                }
                                                className="primary-btn"
                                                onClick={() => addFormFields()}
                                                icon={<PlusOutlined />}
                                                size="small"
                                            />
                                        </div>
                                        <Divider />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </Card>
            </div>
        </BlockUi>
    );
};
