import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
    faCog,
    faDollarSign,
    faFileAlt,
    faFileUpload,
    faImage,
    faTachometerAlt,
    faUser,
    faUserCog,
    faUserFriends,
    faUserPlus,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { v4 as uuid } from 'uuid';

export const routes: NavLink[] = [
    {
        title: 'Dashboard',
        icon: faTachometerAlt,
        path: '/home',
        key: uuid(),
        isSelected: window.location.pathname === '/home',
    },
    {
        title: 'DigiFarm Master Agents',
        icon: faUserPlus,
        path: '/dma',
        key: uuid(),
        isSelected: window.location.pathname === '/dma',
    },
    {
        title: 'Manufacturers',
        icon: faUserFriends,
        path: '/manufacturers',
        key: uuid(),
        isSelected: window.location.pathname === '/manufacturers',
    },
    {
        title: 'Manufacturer Sales',
        icon: faDollarSign,
        path: '/sales',
        key: uuid(),
        isSelected: window.location.pathname === '/sales',
    },
    {
        title: 'DMA Sales',
        icon: faDollarSign,
        path: '/sales-farmer',
        key: uuid(),
        isSelected: window.location.pathname === '/sales-farmer',
    },
    // {
    //     title: 'Products',
    //     icon: faShoppingBag,
    //     path: '/products',
    //     key: uuid(),
    // },
    {
        title: 'Products Upload',
        icon: faFileUpload,
        path: '/products/upload',
        key: uuid(),
        isSelected: window.location.pathname === '/products/upload',
    },
    {
        title: 'Products List',
        icon: faFileAlt,
        path: '/products',
        key: uuid(),
        isSelected: window.location.pathname === '/products',
    },
    {
        title: 'Farmer Requests',
        icon: faUsers,
        path: '/farmer/requests',
        key: uuid(),
        isSelected: window.location.pathname === '/farmer/requests',
    },
    {
        title: 'Advertise',
        icon: faImage,
        path: '/advertise',
        key: uuid(),
        isSelected: window.location.pathname === '/advertise',
    },
    // {
    //     title: 'Reports',
    //     icon: faChartBar,
    //     disabled: true,
    //     path: '',
    //     key: uuid(),
    //     isSelected: window.location.pathname === '',
    // },
    {
        title: 'Users',
        icon: faUser,
        path: '/users',
        key: uuid(),
        isSelected: window.location.pathname === '/users',
    },
    {
        title: 'Roles & Permissions',
        icon: faUserCog,
        path: '/roles',
        key: uuid(),
        isSelected: window.location.pathname === '/roles',
    },
    {
        title: 'DMA Roles and Permissions',
        icon: faUserPlus,
        path: '/dma-roles',
        key: uuid(),
        isSelected: window.location.pathname === '/dma-roles',
    },
    {
        title: 'Wards',
        icon: faCog,
        path: '/wards',
        key: uuid(),
        isSelected: window.location.pathname === '/wards',
    },
    {
        title: 'Categories',
        icon: faCog,
        path: '/categories',
        key: uuid(),
        isSelected: window.location.pathname === '/categories',
    },
    {
        title: 'Audit',
        icon: faFileAlt,
        path: '/audit',
        key: uuid(),
        isSelected: window.location.pathname === '/audit',
    },
];
export interface NavLink {
    title: string;
    icon?: IconProp;
    disabled?: boolean;
    path: string;
    key: string;
    isSelected?: boolean;
    render?: (args: Omit<NavLink, 'render'>) => JSX.Element;
    type?: NavLinkTypes;
}

export type NavLinkTypes = 'button' | 'link';
