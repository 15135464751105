import { GraphQLResult } from '@aws-amplify/api';
import { Breadcrumb } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import { FormikHelpers, useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import BlockUi from 'react-block-ui';
import { Button, Card, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { number, object, string } from 'yup';

import {
    CreateDmaStockReconciliationRecordMutation,
    Dmastock,
    DmaStockReconciliationRecord,
    FetchDmaStockReconciliationRecordByIdQuery,
    GetDmastockByIdQuery,
    UpdateDmaStockReconciliationRecordMutation,
} from '../../API';
import { createDmaStockReconciliationRecord, updateDmaStockReconciliationRecord } from '../../graphql/mutations';
import { fetchDmaStockReconciliationRecordById, getDmastockById } from '../../graphql/queries';

const createDmaStockReconciliationRecordSchema = object().shape({
    dmaStockId: string().required('required'),
    productName: string().required('required'),
    creditedCount: number().min(1, 'value must be greater than 0').required('required'),
    verifiedCount: number().min(1, 'value must be greater than 0').required('required'),
    collectedCount: number().min(1, 'value must be greater than 0').required('required'),
    purchasedCount: number().min(1, 'value must be greater than 0').required('required'),
});

const CreateDmaStockReconciliation: React.FC = () => {
    const history = useHistory();
    const [dmaStockDetails, setDmaStockDetails] = useState<Dmastock | undefined | null>();
    const [existingReconciliationRecord, setExistingReconciliationRecord] = useState<
        DmaStockReconciliationRecord | undefined | null
    >();
    const [loading, setLoading] = useState(true);
    const { params } = useRouteMatch();
    const { stockId } = params as any;

    const initialValues = useMemo(
        () => ({
            dmaStockId: stockId.split('-')[0].toUpperCase(),
            productName: dmaStockDetails?.name || '',
            creditedCount: existingReconciliationRecord?.creditedCount || 0,
            verifiedCount: existingReconciliationRecord?.verifiedCount || 0,
            collectedCount: existingReconciliationRecord?.collectedCount || 0,
            purchasedCount: existingReconciliationRecord?.purchasedCount || 0,
        }),
        [stockId, dmaStockDetails, existingReconciliationRecord],
    );

    useEffect(() => {
        setLoading(true);
        Promise.all([fetchDmaStockDetails(), fetchDmaStockReconciliationRecord()]).then(
            ([dmaStockDetails, reconciliationRecord]) => {
                setDmaStockDetails(dmaStockDetails);
                setExistingReconciliationRecord(reconciliationRecord);
                setLoading(false);
            },
        );
    }, [params]);

    const fetchDmaStockDetails = async () => {
        const query = graphqlOperation(getDmastockById, { dmastockId: stockId });
        const { data } = (await API.graphql(query)) as GraphQLResult<GetDmastockByIdQuery>;
        return data?.getDmastockById;
    };

    const fetchDmaStockReconciliationRecord = async () => {
        const query = graphqlOperation(fetchDmaStockReconciliationRecordById, { dmaStockId: stockId });
        const { data } = (await API.graphql(query)) as GraphQLResult<FetchDmaStockReconciliationRecordByIdQuery>;
        return data?.fetchDmaStockReconciliationRecordById;
    };

    const submitDmaStockReconciliation = async (values: any, { setSubmitting, resetForm }: FormikHelpers<any>) => {
        if (!formik.isValid) {
            return toast.error('Enter all required fields');
        }
        const record = {
            dmaStockId: stockId,
            dmaId: dmaStockDetails?.dmaId,
            dmaBranchId: dmaStockDetails?.branchId,
            creditedCount: values.creditedCount,
            verifiedCount: values.verifiedCount,
            collectedCount: values.collectedCount,
            purchasedCount: values.purchasedCount,
        };

        let resp;
        if (existingReconciliationRecord) {
            const query = graphqlOperation(updateDmaStockReconciliationRecord, { record });
            const { data } = (await API.graphql(query)) as GraphQLResult<UpdateDmaStockReconciliationRecordMutation>;
            resp = JSON.parse(data?.updateDmaStockReconciliationRecord || '{}');
        } else {
            const query = graphqlOperation(createDmaStockReconciliationRecord, { record });
            const { data } = (await API.graphql(query)) as GraphQLResult<CreateDmaStockReconciliationRecordMutation>;
            resp = JSON.parse(data?.createDmaStockReconciliationRecord || '{}');
        }

        if (resp.code === 200 || resp.code === 201) {
            toast.success('Reconciliation record successfully created');
            resetForm();
            return history.push(`/dma/${dmaStockDetails?.dmaId}/stock`);
        }

        if (resp.message.includes('Collected count + purchased count')) {
            toast.error('Collected count + purchased count cannot be greater than stock item quantity');
        } else {
            toast.error('Error creating reconciliation record');
        }
        fetchDmaStockReconciliationRecord().then((reconciliationRecord) => {
            setExistingReconciliationRecord(reconciliationRecord);
            setSubmitting(false);
        });
    };

    const formik = useFormik<any>({
        initialValues,
        onSubmit: submitDmaStockReconciliation,
        validationSchema: createDmaStockReconciliationRecordSchema,
    });

    useEffect(() => {
        formik.setValues(initialValues);
    }, [initialValues]);

    return (
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <BlockUi blocking={formik.isSubmitting || loading}>
                            <ToastContainer />
                            <div>
                                <Breadcrumb separator=">">
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/dma">DigiFarm Master Agents</Breadcrumb.Item>
                                    <Breadcrumb.Item href={`/dma/profile/${dmaStockDetails?.dmaId}`}>
                                        View Details
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item href={`/dma/${dmaStockDetails?.dmaId}/stock`}>
                                        View Stock
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>Stock Reconciliation</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>

                            <div>
                                <h6 className="products-heading">Stock Reconciliation Record</h6>
                                <Card className="products-cardbody shadow-lg p-3 mb-5">
                                    <Card.Body>
                                        <Form onSubmit={(e) => formik.handleSubmit(e)}>
                                            <Form.Group controlId="formBasicName">
                                                <Row>
                                                    <div className="col-md-6 mb-2">
                                                        <Form.Label className="new-product-form-label">Id</Form.Label>
                                                        <Form.Control
                                                            placeholder="Id"
                                                            isInvalid={Boolean(
                                                                formik.touched.dmaStockId && formik.errors.dmaStockId,
                                                            )}
                                                            {...formik.getFieldProps('dmaStockId')}
                                                            value={formik.values.dmaStockId}
                                                            disabled
                                                        />
                                                        {(formik.touched.dmaStockId && formik.errors.dmaStockId) ?? (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.dmaStockId}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </div>

                                                    <div className="col-md-6 mb-2">
                                                        <Form.Label className="new-product-form-label">
                                                            Product Name
                                                        </Form.Label>
                                                        <Form.Control
                                                            placeholder="Product Name"
                                                            isInvalid={Boolean(
                                                                formik.touched.productName && formik.errors.productName,
                                                            )}
                                                            {...formik.getFieldProps('productName')}
                                                            value={formik.values.productName}
                                                            disabled
                                                        />
                                                        {(formik.touched.productName && formik.errors.productName) ?? (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.productName}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </div>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group className="new-product-form-group" controlId="formBasicInputs">
                                                <Row>
                                                    <div className="col-md-3">
                                                        <Form.Label className="new-product-form-label">
                                                            Credited Count
                                                        </Form.Label>
                                                        <Form.Control
                                                            placeholder="Enter credited count"
                                                            isInvalid={Boolean(
                                                                formik.touched.creditedCount &&
                                                                    formik.errors.creditedCount,
                                                            )}
                                                            {...formik.getFieldProps('creditedCount')}
                                                            value={formik.values.creditedCount}
                                                            type="number"
                                                        />
                                                        {(formik.touched.emailAddress &&
                                                            formik.errors.creditedCount) ?? (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.creditedCount}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </div>

                                                    <div className="col-md-3">
                                                        <Form.Label className="new-product-form-label">
                                                            Verified Count
                                                        </Form.Label>
                                                        <Form.Control
                                                            placeholder="Enter verified Count"
                                                            isInvalid={Boolean(
                                                                formik.touched.verifiedCount &&
                                                                    formik.errors.verifiedCount,
                                                            )}
                                                            {...formik.getFieldProps('verifiedCount')}
                                                            value={formik.values.verifiedCount}
                                                            type="number"
                                                        />
                                                        {(formik.touched.verifiedCount &&
                                                            formik.errors.verifiedCount) ?? (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.verifiedCount}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </div>

                                                    <div className="col-md-3">
                                                        <Form.Label className="new-product-form-label">
                                                            Collected Count
                                                        </Form.Label>
                                                        <Form.Control
                                                            placeholder="Enter collected count"
                                                            isInvalid={Boolean(
                                                                formik.touched.collectedCount &&
                                                                    formik.errors.collectedCount,
                                                            )}
                                                            {...formik.getFieldProps('collectedCount')}
                                                            value={formik.values.collectedCount}
                                                            type="number"
                                                        />
                                                        {(formik.touched.collectedCount &&
                                                            formik.errors.collectedCount) ?? (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.collectedCount}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </div>

                                                    <div className="col-md-3">
                                                        <Form.Label className="new-product-form-label">
                                                            Purchased Count
                                                        </Form.Label>
                                                        <Form.Control
                                                            placeholder="Enter purchased count"
                                                            isInvalid={Boolean(
                                                                formik.touched.purchasedCount &&
                                                                    formik.errors.purchasedCount,
                                                            )}
                                                            {...formik.getFieldProps('purchasedCount')}
                                                            value={formik.values.purchasedCount}
                                                            type="number"
                                                        />
                                                        {(formik.touched.purchasedCount &&
                                                            formik.errors.purchasedCount) ?? (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.purchasedCount}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </div>
                                                </Row>
                                            </Form.Group>
                                            <div className="new-product-action-buttons">
                                                <Button type="submit" className="new-product-action-buttons-create">
                                                    Submit
                                                </Button>
                                                <Button
                                                    onClick={() => history.push(`/dma/${dmaStockDetails?.dmaId}/stock`)}
                                                    className="new-product-action-buttons-cancel"
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(null)(CreateDmaStockReconciliation);
