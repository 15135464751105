import { GraphQLResult } from '@aws-amplify/api';
import { message as toast } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { API, graphqlOperation } from 'aws-amplify';
import { Storage } from 'aws-amplify';
import { useCallback, useState } from 'react';
import { useEffect } from 'react';

import {
    CreateProductMutation,
    DeleteProductByIdMutation,
    GetProductByIdQuery,
    NestedProducts,
    Product,
    ProductInput,
    SearchProductsAdminQuery,
    SearchProductsAdminQueryVariables,
} from '../../API';
import {
    createProduct as createProductQuery,
    deleteProductById,
    updateProduct as updateProductQuery,
} from '../../graphql/mutations';
import { getProductById, searchProductsAdmin } from '../../graphql/queries';

type AntUploadChange = UploadChangeParam<UploadFile<any>>;

const createProduct = async (
    product: ProductInput,
    onSuccess?: (data?: string | null, message?: string) => void,
    onError?: (message: string) => void,
) => {
    try {
        const q = graphqlOperation(createProductQuery, { product });
        const { data } = (await API.graphql(q)) as GraphQLResult<CreateProductMutation>;
        const createdProduct = data?.createProduct;

        if (createdProduct) {
            onSuccess && onSuccess(createdProduct.data, 'Create Product Successfully');
        } else {
            onError && onError('An error occurs while creating product');
        }
    } catch (e: any) {
        onError && onError(e?.message);
    }
};

const getProduct = async (productId: string) => {
    try {
        const q = graphqlOperation(getProductById, { productId });
        const { data } = (await API.graphql(q)) as GraphQLResult<GetProductByIdQuery>;

        if (data?.getProductById) {
            return {
                product: data?.getProductById,
                message: 'Success',
            };
        } else {
            throw new Error('Product does not exist');
        }
    } catch (e: any) {
        console.log(e);
        return {
            product: null,
            message: e,
        };
    }
};

const searchProducts = async (params: SearchProductsAdminQueryVariables | null = { limit: 12, page: 1, q: '' }) => {
    try {
        const q = await graphqlOperation(searchProductsAdmin, params as SearchProductsAdminQuery);
        const { data } = (await API.graphql(q)) as any;
        return { result: data?.searchProductsAdmin, message: 'search completed' };
    } catch (e: any) {
        return {
            result: null,
            message: 'Could not complete search',
        };
    }
};

const updateProduct = async (productId: string | undefined, update: any) => {
    try {
        'creationDate' in update && delete update.creationDate;
        'productId' in update && delete update.productId;
        'category' in update && delete update.category;
        'subCategory' in update && delete update.subCategory;
        'modificationDate' in update && delete update.modificationDate;
        'company_name' in update && delete update.company_name;
        'subCategoryName' in update && delete update.subCategoryName;

        const q = await graphqlOperation(updateProductQuery, { productId, produces: update });
        const { data } = (await API.graphql(q)) as any;
        return { result: data?.updateProduct, message: 'update completed' };
    } catch (e: any) {
        return {
            result: null,
            message: 'Could not update',
        };
    }
};

const deleteProduct = async (productId?: string) => {
    try {
        const q = graphqlOperation(deleteProductById, { productId });

        (await API.graphql(q)) as GraphQLResult<DeleteProductByIdMutation>;

        return {
            message: 'Successfully deleted',
        };
    } catch (e: any) {
        return {
            message: e.message,
        };
    }
};

export const useProducts = () => {
    const [product, setProduct] = useState<Product | null | undefined>();
    const [message, setMessage] = useState('');
    const [inputTypes, setInputTypes] = useState<Set<string>>(new Set());
    const [images, setImages] = useState<any[]>([]);
    const [isUploadingImages, setIsUploadingImages] = useState(false);
    const [paginatedProducts, setPaginatedProducts] = useState<{
        page?: number | null;
        total?: number | null;
        products?: Array<NestedProducts | null> | null;
    }>();

    const getSingleProduct = useCallback(
        async (productId: string) => {
            const result = await getProduct(productId);

            setProduct(() => result.product);
            setMessage(() => result.message);
        },
        [setProduct, setMessage],
    );

    const getProductDetails = async (productId: string) => {
        const result = await getProduct(productId);
        return result;
    };

    useEffect(() => {
        if (message) {
            setTimeout(() => {
                setMessage('');
            }, 1000);
        }
    }, [message]);

    const fetchPaginatedProducts = useCallback(async (params?: SearchProductsAdminQueryVariables | null) => {
        console.log('Search params', params);
        const { result, message: msg } = await searchProducts(params);
        const retrievedInputTypes = Array.from(new Set<string>(result?.result.map((p: any) => p.type)));
        console.log('Amanhui', inputTypes);

        const aggregatedInputTypes = [...Array.from(inputTypes), retrievedInputTypes];
        setInputTypes(new Set<string>(...aggregatedInputTypes));

        setPaginatedProducts(() => ({
            products: result?.result,
            page: result?.page,
            total: result?.totalCount,
        }));
        setMessage(msg);
    }, []);

    const removeProduct = useCallback(async (productId?: string) => {
        const { message } = await deleteProduct(productId);
        setMessage(message);
        setPaginatedProducts((prev) => {
            return {
                ...prev,
                products: prev?.products && prev.products.filter((p) => p?.productId !== productId),
            };
        });
    }, []);

    const handleImageChange = useCallback((info: AntUploadChange) => {
        const { status } = info.file;

        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }

        if (status === 'done') {
            toast.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            toast.error(`${info.file.name} file upload failed.`);
        }
    }, []);

    const getPublicStorageUrl = (key: string) => {
        return `https://${process.env.REACT_APP_PRODUCT_IMAGE_STORAGE}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/public/${key}`;
    };

    const uploadImage = useCallback(async (files: File[]) => {
        setIsUploadingImages(true);
        try {
            const fileKeys = files.map((file) => `products/${file.name}`);
            await Promise.all(
                files.map((file) => {
                    return Storage.put(`products/${file.name}`, file, {
                        contentType: 'image/*',
                        // authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '{}').accessToken}`,
                    });
                }),
            );

            const images: Array<Object | string> = [];

            for (let i = 0; i < fileKeys.length; i++) {
                const url = getPublicStorageUrl(fileKeys[i]);
                images.push(url);
            }

            setImages(images);
            setIsUploadingImages(false);
        } catch (e: any) {
            console.log({ e });
            throw e;
        }
    }, []);

    return {
        uploadImage,
        createProduct,
        updateProduct,
        handleImageChange,
        getSingleProduct,
        product,
        message,
        inputTypes,
        fetchPaginatedProducts,
        paginatedProducts,
        removeProduct,
        isUploadingImages,
        uploads: images,
        getProductDetails,
    };
};
