import '../roles.css';

import { GraphQLResult } from '@aws-amplify/api';
import { Breadcrumb } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { UpdateRoleMutation } from '../../../API';
import { updateDMARole } from '../../../graphql/mutations';
import { useDmaRoles } from './useDmaRoles';

function EditDMARoles() {
    const [selectedRole, setSelectedRole] = useState('');
    const [permissionsData, setPermissionsData] = useState<any[] | null | undefined>([]);

    const [loading, setLoading] = useState(true);
    const [, setRolePermissionsId] = useState<String[]>([]);
    const [trackDefaultPermissions, setTrackDefaultPermissions] = useState<{ id: string; checked: boolean }[] | []>([]);

    const { getAllRoles, rolesData, getAllPermissions } = useDmaRoles();
    const history = useHistory();

    const updateRolePermissions = async () => {
        const activePermissions = trackDefaultPermissions.filter((p) => p.checked === true);

        if (_.isEmpty(activePermissions)) {
            return toast.error('Please select permissions to proceed');
        } else {
            try {
                setLoading(true);
                const getPermissionsId = trackDefaultPermissions.map((p: any) => p.id);
                const mutation = graphqlOperation(updateDMARole, {
                    dmaRolesId: selectedRole,
                    role: {
                        permissions: getPermissionsId,
                    },
                });
                const data = (await API.graphql(mutation)) as GraphQLResult<UpdateRoleMutation>;
                if (data) {
                    await getData();
                    toast.success('Roles permissions updated successfully!');
                }

                setLoading(false);
            } catch (error) {
                console.log({ error });
                setLoading(false);
            }
        }
    };

    const handlePermissionChange = async (permissionId: string) => {
        const permissionState = trackDefaultPermissions.find((p: any) => p.id === permissionId);
        if (!permissionState) {
            setTrackDefaultPermissions([...trackDefaultPermissions, { id: permissionId, checked: true }]);
        } else {
            const stateDefaultPermissions = [...trackDefaultPermissions];
            const index = _.findIndex(stateDefaultPermissions, { id: permissionId, checked: permissionState?.checked });
            stateDefaultPermissions.splice(index, 1, { id: permissionId, checked: !permissionState?.checked });
            setTrackDefaultPermissions([...stateDefaultPermissions]);
        }
    };

    const getData = async () => {
        const roles = await getAllRoles();
        if (roles && roles[0]) {
            setSelectedRole(sessionStorage.getItem('createdRole') || selectedRole || (roles[0].dmaRolesId as string));
        }
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        const setSelected = async () => {
            setLoading(true);
            const selectedRoleObject = rolesData.find((p: any) => p.dmaRolesId === selectedRole);
            if (!_.isEmpty(selectedRoleObject)) {
                const permissions = await getAllPermissions();

                setPermissionsData(permissions);

                const selectedRolePermission = selectedRoleObject.permissions;
                const getRolePermissionsId = selectedRolePermission?.map((p: any) => p.permissionId);
                const rolePermissionsTrack = selectedRolePermission?.map((p: any) => ({
                    id: p.permissionId,
                    checked: true,
                }));
                setTrackDefaultPermissions(rolePermissionsTrack || []);
                setRolePermissionsId(getRolePermissionsId || []);
                setLoading(false);
            }
        };
        setSelected();
    }, [selectedRole]);

    return (
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <BlockUi blocking={loading}>
                            <ToastContainer />
                            <div>
                                <Breadcrumb separator=">">
                                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item>Edit Roles</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>

                            <div>
                                <h6 className="roles-heading">Edit DMA Roles</h6>
                                <Card className="roles-cardbody">
                                    <Card.Body>
                                        {/* <h6>CHECKLIST ITEMS</h6> */}
                                        <Form>
                                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                                <Form.Label column sm="12">
                                                    <h6> Role Type </h6>
                                                </Form.Label>
                                                <Col sm="10">
                                                    <Form.Control
                                                        onChange={(e) => setSelectedRole(e.target.value)}
                                                        as="select"
                                                        className="roles-select"
                                                    >
                                                        {rolesData.map((role: any) => {
                                                            return (
                                                                <option
                                                                    selected={
                                                                        sessionStorage.getItem('createdRole') ===
                                                                        role.dmaRolesId
                                                                    }
                                                                    value={role.dmaRolesId}
                                                                    key={role.dmaRolesId}
                                                                >
                                                                    {role.rolename}
                                                                </option>
                                                            );
                                                        })}
                                                    </Form.Control>
                                                </Col>
                                                <Form.Group
                                                    className="roles-permission-checkbox-group"
                                                    controlId="formBasicCheckbox"
                                                >
                                                    <Form.Label column sm="12" className="black-color">
                                                        <h6>Permissions </h6>
                                                    </Form.Label>
                                                    {permissionsData?.map((permission: any) => {
                                                        return (
                                                            <Form.Check
                                                                key={permission.permissionId}
                                                                checked={
                                                                    trackDefaultPermissions.find(
                                                                        (p) => p.id === permission.permissionId,
                                                                    )?.checked as boolean
                                                                }
                                                                onChange={() =>
                                                                    handlePermissionChange(permission.permissionId)
                                                                }
                                                                type="checkbox"
                                                                label={`${permission.alias} (${permission.description})`}
                                                            />
                                                        );
                                                    })}
                                                </Form.Group>
                                            </Form.Group>
                                            <Col className="mt-5 d-flex flex-row-reverse">
                                                <Button
                                                    onClick={updateRolePermissions}
                                                    className="primary-bg-color n-btn-style  white-color fw-500 m-3 px-5 btn-width"
                                                    disabled={loading}
                                                >
                                                    {loading ? 'Loading...' : 'Save'}
                                                </Button>
                                                <Button
                                                    onClick={() => history.push('/dma-roles')}
                                                    className="cancel-btn fw-500 m-3 btn-width muted-bg-color white-color px-5"
                                                >
                                                    Cancel
                                                </Button>
                                            </Col>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditDMARoles;
