import 'antd/dist/antd.css';

import Amplify, { Auth } from 'aws-amplify';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import config from './aws';
import PrivateRoute from './layouts/PrivateRoute';
import AdminRoutes from './pages/AdminRoutes';
import AuthRoute from './pages/AuthRoute';
import { getCurrentSession } from './redux/actions/authActions';

const getIdToken = async () => {
    try {
        return {
            Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
        };
    } catch (e) {
        console.debug(e);
    }
};
Amplify.configure({ ...config, graphql_headers: getIdToken });

const App: React.FC = () => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(getCurrentSession());
    }, []);

    return (
        <Switch>
            <Route path="/auth/login" component={AuthRoute} />
            <PrivateRoute path="" component={AdminRoutes} />
            <Redirect to="/" />
        </Switch>
    );
};

export default App;
