import { GraphQLResult } from '@aws-amplify/api';
import { message } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import { useState } from 'react';
import { useHistory } from 'react-router';

import { CreateAdminMutation, GetRolesQuery } from '../../../API';
import { createAdmin } from '../../../graphql/mutations';
import { getRoles } from '../../../graphql/queries';
import { formatNumber } from '../../../utils';

export const useCreateUser = () => {
    //hooks
    const history = useHistory();

    //states
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [role, setRole] = useState('Select a role');
    const [roles, setRoles] = useState<GetRolesQuery['getRoles']>([]);
    const [isCreating, setCreating] = useState(false);
    // const [selectedRole, setSelectedRoles] = useState('');

    //function to handle select role.
    const handleSelectRole = (e: string) => {
        setRole(e);
    };

    const validateEmail = (email: string) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true;
        } else {
            return false;
        }
    };

    //function to get user roles

    const getUserRoles = async () => {
        try {
            const query = graphqlOperation(getRoles);
            const { data } = (await API.graphql(query)) as GraphQLResult<GetRolesQuery>;
            setRoles(data?.getRoles);
        } catch (error) {
            console.log({ error });
        }
    };

    //function to create an admin

    const createUser = async () => {
        if (!validateEmail(email)) {
            return message.error('Email format is not correct');
        } else if (name.length < 1) {
            return message.error('The name field cannot be empty');
        } else if (phoneNumber.length < 1) {
            return message.error('The phone number field cannot be empty');
        } else if (role.length < 1 || role === 'Select a role') {
            return message.error('The role field cannot be empty');
        }

        setCreating(true);
        const params = {
            email: email.trim().toLowerCase(),
            group: role,
            name,
            phoneNumber: formatNumber(phoneNumber),
        };
        try {
            const mutation = graphqlOperation(createAdmin, params);
            const { data } = (await API.graphql(mutation)) as GraphQLResult<CreateAdminMutation>;
            if (!data?.createAdmin?.User) {
                message.error(data?.createAdmin?.message);
            } else {
                message.success(data?.createAdmin?.message);
                history.push('/users');
            }
            setCreating(false);
        } catch (error) {
            console.log({ error });
            setCreating(false);
        }
    };

    return {
        setName,
        name,
        email,
        setEmail,
        handleSelectRole,
        role,
        getUserRoles,
        roles,
        phoneNumber,
        setPhoneNumber,
        createUser,
        isCreating,
    };
};
