import { Col, Row } from 'antd';
import React from 'react';

interface TitleProps {
    empty: string;
    name: string;
    message: string;
}

export const EmptyUserData: React.FC<TitleProps> = ({ empty, name, message }) => {
    return (
        <Row className="bg-white p-3 mb-2 border">
            <Col span={18} style={{ fontSize: '1.5rem' }}>
                {name} {message}
            </Col>
            <Col span={6}>
                <img alt="empty-data" className="fullwidth" src={empty} />
            </Col>
        </Row>
    );
};
