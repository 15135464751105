export const subtractMonths = (numOfMonths: number, date: Date) => {
    const dateCopy = new Date(date.getTime());
    dateCopy.setMonth(dateCopy.getMonth() - numOfMonths);
    return dateCopy;
};

export const addMonths = (numOfMonths: number, date: Date) => {
    const dateCopy = new Date(date.getTime());
    dateCopy.setMonth(dateCopy.getMonth() + numOfMonths);
    return dateCopy;
};
