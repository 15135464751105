import { GraphQLResult } from '@aws-amplify/api-graphql';
import { Card, Col, Row } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

import { DMAResponse } from '../../../API';
import { adminDmaMonthlyStatistics } from '../../../graphql/queries';
import { Status } from '../../../models';
import { EmptyUserData } from './EmptyUserData';

interface SalesProps {
    title: string;
    userId: string;
    empty: string;
    status: string | null | undefined;
    name: string | undefined;
    user: DMAResponse['data'] | null | undefined;
}

export const SalesGraph: React.FC<SalesProps> = ({ status, user, title, name, empty, userId }) => {
    console.log({ status });

    // const pending = (status === Status.Pending && user?.kyc_flow_state) || !status;
    const accepted = status === Status.Approved && user?.kyc_flow_state;
    // const rejected = status === Status.Rejected && user?.kyc_flow_state;
    const [graphData, setGraphData] = useState<number[] | null>(null);

    useEffect(() => {
        const getData = async () => {
            try {
                const q = await graphqlOperation(adminDmaMonthlyStatistics, { dmaId: userId });
                const { data } = (await API.graphql(q)) as GraphQLResult<any>;
                const salesData = data?.adminDmaMonthlyStatistics?.monthlySales?.map(
                    (p: any) => p?.monthSales,
                ) as number[];
                setGraphData(salesData);
            } catch (e: any) {
                return {
                    result: null,
                    message: 'Could not complete search',
                };
            }
        };

        getData();
    }, []);

    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Sales',
                data: graphData ?? [],
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div className="fullwidth mb-4">
            <Row>
                <Col span={24}>
                    <div className="primary-color mb-2 fw-500 fs-18">{title}</div>
                    <Card className="dma-sales-card overflow-scroll">
                        {!accepted ? (
                            <EmptyUserData name={name} empty={empty} />
                        ) : (
                            <Line data={data} options={options} />
                        )}
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
