import './categories.css';

// import { CheckOutlined } from '@ant-design/icons';
// import { Dropdown, Menu } from 'antd';
import { Breadcrumb } from 'antd';
// import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { PageTitle } from '../dma/PageTitle';
import { useCategories } from './useCategories';

function Categories() {
    // const [selectedCategory, setselectedCategory] = useState('');

    const [loading, setLoading] = useState(true);
    // const history = useHistory();

    const { categories, getAllCategories } = useCategories();

    const history = useHistory();

    useEffect(() => {
        (async function () {
            await getAllCategories();
            setLoading(false);
        })();
    }, []);

    return (
        <BlockUi blocking={loading}>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Categories</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <div className="row mt-4 mb-3">
                            <div className="col-md-10">
                                <PageTitle title="Categories" />
                            </div>
                            <div className="col-md-2">
                                <NavLink to={'/categories/create'}>
                                    <div className="border-btn text-center"> Add Category</div>
                                </NavLink>
                            </div>
                        </div>

                        <div>
                            <Card className="categories-cardbody shadow-lg p-3 mb-5">
                                <Card.Body>
                                    <Form>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                            <ul>
                                                {categories.map((category: any) => (
                                                    <li
                                                        style={{ margin: '20px', fontSize: '18px' }}
                                                        key={category.categoryId}
                                                    >
                                                        <p
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() =>
                                                                history.push(
                                                                    `/categories/${category.categoryId}/details`,
                                                                )
                                                            }
                                                        >
                                                            {category.name}
                                                        </p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </Form.Group>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </BlockUi>
    );
}

export default Categories;
