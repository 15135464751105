import React from 'react';
import { Col, Row, Tab, Table, Tabs } from 'react-bootstrap';

// import { FarmerDemandData } from '../../API';
import { formatAmountWithCurrency } from '../../utils';

const OrderInformation = (props: any) => {
    const data = props.data;
    return (
        <div className="m-3">
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Tabs defaultActiveKey="farmer" id="uncontrolled-tab-example">
                                <Tab eventKey="farmer" title="Farmer Details">
                                    <Row>
                                        <Col md="10" sm="12" className="m-4">
                                            <Table striped borderless responsive size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>ID Number</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Phone</th>
                                                        <th>Member Number</th>
                                                        <th>Location</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{data.farmerModel?.farmerId}</td>
                                                        <td>{data.farmerModel?.firstName?.toUpperCase()}</td>
                                                        <td>{data.farmerModel?.lastName?.toUpperCase()}</td>
                                                        <td>{data.farmerMsisdn?.toUpperCase()}</td>
                                                        <td>{data.farmerModel?.memberNumber?.toUpperCase()}</td>
                                                        <td>
                                                            {[data.region, data.county, data.subCounty, data.ward].join(
                                                                ' ',
                                                            )}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="products" title="Products">
                                    <Row>
                                        <Col md="10" sm="12" className="m-4">
                                            <Table striped borderless responsive size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>Size</th>
                                                        <th>Unit Price</th>
                                                        <th>Rebate</th>
                                                        <th>Quantity</th>
                                                        <th>Total Input Cost</th>
                                                        <th>Total Rebate Cost</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.orderInputs?.map((i: any) => (
                                                        <tr key={i?.inputId as number}>
                                                            <td>{i?.inputName?.toUpperCase()}</td>
                                                            <td>{i?.packageQuantity}</td>
                                                            <td>{formatAmountWithCurrency(i?.price)}</td>
                                                            <td>{formatAmountWithCurrency(i?.rebate)}</td>
                                                            <td>{i?.orderedQty}</td>
                                                            <td>{formatAmountWithCurrency(i?.totalInputCost)}</td>
                                                            <td>{formatAmountWithCurrency(i?.totalRebateCost)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="payments" title="Payments">
                                    <Row>
                                        <Col md="8" sm="12" className="m-4">
                                            <dl className="row">
                                                <dt className="col-4">Payment Status</dt>
                                                <dd className="col-8">{data.orderPaymentStatus?.toUpperCase()}</dd>
                                                <dt className="col-4">TOTAL PAYMENT AMOUNT</dt>
                                                <dd className="col-8">
                                                    {formatAmountWithCurrency(data.orderTotalCost)}
                                                </dd>
                                                <dt className="col-4">Transaction ID</dt>
                                                <dd className="col-8">{data.orderPaymentTransactionId ?? 'N/A'}</dd>
                                                <dt className="col-4">Rebate Payment Status</dt>
                                                <dd className="col-8">{data.rebatePaymentStatus?.toUpperCase()}</dd>
                                                <dt className="col-4">Rebate Transaction ID</dt>
                                                <dd className="col-8">{data.rebatePaymentTransactionId ?? 'N/A'}</dd>
                                            </dl>
                                        </Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderInformation;
