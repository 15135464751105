import './roles.css';

import { CheckOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { Breadcrumb } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { NavLink, useHistory } from 'react-router-dom';

import { PageTitle } from '../dma/PageTitle';
import { useRoles } from './useRoles';

function Roles() {
    const [selectedRole, setSelectedRole] = useState('');
    const [permissions, setPermissions] = useState([]);
    const { getAllRoles, rolesData } = useRoles();
    const history = useHistory();

    useEffect(() => {
        const getData = async () => {
            const roles = await getAllRoles();
            if (roles && roles[0]) {
                setSelectedRole(roles[0].rolesId as string);
            }
        };
        getData();
    }, []);

    useEffect(() => {
        const selectedRoleObject = rolesData.find((p) => p.rolesId === selectedRole);
        if (!_.isEmpty(selectedRoleObject)) {
            const selectedRolePermission = selectedRoleObject.permissions;
            console.log(selectedRolePermission);
            setPermissions(selectedRolePermission || []);
        }
    }, [selectedRole]);

    const menu = (
        <Menu>
            <Menu.Item onClick={() => history.push('/roles/edit')} key="1">
                <p onClick={() => history.push('/roles/edit')} className="status-approval">
                    {' '}
                    <CheckOutlined /> Edit
                </p>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Roles and Permissions</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <div className="row mt-4 mb-3">
                            <div className="col-md-11">
                                <PageTitle title="Roles and Permissions" />
                            </div>
                            <div className="col-md-1">
                                <NavLink to={'/roles/create'}>
                                    <div className="border-btn text-center"> Add Role</div>
                                </NavLink>
                            </div>
                        </div>

                        {/*    <PageTitle title="Roles and Permissions">*/}
                        {/*        <div className="d-flex">*/}
                        {/*            /!* <div*/}
                        {/*    id="add-role"*/}
                        {/*    className="border-btn mr-5 d-flex align-items-center justify-content-center uppercase fs-12"*/}
                        {/*    style={{ width: '151px' }}*/}
                        {/*>*/}
                        {/*    <NavLink to={'/roles/create'} className="primary-color">*/}
                        {/*        Add Role*/}
                        {/*    </NavLink>*/}
                        {/*</div> *!/*/}
                        {/*            <NavLink*/}
                        {/*                style={{ width: '151px' }}*/}
                        {/*                className="add-product-btn border-btn mr-5 d-flex align-items-center justify-content-center uppercase fs-12"*/}
                        {/*                to={'/roles/create'}*/}
                        {/*            >*/}
                        {/*                Add Role*/}
                        {/*            </NavLink>*/}
                        {/*        </div>*/}
                        {/*    </PageTitle>*/}

                        <div>
                            <Card className="roles-cardbody shadow-lg p-3 mb-5">
                                <Card.Body>
                                    <h6>CHECKLIST ITEMS</h6>
                                    <Dropdown.Button overlay={menu} />
                                    <Form>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                            <div className="row">
                                                <div className="col-md-1 mt-2">
                                                    <Form.Label>Select Type</Form.Label>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <Form.Control
                                                        onChange={(e) => setSelectedRole(e.target.value)}
                                                        as="select"
                                                        className="w-100 mb-4"
                                                    >
                                                        {rolesData.map((role) => {
                                                            return (
                                                                <option value={role.rolesId} key={role.rolesId}>
                                                                    {role.rolename}
                                                                </option>
                                                            );
                                                        })}
                                                    </Form.Control>
                                                </div>
                                            </div>

                                            <Form.Group
                                                className="roles-permission-checkbox-group"
                                                controlId="formBasicCheckbox"
                                            >
                                                {permissions.map((permission: any) => (
                                                    <Form.Check
                                                        key={permission.permissionId}
                                                        checked
                                                        type="checkbox"
                                                        label={`${permission.alias} (${permission.description})`}
                                                    />
                                                ))}
                                            </Form.Group>
                                        </Form.Group>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Roles;
