import { PayloadAction } from '@reduxjs/toolkit';

import { GET_ALL_MANUFACTURERS, GET_ALL_MANUFACTURERS_ERROR, GET_ALL_MANUFACTURERS_SUCCESS } from '../actionTypes';
import {
    GET_MANUFACTURER,
    GET_MANUFACTURER_ERROR,
    TOGGLE_MANUFACTURER,
    TOGGLE_MANUFACTURER_ERROR,
} from './../actionTypes';
import { ManufacturersReducerState } from './reducer.model';

const initialState: ManufacturersReducerState = {
    isLoading: false,
    manufacturers: [],
};

const manufacturers = (state = initialState, action: PayloadAction<any>): ManufacturersReducerState => {
    switch (action.type) {
        case GET_ALL_MANUFACTURERS:
            return { ...state, isLoading: true };
        case GET_ALL_MANUFACTURERS_SUCCESS:
            return { ...state, isLoading: false, manufacturers: action.payload };
        case GET_ALL_MANUFACTURERS_ERROR:
            return { ...state, error: 'unable to fetch manufacturers ' };
        case TOGGLE_MANUFACTURER:
            return {
                ...state,
                ...action.payload,
            };
        case TOGGLE_MANUFACTURER_ERROR:
            return {
                ...state,
                error: 'An error ocurred while toggling user',
            };
        case GET_MANUFACTURER:
            return {
                ...state,
                manufacturer: action.payload,
            };
        case GET_MANUFACTURER_ERROR:
            return {
                ...state,
                error: 'An error occurred when trying to get manufacturer',
            };
        default:
            return state;
    }
};

export default manufacturers;
