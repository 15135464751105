import './manufacturers.css';

import { DownloadOutlined, EyeOutlined, MoreOutlined, PrinterOutlined } from '@ant-design/icons';
import { Breadcrumb, Popover, Select, Table } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { ActionModal } from '../../components/ActionModal';
import { Search } from '../../components/inputs/Search';
import { PageTitle } from './PageTitle';
import { useManufacturer } from './useManufacturer';

interface Filter {
    value: string;
    name: string;
    key: string;
}

export const csvHeaders = [
    { label: 'Id', key: 'username' },
    { label: 'Company Name', key: 'name' },
    { label: 'Contact Person', key: 'contactPerson' },
    { label: 'Email Address', key: 'emailAddress' },
    { label: 'Phone Number', key: 'phoneNumber' },
    { label: 'Location', key: 'location' },
    { label: 'Enabled', key: 'enabled' },
];

const statusOptions: Filter[] = [
    { value: '', name: 'Select status', key: 'select-status' },
    { value: 'active', name: 'Active', key: 'active' },
    {
        value: 'inactive',
        name: 'Inactive',
        key: 'inactive',
    },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getColumns = (onMoreClicked: (...args: any[]) => void) => [
    {
        title: 'Id',
        dataIndex: 'manufacturerId',
        key: 'manufacturerId',
    },
    {
        title: 'Company name',
        dataIndex: 'companyName',
        key: 'companyName',
    },
    {
        title: 'Contact Person',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Email address',
        dataIndex: 'email',
        key: 'email',
    },

    {
        title: 'Phone Number',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_: unknown, data?: any) => {
            const isActive = data.status && data.status.toLowerCase() === 'active';
            return <div className={isActive ? 'active' : 'inactive'}>{isActive ? 'Active' : 'Inactive'}</div>;
        },
    },
    {
        dataIndex: 'action',
        key: 'action',
        render: (action: any, data: any) => {
            const content = (
                <>
                    <NavLink to={`/manufacturer/profile/${data.manufacturerId}`}>
                        <EyeOutlined /> &nbsp; View More
                    </NavLink>
                </>
            );
            return (
                <Popover placement="leftTop" content={content} trigger="click">
                    <MoreOutlined />
                </Popover>
            );
        },
    },
];
const Manufacturers: React.FC = () => {
    //'I would use this line as soon as backend is up' const [data, setData] = useState();

    const { handleCancel, handleOk, setIsModalVisible, isModalVisible } = useManufacturer();
    const [statusFilter, setStatusFilter] = useState('');
    const [locationFilter, setLocationFilter] = useState('');
    const history = useHistory();
    // const [searchText, setSearchText] = useState('');

    const {
        fetchManufacturers,
        initialData,
        setManufacturersData,
        manufacturersData,
        isLocationsSet,
        setIsLocationsSet,
        locations,
        setLocations,
    } = useManufacturer();

    // const dispatch = useDispatch();
    const [selectedRow, setSelectedRow] = useState<Record<string, any>>();

    const columns = useMemo(() => getColumns(() => setIsModalVisible(true)), [setIsModalVisible]);

    useEffect(() => {
        fetchManufacturers();
    }, []);

    const handleStatusChange = (status: string) => {
        if (!status) {
            return setManufacturersData(initialData);
        }
        setStatusFilter(status);
        if (status === 'active') {
            const statusFilteredItems = initialData.filter((p) => p?.status?.toLowerCase() === 'active') ?? [];
            setManufacturersData(statusFilteredItems);
        } else if (status === 'inactive') {
            const statusFilteredItems = initialData.filter((p) => p?.status?.toLowerCase() !== 'active') ?? [];
            setManufacturersData(statusFilteredItems);
        }
    };

    useEffect(() => {
        if (manufacturersData.length && !isLocationsSet) {
            const manufacturersLocations = manufacturersData.filter((p) => p.location).map((man) => man.location);
            setLocations(Array.from(new Set([...locations, ...manufacturersLocations])));
            setIsLocationsSet(true);
        }
    }, [manufacturersData, isLocationsSet]);

    const handleOnRow = useCallback((row: any) => {
        return {
            onClick: () => setSelectedRow(row),
        };
    }, []);

    const { Option } = Select;

    const clearFilters = () => {
        setStatusFilter('');
        setLocationFilter('');
        setManufacturersData(initialData);
    };

    const handleSearch = (value: string) => {
        if (!value) {
            return setManufacturersData(initialData);
        }
        setStatusFilter('');
        setLocationFilter('');
        const searchedItems = initialData.filter((p) => p?.name?.toLowerCase().includes(value.toLowerCase())) ?? [];
        setManufacturersData(searchedItems);
    };

    const handleLocationChange = (selected: string) => {
        if (!selected) {
            return setManufacturersData(initialData);
        }
        setLocationFilter(selected);
        setStatusFilter('');
        const locationFilteredItems =
            initialData.filter((p) => p?.location?.toLowerCase() === selected.toLowerCase()) ?? [];
        setManufacturersData(locationFilteredItems);
    };

    return (
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="">Manufacturers</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className="row mt-4 mb-3">
                            <PageTitle title="Manufacturers" />
                        </div>

                        <div className="row mb-2">
                            <div className="d-flex justify-content-end">
                                <div className="p-2 bd-highlight">
                                    <div className="border-btn text-center" onClick={() => window.print()}>
                                        Print <PrinterOutlined style={{ color: '#2cb34a' }} />
                                    </div>
                                </div>
                                <div className="p-2 bd-highlight">
                                    {' '}
                                    <CSVLink
                                        data={manufacturersData as any[]}
                                        headers={csvHeaders}
                                        filename="Manufacturers-data"
                                    >
                                        <div className="border-btn text-center">
                                            Export <DownloadOutlined style={{ color: '#2cb34a' }} />
                                        </div>
                                    </CSVLink>
                                </div>
                                <div className="p-2 bd-highlight">
                                    <NavLink to={'/manufacturer/new'}>
                                        <div className="border-btn text-center">Add Manufacturer</div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3 mb-5">
                            <div className="col-md-3 mb-2">
                                <Select className="w-100" value={statusFilter} onChange={handleStatusChange}>
                                    {statusOptions.map((option: Filter) => (
                                        <Option key={option.key} value={option.value}>
                                            {option.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="col-md-3 mb-2">
                                <Select className="w-100" value={locationFilter} onChange={handleLocationChange}>
                                    <Option value={locationFilter} key="default">
                                        Select location
                                    </Option>
                                    {locations.map((location: any) => (
                                        <Option key={location} value={location}>
                                            {location}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="col-md-4 mb-2">
                                <Search iconColor={'#d7d7d7'} placeholder="Search" onChange={handleSearch} />
                            </div>
                            <div className="col-md-2 mb-2">
                                <div className="primary-color fw-500 p-3 pointer" onClick={clearFilters}>
                                    Clear Filter
                                </div>
                            </div>
                        </div>

                        <div style={{ overflowX: 'auto' }}>
                            <Table
                                dataSource={manufacturersData}
                                columns={columns}
                                onRow={handleOnRow}
                                rowKey={(record) => record.username}
                            />
                        </div>
                        {isModalVisible ? (
                            <ActionModal
                                isActivating={!selectedRow?.isActive}
                                handleCancel={handleCancel}
                                handleOk={() =>
                                    handleOk(() => {
                                        // dispatch(toggleManufacturerActiveState(selectedRow?.key, !selectedRow?.isActive));
                                    })
                                }
                                isModalVisible={isModalVisible}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Manufacturers;
