import { DownloadOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import DatePicker from 'react-datepicker';

import { addMonths, subtractMonths } from '../../../utils/dateUtil';

interface CustomerPopoverButtonProps {
    popoverVisible: boolean;
    handlePopoverVisibleChange: (popOverVisible: boolean) => void;
    csvHeaders: { label: string; key: string }[];
    csvData: any;
    handleExportClick: (dateRange: { startDate: Date; endDate: Date }, csvLinkRef: any) => void;
    csvFileName: string;
}

const CustomPopoverExportButton: React.FC<CustomerPopoverButtonProps> = ({
    popoverVisible,
    handlePopoverVisibleChange,
    csvHeaders,
    csvData,
    handleExportClick,
    csvFileName,
}) => {
    const [dateRange, setDateRange] = useState(() => ({
        startDate: subtractMonths(1, new Date()),
        endDate: new Date(),
    }));

    const csvLinkRef = useRef<any>(null);

    const getCsvFileName = (csvFileName: string) =>
        `${csvFileName}  ${dateRange.startDate.toLocaleDateString('en-GB', {
            dateStyle: 'medium',
        })} - ${dateRange.endDate.toLocaleDateString('en-GB', { dateStyle: 'medium' })}`;

    const handleDateRangeSelection = (range: 'start' | 'end', date: Date | null) => {
        if (!date) return;
        if (range === 'start') {
            if (date >= dateRange.endDate) {
                setDateRange({ startDate: date, endDate: date });
            } else if (date < subtractMonths(1, dateRange.endDate)) {
                const newEndDate = addMonths(1, date);
                setDateRange({ startDate: date, endDate: newEndDate });
            } else {
                setDateRange({ startDate: date, endDate: dateRange.endDate });
            }
        } else {
            if (date > addMonths(1, dateRange.startDate)) {
                const newStartDate = subtractMonths(1, date);
                setDateRange({ startDate: newStartDate, endDate: date });
            } else {
                setDateRange({ startDate: dateRange.startDate, endDate: date });
            }
        }
    };

    const dateRangeSelector = (
        startDate: Date,
        endDate: Date,
        handleDateRangeSelection: (range: 'start' | 'end', date: Date | null) => void,
    ) => (
        <div className="d-flex align-items-end">
            <label>
                From
                <DatePicker
                    selected={startDate}
                    onChange={(date) => handleDateRangeSelection('start', date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    className="audit-filter-input"
                />
            </label>
            <label>
                To
                <DatePicker
                    selected={endDate}
                    onChange={(date) => handleDateRangeSelection('end', date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    className="audit-filter-input"
                />
            </label>
            <div className="border-btn text-center" onClick={() => handleExportClick(dateRange, csvLinkRef)}>
                Submit
            </div>
            <CSVLink
                data={csvData}
                headers={csvHeaders}
                filename={getCsvFileName(csvFileName)}
                ref={csvLinkRef}
                className="d-none"
            />
        </div>
    );

    return (
        <Popover
            placement="leftTop"
            content={dateRangeSelector(dateRange.startDate, dateRange.endDate, handleDateRangeSelection)}
            trigger="click"
            visible={popoverVisible}
            onVisibleChange={handlePopoverVisibleChange}
        >
            <div className="border-btn text-center">
                Export <DownloadOutlined style={{ color: '#2cb34a' }} />
            </div>
        </Popover>
    );
};

export default CustomPopoverExportButton;
