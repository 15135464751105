import { GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { useState } from 'react';

import { GetCategoriesQuery, GetCategoryByIdQuery } from '../../API';
import { getCategories, getCategoryById } from '../../graphql/queries';

export const useCategories = () => {
    const [categories, setCategories] = useState<any[]>([]);
    const [subCategories, setSubCategories] = useState<any[]>([]);

    const getAllCategories = async () => {
        try {
            const query = graphqlOperation(getCategories);
            const { data } = (await API.graphql(query)) as GraphQLResult<GetCategoriesQuery>;

            const results = data?.getCategories;

            if (results) {
                setCategories(results);
            }
            return results;
        } catch (e: any) {
            console.log(e);
        }
    };

    const getSubCategoriesList = async (categoryId: string) => {
        try {
            const query = graphqlOperation(getCategoryById, {
                categoryId,
            });
            const { data } = (await API.graphql(query)) as GraphQLResult<GetCategoryByIdQuery>;

            const results = data?.getCategoryById;

            if (results) {
                setSubCategories(results.subCategory || []);
            }
            return results;
        } catch (e: any) {
            console.log(e);
        }
    };

    return {
        categories,
        getAllCategories,
        getSubCategoriesList,
        subCategories,
    };
};
