import './input.css';

import React, { ChangeEvent } from 'react';

interface CustomInputProps extends Partial<HTMLTextAreaElement> {
    onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const FormTextarea: React.FC<CustomInputProps> = ({ className, placeholder, value, onChange }) => {
    return (
        <textarea
            className={`custom-input ${className || ''}`}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange && onChange(e)}
            name="name"
            rows={4}
        />
    );
};

export default FormTextarea;
