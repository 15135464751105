import '../../../components/inputs/input.css';
import '../auth.css';
import '../../../components/button/button.css';

import React, { useEffect, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { Input } from '../../../components/inputs/Input';
import { AuthHeading } from '../components/AuthHeading';

export interface ChangePasswordPayload {
    currentPassword: string;
    confirmPassword: string;
    newPassword: string;
    givenName: string;
}

interface ChangePasswordFormProps {
    onChangePassword: (payload: ChangePasswordPayload) => void;
    close: () => void;
    show?: boolean;
    setLoading: (loading: boolean) => void;
    shouldCheckCurrentPassword?: boolean;
}
export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
    onChangePassword,
    show,
    close,
    setLoading,
}) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [givenName, setGivenName] = useState('');

    const [doesPasswordsMatch, setDoesPasswordsMatch] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChangePassword = (e: React.FormEvent) => {
        e.preventDefault();
        setDoesPasswordsMatch(confirmPassword === newPassword);

        if (doesPasswordsMatch) {
            setIsSubmitting(true);
            onChangePassword({ currentPassword, newPassword, confirmPassword, givenName });
            setLoading(true);
            close();
        }
    };

    useEffect(() => {
        setDoesPasswordsMatch(true);
    }, [newPassword, confirmPassword]);

    return (
        <Modal show={show} onHide={close}>
            <Modal.Body>
                <div className="mt-3 mb-5 px-4">
                    <AuthHeading title="Change Password" subTitle="" />
                    <form onSubmit={handleChangePassword}>
                        {!doesPasswordsMatch && <Alert variant="warning">{`Passwords didn't match`}</Alert>}
                        <div className="form-group mt-5 mb-5">
                            <label className="auth-label mb-2">CURRENT PASSWORD</label>
                            <Input
                                placeholder="XXXXXXXX"
                                value={currentPassword}
                                type="password"
                                required={true}
                                onChange={setCurrentPassword}
                                name="currentPassword"
                                disabled={false}
                            />
                        </div>
                        <div className="form-group mt-4 mb-4">
                            <label className="auth-label mb-2">NEW PASSWORD</label>
                            <Input
                                placeholder="XXXXXXX"
                                value={newPassword}
                                type="password"
                                required={true}
                                onChange={setNewPassword}
                                name="newPassword"
                                disabled={false}
                            />
                        </div>
                        <div className="form-group mt-4 mb-4">
                            <label className="auth-label mb-2">CONFIRM NEW PASSWORD</label>
                            <Input
                                placeholder="XXXXXXX"
                                value={confirmPassword}
                                type="password"
                                required={true}
                                onChange={setConfirmPassword}
                                name="confirmPassword"
                                disabled={false}
                            />
                        </div>
                        <div className="form-group mt-4 mb-4">
                            <label className="auth-label mb-2">GIVEN NAME (USERNAME)</label>
                            <Input
                                placeholder="John Doe"
                                value={givenName}
                                type="text"
                                required={true}
                                onChange={setGivenName}
                                name="givenName"
                                disabled={false}
                            />
                        </div>
                        <button disabled={isSubmitting} className="primary-btn col-md-12 fw-500" type="submit">
                            CHANGE PASSWORD
                        </button>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};
