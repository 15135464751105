import './stock.css';

import { GraphQLResult } from '@aws-amplify/api-graphql';
import { Breadcrumb } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useState } from 'react';
import BlockUi from 'react-block-ui';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AddDmastockMutation } from '../../API';
import ProductField from '../../components/Products/ProductField';
import { addDmastock } from '../../graphql/mutations';
import { useDma } from './useDma';

export const unitTypes = [
    {
        name: 'kg',
        value: 'kg',
    },
    {
        name: 'g',
        value: 'g',
    },
    {
        name: 'ltr',
        value: 'ltr',
    },
    {
        name: 'mls',
        value: 'mls',
    },
    {
        name: 'm',
        value: 'm',
    },
];

const AddNewDmaStock: React.FC = () => {
    const { params } = useRouteMatch();
    const { id } = params as any;
    const { getDMABranches } = useDma();
    const [stockDetails, setStockDetails] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [unitType, setUnitType] = useState('');

    const history = useHistory();

    const addNewStock = async () => {
        try {
            if (
                !stockDetails ||
                !stockDetails.name ||
                !unitType ||
                !stockDetails.selling_price ||
                !stockDetails.quantity ||
                !stockDetails.unit
            ) {
                return toast.error('Kindly fill all fields to add a product');
            }
            const details = await getDMABranches(id);
            const branchId = details?.find((branch: any) => branch.isHQ === 'true')?.branchId;
            setLoading(true);
            stockDetails.unit = `${stockDetails.unit} ${unitType}`;

            const payload = {
                ...stockDetails,
                dmaId: id,
                branchId: branchId,
                status: 'APPROVED',
            };
            const mutation = graphqlOperation(addDmastock, {
                dmastock: payload,
            });
            const { data } = (await API.graphql(mutation)) as GraphQLResult<AddDmastockMutation>;
            const resp = JSON.parse(data?.addDmastock as string);
            setLoading(false);
            if (resp.code === 200) {
                toast.success('Product Added successfully');
                history.push(`/dma/${id}/stock`);
                return;
            }
            if (resp.code === 401 || resp.status === 'DUPLICATE') {
                toast.error(`Product with name ${stockDetails.name} already exist`);
                return;
            }
            return toast.error('Failed to add product');
        } catch (e: any) {
            console.log(e);
            return toast.error(e);
        } finally {
            setLoading(false);
        }
    };

    const cancelAddStock = () => {
        history.push(`/dma/${id}/stock`);
    };

    return (
        <div className="content-wrapper p-4">
            <Breadcrumb separator=">">
                <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => history.push('/dma')}>Digital Master Agents</Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => history.goBack()}>View Details</Breadcrumb.Item>
                <Breadcrumb.Item href="">Add Product</Breadcrumb.Item>
            </Breadcrumb>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <BlockUi blocking={loading}>
                            <div>
                                <h6 className="products-heading">Add Product</h6>
                                <Card className="products-cardbody">
                                    <Card.Body>
                                        <Form className="p-3">
                                            <Row className="d-flex">
                                                <Col md={6} className="">
                                                    <ProductField
                                                        label="PRODUCT NAME"
                                                        value={stockDetails?.name || ''}
                                                        placeholder="Enter Product name"
                                                        required
                                                        name="name"
                                                        onChange={(ev) => {
                                                            setStockDetails({
                                                                ...stockDetails,
                                                                name: ev.target.value,
                                                            });
                                                        }}
                                                    />
                                                </Col>
                                                <Col md={4} className="">
                                                    <ProductField
                                                        name="quantity"
                                                        label="QUANTITY TYPE"
                                                        type="number"
                                                        value={stockDetails?.unit || ''}
                                                        placeholder="Enter Quantity Type"
                                                        onChange={(ev) => {
                                                            setStockDetails({
                                                                ...stockDetails,
                                                                unit: ev.target.value,
                                                            });
                                                        }}
                                                    />
                                                </Col>
                                                <Col md={2} className="">
                                                    <Form.Label className="new-product-form-label">Unit</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        value={unitType}
                                                        onChange={(e) => setUnitType(e.target.value)}
                                                    >
                                                        <option value="" key={99}>
                                                            Select Unit Type
                                                        </option>
                                                        {unitTypes.map(
                                                            (unit: { name: string; value: string }, k: any) => {
                                                                return (
                                                                    <option value={unit?.value} key={k}>
                                                                        {unit?.name}
                                                                    </option>
                                                                );
                                                            },
                                                        )}
                                                    </Form.Control>
                                                </Col>
                                            </Row>
                                            <Row className="d-flex ">
                                                <Col md={6} className="">
                                                    <ProductField
                                                        name="price"
                                                        required
                                                        value={stockDetails?.selling_price || ''}
                                                        placeholder="Enter Price (KSH)"
                                                        label="PRICE (KSH)"
                                                        onChange={(ev) => {
                                                            setStockDetails({
                                                                ...stockDetails,
                                                                selling_price: Number(ev.target.value),
                                                            });
                                                        }}
                                                    />
                                                </Col>
                                                <Col md={6} className="">
                                                    <ProductField
                                                        label="STOCK"
                                                        value={stockDetails?.quantity || ''}
                                                        required
                                                        placeholder="Enter Stock"
                                                        name="quantity"
                                                        onChange={(ev) => {
                                                            setStockDetails({
                                                                ...stockDetails,
                                                                quantity: ev.target.value,
                                                            });
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="d-flex">
                                                <Col md={6} className="">
                                                    <ProductField
                                                        label="REBATES"
                                                        placeholder="Enter Rebates (KSH)"
                                                        value={stockDetails?.rebates || ''}
                                                        required
                                                        name="rebates"
                                                        onChange={(ev) => {
                                                            setStockDetails({
                                                                ...stockDetails,
                                                                rebates: Number(ev.target.value),
                                                            });
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Col className="mt-5 d-flex flex-row-reverse">
                                                <Button
                                                    onClick={addNewStock}
                                                    className="primary-bg-color n-btn-style  white-color fw-500 m-3 px-5 btn-width"
                                                    disabled={loading}
                                                >
                                                    {loading ? 'Loading...' : 'Add'}
                                                </Button>
                                                <Button
                                                    onClick={cancelAddStock}
                                                    className="cancel-btn fw-500 m-3 btn-width muted-bg-color white-color px-5"
                                                >
                                                    Cancel
                                                </Button>
                                            </Col>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddNewDmaStock;
