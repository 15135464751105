import { SearchOutlined } from '@ant-design/icons';
import React from 'react';

interface CustomInputProps extends Partial<HTMLInputElement> {
    onChange?: (arg: string) => void;
    iconColor?: string;
    ref?: any;
}

export const Search: React.FC<CustomInputProps> = ({
    className,
    type,
    placeholder,
    value,
    onChange,
    iconColor,
    ref,
}) => {
    return (
        <div className="d-flex align-items-center input-search fullwidth">
            <SearchOutlined className="icon-padding" style={{ fontSize: '16px', color: iconColor }} />
            <input
                className={`input-search ${className || ''}`}
                placeholder={placeholder}
                value={value}
                type={type || 'text'}
                onChange={(e) => onChange && onChange(e.target.value)}
                name="name"
                ref={ref}
            />
        </div>
    );
};
