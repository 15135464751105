import { EyeOutlined, MoreOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';

export const getColumns = () => [
    {
        title: 'ID',
        dataIndex: 'username',
        key: 'username',
    },
    {
        title: 'Name',
        dataIndex: 'given_name',
        key: 'given_name',
    },
    {
        title: 'Phone',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
    },
    {
        title: 'Email',
        dataIndex: 'emailAddress',
        key: 'emailAddress',
    },
    {
        title: 'Enabled',
        dataIndex: 'isActive',
        key: 'isActive',
        render: (_: unknown, data?: any) => {
            return <div className={data.isActive ? 'active' : 'inactive'}>{data.isActive ? 'Active' : 'Inactive'}</div>;
        },
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        dataIndex: 'action',
        key: 'action',
        render: (action: any, data: any) => {
            const content = (
                <>
                    <NavLink to={`/users/${data.username}`}>
                        <EyeOutlined /> &nbsp; View More
                    </NavLink>
                </>
            );
            return (
                <Popover placement="leftTop" content={content} trigger="click">
                    <MoreOutlined />
                </Popover>
            );
        },
    },
];
