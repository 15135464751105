import { PayloadAction } from '@reduxjs/toolkit';

import { DONE_LOGGING_IN, IS_LOGGING_IN, LOGIN_MANUFACTURER, SET_USER } from '../actionTypes';
import {
    GET_CURRENT_SESSION,
    LOGIN_MANUFACTURER_ERROR,
    LOGIN_MANUFACTURER_SUCCESS,
    LOGOUT,
    RESET_NEW_MANUFACTURER_PASSWORD_SUCCESS,
} from './../actionTypes';
import { AuthReducerState } from './reducer.model';

const initialState: AuthReducerState = { isAuthenticated: false };

const auth = (state = initialState, action: PayloadAction<any>) => {
    let authError: any;
    if (action?.payload?.message) {
        authError = action?.payload?.message;
    }

    switch (action.type) {
        case IS_LOGGING_IN:
            return { ...state, isLoggingIn: true };
        case DONE_LOGGING_IN:
            return { ...state, isLoggingIn: false };
        case SET_USER:
            return {
                ...state,
                user: action.payload,
                isAuthenticated: !!action.payload,
            };
        case LOGIN_MANUFACTURER:
            return { ...state, isLoggingIn: true, isManufacturer: true };
        case LOGIN_MANUFACTURER_SUCCESS: {
            const session = action.payload;
            const isNewUser = session.challengeName && session.challengeName.toLowerCase() === 'new_password_required';

            localStorage.setItem('isAuthenticated', JSON.stringify(!isNewUser));
            return {
                ...state,
                isLoggingIn: false,
                isAuthenticated: !isNewUser,
                isNewUser,
                isManufacturer: true,
                user: session.user,
            };
        }
        case RESET_NEW_MANUFACTURER_PASSWORD_SUCCESS:
            const session = { ...state, challengeName: '' };
            return { ...state, isNewUser: false, session };
        case GET_CURRENT_SESSION:
            return {
                ...state,
                isAuthenticated: !!action.payload,
                session: action.payload,
            };
        case LOGIN_MANUFACTURER_ERROR:
            return {
                ...state,
                isLoggingIn: false,
                isAuthenticated: false,
                isManufacturer: true,
                error: authError,
            };
        case LOGOUT:
            return { ...initialState, isAuthenticated: false };
        default:
            return state;
    }
};

export default auth;
