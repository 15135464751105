import './input.css';

import React, { InputHTMLAttributes } from 'react';

export const FormInput: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({
    className,
    type,
    placeholder,
    value,
    onChange,
    name,
}) => {
    return (
        <input
            className={`custom-input ${className || ''}`}
            placeholder={placeholder}
            value={value}
            type={type || 'text'}
            onChange={(e) => onChange && onChange(e)}
            name={name || 'name'}
        />
    );
};
