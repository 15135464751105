import React from 'react';

type HeadingType = {
    title: string;
    subTitle?: string;
};
export const AuthHeading: React.FC<HeadingType> = (props) => {
    return (
        <div>
            <div className="text-align-center fs-20 fw-500 auth-header mb-2">{props.title}</div>
            <div className="text-align-center fs-16 fw-500 auth-sub-header">{props.subTitle}</div>
        </div>
    );
};
