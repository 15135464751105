import { GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { useState } from 'react';

import { GetPermissionsQuery, GetRolesQuery } from '../../API';
import { getPermissions, getRoles } from '../../graphql/queries';

export const useRoles = () => {
    const [rolesData, setRolesData] = useState<any[]>([]);
    const [permissionsData, setPermissionsData] = useState<any[] | undefined | null>([]);

    const getAllRoles = async (rolesId = '') => {
        try {
            const query = graphqlOperation(getRoles, { rolesId });
            const { data } = (await API.graphql(query)) as GraphQLResult<GetRolesQuery>;

            const roles = data?.getRoles;

            if (roles) {
                setRolesData(roles);
            }
            return roles;
        } catch (e: any) {
            console.log(e);
        }
    };

    const getAllPermissions = async (permissionsId = '') => {
        try {
            const query = graphqlOperation(getPermissions, { permissionsId });
            const { data } = (await API.graphql(query)) as GraphQLResult<GetPermissionsQuery>;

            const permissions = data?.getPermissions;
            console.log({ permissions });

            if (permissions) {
                setPermissionsData(permissions);
            }
            return permissions;
        } catch (e: any) {
            console.log(e);
        }
    };

    return {
        rolesData,
        getAllRoles,
        permissionsData,
        getAllPermissions,
    };
};
