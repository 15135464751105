import './sales.css';

import { DownloadOutlined, EyeOutlined, MoreOutlined, PrinterOutlined } from '@ant-design/icons';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { Breadcrumb, DatePicker, Popover, Select } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import { get, sumBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import DataTable, { TableColumn } from 'react-data-table-component';
import { NavLink } from 'react-router-dom';

import { NestedOrders, SearchOrdersAdminQuery } from '../../API';
import { searchOrdersAdmin } from '../../graphql/queries';
import { capitalizeFirstLetter } from '../products/helpers';
import { PageTitle } from '../products/PageTitle';

const { Option } = Select;
const { RangePicker } = DatePicker;

const csvHeaders = [
    { label: 'ID', key: 'orderId' },
    { label: 'DMA Name', key: 'dmaName' },
    { label: 'Date of Order', key: 'creationDate' },
    { label: 'Total Price', key: 'total' },
    { label: 'Payment Type', key: 'paymentMethod' },
    { label: 'Status', key: 'orderStatus' },
];

const badgeStyle = {
    padding: '5px',
    width: '87px',
    fontSize: '14px',
};

const mappedTableData = (tableData: any[]) => {
    return tableData.map((data) => {
        return {
            orderId: data.orderId,
            dmaName: data.dmaName,
            creationDate: data.creationDate,
            total: sumBy(data.orderItems, 'total'),
            paymentMethod: data.paymentMethod,
            orderStatus: data.orderStatus,
        };
    });
};

const content = (id: any) => (
    <NavLink to={`/manufacturer-sales/${id}`} style={{ backgroundColor: 'white', color: 'black' }}>
        <EyeOutlined /> &nbsp; View More
    </NavLink>
);

const columns: TableColumn<NestedOrders>[] = [
    {
        name: 'ID',
        selector: (i) => i.orderId?.slice(-6).toUpperCase() as any,
    },
    {
        name: 'Manufacturer',
        selector: (i) => i.company_name as any,
    },
    {
        name: 'DMA Name',
        selector: (i) => i.dmaName as any,
    },
    {
        name: 'Hub Name',
        selector: (i) => i?.hub?.hubName || ('N/A' as any),
    },
    {
        name: 'Date of Order',
        selector: (i) => i.creationDate as any,
    },
    {
        name: 'Order Status',
        selector: (i) => i.orderStatus as any,
    },
    {
        name: 'Payment Status',
        selector: (i) => i.paymentStatus as any,
    },
    {
        name: 'Transaction ID',
        selector: (i) => i.mpesaTransactionId as any,
    },
    {
        name: 'Total Price',
        selector: (i) => sumBy(i.orderItems, 'total') as any,
    },
    {
        name: 'Rebates',
        selector: (i) => i.rebates as any,
    },

    {
        name: 'Status',
        cell: (data) => {
            return (
                <div className={`badge ${data.orderStatus}`} style={badgeStyle}>
                    {capitalizeFirstLetter(data.orderStatus)}
                </div>
            );
        },
    },
    {
        name: '',
        width: '5%',
        cell: (data: any) => {
            return (
                <div onClick={() => localStorage.setItem('manufacturerSale', JSON.stringify(data))}>
                    <Popover placement="leftTop" content={content(data.orderId)} trigger="click">
                        <MoreOutlined />
                    </Popover>
                </div>
            );
        },
    },
];

type FilterType = {
    q: string;
    page: number;
    limit: number;
};

const customStyles = {
    headCells: {
        style: {
            backgroundColor: '#2cb34a', // override the cell padding for head cells
            paddingRight: '2px',
            color: 'white',
            fontWeight: 'bolder',
        },
    },
};
const Sales = () => {
    const [tableData, setTableData] = useState<NestedOrders[]>([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [dateFilter, setDateFilter] = useState<any>(null);
    const [fromDate, setFromDate] = useState<any>(null);
    const [toDate, setToDate] = useState<any>(null);
    const [statusFilter, setStatusFilter] = useState('');
    const [dmaFilter, setDmaFilter] = useState('');
    const [hubFilter, setHubFilter] = useState('');
    const [aggregations, setAggregations] = useState<any>([]);
    const [dmas, setDmas] = useState<any>([]);
    const [hubs, setHubs] = useState<any>([]);
    const [filters, setFilters] = useState<FilterType>({
        q: '',
        page: 1,
        limit: 10,
    });

    const deduceDmas = (aggregations: any) => {
        return aggregations?.dma?.buckets.map((dma: any) => {
            const dmaBucket = dma?.dmaName?.buckets[0];
            return { dmaId: dma.key, dmaName: dmaBucket?.key };
        });
    };

    const mapToHubs = (aggregations: any) => {
        return aggregations?.hub?.buckets.map((currentHub: any) => {
            const hubBucket = currentHub?.hubName?.buckets[0];
            return { hubId: currentHub.key, hubName: hubBucket?.key };
        });
    };
    const fetchOrders = async (
        page = filters.page,
        limit = filters.limit,
        q = filters.q,
        orderStatus = '',
        creationDate = '',
        dmaId = '',
        hubId = '',
        fromDate = '',
        toDate = '',
    ) => {
        try {
            setLoading(true);
            const query = graphqlOperation(searchOrdersAdmin, {
                q,
                page,
                limit,
                creationDate,
                orderStatus,
                dmaId,
                hubId,
                gte: fromDate,
                lte: toDate,
            });
            const data = (await API.graphql(query)) as GraphQLResult<SearchOrdersAdminQuery>;
            setTableData(get(data, 'data.searchOrdersAdmin.result'));
            setTotalRows(get(data, 'data.searchOrdersAdmin.totalCount'));
            if (aggregations.length === 0) {
                const aggregations = JSON.parse(data?.data?.searchOrdersAdmin?.aggregations as string);
                setAggregations(aggregations);
                setDmas(deduceDmas(aggregations));
                setHubs(mapToHubs(aggregations));
            }
            setLoading(false);
        } catch (err: any) {
            console.log(err);
        }
    };

    useEffect(() => {
        (async () => {
            await fetchOrders();
        })();
    }, []);

    const disabledDate = (current: any) => {
        return current && current > moment().endOf('day');
    };

    const handlePageChange = (page: number) => {
        fetchOrders(page);
        setCurrentPage(page);
    };

    const clearFilters = () => {
        setFilters({
            q: '',
            page: 1,
            limit: 10,
        });
        setDateFilter('');
        setFromDate('');
        setToDate('');
        setStatusFilter('');
        setHubFilter('');
        setDmaFilter('');
        fetchOrders(1);
    };

    const handleStatusChange = async (value: string) => {
        if (!value) {
            return;
        }
        setStatusFilter(value);
        setLoading(true);
        await fetchOrders(filters.page, filters.limit, '', value, '', dmaFilter, hubFilter, fromDate, toDate);
        setLoading(false);
    };

    const handleDmaChange = async (value: string) => {
        setDmaFilter(value);
        setLoading(true);
        await fetchOrders(filters.page, filters.limit, '', statusFilter, '', value, hubFilter, fromDate, toDate);
        setLoading(false);
    };

    const handleHubChange = async (value: string) => {
        setHubFilter(value);
        setLoading(true);
        await fetchOrders(filters.page, filters.limit, '', statusFilter, '', dmaFilter, value, fromDate, toDate);
        setLoading(false);
    };

    const handleDateChange = async (dates: any, dateStrings: any) => {
        if (!dateStrings) {
            return;
        }
        const [startDate, endDate] = dateStrings;
        setDateFilter(dates);
        setFromDate(startDate);
        setToDate(endDate);
        setLoading(true);
        await fetchOrders(filters.page, filters.limit, '', statusFilter, '', dmaFilter, hubFilter, startDate, endDate);
        setLoading(false);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        fetchOrders(page, newPerPage);
        setPerPage(newPerPage);
    };
    return (
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="">Sales</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className="row mt-4 mb-3">
                            <PageTitle title="Sales" />
                        </div>
                        <div className="row">
                            <div className="d-flex justify-content-end">
                                <div className="p-2 bd-highlight">
                                    <div className="border-btn text-center" onClick={() => window.print()}>
                                        Print <PrinterOutlined style={{ color: '#2cb34a' }} />
                                    </div>
                                </div>
                                <div className="p-2 bd-highlight">
                                    {' '}
                                    <CSVLink
                                        data={mappedTableData(tableData)}
                                        headers={csvHeaders}
                                        filename="Sales-data"
                                    >
                                        <div className="border-btn text-center">
                                            Export <DownloadOutlined style={{ color: '#2cb34a' }} />
                                        </div>
                                    </CSVLink>
                                </div>
                            </div>
                        </div>

                        <div className="row sales-filter">
                            <div className="rg-picker col-md-3 mb-5 mt-3">
                                {/* <DatePicker
                                    style={{ height: '100%', backgroundColor: '#fff' }}
                                    onChange={handleDateChange}
                                    value={dateFilter as Moment}
                                /> */}
                                <RangePicker
                                    onChange={handleDateChange}
                                    size="large"
                                    className="w-100"
                                    allowClear
                                    style={{ height: '100%', backgroundColor: '#fff', borderRadius: '7px' }}
                                    disabledDate={disabledDate}
                                    value={dateFilter}
                                />
                            </div>

                            <div className="col-md-3 mb-5 mt-3">
                                <Select
                                    showSearch
                                    className="w-100 dma-location-select"
                                    value={statusFilter}
                                    onChange={handleStatusChange}
                                    filterOption={(input, option: any) =>
                                        option.props.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    <Option value="status" disabled>
                                        filter by status
                                    </Option>
                                    {aggregations?.orderStatus?.buckets?.map((p: { key: string; value: string }) => {
                                        return (
                                            <Option key={p.key} value={p.key}>
                                                {p.key}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </div>
                            <div className="col-md-3 mb-5 mt-3">
                                <Select
                                    className="w-100 dma-location-select"
                                    value={dmaFilter}
                                    onChange={handleDmaChange}
                                    filterOption={(input, option: any) =>
                                        option.props.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    <Option value="">Select DMA</Option>
                                    {dmas?.map((p: { dmaId: string; dmaName: string }) => {
                                        return (
                                            <Option key={p.dmaId} value={p.dmaId}>
                                                {capitalizeFirstLetter(p.dmaName)}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </div>
                            <div className="col-md-3 mb-5 mt-3">
                                <Select
                                    className="w-100 dma-location-select"
                                    value={hubFilter}
                                    onChange={handleHubChange}
                                    filterOption={(input, option: any) =>
                                        option.props.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    <Option value="">Select Hub</Option>
                                    {hubs?.map((p: { hubId: string; hubName: string }) => {
                                        return (
                                            <Option key={p.hubId} value={p.hubId}>
                                                {capitalizeFirstLetter(p.hubName)}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </div>
                            <div className=" d-flex justify-content-end">
                                <div className="primary-color fw-500 p-3 pointer" onClick={clearFilters}>
                                    Clear All Filters
                                </div>
                            </div>
                        </div>
                        <div style={{ overflowX: 'auto' }}>
                            <DataTable<NestedOrders>
                                className="dma-header products-table"
                                data={tableData}
                                columns={columns}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                paginationDefaultPage={currentPage}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                progressPending={loading}
                                striped
                                customStyles={customStyles}
                            />
                        </div>
                    </div>
                </div>
            </div>{' '}
        </div>
    );
};

export default Sales;
