import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { logout } from '../redux/actions/authActions';

export const LogoutButton = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleLogout = () => {
        dispatch(logout(history));
    };

    return (
        <div className="logout" style={{ width: '80px' }} onClick={() => handleLogout()}>
            Logout
        </div>
    );
};
