import { PayloadAction } from '@reduxjs/toolkit';

import { GET_CATEGORIES, GET_CATEGORIES_ERROR, GET_CATEGORIES_SUCCESS } from '../actionTypes';
import { CategoriesReducerState } from './reducer.model';

const initialState: CategoriesReducerState = {
    isLoading: false,
    categories: [],
};

const categories = (state = initialState, action: PayloadAction<any>): CategoriesReducerState => {
    switch (action.type) {
        case GET_CATEGORIES:
            return { ...state, isLoading: true };
        case GET_CATEGORIES_SUCCESS:
            return { ...state, isLoading: false, categories: action.payload };
        case GET_CATEGORIES_ERROR:
            return { ...state, error: 'unable to fetch categories ' };
        default:
            return state;
    }
};

export default categories;
