import './users.css';

import { Breadcrumb, Button, Select, Table } from 'antd';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
// import { CSVLink } from 'react-csv';
import { NavLink } from 'react-router-dom';

import { getColumns } from './helpers';
import { PageTitle } from './PageTitle';
import { useAdmin } from './useAdmin';

const { Option } = Select;

let cache: any = {};

function Users() {
    const [, setSelectedRow] = useState<Record<string, any>>();
    const { fetchAdmins, adminsData, setAdminsData } = useAdmin();
    const [limit, setLimit] = useState('20');
    const [loading, setLoading] = useState(true);
    const [hasNextPaginationToken, setHasNextPaginationToken] = useState(false);
    const [nextToken, setNextToken] = useState<string | null>(null);
    const [initalToken, setInitialToken] = useState<string | null>(null);

    const cacheAndStoreData = (data: any, key?: string) => {
        const getCachedData = JSON.parse(localStorage.getItem('users') || '{}');

        if (_.isEmpty(getCachedData) && key) {
            cache[key] = data?.users;
            localStorage.setItem('users', JSON.stringify(cache));
        } else if (!key) {
            const keys = Object.keys(getCachedData);
            const lastKey = parseInt(keys[keys.length - 1], 10);
            const cachedData = getCachedData;
            const newCachedData = {
                ...cachedData,
                [(lastKey + 1).toString()]: data?.users,
            };
            localStorage.setItem('users', JSON.stringify(newCachedData));
        }
    };

    const removeCachedData = (key: string) => {
        const getCachedData = JSON.parse(localStorage.getItem('users') || '{}');
        const cachedData = getCachedData;
        delete cachedData[key];
        localStorage.setItem('users', JSON.stringify(cachedData));
    };

    const getUsersCacheData = () => {
        const getCachedData = JSON.parse(localStorage.getItem('users') || '{}');
        const cachedData = getCachedData;
        const cachedDataKeys = Object.keys(cachedData);
        return cachedDataKeys.length > 1;
    };

    const prevPage = async () => {
        const getCachedData = JSON.parse(localStorage.getItem('users') || '{}');
        const keys = Object.keys(getCachedData);
        const getLastKey = parseInt(keys[keys.length - 1], 10);
        const keyBeforeLastKey = getLastKey - 1;
        if (keyBeforeLastKey === 0) {
            setHasNextPaginationToken(true);
        }
        const getCachedDataByKey = getCachedData[keyBeforeLastKey.toString()];
        setAdminsData(getCachedDataByKey);
        removeCachedData(getLastKey.toString());
        setLoading(false);
    };

    const nextPage = async () => {
        setLoading(true);
        const getCachedDataLength = Object.keys(JSON.parse(localStorage.getItem('users') || '{}')).length;

        let data = null;
        if (getCachedDataLength === 1 && initalToken) {
            data = await fetchAdmins({ limit: parseInt(limit, 10), nextPageToken: initalToken as string });
        } else {
            data = await fetchAdmins({ limit: parseInt(limit, 10), nextPageToken: nextToken as string });
        }
        if (data?.paginationToken) {
            setNextToken(data?.paginationToken);
            setHasNextPaginationToken(true);
        } else {
            setHasNextPaginationToken(false);
        }
        cacheAndStoreData(data);
        setLoading(false);
    };

    useEffect(() => {
        (async () => {
            localStorage.removeItem('users');
            cache = {};
            const data = await fetchAdmins({ limit: parseInt(limit, 10) });
            if (data?.paginationToken) {
                setNextToken(data?.paginationToken);
                setInitialToken(data?.paginationToken);
                cacheAndStoreData(data, '0');
                setHasNextPaginationToken(true);
            }
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            setLoading(true);

            localStorage.removeItem('users');
            cache = {};

            const data = await fetchAdmins({ limit: parseInt(limit, 10) });
            if (data?.paginationToken) {
                setNextToken(data?.paginationToken);
                cacheAndStoreData(data, '0');
                setInitialToken(data?.paginationToken);
                setHasNextPaginationToken(true);
            } else {
                setHasNextPaginationToken(false);
            }
            setLoading(false);
        })();
    }, [limit]);

    const handleOnRow = (row: any) => {
        return {
            onClick: () => setSelectedRow(row),
        };
    };

    const handleLimitChange = (value: string) => {
        setLimit(value);
    };

    const columns = useMemo(() => getColumns(), []);

    return (
        <>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Users</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <div className="row mt-4 mb-3">
                            <PageTitle title="All Users" />
                        </div>

                        <div className="row">
                            <div className="d-flex justify-content-end">
                                {/* <div className="p-2 bd-highlight">
                                    <NavLink to="/create-user">
                                        <div className="border-btn add-user-btn">ADD USER</div>
                                    </NavLink>
                                </div> */}
                                <div className="col-md-1">
                                    <NavLink to={'/create-user'}>
                                        <div className="border-btn text-center"> Add User</div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 mb-5">
                                <Select value="ALL" className="w-100">
                                    <Option value="ALL" key="ALL">
                                        ALL
                                    </Option>
                                </Select>
                            </div>
                        </div>
                        <div style={{ overflowX: 'auto' }}>
                            <Table
                                className="dma-header products-table"
                                dataSource={adminsData}
                                columns={columns}
                                onRow={handleOnRow}
                                loading={loading}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '15px',
                                }}
                            >
                                <div style={{ width: '70px', height: '40px', marginTop: '8px', marginBottom: '15px' }}>
                                    <Select value={limit} onChange={handleLimitChange}>
                                        <Option selected value="20" key="20">
                                            20
                                        </Option>
                                        <Option value="50" key="50">
                                            50
                                        </Option>
                                        <Option value="100" key="100">
                                            100
                                        </Option>
                                    </Select>
                                </div>
                                <div style={{ marginTop: '8px', marginLeft: '10px' }}>
                                    <Button
                                        style={{ height: '50px', borderRadius: '8px' }}
                                        disabled={!getUsersCacheData()}
                                        onClick={prevPage}
                                    >
                                        Prev Page
                                    </Button>
                                    <Button
                                        disabled={!hasNextPaginationToken}
                                        style={{ height: '50px', marginLeft: '10px', borderRadius: '8px' }}
                                        onClick={nextPage}
                                    >
                                        Next Page
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Users;
