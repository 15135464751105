import { Table } from 'antd';
// import { Breadcrumb } from 'antd';
import React, { useMemo } from 'react';

import { getApprovedProductsColumns } from './helpers';

type UploadedProducts = {
    code: Number;
    status: String;
    failedToBeAddedToDynamoProducts: any[];
    validProducts: ValidProducts[];
    invalidProducts: InvalidProducts[];
    identifiedDuplicateProducts: InvalidProducts[];
};

type InvalidProducts = {
    productId: string | undefined;
    company_name: string | undefined | null;
    type: number | string | null | undefined;
    name: string | null | undefined;
    quantity: number | string | null | undefined;
    category: number | string | null | undefined;
    price: number | string | undefined;
    dateAdded: string | null | undefined;
    status: string | null | undefined;
    unit: number | null | undefined;
    creationDate: string | null | undefined;
};

type ValidProducts = {
    productId: string | undefined;
    company_name: string | undefined | null;
    type: number | string | null | undefined;
    name: string | null | undefined;
    quantity: number | string | null | undefined;
    category: number | string | null | undefined;
    price: number | string | undefined;
    dateAdded: string | null | undefined;
    status: string | null | undefined;
    unit: number | null | undefined;
    creationDate: string | null | undefined;
};

type ProductsApprovedProps = {
    productUploadData: UploadedProducts;
    setView: React.Dispatch<React.SetStateAction<string>>;
    approveProducts: () => Promise<void>;
};

const ProductsApproval: React.FC<ProductsApprovedProps> = ({ productUploadData, approveProducts }) => {
    const { validProducts } = productUploadData as UploadedProducts;

    const mapProductsToTableData = (products: Array<ValidProducts | InvalidProducts | null>) => {
        const mappedProducts = products?.map((product) => {
            return {
                productId: product?.productId || 'N/A',
                company_name: product?.company_name || 'N/A',
                inputName: product?.name,
                quantity: product?.quantity,
                type: product?.type,
                name: product?.name,
                category: product?.category,
                price: product?.price || 'N/A',
                dateAdded: product?.creationDate,
                status: product?.status || 'rejected',
                unit: product?.unit || 'N/A',
                creationDate: product?.creationDate || 'N/A',
            };
        });
        return mappedProducts;
    };

    const columns = useMemo(() => getApprovedProductsColumns(), []);
    return (
        <div>
            <div className="row mb-2 mt-4">
                <div className="d-flex justify-content-end">
                    <div className="p-2 bd-highlight">
                        <div className="border-btn text-center" onClick={approveProducts}>
                            Approve Products
                        </div>
                    </div>
                </div>
            </div>
            <Table
                style={{ marginTop: '25px' }}
                className="dma-header products-table"
                dataSource={mapProductsToTableData(validProducts)}
                columns={columns}
                scroll={{ x: 768 }}
            />
        </div>
    );
};

export default ProductsApproval;
