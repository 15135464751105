import './dma.css';

import { EditOutlined, EyeOutlined, MoreOutlined, PrinterOutlined } from '@ant-design/icons';
import { Breadcrumb, Popover, Select } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { ActionModal } from '../../components/ActionModal';
import { Search } from '../../components/inputs/Search';
import CustomPopoverExportButton from './components/CustomPopoverExportButton';
import { PageTitle } from './PageTitle';
import { useDma } from './useDma';

const DMA: React.FC = () => {
    const [id, setId] = useState('');

    const {
        handleCancel,
        handleOk,
        setIsLoading,
        isModalVisible,
        handleLocationChange,
        handleGetWards,
        isLoading,
        filters,
        onChangeStatusFilter,
        getDMAAdmin,
        setFilters,
        totalRows,
        dma,
        dmaExportData,
        handlePopoverVisibleChange,
        popOverVisible,
        handleExportClick,
        aggregations,
        onChangeWardRegionFilter,
        onChangeCountiesFilter,
        onChangeSubCountiesFilter,
    } = useDma();

    const content = (
        <>
            <NavLink to={`/dma/profile/${id}`}>
                <EyeOutlined /> &nbsp; View More
            </NavLink>
        </>
    );

    useEffect(() => {
        getDMAAdmin(1, 10, '', '', '', true, filters.regions, filters.counties, filters.subCounties);
        handleGetWards();
    }, []);

    const csvHeaders = [
        { label: 'ID', key: 'dmaId' },
        { label: 'Name', key: 'businessName' },
        { label: 'Type', key: 'dmaType' },
        { label: 'Email', key: 'mail' },
        { label: 'Location', key: 'location' },
        { label: 'Ward', key: 'ward' },
        { label: 'Phone Number', key: 'phoneNumber' },
        { label: 'Credit Limit', key: 'creditLimit' },
        { label: 'DigiFarm Hub', key: 'digiFarmHub' },
        { label: 'KYC Status', key: 'kyc_flow_state' },
        { label: 'Creation Date', key: 'creationDate' },
    ];

    const columns: TableColumn<any>[] = [
        {
            name: 'ID',
            selector: (i) => i.dmaId?.slice(-6).toUpperCase() as any,
        },
        {
            name: 'Name',
            selector: (i) => i.businessName as any,
        },
        {
            name: 'Type',
            selector: (i) => i.dmaType as any,
        },
        {
            name: 'Email',
            selector: (i) => i.mail as any,
        },
        {
            name: 'Phone',
            selector: (i) => i.phoneNumber as any,
        },
        {
            name: 'Location',
            selector: (i) => i.location as any,
        },
        {
            name: 'Ward',
            selector: (i) => i.ward as any,
        },
        {
            name: 'Region',
            selector: (i) => i?.wardRegion || ('N/A' as any),
        },
        {
            name: 'County',
            selector: (i) => i?.wardCounty || ('N/A' as any),
        },
        {
            name: 'Subcounty',
            selector: (i) => i?.wardSubcounty || ('N/A' as any),
        },
        {
            name: 'Date Added',
            selector: (i) => moment(i.creationDate).format('Do MMM YYYY') as any,
        },
        {
            name: 'KYC Status',
            cell: (data) => {
                switch (data.kyc_flow_state) {
                    case 'APPROVED':
                        return <div className="approved">Approved</div>;
                    case 'REJECTED':
                        return <div className="rejected">Rejected</div>;
                    case 'APPROVAL_PENDING':
                        return <div className="pending">Pending</div>;
                    case 'NOT_UPLOADED':
                        return (
                            <div style={{ width: '100px' }} className="notuploaded">
                                Not Uploaded
                            </div>
                        );
                    default:
                        return (
                            <p style={{ width: '100px' }} className="notuploaded">
                                Not Uploaded
                            </p>
                        );
                }
            },
        },
        {
            name: '',
            cell: (data) => {
                return (
                    <div onClick={() => setId(data.dmaId)}>
                        <Popover placement="leftTop" content={content} trigger="click">
                            <MoreOutlined />
                        </Popover>
                    </div>
                );
            },
        },
    ];

    const { Option } = Select;
    const history = useHistory();

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#39b54a', // override the cell padding for head cells
                paddingRight: '2px',
                color: 'white',
                fontWeight: 'bolder',
            },
        },
    };

    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    // highlight-starts
    const debouncedSave = useCallback(
        debounce(
            (nextValue) =>
                getDMAAdmin(1, 10, '', '', nextValue, false, filters.regions, filters.counties, filters.subCounties),
            1000,
        ),
        [], // will be created only once initially
    );
    // highlight-ends

    const handleSearchText = (value: string) => {
        setFilters({
            ...filters,
            location: 'Select Location (Ward)',
            kyc_flow_state: 'Select Status',
            search_text: value,
        });
        setIsLoading(true);
        setCurrentPage(1);
        setPerPage(10);
        debouncedSave(value);
    };

    const handlePageChange = (page: number) => {
        getDMAAdmin(page, perPage, filters.location, filters.kyc_flow_state);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        getDMAAdmin(page, newPerPage, filters.location, filters.kyc_flow_state);
        setPerPage(newPerPage);
    };

    const clearFilter = () => {
        setFilters({
            ...filters,
            location: 'Select Location (Ward)',
            kyc_flow_state: 'Select Status',
            search_text: '',
            regions: '',
            counties: '',
            subCounties: '',
        });
        getDMAAdmin(currentPage, perPage, '', '');
    };
    return (
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="">DigiFarm Master Agents</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className="do-not-print">
                            <div className="row mt-4 mb-3">
                                <PageTitle title="DigiFarm Master Agents" />
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-6 d-flex justify-content-start">
                                    <div className="p-2 bd-highlight">
                                        <div
                                            className="border-btn text-center"
                                            onClick={() => history.push('/dma/bulk-update')}
                                        >
                                            Bulk Update <EditOutlined style={{ color: '#2cb34a' }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <div className="p-2 bd-highlight">
                                        <div className="border-btn text-center" onClick={() => window.print()}>
                                            Print <PrinterOutlined style={{ color: '#2cb34a' }} />
                                        </div>
                                    </div>
                                    <div className="p-2 bd-highlight">
                                        {' '}
                                        <CustomPopoverExportButton
                                            popoverVisible={popOverVisible}
                                            csvHeaders={csvHeaders}
                                            csvData={dmaExportData}
                                            handleExportClick={handleExportClick}
                                            handlePopoverVisibleChange={handlePopoverVisibleChange}
                                            csvFileName={`DMA Export Data`}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3 mb-5">
                                <div className="col-md-3 mb-2">
                                    <Select
                                        className="w-100"
                                        onChange={onChangeStatusFilter}
                                        value={filters.kyc_flow_state}
                                    >
                                        {aggregations?.kyc_flow_state?.buckets.map(
                                            (p: Record<string, string>, index: number) => {
                                                return (
                                                    <Option key={`status-${index}`} value={p.key}>
                                                        {p.key}
                                                    </Option>
                                                );
                                            },
                                        )}
                                    </Select>
                                </div>
                                <div className="col-md-3 mb-2">
                                    <Select
                                        showSearch
                                        className="w-100 dma-location-select"
                                        value={filters.location}
                                        onChange={handleLocationChange}
                                        filterOption={(input, option: any) =>
                                            option.props.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                        placeholder="Select Ward"
                                    >
                                        {aggregations?.ward?.buckets?.map(
                                            (p: Record<string, string>, index: number) => {
                                                return (
                                                    <Option key={`ward-${index}`} value={p.key}>
                                                        {p.key}
                                                    </Option>
                                                );
                                            },
                                        )}
                                    </Select>
                                </div>
                                <div className="col-md-4 mb-2">
                                    <Search
                                        iconColor={'#d7d7d7'}
                                        value={filters.search_text}
                                        onChange={handleSearchText}
                                        className="col-md-10 dma-search-input"
                                        placeholder="Search"
                                    />
                                </div>
                                <div className="col-md-3 mb-2">
                                    <Select
                                        className="w-100"
                                        onChange={onChangeWardRegionFilter}
                                        value={filters.regions}
                                        filterOption={(input, option: any) =>
                                            option.props.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                        placeholder="Select Region"
                                    >
                                        <Option value="">Select Region</Option>
                                        {aggregations?.wardRegion?.buckets.map(
                                            (p: Record<string, string>, index: number) => {
                                                return (
                                                    <Option key={`wardRegion-${index}`} value={p.key}>
                                                        {p.key}
                                                    </Option>
                                                );
                                            },
                                        )}
                                    </Select>
                                </div>

                                <div className="col-md-3 mb-2">
                                    <Select
                                        className="w-100"
                                        onChange={onChangeCountiesFilter}
                                        value={filters.counties}
                                        filterOption={(input, option: any) =>
                                            option.props.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                        placeholder="Select County"
                                    >
                                        <Option value="">Select County</Option>
                                        {aggregations?.wardCounty?.buckets.map(
                                            (p: Record<string, string>, index: number) => {
                                                return (
                                                    <Option key={`wardCounty-${index}`} value={p.key}>
                                                        {p.key}
                                                    </Option>
                                                );
                                            },
                                        )}
                                    </Select>
                                </div>

                                <div className="col-md-3 mb-2">
                                    <Select
                                        className="w-100"
                                        onChange={onChangeSubCountiesFilter}
                                        value={filters.subCounties}
                                        filterOption={(input, option: any) =>
                                            option.props.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                        placeholder="Select Subcounty"
                                    >
                                        <Option value="">Select Subcounty</Option>
                                        {aggregations?.wardSubCounty?.buckets.map(
                                            (p: Record<string, string>, index: number) => {
                                                return (
                                                    <Option key={`wardSubCounty-${index}`} value={p.key}>
                                                        {p.key}
                                                    </Option>
                                                );
                                            },
                                        )}
                                    </Select>
                                </div>

                                <div className="col-md-2 mb-2">
                                    <div
                                        className="primary-color reduce-opacity fw-500 p-3 pointer"
                                        onClick={clearFilter}
                                    >
                                        Clear Filter
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ overflowX: 'auto' }}>
                            <DataTable<any>
                                className={'dma-header shadow-lg p'}
                                data={dma}
                                columns={columns}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                paginationDefaultPage={currentPage}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                progressPending={isLoading}
                                striped
                                customStyles={customStyles}
                            />
                        </div>
                        {/* <Table
                                className={'dma-header shadow-lg p'}
                                loading={isLoading}
                                dataSource={dma}
                                columns={columns}
                            /> */}
                        {isModalVisible ? (
                            <ActionModal
                                handleCancel={handleCancel}
                                handleOk={handleOk}
                                isModalVisible={isModalVisible}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>{' '}
            </div>
        </div>
    );
};

export default DMA;
