import { useEffect, useState } from 'react';

export type CSSBreakPoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const getDeviceConfig = (width: number): CSSBreakPoints => {
    if (width < 576) {
        return 'xs';
    } else if (width >= 576 && width < 768) {
        return 'sm';
    } else if (width >= 768 && width < 992) {
        return 'md';
    } else if (width >= 992 && width < 1200) {
        return 'lg';
    } else if (width >= 1200 && width < 1400) {
        return 'xl';
    } else {
        return 'xxl';
    }
};

const useBreakpoint = () => {
    const [breakPoint, setBreakPoint] = useState(() => getDeviceConfig(window.innerWidth));

    const calculateInnerWidth = () => {
        setBreakPoint(getDeviceConfig(window.innerWidth));
    };
    useEffect(() => {
        setBreakPoint(getDeviceConfig(window.innerWidth));
        window.addEventListener('resize', calculateInnerWidth);

        return () => window.removeEventListener('resize', calculateInnerWidth);
    }, []);

    return breakPoint;
};

export default useBreakpoint;
