export class ApiNames {
    public static accounts = 'AccountsAPIGateway';
}

const awsConfig = {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_APPSYNC_URL,
    aws_appsync_region: process.env.REACT_APP_AWS_REGION,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    Auth: {
        identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_AWS_REGION,
        identityPoolRegion: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    },
    Storage: {
        region: process.env.REACT_APP_AWS_REGION,
        // bucket:
        //   's3://df-id-produce-service-dfiddevelopmentproducebucke-1aqfrll5f6kll/products',
        // bucket: 'df-id-produce-service-dfiddevelopmentproducebucke-1aqfrll5f6kll',
        bucket: process.env.REACT_APP_UPLOAD_BUCKET,
        // identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
        asset: process.env.REACT_APP_PUBLIC_ASSETS_URL,
    },

    API: {
        endpoints: [
            {
                name: ApiNames.accounts,
                endpoint: process.env.REACT_APP_API_ACCOUNT_ENDPOINT,
                custom_header: async () => ({
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                }),
            },
        ],
    },
};

export default awsConfig;
