import { DeleteOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';

interface ActionModalTypes {
    isModalVisible: boolean;
    message?: string;
    cancelButtonText?: string;
    okButtonText?: string;
    handleOk: () => void;
    handleCancel: () => void;
    userType?: string;
    username?: string;
    isActivating?: boolean;
}

export const ActionModal: React.FC<ActionModalTypes> = (props) => {
    const { isModalVisible, handleCancel, handleOk, userType, username, isActivating } = props;
    return (
        <>
            <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
                <div className={'d-flex flex-column align-items-center justify-content-center'}>
                    <div>
                        <DeleteOutlined
                            className="p-3"
                            style={{
                                color: 'red',
                                backgroundColor: '#ededed',
                                borderRadius: '50%',
                                fontSize: '36px',
                            }}
                        />
                    </div>
                    <div className={'fs-24 fw-500 mt-3 red-color'}>
                        {isActivating ? 'Activate' : 'Deactivate'} {userType || 'account'}
                    </div>
                    <div className="grey-color mt-2 fs-16">
                        Are you sure you want to {isActivating ? 'activate' : 'deactivate'} {username || 'this account'}
                        ?
                    </div>

                    <div className={'d-flex col-md-8 justify-content-around mt-5 mb-3'}>
                        <div className={'cancel-btn col-md-5'} onClick={handleCancel}>
                            Cancel
                        </div>
                        <div className={'delete-btn col-md-5'} onClick={handleOk}>
                            {isActivating ? 'Activate' : 'Deactivate'}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
