import { Action } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';

export const IS_LOGGING_IN = 'IS_LOGGING_IN';
export const DONE_LOGGING_IN = 'DONE_LOGGING_IN';
export const REGISTERING = 'REGISTERING';
export const DONE_REGISTERING = 'DONE_REGISTERING';
export const SET_USER = 'SET_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const LOGIN_MANUFACTURER = '[AUTH] LOGIN_MANUFACTURER';
export const LOGIN_MANUFACTURER_SUCCESS = '[AUTH] LOGIN_MANUFACTURER_SUCCESS';
export const LOGIN_MANUFACTURER_ERROR = '[AUTH] LOGIN_MANUFACTURER_ERROR';
export const RESET_NEW_MANUFACTURER_PASSWORD = '[AUTH] RESET_NEW_MANUFACTURER_PASSWORD';
export const RESET_NEW_MANUFACTURER_PASSWORD_SUCCESS = '[AUTH] RESET_NEW_MANUFACTURER_PASSWORD_SUCCESS';
export const RESET_NEW_MANUFACTURER_PASSWORD_ERROR = '[AUTH] RESET_NEW_MANUFACTURER_PASSWORD_ERROR';

export const GET_CURRENT_SESSION = `[AUTH] GET_CURRENT_SESSION`;
export const GET_CURRENT_SESSION_ERROR = '[AUTH] GET_CURRENT_SESSION_ERROR';

export const LOGOUT = `[AUTH] LOGOUT `;

export const GET_ALL_MANUFACTURERS = '[MANUFACTURERS] GET_ALL_MANUFACTURERS';
export const GET_ALL_MANUFACTURERS_SUCCESS = '[MANUFACTURERS] GET_ALL_MANUFACTURES_SUCCESS';
export const GET_ALL_MANUFACTURERS_ERROR = '[MANUFACTURERS] GET_ALL_MANUFACTURES_ERROR';
export const TOGGLE_MANUFACTURER = '[MANUFACTURERS] TOGGLE_MANUFACTURER';
export const TOGGLE_MANUFACTURER_ERROR = '[MANUFACTURERS] TOGGLE_MANUFACTURER_ERROR';
export const GET_MANUFACTURER = '[MANUFACTURERS] GET_MANUFACTURER';
export const GET_MANUFACTURER_ERROR = '[MANUFACTURERS] GET_MANUFACTURER_ERROR';

export const GET_ALL_PRODUCTS = '[PRODUCTS] GET_ALL_PRODUCTS';
export const GET_ALL_PRODUCTS_SUCCESS = '[PRODUCTS] GET_ALL_PRODUCTS_SUCCESS';
export const GET_ALL_PRODUCTS_ERROR = '[PRODUCTS] GET_ALL_PRODUCTS_ERROR';
export const TOGGLE_PRODUCT = '[PRODUCTS] TOGGLE_PRODUCT';
export const TOGGLE_PRODUCT_ERROR = '[PRODUCTS] TOGGLE_MANUFACTURER_ERROR';
export const GET_PRODUCT = '[PRODUCTS] GET_PRODUCT';
export const GET_PRODUCT_ERROR = '[PRODUCTS] GET_PRODUCT_ERROR';

export const GET_CATEGORIES = '[CATEGORIES] GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = '[CATEGORIES] GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = '[CATEGORIES] GET_CATEGORIES_ERROR';

export const RETRIEVE_ALL_MANUFACTURERS = '[MANUFACTURERS] RETRIEVE_ALL_MANUFACTURERS';
export const RETRIEVE_ALL_MANUFACTURERS_SUCCESS = '[MANUFACTURERS] RETRIEVE_ALL_MANUFACTURES_SUCCESS';
export const RETRIEVE_ALL_MANUFACTURERS_ERROR = '[MANUFACTURERS] RETRIEVE_ALL_MANUFACTURES_ERROR';
export const RETRIEVE_MANUFACTURER = '[MANUFACTURERS] RETRIEVE_MANUFACTURER';
export const RETRIEVE_MANUFACTURER_ERROR = '[MANUFACTURERS] RETRIEVE_MANUFACTURER_ERROR';

export type ActionTypes =
    | typeof IS_LOGGING_IN
    | typeof DONE_LOGGING_IN
    | typeof REGISTERING
    | typeof DONE_REGISTERING
    | typeof SET_USER
    | typeof LOGOUT_USER
    | typeof LOGIN_MANUFACTURER
    | typeof LOGIN_MANUFACTURER_ERROR
    | typeof LOGIN_MANUFACTURER_SUCCESS
    | typeof RESET_NEW_MANUFACTURER_PASSWORD_SUCCESS
    | typeof RESET_NEW_MANUFACTURER_PASSWORD_ERROR
    | typeof RESET_NEW_MANUFACTURER_PASSWORD
    | typeof GET_CURRENT_SESSION
    | typeof GET_CURRENT_SESSION_ERROR
    | typeof LOGOUT
    | typeof GET_ALL_MANUFACTURERS
    | typeof GET_ALL_MANUFACTURERS_SUCCESS
    | typeof GET_ALL_MANUFACTURERS_ERROR
    | typeof TOGGLE_MANUFACTURER
    | typeof TOGGLE_MANUFACTURER_ERROR
    | typeof GET_MANUFACTURER
    | typeof GET_MANUFACTURER_ERROR
    | typeof GET_ALL_PRODUCTS
    | typeof GET_ALL_PRODUCTS_SUCCESS
    | typeof GET_ALL_PRODUCTS_ERROR
    | typeof GET_PRODUCT_ERROR
    | typeof TOGGLE_PRODUCT
    | typeof TOGGLE_PRODUCT_ERROR
    | typeof GET_PRODUCT
    | typeof GET_CATEGORIES
    | typeof GET_CATEGORIES_SUCCESS
    | typeof GET_CATEGORIES_ERROR
    | typeof RETRIEVE_ALL_MANUFACTURERS
    | typeof RETRIEVE_ALL_MANUFACTURERS_SUCCESS
    | typeof RETRIEVE_ALL_MANUFACTURERS_ERROR
    | typeof RETRIEVE_MANUFACTURER_ERROR
    | typeof RETRIEVE_MANUFACTURER;

export type ActionDispatch<T = unknown, S = unknown> = ThunkDispatch<T, S, Action<ActionTypes>>;
