import { number, object, string } from 'yup';

export const createProductSchema = object().shape({
    manufacturerId: string().required('Required'),
    hubId: string().required('Required'),
    description: string().required('Required'),
    name: string().required('Required'),
    price: number().min(1).required('Required'),
    unit: string().required('Required'),
    quantity: number().required('Required').typeError('Must be a number'),
    dmaPrice: number().required('Required').typeError('Must be a number'),
    rebates: number().required('Required').min(1).max(100).typeError('Must be a number'),
});
