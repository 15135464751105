import { GraphQLResult } from '@aws-amplify/api-graphql';
import { Card, Col, Row } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useState } from 'react';
import BlockUi from 'react-block-ui';
import { toast } from 'react-toastify';

import { DMA, UpdateType } from '../../../API';
import { Input } from '../../../components/inputs/Input';
import { updateDmaProfileDetailsByDmaId } from '../../../graphql/mutations';

interface ProfileType {
    id: string;
    user: DMA;
}
export const CreditLimitCard: React.FC<ProfileType> = ({ user, id }) => {
    const [creditLimit, setCreditLimit] = useState(user?.creditLimit || 0);
    const [edit, setEdit] = useState(false);
    const [updating, setUpdating] = useState(false);

    const save = async () => {
        try {
            console.log('save', creditLimit);
            setUpdating(true);
            const query = graphqlOperation(updateDmaProfileDetailsByDmaId, {
                dmaProfile: {
                    dmaId: id,
                    creditLimit,
                    updateType: UpdateType.CREDITLIMIT,
                },
            });
            const { data } = (await API.graphql(query)) as GraphQLResult<any>;
            console.log(data);
            if (data?.updateDmaProfileDetailsByDmaId?.code === 200) {
                setUpdating(false);
                setEdit(false);
                toast.success('Profile Updated successfully!');
                return;
            } else {
                setUpdating(false);
                setEdit(false);
                setCreditLimit(user.creditLimit || 0);
                toast.error(data?.message);
                return;
            }
        } catch (e: any) {
            setCreditLimit(user.creditLimit || 0);
            toast.error('Error updating profile', e);
            return;
        } finally {
            setUpdating(false);
            setEdit(false);
        }
    };

    return (
        <BlockUi blocking={updating}>
            <Row>
                <Col span={12}>
                    <div className="primary-color fs-20 fw-500 mb-4">DMA Credit Limit</div>
                </Col>
                <Col span={12}>
                    <button
                        className="primary-btn-2 fw-500 pull-right mb-2"
                        onClick={() => {
                            edit ? save() : setEdit(true);
                        }}
                    >
                        {edit ? 'SAVE CHANGES' : 'UPDATE CREDIT LIMIT'}
                    </button>
                </Col>
            </Row>
            <div className="d-flex justify-content-between fullwidth">
                <Card className="profile-card fullwidth mb-5">
                    <div className="flex-column fullwidth d-flex fw-500">Credit Limit</div>
                    <hr />
                    {!edit ? (
                        <div className="mb-3">
                            <Row>
                                <Col span={6}>Limit: {creditLimit}</Col>
                            </Row>
                        </div>
                    ) : (
                        <div className="mb-3">
                            <Row>
                                <Col span={6}>Limit:</Col>
                                <Col span={10}>
                                    <Input
                                        className=""
                                        placeholder="1000"
                                        value={String(creditLimit)}
                                        type="number"
                                        required
                                        onChange={(e) => {
                                            setCreditLimit(Number(e));
                                        }}
                                        name="amount"
                                        disabled={false}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                </Card>
            </div>
        </BlockUi>
    );
};
