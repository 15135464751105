import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { RootState } from '../redux/store';

const connector = connect((state: RootState) => ({
    isAuthenticated: state.auth.isAuthenticated,
}));
type IProps = ConnectedProps<typeof connector> & RouteProps;
let storageIsAuthenticated: boolean;

if (localStorage.getItem('isAuthenticated') || '') {
    storageIsAuthenticated = JSON.parse(localStorage.getItem('isAuthenticated') || '');
}
const PrivateRoute: React.FC<IProps> = ({ isAuthenticated, component: Component, ...rest }) => {
    if (storageIsAuthenticated || isAuthenticated) {
        return <Route {...rest} component={Component} />;
    }
    return (
        <Route
            {...rest}
            render={(props) => <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />}
        />
    );
};
export default connector(PrivateRoute);
