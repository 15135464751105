/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getWards = /* GraphQL */ `
  query GetWards($wardId: String, $limit: Int) {
    getWards(wardId: $wardId, limit: $limit) {
      name
      wardId
      county
      subcounty
      region
      creationDate
      description
      modificationDate
    }
  }
`;
export const getRoles = /* GraphQL */ `
  query GetRoles($rolesId: String) {
    getRoles(rolesId: $rolesId) {
      rolename
      alias
      rolesId
      permissions {
        fieldName
        alias
        permissionId
        creationDate
        description
        modificationDate
        userLevel
      }
      creationDate
      description
      modificationDate
    }
  }
`;
export const getDMARoles = /* GraphQL */ `
  query GetDMARoles {
    getDMARoles {
      rolename
      alias
      dmaRolesId
      permissions {
        fieldName
        alias
        permissionId
        creationDate
        description
        modificationDate
        userLevel
      }
      creationDate
      description
      modificationDate
    }
  }
`;
export const getDMARolesById = /* GraphQL */ `
  query GetDMARolesById($dmaRolesId: String) {
    getDMARolesById(dmaRolesId: $dmaRolesId) {
      rolename
      alias
      dmaRolesId
      permissions {
        fieldName
        alias
        permissionId
        creationDate
        description
        modificationDate
        userLevel
      }
      creationDate
      description
      modificationDate
    }
  }
`;
export const getPermissions = /* GraphQL */ `
  query GetPermissions($permissionId: String) {
    getPermissions(permissionId: $permissionId) {
      fieldName
      alias
      permissionId
      creationDate
      description
      modificationDate
      userLevel
    }
  }
`;
export const getPermissionsByUserLevel = /* GraphQL */ `
  query GetPermissionsByUserLevel($userLevel: String) {
    getPermissionsByUserLevel(userLevel: $userLevel) {
      data {
        fieldName
        alias
        permissionId
        creationDate
        description
        modificationDate
        userLevel
      }
      code
      message
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers($limit: String, $group: String) {
    listUsers(limit: $limit, group: $group) {
      Locations
      Users {
        Username
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
        Attributes {
          Name
          Value
        }
      }
      message
    }
  }
`;
export const listUsersInGroup = /* GraphQL */ `
  query ListUsersInGroup(
    $limit: String
    $group: String!
    $filters: String
    $next_page_token: String
    $company_name: String
    $location: String
    $kyc_flow_state: String
    $enabled: String
    $search_text: String
  ) {
    listUsersInGroup(
      limit: $limit
      group: $group
      filters: $filters
      next_page_token: $next_page_token
      company_name: $company_name
      location: $location
      kyc_flow_state: $kyc_flow_state
      enabled: $enabled
      search_text: $search_text
    ) {
      Locations
      Users {
        Username
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
        Attributes {
          Name
          Value
        }
      }
      message
    }
  }
`;
export const keyListUsersInGroup = /* GraphQL */ `
  query KeyListUsersInGroup(
    $limit: String
    $group: String!
    $filters: String
    $next_page_token: String
    $company_name: String
    $location: String
    $kyc_flow_state: String
    $enabled: String
    $search_text: String
  ) {
    keyListUsersInGroup(
      limit: $limit
      group: $group
      filters: $filters
      next_page_token: $next_page_token
      company_name: $company_name
      location: $location
      kyc_flow_state: $kyc_flow_state
      enabled: $enabled
      search_text: $search_text
    ) {
      Locations
      Users {
        Username
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
        Attributes {
          Name
          Value
        }
      }
      message
    }
  }
`;
export const adminGetDmaReadPresignedUrl = /* GraphQL */ `
  query AdminGetDmaReadPresignedUrl($file_key: String) {
    adminGetDmaReadPresignedUrl(file_key: $file_key)
  }
`;
export const adminGetManufacturerPresignedUrl = /* GraphQL */ `
  query AdminGetManufacturerPresignedUrl($username: String) {
    adminGetManufacturerPresignedUrl(username: $username) {
      url
      expires_in
      fields
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($username: String) {
    getAccount(username: $username) {
      Username
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
      UserAttributes {
        Name
        Value
      }
    }
  }
`;
export const userGetAccount = /* GraphQL */ `
  query UserGetAccount($username: String) {
    userGetAccount(username: $username)
  }
`;
export const approveDma = /* GraphQL */ `
  query ApproveDma($username: String) {
    approveDma(username: $username)
  }
`;
export const rejectDma = /* GraphQL */ `
  query RejectDma($username: String) {
    rejectDma(username: $username)
  }
`;
export const disableManufacturer = /* GraphQL */ `
  query DisableManufacturer($company_name: String) {
    disableManufacturer(company_name: $company_name)
  }
`;
export const enableManufacturer = /* GraphQL */ `
  query EnableManufacturer($company_name: String) {
    enableManufacturer(company_name: $company_name)
  }
`;
export const enableUser = /* GraphQL */ `
  query EnableUser($username: String) {
    enableUser(username: $username)
  }
`;
export const disableUser = /* GraphQL */ `
  query DisableUser($username: String) {
    disableUser(username: $username)
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins(
    $limit: Int
    $filter: String
    $attributes: [String]
    $nextPageToken: String
  ) {
    listAdmins(
      limit: $limit
      filter: $filter
      attributes: $attributes
      nextPageToken: $nextPageToken
    ) {
      Users {
        Username
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
        Attributes {
          Name
          Value
        }
      }
      PaginationToken
    }
  }
`;
export const listAdminsInGroup = /* GraphQL */ `
  query ListAdminsInGroup($limit: Int, $group: String, $nextPageToken: String) {
    listAdminsInGroup(
      limit: $limit
      group: $group
      nextPageToken: $nextPageToken
    ) {
      Users {
        Username
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
        Attributes {
          Name
          Value
        }
      }
      NextToken
    }
  }
`;
export const getAdminAccount = /* GraphQL */ `
  query GetAdminAccount($Username: String) {
    getAdminAccount(Username: $Username) {
      Username
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
      UserAttributes {
        Name
        Value
      }
      roles
      permissions {
        fieldName
        alias
        permissionId
        creationDate
        description
        modificationDate
        userLevel
      }
    }
  }
`;
export const dmaGetWritePresignedUrl = /* GraphQL */ `
  query DmaGetWritePresignedUrl {
    dmaGetWritePresignedUrl
  }
`;
export const userGetFarmerDetails = /* GraphQL */ `
  query UserGetFarmerDetails($phone_number: String) {
    userGetFarmerDetails(phone_number: $phone_number) {
      farmer_id
      member_number
      first_name
      middle_name
      last_name
      phone_number
    }
  }
`;
export const getAccountDashboard = /* GraphQL */ `
  query GetAccountDashboard {
    getAccountDashboard {
      dma
      manufacturer
      unassigned
      name
    }
  }
`;
export const getAccountDashboardChart = /* GraphQL */ `
  query GetAccountDashboardChart {
    getAccountDashboardChart {
      dma
      manufacturer
      unassigned
      name
    }
  }
`;
export const manufacturerStatistics = /* GraphQL */ `
  query ManufacturerStatistics {
    manufacturerStatistics {
      manToday
      manMonthly {
        monthLong
        monthShort
        creationDateFrom
        creationDateTo
        manStats
        monthStats
      }
    }
  }
`;
export const getTempDma = /* GraphQL */ `
  query GetTempDma($email: String!) {
    getTempDma(email: $email) {
      data
      code
      message
    }
  }
`;
export const listAuditLog = /* GraphQL */ `
  query ListAuditLog(
    $filterCriterias: [FilterCriteria!]
    $exclusiveStartKey: String
  ) {
    listAuditLog(
      filterCriterias: $filterCriterias
      exclusiveStartKey: $exclusiveStartKey
    ) {
      code
      message
      data {
        auditId
        username
        action
        date
        event
      }
      lastEvaluatedKey
    }
  }
`;
export const dmaGetReadPresignedUrl = /* GraphQL */ `
  query DmaGetReadPresignedUrl {
    dmaGetReadPresignedUrl
  }
`;
export const getAdverts = /* GraphQL */ `
  query GetAdverts($limit: Int, $nextToken: String, $advertIds: [ID]) {
    getAdverts(limit: $limit, nextToken: $nextToken, advertIds: $advertIds) {
      adcategory {
        adcategoryId
        name
        creationDate
        modificationDate
      }
      creationDate
      description
      advertId
      modificationDate
      name
      time
      action
      image
    }
  }
`;
export const getAdvertById = /* GraphQL */ `
  query GetAdvertById($advertId: ID!) {
    getAdvertById(advertId: $advertId) {
      adcategory {
        adcategoryId
        name
        creationDate
        modificationDate
      }
      creationDate
      description
      advertId
      modificationDate
      name
      time
      action
      image
    }
  }
`;
export const getAdCategories = /* GraphQL */ `
  query GetAdCategories($limit: Int, $nextToken: String) {
    getAdCategories(limit: $limit, nextToken: $nextToken) {
      adcategories {
        adcategoryId
        name
        creationDate
        modificationDate
      }
      nextToken
    }
  }
`;
export const getAdCategoryById = /* GraphQL */ `
  query GetAdCategoryById($adcategoryId: ID!) {
    getAdCategoryById(adcategoryId: $adcategoryId) {
      adcategoryId
      name
      creationDate
      modificationDate
    }
  }
`;
export const getAdCompany = /* GraphQL */ `
  query GetAdCompany($limit: Int) {
    getAdCompany(limit: $limit) {
      adcompanyId
      adcategory {
        adcategoryId
        name
        creationDate
        modificationDate
      }
      creationDate
      description
      modificationDate
      name
      time
      action
      image
    }
  }
`;
export const fetchCartByUserId = /* GraphQL */ `
  query FetchCartByUserId($userId: String!) {
    fetchCartByUserId(userId: $userId) {
      cartId
      cartItems {
        product {
          manufacturerUserId
          description
          productId
          name
          price
          unit
          type
          image
          weight
          quantity
          company_name
          manufacturerId
        }
        quantity
        total
      }
      dmaId
      userId
      branchId
      cartStatus
      creationDate
      modificationDate
      paymentStatus
      paymentMethod
      mpesaTransactionId
      total
    }
  }
`;
export const getDmaByDmaID = /* GraphQL */ `
  query GetDmaByDmaID($dmaId: ID!) {
    getDmaByDmaID(dmaId: $dmaId) {
      data {
        userId
        dmaId
        dmaType
        businessName
        phoneNumber
        mail
        location
        ward
        wardObj
        kyc_flow_state
        paymentAccounts {
          payBillNumber
          tillNumber
          mpesaNumber
          accountNumber
        }
        digiFarmHub {
          manufacturerId
          manufacturerName
          hubs {
            hubId
            hubName
          }
        }
        creditLimit
        creationDate
        modificationDate
      }
      code
      message
    }
  }
`;
export const getBranchByID = /* GraphQL */ `
  query GetBranchByID($branchId: ID!, $dmaId: ID!) {
    getBranchByID(branchId: $branchId, dmaId: $dmaId) {
      data {
        branchId
        dmaId
        branchName
        location
        isHQ
        phoneNumber
        mail
        ward
        creationDate
      }
      code
      message
    }
  }
`;
export const getBranches = /* GraphQL */ `
  query GetBranches($dmaId: ID!) {
    getBranches(dmaId: $dmaId) {
      data {
        branchId
        dmaId
        branchName
        location
        isHQ
        phoneNumber
        mail
        ward
        creationDate
      }
      code
      message
    }
  }
`;
export const getBranchUsers = /* GraphQL */ `
  query GetBranchUsers($branchId: ID!) {
    getBranchUsers(branchId: $branchId) {
      data {
        dmaId
        branchId
        collectionCentreId
        national_id
        username
        email_verified
        phone_number_verified
        group
        kyc_flow_state
        location
        name
        phone_number
        email
        roles {
          name
          description
          creationDate
          modificationDate
        }
        enabled
        userStatus
      }
      code
      message
    }
  }
`;
export const getFarmerTransaction = /* GraphQL */ `
  query GetFarmerTransaction($farmerId: ID!) {
    getFarmerTransaction(farmerId: $farmerId) {
      farmerId
      amount
      transactionType
      paymentMethod
      transactionId
      transactionDate
    }
  }
`;
export const getDmaUserDetails = /* GraphQL */ `
  query GetDmaUserDetails($userId: ID!) {
    getDmaUserDetails(userId: $userId) {
      data {
        userDetail {
          dmaId
          branchId
          collectionCentreId
          national_id
          username
          email_verified
          phone_number_verified
          group
          kyc_flow_state
          location
          name
          phone_number
          email
          roles {
            name
            description
            creationDate
            modificationDate
          }
          enabled
          userStatus
        }
        dmaDetail {
          userId
          dmaId
          dmaType
          businessName
          phoneNumber
          mail
          location
          ward
          wardObj
          kyc_flow_state
          paymentAccounts {
            payBillNumber
            tillNumber
            mpesaNumber
            accountNumber
          }
          digiFarmHub {
            manufacturerId
            manufacturerName
          }
          creditLimit
          creationDate
          modificationDate
        }
        dmaBranchDetail {
          branchId
          dmaId
          branchName
          location
          isHQ
          phoneNumber
          mail
          ward
          creationDate
        }
      }
      code
      message
    }
  }
`;
export const getFarmer = /* GraphQL */ `
  query GetFarmer($farmerId: ID!) {
    getFarmer(farmerId: $farmerId) {
      farmerDetails {
        farmerId
        dmaId
        national_id
        member_number
        phone
        first_name
        middle_name
        last_name
        route
        supply_category
        MCC
        gender
        village
      }
      farmerFinancialDetails {
        farmerId
        avg_income
        limit
        df_exclusions
        segment
        score
        income_proxy_variable
        df_score_band
        df_income_proxy
        currentMonthRemainingCashAdvanceLimit
        currentMonthRemainingCreditLimit
        repayment
        currentMonthTotalCashEntitlement
      }
      todayFarmerDeliveryDetails {
        morning
        evening
      }
      currentMonthFarmerTransactions {
        farmerId
        amount
        transactionType
        paymentMethod
        transactionId
        transactionDate
      }
      currentMonthFarmerDelivery {
        farmerId
        collectionCenterId
        transporterId
        deliveryDate
        containerNumber
        product
        quantity
        unitPrice
        status
      }
    }
  }
`;
export const getFarmerCashAdvanceLimit = /* GraphQL */ `
  query GetFarmerCashAdvanceLimit($farmerId: ID, $phone: String) {
    getFarmerCashAdvanceLimit(farmerId: $farmerId, phone: $phone) {
      first_name
      middle_name
      last_name
      member_number
      phone
      cooperative
      cashAdvanceEntitlement
      code
      message
    }
  }
`;
export const getFarmerCreditLimit = /* GraphQL */ `
  query GetFarmerCreditLimit($farmerId: ID, $phone: String) {
    getFarmerCreditLimit(farmerId: $farmerId, phone: $phone) {
      first_name
      middle_name
      last_name
      member_number
      phone
      cooperative
      limit
      code
      message
    }
  }
`;
export const getCollectionCentres = /* GraphQL */ `
  query GetCollectionCentres($dmaId: ID!) {
    getCollectionCentres(dmaId: $dmaId) {
      data {
        collectionCentreId
        centreId
        dmaId
        centreName
        location
        creationDate
      }
      code
      message
    }
  }
`;
export const getCollectionCentreByID = /* GraphQL */ `
  query GetCollectionCentreByID($collectionCentreId: ID!) {
    getCollectionCentreByID(collectionCentreId: $collectionCentreId) {
      data {
        collectionCentreId
        centreId
        dmaId
        centreName
        location
        creationDate
      }
      code
      message
    }
  }
`;
export const getDmaTransporters = /* GraphQL */ `
  query GetDmaTransporters($dmaId: ID!) {
    getDmaTransporters(dmaId: $dmaId) {
      data {
        dmaId
        branchId
        collectionCentreId
        national_id
        username
        email_verified
        phone_number_verified
        group
        kyc_flow_state
        location
        name
        phone_number
        email
        roles {
          name
          description
          creationDate
          modificationDate
        }
        enabled
        userStatus
      }
      code
      message
    }
  }
`;
export const getTransporterById = /* GraphQL */ `
  query GetTransporterById($transporterId: ID!) {
    getTransporterById(transporterId: $transporterId) {
      data {
        dmaId
        branchId
        collectionCentreId
        national_id
        username
        email_verified
        phone_number_verified
        group
        kyc_flow_state
        location
        name
        phone_number
        email
        roles {
          name
          description
          creationDate
          modificationDate
        }
        enabled
        userStatus
      }
      code
      message
    }
  }
`;
export const getCollectionCentreUsers = /* GraphQL */ `
  query GetCollectionCentreUsers($collectionCentreId: ID!) {
    getCollectionCentreUsers(collectionCentreId: $collectionCentreId) {
      data {
        dmaId
        branchId
        collectionCentreId
        national_id
        username
        email_verified
        phone_number_verified
        group
        kyc_flow_state
        location
        name
        phone_number
        email
        roles {
          name
          description
          creationDate
          modificationDate
        }
        enabled
        userStatus
      }
      code
      message
    }
  }
`;
export const transporterGetTodayCollectedMilkQuantity = /* GraphQL */ `
  query TransporterGetTodayCollectedMilkQuantity {
    transporterGetTodayCollectedMilkQuantity {
      data {
        morning
        evening
      }
      code
      message
    }
  }
`;
export const verifyFarmerCooperativeAssociation = /* GraphQL */ `
  query VerifyFarmerCooperativeAssociation($verifyFarmer: VerifyFarmerInput) {
    verifyFarmerCooperativeAssociation(verifyFarmer: $verifyFarmer) {
      code
      message
    }
  }
`;
export const getFarmerByPhone = /* GraphQL */ `
  query GetFarmerByPhone($phone: String) {
    getFarmerByPhone(phone: $phone) {
      code
      message
      data {
        farmerId
        dmaId
        national_id
        member_number
        phone
        first_name
        middle_name
        last_name
        route
        supply_category
        MCC
        gender
        village
      }
    }
  }
`;
export const farmerGetTodayCollectedMilkQuantity = /* GraphQL */ `
  query FarmerGetTodayCollectedMilkQuantity($farmerId: String) {
    farmerGetTodayCollectedMilkQuantity(farmerId: $farmerId) {
      data {
        morning
        evening
      }
      code
      message
    }
  }
`;
export const getDeliveryApprovals = /* GraphQL */ `
  query GetDeliveryApprovals($collectionCenterId: ID, $transporterId: ID) {
    getDeliveryApprovals(
      collectionCenterId: $collectionCenterId
      transporterId: $transporterId
    ) {
      data
      code
      message
    }
  }
`;
export const getAgrovetList = /* GraphQL */ `
  query GetAgrovetList($businessName: String, $limit: Int, $startKey: AWSJSON) {
    getAgrovetList(
      businessName: $businessName
      limit: $limit
      startKey: $startKey
    ) {
      data {
        userId
        dmaId
        dmaType
        businessName
        phoneNumber
        mail
        location
        ward
        wardObj
        kyc_flow_state
        paymentAccounts {
          payBillNumber
          tillNumber
          mpesaNumber
          accountNumber
        }
        digiFarmHub {
          manufacturerId
          manufacturerName
          hubs {
            hubId
            hubName
          }
        }
        creditLimit
        creationDate
        modificationDate
      }
      code
      message
      lastKey
    }
  }
`;
export const getCooperativeDmaPartners = /* GraphQL */ `
  query GetCooperativeDmaPartners(
    $cooperativeId: ID!
    $limit: Int
    $nextToken: String
  ) {
    getCooperativeDmaPartners(
      cooperativeId: $cooperativeId
      limit: $limit
      nextToken: $nextToken
    ) {
      data {
        userId
        dmaId
        dmaType
        businessName
        phoneNumber
        mail
        location
        ward
        wardObj
        kyc_flow_state
        paymentAccounts {
          payBillNumber
          tillNumber
          mpesaNumber
          accountNumber
        }
        digiFarmHub {
          manufacturerId
          manufacturerName
          hubs {
            hubId
            hubName
          }
        }
        creditLimit
        creationDate
        modificationDate
      }
      code
      message
      lastKey
    }
  }
`;
export const getAgrovetDmaPartners = /* GraphQL */ `
  query GetAgrovetDmaPartners(
    $agrovetId: ID!
    $limit: Int
    $nextToken: String
  ) {
    getAgrovetDmaPartners(
      agrovetId: $agrovetId
      limit: $limit
      nextToken: $nextToken
    ) {
      data {
        userId
        dmaId
        dmaType
        businessName
        phoneNumber
        mail
        location
        ward
        wardObj
        kyc_flow_state
        paymentAccounts {
          payBillNumber
          tillNumber
          mpesaNumber
          accountNumber
        }
        digiFarmHub {
          manufacturerId
          manufacturerName
          hubs {
            hubId
            hubName
          }
        }
        creditLimit
        creationDate
        modificationDate
      }
      code
      message
      lastKey
    }
  }
`;
export const verifyDmaPartnerShip = /* GraphQL */ `
  query VerifyDmaPartnerShip($cooperativeId: ID!, $agrovetId: ID!) {
    verifyDmaPartnerShip(cooperativeId: $cooperativeId, agrovetId: $agrovetId) {
      data
      code
      message
    }
  }
`;
export const getDmastocks = /* GraphQL */ `
  query GetDmastocks {
    getDmastocks {
      dmastockId
      manufacturerUserId
      manufacturerName
      dmaUserId
      dmaId
      branchId
      creationDate
      description
      modificationDate
      name
      buying_price
      selling_price
      stockSubCategoryId
      unit
      type
      image
      quantity
      status
      weight
      bulkUploadProcessStatus
      bulkUploadProcessId
      rebates
    }
  }
`;
export const getDmastockById = /* GraphQL */ `
  query GetDmastockById($dmastockId: ID!) {
    getDmastockById(dmastockId: $dmastockId) {
      dmastockId
      manufacturerUserId
      manufacturerName
      dmaUserId
      dmaId
      branchId
      creationDate
      description
      modificationDate
      name
      buying_price
      selling_price
      stockSubCategoryId
      unit
      type
      image
      quantity
      status
      weight
      bulkUploadProcessStatus
      bulkUploadProcessId
      rebates
    }
  }
`;
export const getStockRequestById = /* GraphQL */ `
  query GetStockRequestById($stockRequestId: ID!) {
    getStockRequestById(stockRequestId: $stockRequestId) {
      status
      code
      data {
        stockRequestId
        branchId
        productId
        dmaId
        name
        type
        unit
        quantity
        status
        creationDate
        product {
          description
          productId
          name
          price
          subCategoryName
          unit
          type
          image
          quantity
          status
          weight
          manufacturerUserId
          creationDate
          modificationDate
          subCategoryId
          rebates
          dmaPrice
          company_name
        }
        branch {
          branchId
          dmaId
          branchName
          location
          isHQ
          phoneNumber
          mail
          ward
          creationDate
        }
      }
      message
    }
  }
`;
export const getFarmerOrders = /* GraphQL */ `
  query GetFarmerOrders {
    getFarmerOrders {
      farmerOrderId
      memberNumber
      farmerName
      totalCost
      paymentMethod
      product {
        quantity
        product {
          dmastockId
          manufacturerUserId
          manufacturerName
          dmaUserId
          dmaId
          branchId
          creationDate
          description
          modificationDate
          name
          buying_price
          selling_price
          stockSubCategoryId
          unit
          type
          image
          quantity
          status
          weight
          bulkUploadProcessStatus
          bulkUploadProcessId
          rebates
        }
        price
        selling_price
      }
      creationDate
      paymentStatus
      farmerOrderStatus
      dmaName
      dmaUserId
      dmaId
      branchId
      mpesaTransactionId
    }
  }
`;
export const getFarmerOrdersByFarmerMemberName = /* GraphQL */ `
  query GetFarmerOrdersByFarmerMemberName($memberName: String) {
    getFarmerOrdersByFarmerMemberName(memberName: $memberName) {
      farmerOrderId
      memberNumber
      farmerName
      totalCost
      paymentMethod
      product {
        quantity
        product {
          dmastockId
          manufacturerUserId
          manufacturerName
          dmaUserId
          dmaId
          branchId
          creationDate
          description
          modificationDate
          name
          buying_price
          selling_price
          stockSubCategoryId
          unit
          type
          image
          quantity
          status
          weight
          bulkUploadProcessStatus
          bulkUploadProcessId
          rebates
        }
        price
        selling_price
      }
      creationDate
      paymentStatus
      farmerOrderStatus
      dmaName
      dmaUserId
      dmaId
      branchId
      mpesaTransactionId
    }
  }
`;
export const getFarmerOrderById = /* GraphQL */ `
  query GetFarmerOrderById($farmerOrderId: ID!) {
    getFarmerOrderById(farmerOrderId: $farmerOrderId) {
      farmerOrderId
      memberNumber
      farmerName
      totalCost
      paymentMethod
      product {
        quantity
        product {
          dmastockId
          manufacturerUserId
          manufacturerName
          dmaUserId
          dmaId
          branchId
          creationDate
          description
          modificationDate
          name
          buying_price
          selling_price
          stockSubCategoryId
          unit
          type
          image
          quantity
          status
          weight
          bulkUploadProcessStatus
          bulkUploadProcessId
          rebates
        }
        price
        selling_price
      }
      creationDate
      paymentStatus
      farmerOrderStatus
      dmaName
      dmaUserId
      dmaId
      branchId
      mpesaTransactionId
    }
  }
`;
export const getDmaStockDashboard = /* GraphQL */ `
  query GetDmaStockDashboard {
    getDmaStockDashboard {
      dmasales
    }
  }
`;
export const getDmaStockDashboardChart = /* GraphQL */ `
  query GetDmaStockDashboardChart {
    getDmaStockDashboardChart
  }
`;
export const getUSSDFarmerOrder = /* GraphQL */ `
  query GetUSSDFarmerOrder(
    $startDate: String
    $endDate: String
    $page: Int
    $size: Int
    $orderType: OrderType
  ) {
    getUSSDFarmerOrder(
      startDate: $startDate
      endDate: $endDate
      page: $page
      size: $size
      orderType: $orderType
    ) {
      header {
        requestRefId
        responseCode
        responseMessage
        customerMessage
        timestamp
      }
      body {
        numberOfPages
        hasNextPage
        hasPreviousPage
        totalNumberOfElements
        data {
          orderId
          farmerMsisdn
          sessionId
          dmaShopName
          dmaFirstName
          dmaLastName
          dmaMsisdn
          region
          county
          subCounty
          ward
          orderType
          preOrderDuration
          preOrderDeliveryDate
          orderTotalCost
          orderPaymentStatus
          orderPaymentTransactionId
          orderTotalRebateCost
          rebatePaymentStatus
          rebatePaymentTransactionId
          createdDateTime
          updatedDateTime
          farmerModel {
            farmerId
            firstName
            lastName
            memberNumber
          }
          orderInputs {
            orderInputId
            inputId
            inputName
            packageQuantity
            price
            rebate
            inputCategory
            valueChain
            orderedQty
            totalInputCost
            totalRebateCost
          }
        }
      }
      responseID
      responseCode
      responseDesc
      status
      version
      lastModified
    }
  }
`;
export const getDmastockUpdateHistoryById = /* GraphQL */ `
  query GetDmastockUpdateHistoryById($dmastockId: ID!) {
    getDmastockUpdateHistoryById(dmastockId: $dmastockId) {
      dmastockId
      name
      bulkUploadProcessStatus
      updateHistory {
        user
        modificationDate
        newVersion {
          name
          quantity
          rebates
          selling_price
          unit
          buying_price
          bulkUploadProcessStatus
          bulkUploadProcessId
        }
        oldVersion {
          name
          quantity
          rebates
          selling_price
          unit
          buying_price
          bulkUploadProcessStatus
          bulkUploadProcessId
        }
      }
    }
  }
`;
export const fetchDmaStockReconciliationRecords = /* GraphQL */ `
  query FetchDmaStockReconciliationRecords(
    $from: String!
    $to: String!
    $dmaId: ID
    $dmaBranchId: ID
  ) {
    fetchDmaStockReconciliationRecords(
      from: $from
      to: $to
      dmaId: $dmaId
      dmaBranchId: $dmaBranchId
    ) {
      data {
        dmastockId
        dmaId
        branchId
        creditedCount
        verifiedCount
        purchasedCount
        collectedCount
        dma
        branch
        product
        date
        user
      }
      code
      message
    }
  }
`;
export const fetchDmaStockReconciliationRecordById = /* GraphQL */ `
  query FetchDmaStockReconciliationRecordById($dmaStockId: ID!) {
    fetchDmaStockReconciliationRecordById(dmaStockId: $dmaStockId) {
      dmastockId
      dmaId
      branchId
      creditedCount
      verifiedCount
      purchasedCount
      collectedCount
      dma
      branch
      product
      date
      user
    }
  }
`;
export const getManufacturerHubDetails = /* GraphQL */ `
  query GetManufacturerHubDetails($hubId: String!, $manufacturerId: String!) {
    getManufacturerHubDetails(hubId: $hubId, manufacturerId: $manufacturerId) {
      data {
        location
        hubName
        phoneNumber
        email
        hubId
        manufacturerId
        address
        creationDate
        modificationDate
      }
      code
      message
    }
  }
`;
export const getManufacturerHubs = /* GraphQL */ `
  query GetManufacturerHubs($manufacturerId: String!) {
    getManufacturerHubs(manufacturerId: $manufacturerId) {
      data {
        location
        hubName
        phoneNumber
        email
        hubId
        manufacturerId
        address
        creationDate
        modificationDate
      }
      code
      message
    }
  }
`;
export const getOrderById = /* GraphQL */ `
  query GetOrderById($orderId: ID!, $manufacturerId: ID!) {
    getOrderById(orderId: $orderId, manufacturerId: $manufacturerId) {
      orderId
      manufacturerId
      dmaId
      branchId
      orderItems {
        product {
          productId
          manufacturerUserId
          description
          name
          company_name
          price
          unit
          type
          image
          weight
          quantity
        }
        quantity
        availableQuantity
        receivedQuantity
        total
      }
      orderStatus
      paymentStatus
      paymentMethod
      creationDate
      modificationDate
      rebates
    }
  }
`;
export const fetchManufacturerPlacard = /* GraphQL */ `
  query FetchManufacturerPlacard {
    fetchManufacturerPlacard {
      manufacturersales
    }
  }
`;
export const fetchManufacturerChart = /* GraphQL */ `
  query FetchManufacturerChart {
    fetchManufacturerChart
  }
`;
export const pushQuery = /* GraphQL */ `
  query PushQuery($CheckoutRequestID: String) {
    pushQuery(CheckoutRequestID: $CheckoutRequestID) {
      MerchantRequestID
      CheckoutRequestID
      ResponseCode
      ResultDesc
      ResponseDescription
      ResultCode
      requestId
      errorCode
      errorMessage
    }
  }
`;
export const getPaymentDetails = /* GraphQL */ `
  query GetPaymentDetails($CheckoutRequestID: ID!) {
    getPaymentDetails(CheckoutRequestID: $CheckoutRequestID) {
      CheckoutRequestID
      MerchantRequestID
      ResultCode
      ResultDesc
      buyerId
      creationDate
      deliveryId
      CallbackMetadata {
        Item {
          Name
          Value
        }
      }
    }
  }
`;
export const getProducts = /* GraphQL */ `
  query GetProducts {
    getProducts {
      manufacturerUserId
      creationDate
      description
      productId
      modificationDate
      name
      price
      subCategoryId
      subCategoryName
      unit
      type
      image
      quantity
      status
      weight
      rebates
      dmaPrice
      company_name
      manufacturerId
      hubId
    }
  }
`;
export const getProductById = /* GraphQL */ `
  query GetProductById($productId: ID!) {
    getProductById(productId: $productId) {
      manufacturerUserId
      creationDate
      description
      productId
      modificationDate
      name
      price
      subCategoryId
      subCategoryName
      unit
      type
      image
      quantity
      status
      weight
      rebates
      dmaPrice
      company_name
      manufacturerId
      hubId
    }
  }
`;
export const getCategories = /* GraphQL */ `
  query GetCategories($ids: [ID!]) {
    getCategories(ids: $ids) {
      categoryId
      name
      subCategory {
        subCategoryId
        categoryId
        name
        creationDate
        modificationDate
        image
      }
      creationDate
      modificationDate
      image
    }
  }
`;
export const getCategoryById = /* GraphQL */ `
  query GetCategoryById($categoryId: ID!) {
    getCategoryById(categoryId: $categoryId) {
      categoryId
      name
      subCategory {
        subCategoryId
        categoryId
        name
        creationDate
        modificationDate
        image
      }
      creationDate
      modificationDate
      image
    }
  }
`;
export const getSubCategories = /* GraphQL */ `
  query GetSubCategories($ids: [ID!]) {
    getSubCategories(ids: $ids) {
      subCategoryId
      categoryId
      name
      creationDate
      modificationDate
      image
    }
  }
`;
export const getSubCategoryById = /* GraphQL */ `
  query GetSubCategoryById($subCategoryId: ID!) {
    getSubCategoryById(subCategoryId: $subCategoryId) {
      subCategoryId
      categoryId
      name
      creationDate
      modificationDate
      image
    }
  }
`;
export const searchOrders = /* GraphQL */ `
  query SearchOrders(
    $q: String
    $limit: Int
    $page: Int
    $manufacturerId: String
    $dmaId: String
    $orderId: String
    $paymentStatus: String
    $orderStatus: String
    $creationDate: String
    $userId: String
    $hubId: String
    $gte: String
    $lte: String
  ) {
    searchOrders(
      q: $q
      limit: $limit
      page: $page
      manufacturerId: $manufacturerId
      dmaId: $dmaId
      orderId: $orderId
      paymentStatus: $paymentStatus
      orderStatus: $orderStatus
      creationDate: $creationDate
      userId: $userId
      hubId: $hubId
      gte: $gte
      lte: $lte
    ) {
      totalCount
      page
      result {
        orderId
        dmaId
        userId
        dmaName
        dmaLocation
        dmaPhone
        manufacturerId
        orderStatus
        paymentStatus
        paymentMethod
        mpesaTransactionId
        creationDate
        orderItems {
          product {
            productId
            manufacturerUserId
            description
            company_name
            name
            price
            unit
            type
            image
            weight
            quantity
          }
          quantity
          availableQuantity
          receivedQuantity
          total
        }
        company_name
        rebates
        dma {
          businessName
          dmaType
          location
          phoneNumber
          ward
          creationDate
        }
        hub {
          hubName
          hubId
        }
      }
      aggregations
    }
  }
`;
export const searchOrdersAdmin = /* GraphQL */ `
  query SearchOrdersAdmin(
    $q: String
    $limit: Int
    $page: Int
    $manufacturerId: String
    $dmaId: String
    $orderId: String
    $paymentStatus: String
    $orderStatus: String
    $creationDate: String
    $userId: String
    $hubId: String
    $gte: String
    $lte: String
  ) {
    searchOrdersAdmin(
      q: $q
      limit: $limit
      page: $page
      manufacturerId: $manufacturerId
      dmaId: $dmaId
      orderId: $orderId
      paymentStatus: $paymentStatus
      orderStatus: $orderStatus
      creationDate: $creationDate
      userId: $userId
      hubId: $hubId
      gte: $gte
      lte: $lte
    ) {
      totalCount
      page
      result {
        orderId
        dmaId
        userId
        dmaName
        dmaLocation
        dmaPhone
        manufacturerId
        orderStatus
        paymentStatus
        paymentMethod
        mpesaTransactionId
        creationDate
        orderItems {
          product {
            productId
            manufacturerUserId
            description
            company_name
            name
            price
            unit
            type
            image
            weight
            quantity
          }
          quantity
          availableQuantity
          receivedQuantity
          total
        }
        company_name
        rebates
        dma {
          businessName
          dmaType
          location
          phoneNumber
          ward
          creationDate
        }
        hub {
          hubName
          hubId
        }
      }
      aggregations
    }
  }
`;
export const searchProducts = /* GraphQL */ `
  query SearchProducts(
    $q: String
    $limit: Int
    $page: Int
    $upper: String
    $lower: String
    $status: String
    $type: String
    $company_name: [String]
    $manufacturerUserId: String
    $category: [String]
    $hubId: [String]
  ) {
    searchProducts(
      q: $q
      limit: $limit
      page: $page
      upper: $upper
      lower: $lower
      status: $status
      type: $type
      company_name: $company_name
      manufacturerUserId: $manufacturerUserId
      category: $category
      hubId: $hubId
    ) {
      totalCount
      page
      result {
        creationDate
        description
        productId
        modificationDate
        name
        price
        subCategoryId
        unit
        type
        image
        quantity
        status
        manufacturerUserId
        weight
        category
        subCategory
        company_name
        dmaPrice
        rebates
        manufacturerId
        hubId
      }
      aggregations
    }
  }
`;
export const searchProductsAdmin = /* GraphQL */ `
  query SearchProductsAdmin(
    $q: String
    $limit: Int
    $page: Int
    $upper: String
    $lower: String
    $status: String
    $type: String
    $company_name: [String]
    $manufacturerUserId: String
    $category: [String]
    $manufacturerId: String
    $hubId: [String]
  ) {
    searchProductsAdmin(
      q: $q
      limit: $limit
      page: $page
      upper: $upper
      lower: $lower
      status: $status
      type: $type
      company_name: $company_name
      manufacturerUserId: $manufacturerUserId
      category: $category
      manufacturerId: $manufacturerId
      hubId: $hubId
    ) {
      totalCount
      page
      result {
        creationDate
        description
        productId
        modificationDate
        name
        price
        subCategoryId
        unit
        type
        image
        quantity
        status
        manufacturerUserId
        weight
        category
        subCategory
        company_name
        dmaPrice
        rebates
        manufacturerId
        hubId
      }
      aggregations
    }
  }
`;
export const searchProductsCat = /* GraphQL */ `
  query SearchProductsCat(
    $category: [String]
    $limit: Int
    $page: Int
    $upper: String
    $lower: String
  ) {
    searchProductsCat(
      category: $category
      limit: $limit
      page: $page
      upper: $upper
      lower: $lower
    ) {
      totalCount
      page
      result {
        creationDate
        description
        productId
        modificationDate
        name
        price
        subCategoryId
        unit
        type
        image
        quantity
        status
        manufacturerUserId
        weight
        category
        subCategory
        company_name
        dmaPrice
        rebates
        manufacturerId
        hubId
      }
      aggregations
    }
  }
`;
export const searchDmastock = /* GraphQL */ `
  query SearchDmastock(
    $q: String
    $limit: Int
    $page: Int
    $upper: String
    $lower: String
    $status: String
    $type: String
    $company_name: String
    $dmaId: String
    $branchId: String
    $userId: String
    $category: [String]
    $name: String
    $unit: String
  ) {
    searchDmastock(
      q: $q
      limit: $limit
      page: $page
      upper: $upper
      lower: $lower
      status: $status
      type: $type
      company_name: $company_name
      dmaId: $dmaId
      branchId: $branchId
      userId: $userId
      category: $category
      name: $name
      unit: $unit
    ) {
      totalCount
      page
      result {
        selling_price
        dmaUserId
        dmastockId
        buying_price
        productId
        name
        quantity
        image
        description
        manufacturerUserId
        manufacturerName
        creationDate
        stockSubCategoryId
        stockcategoryId
        id
        weight
        type
        unit
        bulkUploadProcessStatus
        bulkUploadProcessId
      }
      aggregations
    }
  }
`;
export const searchDmastockAdmin = /* GraphQL */ `
  query SearchDmastockAdmin(
    $q: String
    $limit: Int
    $page: Int
    $upper: String
    $lower: String
    $status: String
    $type: String
    $company_name: String
    $dmaId: String
    $branchId: String
    $userId: String
    $category: [String]
    $bulkUploadProcessId: String
    $bulkUploadProcessStatus: BulkUploadStatus
  ) {
    searchDmastockAdmin(
      q: $q
      limit: $limit
      page: $page
      upper: $upper
      lower: $lower
      status: $status
      type: $type
      company_name: $company_name
      dmaId: $dmaId
      branchId: $branchId
      userId: $userId
      category: $category
      bulkUploadProcessId: $bulkUploadProcessId
      bulkUploadProcessStatus: $bulkUploadProcessStatus
    ) {
      totalCount
      page
      result {
        selling_price
        dmaUserId
        dmastockId
        buying_price
        productId
        name
        quantity
        image
        description
        manufacturerUserId
        manufacturerName
        creationDate
        stockSubCategoryId
        stockcategoryId
        id
        weight
        type
        unit
        bulkUploadProcessStatus
        bulkUploadProcessId
      }
      aggregations
    }
  }
`;
export const searchStockMobile = /* GraphQL */ `
  query SearchStockMobile(
    $query: String
    $limit: Int
    $page: Int
    $upper: String
    $lower: String
    $type: String
    $company_name: String
    $dmaId: String
    $category: [String]
  ) {
    searchStockMobile(
      query: $query
      limit: $limit
      page: $page
      upper: $upper
      lower: $lower
      type: $type
      company_name: $company_name
      dmaId: $dmaId
      category: $category
    ) {
      totalCount
      page
      result {
        selling_price
        dmaUserId
        dmastockId
        buying_price
        productId
        name
        quantity
        image
        description
        manufacturerUserId
        manufacturerName
        creationDate
        stockSubCategoryId
        stockcategoryId
        id
        weight
        type
        unit
        bulkUploadProcessStatus
        bulkUploadProcessId
      }
      aggregations
    }
  }
`;
export const searchFarmerOrders = /* GraphQL */ `
  query SearchFarmerOrders(
    $q: String
    $limit: Int
    $page: Int
    $dmaId: String
    $branchId: String
    $farmerNumber: String
    $farmerorderId: String
    $paymentStatus: String
    $orderStatus: String
    $dmaName: String
    $origin: String
    $deliveryType: String
    $fromDate: String
    $toDate: String
    $farmerName: String
  ) {
    searchFarmerOrders(
      q: $q
      limit: $limit
      page: $page
      dmaId: $dmaId
      branchId: $branchId
      farmerNumber: $farmerNumber
      farmerorderId: $farmerorderId
      paymentStatus: $paymentStatus
      orderStatus: $orderStatus
      dmaName: $dmaName
      origin: $origin
      deliveryType: $deliveryType
      fromDate: $fromDate
      toDate: $toDate
      farmerName: $farmerName
    ) {
      totalCount
      page
      result {
        farmerOrderId
        memberNumber
        dmaId
        dmaName
        rebates
        branchId
        paymentMethod
        farmerOrderStatus
        deliveryType
        origin
        creationDate
        farmerName
        farmerNumber
        paymentStatus
        totalCost
        id
        mpesaTransactionId
        product {
          quantity
          total
          product {
            quantity
            dmastockId
            price
            selling_price
            productId
            manufacturerName
            buying_price
            weight
            manufacturerUserId
            description
            creationDate
            type
            unit
            total
            dmaUserId
            company_name
            name
            status
            image
          }
        }
      }
      aggregations
    }
  }
`;
export const searchFarmerOrdersAdmin = /* GraphQL */ `
  query SearchFarmerOrdersAdmin(
    $q: String
    $limit: Int
    $page: Int
    $dmaId: String
    $branchId: String
    $farmerNumber: String
    $farmerorderId: String
    $paymentStatus: String
    $orderStatus: String
    $dmaName: String
    $origin: String
    $deliveryType: String
    $fromDate: String
    $toDate: String
    $farmerName: String
  ) {
    searchFarmerOrdersAdmin(
      q: $q
      limit: $limit
      page: $page
      dmaId: $dmaId
      branchId: $branchId
      farmerNumber: $farmerNumber
      farmerorderId: $farmerorderId
      paymentStatus: $paymentStatus
      orderStatus: $orderStatus
      dmaName: $dmaName
      origin: $origin
      deliveryType: $deliveryType
      fromDate: $fromDate
      toDate: $toDate
      farmerName: $farmerName
    ) {
      totalCount
      page
      result {
        farmerOrderId
        memberNumber
        dmaId
        dmaName
        rebates
        branchId
        paymentMethod
        farmerOrderStatus
        deliveryType
        origin
        creationDate
        farmerName
        farmerNumber
        paymentStatus
        totalCost
        id
        mpesaTransactionId
        product {
          quantity
          total
          product {
            quantity
            dmastockId
            price
            selling_price
            productId
            manufacturerName
            buying_price
            weight
            manufacturerUserId
            description
            creationDate
            type
            unit
            total
            dmaUserId
            company_name
            name
            status
            image
          }
        }
      }
      aggregations
    }
  }
`;
export const exportFarmerOrdersAdmin = /* GraphQL */ `
  query ExportFarmerOrdersAdmin($gte: String!, $lte: String!) {
    exportFarmerOrdersAdmin(gte: $gte, lte: $lte) {
      totalCount
      page
      result {
        farmerOrderId
        memberNumber
        dmaId
        dmaName
        rebates
        branchId
        paymentMethod
        farmerOrderStatus
        deliveryType
        origin
        creationDate
        farmerName
        farmerNumber
        paymentStatus
        totalCost
        id
        mpesaTransactionId
        product {
          quantity
          total
          product {
            quantity
            dmastockId
            price
            selling_price
            productId
            manufacturerName
            buying_price
            weight
            manufacturerUserId
            description
            creationDate
            type
            unit
            total
            dmaUserId
            company_name
            name
            status
            image
          }
        }
      }
      aggregations
    }
  }
`;
export const searchFarmerDelivery = /* GraphQL */ `
  query SearchFarmerDelivery(
    $q: String
    $deliveryId: String
    $farmerId: String
    $kyc_flow_state: String
    $collectionCenterId: String
    $transporterId: String
    $containerNumber: String
    $status: String
    $deliveryDate: String
    $page: Int
    $limit: Int
  ) {
    searchFarmerDelivery(
      q: $q
      deliveryId: $deliveryId
      farmerId: $farmerId
      kyc_flow_state: $kyc_flow_state
      collectionCenterId: $collectionCenterId
      transporterId: $transporterId
      containerNumber: $containerNumber
      status: $status
      deliveryDate: $deliveryDate
      page: $page
      limit: $limit
    ) {
      totalCount
      page
      result {
        product
        collectionCenterId
        containerNumber
        deliveryDate
        deliveryId
        status
        unitPrice
        quantity
        transporterId
        farmerId
        transporter {
          name
          location
          phone
        }
        farmer {
          member_number
          route
          supply_category
          MCC
          gender
          farmerId
          last_name
          first_name
          village
          dmaId
          national_id
          phone
          creationDate
        }
        collectionCentre {
          location
          collectionCentreId
          creationDate
          centreName
          dmaId
          centreId
        }
      }
      aggregations
    }
  }
`;
export const searchFarmerDeliveryApikey = /* GraphQL */ `
  query SearchFarmerDeliveryApikey(
    $q: String
    $deliveryId: String
    $farmerId: String
    $kyc_flow_state: String
    $collectionCenterId: String
    $transporterId: String
    $containerNumber: String
    $status: String
    $deliveryDate: String
    $page: Int
    $limit: Int
  ) {
    searchFarmerDeliveryApikey(
      q: $q
      deliveryId: $deliveryId
      farmerId: $farmerId
      kyc_flow_state: $kyc_flow_state
      collectionCenterId: $collectionCenterId
      transporterId: $transporterId
      containerNumber: $containerNumber
      status: $status
      deliveryDate: $deliveryDate
      page: $page
      limit: $limit
    ) {
      totalCount
      page
      result {
        product
        collectionCenterId
        containerNumber
        deliveryDate
        deliveryId
        status
        unitPrice
        quantity
        transporterId
        farmerId
        transporter {
          name
          location
          phone
        }
        farmer {
          member_number
          route
          supply_category
          MCC
          gender
          farmerId
          last_name
          first_name
          village
          dmaId
          national_id
          phone
          creationDate
        }
        collectionCentre {
          location
          collectionCentreId
          creationDate
          centreName
          dmaId
          centreId
        }
      }
      aggregations
    }
  }
`;
export const searchFarmer = /* GraphQL */ `
  query SearchFarmer(
    $q: String
    $dmaId: String
    $page: Int
    $limit: Int
    $gte: String
    $lte: String
    $firstName: String
    $lastName: String
    $middleName: String
    $gender: String
    $village: String
  ) {
    searchFarmer(
      q: $q
      dmaId: $dmaId
      page: $page
      limit: $limit
      gte: $gte
      lte: $lte
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      gender: $gender
      village: $village
    ) {
      totalCount
      page
      result {
        member_number
        route
        supply_category
        MCC
        gender
        farmerId
        last_name
        first_name
        village
        dmaId
        national_id
        phone
        creationDate
      }
      aggregations
      code
      message
    }
  }
`;
export const searchRequestStock = /* GraphQL */ `
  query SearchRequestStock(
    $q: String
    $limit: Int
    $page: Int
    $dateFrom: String
    $dateTo: String
    $status: String
    $type: String
    $dmaId: String
    $branchId: String
  ) {
    searchRequestStock(
      q: $q
      limit: $limit
      page: $page
      dateFrom: $dateFrom
      dateTo: $dateTo
      status: $status
      type: $type
      dmaId: $dmaId
      branchId: $branchId
    ) {
      totalCount
      page
      result {
        stockRequestId
        branchId
        productId
        dmaId
        name
        type
        unit
        quantity
        status
        creationDate
        product {
          description
          productId
          name
          price
          subCategoryName
          unit
          type
          image
          quantity
          status
          weight
          manufacturerUserId
          creationDate
          modificationDate
          subCategoryId
          rebates
          dmaPrice
          company_name
        }
        branch {
          branchId
          dmaId
          branchName
          location
          isHQ
          phoneNumber
          mail
          ward
          creationDate
        }
      }
      aggregations
    }
  }
`;
export const dmaSalesStatistics = /* GraphQL */ `
  query DmaSalesStatistics {
    dmaSalesStatistics {
      dmaSales
      topDmaSellers {
        dmaId
        dmaName
        totalSalesAmount
        totalItemsSold
      }
      monthlyDmaSales {
        monthLong
        monthShort
        creationDateFrom
        creationDateTo
        monthSales
      }
    }
  }
`;
export const manufacturerSalesStatistics = /* GraphQL */ `
  query ManufacturerSalesStatistics {
    manufacturerSalesStatistics {
      manSales
      monthlyManufacturerSales {
        monthLong
        monthShort
        creationDateFrom
        creationDateTo
        monthSales
      }
    }
  }
`;
export const dmaStatistics = /* GraphQL */ `
  query DmaStatistics {
    dmaStatistics {
      dmaStats
      monthlyDmaStats {
        monthLong
        monthShort
        creationDateFrom
        creationDateTo
        manStats
        monthStats
      }
    }
  }
`;
export const adminDmaMonthlyStatistics = /* GraphQL */ `
  query AdminDmaMonthlyStatistics($dmaId: String!) {
    adminDmaMonthlyStatistics(dmaId: $dmaId) {
      monthlySales {
        monthLong
        monthShort
        creationDateFrom
        creationDateTo
        monthSales
      }
    }
  }
`;
export const dmaMonthlyStatistics = /* GraphQL */ `
  query DmaMonthlyStatistics($dmaId: String, $branchId: String) {
    dmaMonthlyStatistics(dmaId: $dmaId, branchId: $branchId) {
      monthlySales {
        monthLong
        monthShort
        creationDateFrom
        creationDateTo
        monthSales
      }
      salesAnalysis {
        total
        approved
        rejected
        pending
      }
      purchaseAnalysis {
        monthLong
        monthShort
        creationDateFrom
        creationDateTo
        monthSales
      }
      bestSellingProducts {
        key
        doc_count
        product {
          dmastockId
          manufacturerUserId
          manufacturerName
          dmaUserId
          dmaId
          branchId
          creationDate
          description
          modificationDate
          name
          buying_price
          selling_price
          stockSubCategoryId
          unit
          type
          image
          quantity
          status
          weight
          bulkUploadProcessStatus
          bulkUploadProcessId
          rebates
        }
      }
      monthlyInputPerformance {
        monthLong
        monthShort
        creationDateFrom
        creationDateTo
        monthPerformance {
          key
          doc_count
        }
      }
    }
  }
`;
export const dmaDailyStatistics = /* GraphQL */ `
  query DmaDailyStatistics($dmaId: String, $branchId: String) {
    dmaDailyStatistics(dmaId: $dmaId, branchId: $branchId) {
      paidOrdersCountToday
      newOrdersCountToday {
        total
        approved
        rejected
        pending
      }
      totalSalesToday
      totalSalesCurrentWeek {
        date
        dayName
        daySales
      }
    }
  }
`;
export const adminManufacturerMonthlyStatistics = /* GraphQL */ `
  query AdminManufacturerMonthlyStatistics($manufacturerId: String!) {
    adminManufacturerMonthlyStatistics(manufacturerId: $manufacturerId) {
      monthlySales {
        monthLong
        monthShort
        creationDateFrom
        creationDateTo
        monthSales
      }
      salesAnalysis {
        total
        approved
        rejected
        pending
      }
      bestSellingProducts {
        key
        doc_count
        product {
          dmastockId
          manufacturerUserId
          manufacturerName
          dmaUserId
          dmaId
          branchId
          creationDate
          description
          modificationDate
          name
          buying_price
          selling_price
          stockSubCategoryId
          unit
          type
          image
          quantity
          status
          weight
          bulkUploadProcessStatus
          bulkUploadProcessId
          rebates
        }
      }
      monthlyInputPerformance {
        monthLong
        monthShort
        creationDateFrom
        creationDateTo
        monthPerformance {
          key
          doc_count
        }
      }
    }
  }
`;
export const manufacturerMonthlyStatistics = /* GraphQL */ `
  query ManufacturerMonthlyStatistics($manufacturerId: String) {
    manufacturerMonthlyStatistics(manufacturerId: $manufacturerId) {
      monthlySales {
        monthLong
        monthShort
        creationDateFrom
        creationDateTo
        monthSales
      }
      salesAnalysis {
        total
        approved
        rejected
        pending
      }
      bestSellingProducts {
        key
        doc_count
        product {
          dmastockId
          manufacturerUserId
          manufacturerName
          dmaUserId
          dmaId
          branchId
          creationDate
          description
          modificationDate
          name
          buying_price
          selling_price
          stockSubCategoryId
          unit
          type
          image
          quantity
          status
          weight
          bulkUploadProcessStatus
          bulkUploadProcessId
          rebates
        }
      }
      monthlyInputPerformance {
        monthLong
        monthShort
        creationDateFrom
        creationDateTo
        monthPerformance {
          key
          doc_count
        }
      }
    }
  }
`;
export const manufacturerDailyStatistics = /* GraphQL */ `
  query ManufacturerDailyStatistics($manufacturerId: String) {
    manufacturerDailyStatistics(manufacturerId: $manufacturerId) {
      paidOrdersCountToday
      newOrdersCountToday {
        total
        approved
        rejected
        pending
      }
      totalSalesToday
      totalSalesCurrentWeek {
        date
        dayName
        daySales
      }
    }
  }
`;
export const searchManufacturer = /* GraphQL */ `
  query SearchManufacturer(
    $q: String
    $location: String
    $status: String
    $manufacturerId: String
    $emailAddress: String
    $page: Int
    $limit: Int
  ) {
    searchManufacturer(
      q: $q
      location: $location
      status: $status
      manufacturerId: $manufacturerId
      emailAddress: $emailAddress
      page: $page
      limit: $limit
    ) {
      data {
        totalCount
        page
        result {
          companyName
          location
          name
          phoneNumber
          address
          email
          manufacturerId
          enabled
          status
        }
        aggregations
      }
      code
      message
    }
  }
`;
export const searchHub = /* GraphQL */ `
  query SearchHub(
    $q: String
    $manufacturerId: String
    $page: Int
    $limit: Int
  ) {
    searchHub(
      q: $q
      manufacturerId: $manufacturerId
      page: $page
      limit: $limit
    ) {
      data {
        totalCount
        page
        result {
          location
          hubName
          phoneNumber
          address
          email
          hubId
          creationDate
        }
        aggregations
      }
      code
      message
    }
  }
`;
export const searchUser = /* GraphQL */ `
  query SearchUser(
    $dmaId: ID
    $branchId: ID
    $collectionCentreId: ID
    $manufacturerId: ID
    $hubId: ID
    $group: String
    $page: Int
    $limit: Int
  ) {
    searchUser(
      dmaId: $dmaId
      branchId: $branchId
      collectionCentreId: $collectionCentreId
      manufacturerId: $manufacturerId
      hubId: $hubId
      group: $group
      page: $page
      limit: $limit
    ) {
      totalCount
      page
      result {
        approvedAt
        created
        dmaId
        branchId
        manufacturerId
        collectionCentreId
        hubId
        name
        username
        phoneNumber
        email
        companyName
        group
        kycFlowState
        location
        nationalId
        ward
        enabled
      }
    }
  }
`;
export const searchDmaAdmin = /* GraphQL */ `
  query SearchDmaAdmin(
    $q: String
    $ward: String
    $businessName: String
    $kyc_flow_state: String
    $dmaType: String
    $dmaId: String
    $page: Int
    $limit: Int
    $regions: [String]
    $counties: [String]
    $subCounties: [String]
  ) {
    searchDmaAdmin(
      q: $q
      ward: $ward
      businessName: $businessName
      kyc_flow_state: $kyc_flow_state
      dmaType: $dmaType
      dmaId: $dmaId
      page: $page
      limit: $limit
      regions: $regions
      counties: $counties
      subCounties: $subCounties
    ) {
      totalCount
      page
      result {
        dmaId
        businessName
        creationDate
        dmaType
        location
        kyc_flow_state
        mail
        phoneNumber
        ward
        wardRegion
        wardCounty
        wardSubcounty
      }
      aggregations
    }
  }
`;
export const searchDmaApikey = /* GraphQL */ `
  query SearchDmaApikey(
    $q: String
    $businessName: String
    $kyc_flow_state: String
    $dmaType: String
    $dmaId: String
    $wards: [String]
    $regions: [String]
    $counties: [String]
    $subCounties: [String]
    $locations: [String]
    $page: Int
    $limit: Int
  ) {
    searchDmaApikey(
      q: $q
      businessName: $businessName
      kyc_flow_state: $kyc_flow_state
      dmaType: $dmaType
      dmaId: $dmaId
      wards: $wards
      regions: $regions
      counties: $counties
      subCounties: $subCounties
      locations: $locations
      page: $page
      limit: $limit
    ) {
      totalCount
      page
      result {
        dmaId
        businessName
        creationDate
        dmaType
        location
        kyc_flow_state
        mail
        phoneNumber
        ward
        wardRegion
        wardCounty
        wardSubcounty
      }
      aggregations
    }
  }
`;
export const exportDmaAdmin = /* GraphQL */ `
  query ExportDmaAdmin($gte: String!, $lte: String!) {
    exportDmaAdmin(gte: $gte, lte: $lte) {
      totalCount
      page
      result {
        dmaId
        businessName
        creationDate
        dmaType
        location
        kyc_flow_state
        mail
        phoneNumber
        ward
        wardRegion
        wardCounty
        wardSubcounty
      }
      aggregations
    }
  }
`;
