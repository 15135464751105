import { CheckOutlined, EditOutlined, EyeOutlined, MoreOutlined } from '@ant-design/icons';
import { GraphQLResult } from '@aws-amplify/api';
import { Col, Pagination, Popover, Row, Select, Table } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';

import {
    ApproveDmastockMutation,
    DeleteAllDmaStockMutation,
    DeleteDmaStockListMutation,
    SearchDmastockAdminQuery,
    UpdateListDmastockMutation,
} from '../../../API';
import { Search } from '../../../components/inputs/Search';
import { approveDmastock, deleteAllDmaStock, deleteDmaStockList, updateListDmastock } from '../../../graphql/mutations';
import { searchDmastockAdmin } from '../../../graphql/queries';
import { useDma } from '../useDma';
import DmaStockReconciliationExport from './DmaStockReconciliationExport';
interface OrderProps {
    userId: string;
}

interface Filter {
    value: string;
    name: string;
    key: string;
}

interface SearchDmaRequest {
    dmaId: string;
    page: number;
    limit: number;
    q: string;
    bulkUploadProcessStatus?: string;
}

const getColumns = (onApprove: (data: any) => unknown, dmaId: string) => [
    {
        title: 'Product ID',
        dataIndex: 'orderId',
        key: 'orderId',
    },
    {
        title: 'Product Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Unit Price(Ksh)',
        dataIndex: 'amount',
        key: 'amount',
    },
    {
        title: 'Unit',
        dataIndex: 'quantity',
        key: 'quantity',
    },
    {
        title: 'Quantity Type',
        dataIndex: 'unit',
        key: 'unit',
    },
    {
        title: 'Date Added',
        dataIndex: 'dateOfOrder',
        key: 'dateOfOrder',
        // render: (_: any, data: any) => {
        //     console.log(data);
        //     return <div className="active-status">{data}</div>;
        // },
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_: any, data: any) => {
            return <div className={data?.status?.toLowerCase() || 'pending'}>{data?.status || 'PENDING'}</div>;
        },
    },
    {
        dataIndex: 'action',
        key: 'action',
        render: (_: any, data: any) => {
            const content = (
                <>
                    {data.status !== 'APPROVED' && (
                        <div className="mb-3" style={{ cursor: 'pointer' }} onClick={() => onApprove(data)}>
                            <CheckOutlined /> &nbsp; Approve
                        </div>
                    )}
                    <div>
                        <NavLink to={`/dma/${dmaId}/stock/${data.id}`} style={{ color: 'black' }}>
                            <div style={{ cursor: 'pointer' }} className="mb-3">
                                <EditOutlined /> &nbsp; Update Stock
                            </div>
                        </NavLink>
                    </div>
                    {data.status === 'APPROVED' && (
                        <div>
                            <NavLink to={`/dma/stock/${data.id}/reconciliation`} style={{ color: 'black' }}>
                                <div style={{ cursor: 'pointer' }} className="mb-3">
                                    <EditOutlined /> &nbsp; Stock Reconciliation
                                </div>
                            </NavLink>
                        </div>
                    )}
                    <div>
                        <NavLink to={`/dma/${dmaId}/stock/${data.id}/history`} style={{ color: 'black' }}>
                            <div style={{ cursor: 'pointer' }} className="mb-3">
                                <EyeOutlined /> &nbsp; View Update History
                            </div>
                        </NavLink>
                    </div>
                </>
            );
            return (
                <div>
                    <Popover placement="leftTop" content={content} trigger="click">
                        <MoreOutlined />
                    </Popover>
                </div>
            );
        },
    },
];

const DEFAULT_PAGE_LIMIT = 10;

const statusOptions: Filter[] = [
    { value: '', name: 'Select status', key: 'select-status' },
    { value: 'APPROVED', name: 'Approved', key: 'Approved' },
    { value: 'PENDING', name: 'Pending', key: 'Pending' },
];

export const StockTable: React.FC<OrderProps> = ({ userId }) => {
    const [dataSource, setDataSource] = useState<any>([]);
    const [total, setTotal] = useState(0);
    const [limit] = useState(DEFAULT_PAGE_LIMIT);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [selectedRowItems, setSelectedRowItems] = useState<any>([]);
    const [filters, setFilters] = useState({
        searchText: '',
        status: '',
    });
    const [dmaBranches, setDmaBranches] = useState<any>();

    const { Option } = Select;

    const BULK_ACTION_SUCCESS_DESCRIPTION =
        'N.B. This bulk operation may take time to reflect in the UI, please refresh the page in a while again';

    const deduceBulkActionDescriptionModalText = (actionExecuted: string): string => {
        return `<div>
                    <div class='mb-2'>Successfully ${actionExecuted} stock!</div>
                    <span class='mt-3' style='font-size: 14px'>${BULK_ACTION_SUCCESS_DESCRIPTION}</span>
                </div>`;
    };
    const [branchId, setBranchId] = useState('');
    const { getDMABranches } = useDma();

    const handleSearchText = (value: string) => {
        setFilters({ ...filters, searchText: value });
        debouncedSearch(value);
        setPage(1);
    };

    const handleStatusChange = (status: string) => {
        setFilters({ ...filters, status });
        getOrders(1, 10, filters.searchText, status);
        setPage(1);
    };

    const clearFilters = () => {
        const { searchText, status } = filters;
        if (!searchText && !status) return;

        setFilters({
            searchText: '',
            status: '',
        });
        getOrders(1, 10, '', '');
    };

    const debouncedSearch = useCallback(
        debounce((searchValue) => getOrders(1, 10, searchValue, filters.status)),
        [filters.status],
    );

    //Fetch DMA stock
    const getOrders = async (page = 1, limit = 10, q = '', status = '') => {
        try {
            setLoading(true);
            const requestVariables: SearchDmaRequest = {
                dmaId: userId,
                page,
                limit,
                q,
            };

            if (!!status) {
                requestVariables.bulkUploadProcessStatus = status;
            }
            const query = graphqlOperation(searchDmastockAdmin, requestVariables);

            const { data } = (await API.graphql(query)) as GraphQLResult<SearchDmastockAdminQuery>;
            setTotal(data?.searchDmastockAdmin?.totalCount || 0);

            // const parsedAgg = JSON.parse(data?.searchDmastockAdmin?.aggregations || '');
            const mappedData = data?.searchDmastockAdmin?.result?.map((data) => {
                return {
                    id: data?.id,
                    dateOfOrder: new Date(data?.creationDate as string).toDateString(),
                    orderId: `${data?.dmastockId?.slice(0, 7)}...`,
                    amount: data?.selling_price,
                    status: data?.bulkUploadProcessStatus,
                    name: data?.name,
                    quantity: data?.quantity,
                    unit: data?.unit,
                };
            });
            if (mappedData) {
                setDataSource(mappedData);
            }
            setLoading(false);
            const details = await getDMABranches(userId);
            if (details) {
                setDmaBranches(details);
            }
            const branch = details?.find((branch: any) => branch.isHQ === 'true');
            setBranchId(branch?.branchId as string);
        } catch (e: any) {
            console.log(e);
            setLoading(false);
            return {
                message: e.message,
            };
        }
    };

    //ApproveDma stock;

    const approveStock = async (id?: string) => {
        Swal.fire({
            title: `Approve ${selectedRowKeys.length > 1 ? 'Stocks' : 'Stock'}`,
            text: `Are you sure you want approve ${selectedRowKeys.length > 1 ? 'Stocks' : 'Stock'}?`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#2cb34a',
        }).then(async (results: any) => {
            if (results.value) {
                Swal.fire({
                    title: 'Processing Request ...',
                    allowOutsideClick: false,
                });
                Swal.showLoading();
                setUpdating(true);
                try {
                    const ids = selectedRowKeys;
                    if (id) {
                        ids.push(id);
                    }
                    const mutation = graphqlOperation(approveDmastock, {
                        bulkAction: 'APPROVED',
                        bulkUploadId: ids,
                    });
                    (await API.graphql(mutation)) as GraphQLResult<ApproveDmastockMutation>;
                    setUpdating(false);
                    return Swal.fire(
                        'Approved!',
                        selectedRowKeys.length > 1
                            ? deduceBulkActionDescriptionModalText('updated')
                            : 'Successfully updated stock!',
                        'success',
                    ).then(() => {
                        getOrders();
                        setSelectedRowKeys([]);
                        setSelectedRowItems([]);
                    });
                } catch (error) {
                    setUpdating(false);
                    console.log({ error });
                    return Swal.fire('Approved!', 'We could not approve this stock at this time!', 'error');
                }
            }
        });
    };

    const approveStockList = async () => {
        Swal.fire({
            title: `Approve ${selectedRowKeys.length > 1 ? 'Stocks' : 'Stock'}`,
            text: `Are you sure you want approve ${selectedRowKeys.length > 1 ? 'Stocks' : 'Stock'}?`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#2cb34a',
        }).then(async (results: any) => {
            if (results.value) {
                Swal.fire({
                    title: 'Processing Request ...',
                    allowOutsideClick: false,
                });
                Swal.showLoading();
                setUpdating(true);
                try {
                    const mutation = graphqlOperation(updateListDmastock, {
                        bulkAction: 'APPROVED',
                        branchId,
                    });
                    (await API.graphql(mutation)) as GraphQLResult<UpdateListDmastockMutation>;
                    setUpdating(false);
                    return Swal.fire(
                        'Approved!',
                        dataSource.length > 1
                            ? deduceBulkActionDescriptionModalText('updated')
                            : 'Successfully updated stock!',
                        'success',
                    ).then(() => {
                        getOrders();
                        setSelectedRowKeys([]);
                        setSelectedRowItems([]);
                    });
                } catch (error) {
                    setUpdating(false);
                    console.log({ error });
                    return Swal.fire('Approved!', 'We could not approve this stock at this time!', 'error');
                }
            }
        });
    };

    const deleteStock = async () => {
        Swal.fire({
            title: `Delete ${selectedRowKeys.length > 1 ? 'Stocks' : 'Stock'}`,
            text: `Are you sure you want delete ${selectedRowKeys.length > 1 ? 'Stocks' : 'Stock'}?`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#2cb34a',
        }).then(async (results: any) => {
            if (results.value) {
                Swal.fire({
                    title: 'Processing Request ...',
                    allowOutsideClick: false,
                });
                Swal.showLoading();
                setDeleting(true);
                try {
                    const mutation = graphqlOperation(deleteDmaStockList, {
                        stockIds: selectedRowKeys,
                    });
                    const result = (await API.graphql(mutation)) as GraphQLResult<DeleteDmaStockListMutation>;
                    const data = JSON.parse(result.data?.deleteDmaStockList || '');
                    if (data?.code === 200) {
                        // message.success('Successfully delete stock!');
                        setDeleting(false);
                        return Swal.fire(
                            'Deleted!',
                            selectedRowKeys.length > 1
                                ? deduceBulkActionDescriptionModalText('deleted')
                                : 'Successfully deleted stock!',
                            'success',
                        ).then(() => {
                            getOrders();
                            setSelectedRowKeys([]);
                            setSelectedRowItems([]);
                        });
                    }
                    setDeleting(false);
                    return Swal.fire('Deleted!', data.message, 'error');
                } catch (error) {
                    setDeleting(false);
                    // message.error('We could not delete this stock at this time!');
                    return Swal.fire('Deleted!', 'We could not delete this stock at this time!', 'error');
                }
            }
        });
    };

    const deleteAllStock = async () => {
        Swal.fire({
            title: 'Delete All Stock',
            text: 'Are you sure you want delete all Stocks',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#2cb34a',
        }).then(async (results: any) => {
            if (results.value) {
                Swal.fire({
                    title: 'Processing Request ...',
                    allowOutsideClick: false,
                });
                Swal.showLoading();
                setDeleting(true);
                try {
                    const mutation = graphqlOperation(deleteAllDmaStock, {
                        branchId,
                    });
                    const result = (await API.graphql(mutation)) as GraphQLResult<DeleteAllDmaStockMutation>;
                    const data = JSON.parse(result.data?.deleteAllDmaStock || '');
                    if (data?.code === 200) {
                        // message.success('Successfully delete stock!');
                        setDeleting(false);
                        return Swal.fire(
                            'Deleted!',
                            dataSource.length > 1
                                ? deduceBulkActionDescriptionModalText('deleted')
                                : 'Successfully deleted all stock!',
                            'success',
                        ).then(() => {
                            getOrders();
                            setSelectedRowKeys([]);
                            setSelectedRowItems([]);
                        });
                    }
                    setDeleting(false);
                    return Swal.fire('Deleted!', data.message, 'error');
                } catch (error) {
                    setDeleting(false);
                    // message.error('We could not delete this stock at this time!');
                    return Swal.fire('Deleted!', 'We could not delete all stock at this time!', 'error');
                }
            }
        });
    };

    const columns = useMemo(
        () =>
            getColumns((data: any) => {
                approveStock(data.id);
            }, userId),
        [],
    );

    useEffect(() => {
        getOrders(page, limit, filters.searchText, filters.status);
    }, [page]);

    const handlePageChange = (pageNumber: number) => {
        getOrders(pageNumber, DEFAULT_PAGE_LIMIT, filters.searchText, filters.status);
        setPage(pageNumber);
    };

    const rowSelection = {
        onChange: (selectedKeys: React.Key[], selectedRows: any[]) => {
            console.log(`selectedRowKeys: ${selectedKeys}`, 'selectedRows: ', selectedRows);
            setSelectedRowKeys(selectedKeys);
            setSelectedRowItems(selectedRows);
        },
        // getCheckboxProps: (record: any) => ({
        //     disabled: record.name === 'Disabled User', // Column configuration not to be checked
        //     name: record.name,
        // }),
    };

    return (
        <Row>
            <div className="d-flex justify-content-end col-md-12 mb-4">
                {selectedRowKeys.length > 0 && (
                    <button
                        className="delete-btn-2 fw-500 mr-3 ml-3"
                        disabled={deleting}
                        type="submit"
                        onClick={() => deleteStock()}
                    >
                        {deleting ? 'DELETING...' : 'DELETE SELECTED STOCK'}
                    </button>
                )}
                {!selectedRowKeys.length && (
                    <>
                        <NavLink
                            className="primary-btn-2 mr-3 ml-3"
                            style={{ padding: '0 15px' }}
                            to={`/dma/${userId}/stock/add`}
                        >
                            <div className="fw-500">ADD PRODUCT</div>
                        </NavLink>
                        <DmaStockReconciliationExport
                            className="primary-btn-2 fw-500 mr-3 ml-3 d-flex align-items-center"
                            style={{ columnGap: '5px' }}
                            dmaBranchList={dmaBranches}
                            dmaId={userId}
                            csvFileName="Dma Stock Reconciliation"
                        />
                    </>
                )}
                {selectedRowKeys.length > 0 &&
                    selectedRowItems.filter((item: any) => item.status !== 'APPROVED').length > 0 && (
                        <button className="primary-btn-2  mr-3 ml-3" type="submit" onClick={() => approveStock()}>
                            {updating ? 'APPROVING...' : 'APPROVE SELECTED STOCK'}
                        </button>
                    )}
                {dataSource.length > 0 &&
                    !selectedRowKeys.length &&
                    dataSource.filter((item: any) => item.status !== 'APPROVED').length > 0 && (
                        <button className="delete-btn fw-500 mr-3 ml-3" type="submit" onClick={() => deleteAllStock()}>
                            {deleting ? 'DELETING...' : 'DELETE ALL STOCK'}
                        </button>
                    )}
                {dataSource.length > 0 &&
                    !selectedRowKeys.length &&
                    dataSource.filter((item: any) => item.status !== 'APPROVED').length > 0 && (
                        <button
                            className="primary-btn fw-500 mr-3 ml-3"
                            type="submit"
                            onClick={() => approveStockList()}
                        >
                            {updating ? 'APPROVING...' : 'APPROVE ALL STOCK'}
                        </button>
                    )}
            </div>

            <div className="col-md-3 mb-2">
                <Select className="w-100" value={filters.status} onChange={handleStatusChange}>
                    {statusOptions.map((option: Filter) => (
                        <Option key={option.key} value={option.value}>
                            {option.name}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className="col-md-6 px-5">
                <Search
                    iconColor={'#d7d7d7'}
                    value={filters.searchText}
                    onChange={handleSearchText}
                    className="col-md-10 dma-search-input"
                    placeholder="Search"
                />
            </div>

            <div className="offset-md-1 col-md-2">
                <div className="primary-color fw-500 p-3 pointer" onClick={clearFilters}>
                    Clear All Filters
                </div>
            </div>

            <Col span={24} className="mt-3">
                <div className=" ">
                    <Table
                        rowSelection={rowSelection}
                        className={'dma-header'}
                        loading={loading}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        rowKey={(record) => record.id}
                    />
                </div>
                <div className="pagination-wrapper">
                    <Pagination
                        // defaultCurrent={paginatedProducts?.page || 1}
                        current={page || 1}
                        total={total}
                        pageSize={limit}
                        showSizeChanger={false}
                        disabled={(total || 0) < limit}
                        onChange={handlePageChange}
                    />
                </div>
            </Col>
        </Row>
    );
};
