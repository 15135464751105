import { PayloadAction } from '@reduxjs/toolkit';

import {
    RETRIEVE_ALL_MANUFACTURERS,
    RETRIEVE_ALL_MANUFACTURERS_ERROR,
    RETRIEVE_ALL_MANUFACTURERS_SUCCESS,
    RETRIEVE_MANUFACTURER,
    RETRIEVE_MANUFACTURER_ERROR,
} from '../actionTypes';
import { ManufacturerReducerState } from './reducer.model';

const initialState: ManufacturerReducerState = {
    isLoading: false,
    manufacturers: [],
};

const manufacturers = (state = initialState, action: PayloadAction<any>): ManufacturerReducerState => {
    switch (action.type) {
        case RETRIEVE_ALL_MANUFACTURERS:
            return { ...state, isLoading: true };
        case RETRIEVE_ALL_MANUFACTURERS_SUCCESS:
            return { ...state, isLoading: false, manufacturers: action.payload };
        case RETRIEVE_ALL_MANUFACTURERS_ERROR:
            return { ...state, error: 'unable to fetch manufacturers ' };
        case RETRIEVE_MANUFACTURER:
            return {
                ...state,
                manufacturer: action.payload,
            };
        case RETRIEVE_MANUFACTURER_ERROR:
            return {
                ...state,
                error: 'An error occurred when trying to get manufacturer',
            };
        default:
            return state;
    }
};

export default manufacturers;
