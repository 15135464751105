import Cookies, { CookieAttributes } from 'js-cookie';

export const setCookieData = (name: string, value: string) => {
    let options: CookieAttributes = { sameSite: 'lax' };
    if (window?.location?.protocol === 'https:') {
        options = { ...options, secure: true };
    }
    Cookies.set(name, value, options);
};

export const getCookieValue = (name: string) => Cookies.get(name);
