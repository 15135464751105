import React, { memo, useLayoutEffect, useRef } from 'react';

import { SingleOTPInputProps } from './otp.modules';
import usePrevious from './useOtp';

export function OtpInput(props: SingleOTPInputProps) {
    const { focus, autoFocus, ...rest } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const prevFocus = usePrevious(!!focus);
    useLayoutEffect(() => {
        if (inputRef.current) {
            if (focus && autoFocus) {
                inputRef.current.focus();
            }
            if (focus && autoFocus && focus !== prevFocus) {
                inputRef.current.focus();
                inputRef.current.select();
            }
        }
    }, [autoFocus, focus, prevFocus]);

    return <input ref={inputRef} {...rest} />;
}

const SingleOTPInput = memo(OtpInput);
export default SingleOTPInput;
