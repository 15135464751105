import './products.css';

import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import axios, { AxiosRequestConfig } from 'axios';
import _ from 'lodash';
// import { resolve } from 'path/posix';
import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { Tab, Tabs } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';

import { logout } from '../../redux/actions/authActions';
// import products from '../../redux/reducers/productsReducers';
import { useManufacturer } from '../manufacturers/useManufacturer';
import EditUploadProduct from './EditUploadProduct';
import ManufacturerUploadInformation from './ManufacturerUploadInformation';
import { PageTitle } from './PageTitle';
import ProductUploadDetails from './ProductDetails/ProductUploadDetails';
import ProductsApproval from './ProductsApproval';
import ProductUploadList from './ProductsUploadList';
import { useProducts } from './useProducts';

type InvalidProducts = {
    productId: string | undefined;
    company_name: string | undefined | null;
    type: number | string | null | undefined;
    name: string | null | undefined;
    quantity: number | string | null | undefined;
    category: number | string | null | undefined;
    price: number | string | undefined;
    dateAdded: string | null | undefined;
    status: string | null | undefined;
    unit: number | null | undefined;
    creationDate: string | null | undefined;
};

type ValidProducts = {
    productId: string | undefined;
    company_name: string | undefined | null;
    type: number | string | null | undefined;
    name: string | null | undefined;
    quantity: number | string | null | undefined;
    category: number | string | null | undefined;
    price: number | string | undefined;
    dateAdded: string | null | undefined;
    status: string | null | undefined;
    unit: number | null | undefined;
    creationDate: string | null | undefined;
};

type UploadedProducts = {
    code: Number;
    status: String;
    failedToBeAddedToDynamoProducts: any[];
    validProducts: ValidProducts[];
    invalidProducts: InvalidProducts[];
    identifiedDuplicateProducts: InvalidProducts[];
};

const ProductsUpload: React.FC = () => {
    const [key, setKey] = useState('upload');
    const [uploadTabKey, setUploadTabKey] = useState('valid');
    const [searchTerm] = useState('');
    const [manufacturer, setManufacturer] = useState(false);
    const [hubId, setManufacturerHub] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const [view, setView] = useState('uploadList');
    const [productDetailsId, setProductDetailsId] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [productUploadData, setProductUploadData] = useState<UploadedProducts>({
        code: 200,
        status: '',
        failedToBeAddedToDynamoProducts: [],
        validProducts: [],
        invalidProducts: [],
        identifiedDuplicateProducts: [],
    });
    const [preserveUploadData, setPreserveProductUploadData] = useState<UploadedProducts>({
        code: 200,
        status: '',
        failedToBeAddedToDynamoProducts: [],
        validProducts: [],
        invalidProducts: [],
        identifiedDuplicateProducts: [],
    });

    const dispatch = useDispatch();
    const history = useHistory();

    const { removeProduct, updateProduct } = useProducts();

    const handleLogout = () => {
        dispatch(logout(history));
    };

    const handleManufacturerChange = (e: React.ChangeEvent<any>) => {
        setManufacturer(e.target.value);
        const manufacturerId = e.target.value;
        fetchManufacturerHubs(manufacturerId);
    };

    const handleManufacturerHubChange = (e: React.ChangeEvent<any>) => {
        setManufacturerHub(e.target.value);
    };

    const { fetchManufacturers, manufacturersData, fetchManufacturerHubs, manufacturerHubs } = useManufacturer();

    const getManufacturers = async () => {
        await fetchManufacturers();
        setLoading(false);
    };

    useEffect(() => {
        getManufacturers();
    }, []);

    const readFileDataAsBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                if (event.target?.result) resolve(event.target.result);
            };

            reader.onerror = (err) => {
                reject(err);
            };

            reader.readAsText(file);
        });
    };

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response.status === 401) {
                return handleLogout();
            }
        },
    );

    const updateEditedUploadProduct = async (productDetailsId: string, product: any) => {
        const products = _.cloneDeep(productUploadData.validProducts);
        const foundIndex = products.findIndex((p) => p.productId == productDetailsId);
        products[foundIndex] = product;
        setProductUploadData({
            code: productUploadData.code,
            status: productUploadData.status,
            failedToBeAddedToDynamoProducts: productUploadData.failedToBeAddedToDynamoProducts,
            validProducts: products,
            invalidProducts: productUploadData.invalidProducts,
            identifiedDuplicateProducts: productUploadData.identifiedDuplicateProducts,
        });
        return;
    };

    useEffect(() => {
        if (!searchTerm) {
            setProductUploadData({
                code: preserveUploadData.code,
                status: preserveUploadData.status,
                failedToBeAddedToDynamoProducts: preserveUploadData.failedToBeAddedToDynamoProducts,
                validProducts: preserveUploadData.validProducts,
                invalidProducts: preserveUploadData.invalidProducts,
                identifiedDuplicateProducts: preserveUploadData.identifiedDuplicateProducts,
            });
            return;
        }
        if (uploadTabKey === 'valid') {
            const products = preserveUploadData.validProducts.filter(
                (p) =>
                    p.company_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    p.name?.toLowerCase().includes(searchTerm.toLowerCase()),
            );
            setProductUploadData({
                code: productUploadData.code,
                status: productUploadData.status,
                failedToBeAddedToDynamoProducts: productUploadData.failedToBeAddedToDynamoProducts,
                validProducts: products,
                invalidProducts: productUploadData.invalidProducts,
                identifiedDuplicateProducts: productUploadData.identifiedDuplicateProducts,
            });
        }

        if (uploadTabKey === 'invalid') {
            const invalidProducts = preserveUploadData.invalidProducts.filter(
                (p) => p.company_name?.toLowerCase().includes(searchTerm) || p.name?.toLowerCase().includes(searchTerm),
            );
            setProductUploadData({
                code: productUploadData.code,
                status: productUploadData.status,
                failedToBeAddedToDynamoProducts: productUploadData.failedToBeAddedToDynamoProducts,
                validProducts: productUploadData.validProducts,
                invalidProducts: invalidProducts,
                identifiedDuplicateProducts: productUploadData.identifiedDuplicateProducts,
            });
        }

        if (uploadTabKey === 'duplicate') {
            const duplicateProducts = preserveUploadData.identifiedDuplicateProducts.filter(
                (p) => p.company_name?.toLowerCase().includes(searchTerm) || p.name?.toLowerCase().includes(searchTerm),
            );
            setProductUploadData({
                code: productUploadData.code,
                status: productUploadData.status,
                failedToBeAddedToDynamoProducts: productUploadData.failedToBeAddedToDynamoProducts,
                validProducts: productUploadData.validProducts,
                invalidProducts: productUploadData.invalidProducts,
                identifiedDuplicateProducts: duplicateProducts,
            });
        }
    }, [searchTerm]);

    const uploadProducts = async () => {
        try {
            if (manufacturer && hubId && files.length > 0) {
                setLoading(true);
                const selectedManufacturer = manufacturersData.find((man) => man?.manufacturerId === manufacturer);
                console.log('SelectedManufacturer ---->', selectedManufacturer);
                if (!selectedManufacturer) return;
                const fileContents = await readFileDataAsBase64(files[0]);
                const config: AxiosRequestConfig = {
                    method: 'post',
                    url: `${process.env.REACT_APP_BULK_PRODUCTS_UPLOAD_URL}/v1/admin-bulk-upload-product?manufacturerId=${manufacturer}&hubId=${hubId}&companyName=${selectedManufacturer.companyName}`,
                    headers: {
                        'Content-Type': files[0].type,
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '{}').accessToken}`,
                    },
                    data: fileContents,
                };
                const res = await axios(config);
                const { data } = await res;
                if ('code' in data) {
                    setProductUploadData(data);
                    setPreserveProductUploadData(data);
                    setKey('uploadList');
                } else {
                    return toast.error(data.message);
                }
            } else {
                return toast.error('Please upload a file and select a manufacturer');
            }
        } catch (e: any) {
            console.log('ERROR: ', e);
            return toast.error(e);
        } finally {
            setLoading(false);
        }
    };

    const approveProducts = () => {
        return Swal.fire({
            title: 'Approve Products',
            text: `Approve all selected products?`,
            confirmButtonColor: '#2cb34a',
            icon: 'success',
            showCancelButton: true,
        }).then(async (results: any) => {
            setLoading(true);
            if (results.value) {
                await Promise.all(
                    productUploadData.validProducts.map(async (p) => {
                        const results = await updateProduct(p.productId, { ...p, status: 'Approved' });
                        return results;
                    }),
                );
                history.push('/products');
            }
        });
    };

    const deleteProduct = () => {
        return Swal.fire({
            title: 'Delete Product',
            text: `Are you sure you want to delete this product?`,
            confirmButtonColor: '#f01d1d',
            icon: 'error',
            showCancelButton: true,
        }).then(async (results: any) => {
            if (results.value) {
                await removeProduct(productDetailsId as string);
                const getValidProducts = productUploadData?.validProducts.filter(
                    (p) => p.productId !== productDetailsId,
                );
                if (productUploadData)
                    setProductUploadData({ ...productUploadData, validProducts: getValidProducts as ValidProducts[] });
                setView('uploadList');
            }
        });
    };

    return (
        <BlockUi blocking={loading}>
            <ToastContainer />
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="/products">Products</Breadcrumb.Item>
                                <Breadcrumb.Item href="">Products Upload</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                    <div className="row mt-4 mb-3">
                        <PageTitle title="Products Upload" />
                    </div>
                    <div className="row">
                        <div className="row mt-4 mb-5">
                            <div className="d-flex justify-content-end">
                                <div className="p-2 bd-highlight">
                                    <div className="border-btn text-center" onClick={() => window.print()}>
                                        Print <PrinterOutlined style={{ color: '#2cb34a' }} />
                                    </div>
                                </div>
                                <div className="p-2 bd-highlight">
                                    {' '}
                                    <CSVLink data={[]} headers={[]} filename="Products-data">
                                        <div className="border-btn text-center">
                                            Export <DownloadOutlined style={{ color: '#2cb34a' }} />
                                        </div>
                                    </CSVLink>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="manufacturer">
                                <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k: any) => setKey(k)}>
                                    <Tab eventKey="upload" tabClassName=" uppercase" title="MANUFACTURER INFORMATION">
                                        <ManufacturerUploadInformation
                                            files={files}
                                            setFiles={setFiles}
                                            handleManufacturerChange={handleManufacturerChange}
                                            handleManufacturerHubChange={handleManufacturerHubChange}
                                            uploadProducts={uploadProducts}
                                            manufacturersData={manufacturersData}
                                            manufacturerHubs={manufacturerHubs}
                                        />
                                    </Tab>
                                    <Tab
                                        eventKey="uploadList"
                                        disabled={key === 'upload'}
                                        tabClassName="uppercase"
                                        title="PRODUCTS UPLOAD LIST"
                                    >
                                        {view === 'uploadList' && (
                                            <ProductUploadList
                                                productUploadData={productUploadData as UploadedProducts}
                                                setView={setView}
                                                setProductDetailsId={setProductDetailsId}
                                                deleteProduct={deleteProduct}
                                                uploadTabKey={uploadTabKey}
                                                setUploadTabKey={setUploadTabKey}
                                            />
                                        )}
                                        {view === 'uploadDetails' && (
                                            <ProductUploadDetails
                                                setView={setView}
                                                productDetailsId={productDetailsId}
                                                deleteProduct={deleteProduct}
                                            />
                                        )}
                                        {view === 'uploadEdit' && (
                                            <EditUploadProduct
                                                setView={setView}
                                                productDetailsId={productDetailsId}
                                                updateEditedUploadProduct={updateEditedUploadProduct}
                                            />
                                        )}
                                    </Tab>
                                    <Tab
                                        eventKey="approval"
                                        disabled={key !== 'uploadList' || productUploadData.validProducts.length === 0}
                                        tabClassName="uppercase"
                                        title="PRODUCTS APPROVAL"
                                    >
                                        <ProductsApproval
                                            setView={setView}
                                            productUploadData={productUploadData as UploadedProducts}
                                            approveProducts={approveProducts}
                                        />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BlockUi>
    );
};

export default ProductsUpload;
