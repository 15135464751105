import './products.css';

import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { Breadcrumb, Pagination, Select, Table } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import { API, graphqlOperation } from 'aws-amplify';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import BlockUi from 'react-block-ui';
// import BlockUi from 'react-block-ui';
// import { Col, Form, Modal } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';

import { SearchProductsAdminQueryVariables } from '../../API';
// import { ProductUpload } from '../../components/ProductUpload';
import { Search } from '../../components/Search';
import { updateMultipleProducts } from '../../graphql/mutations';
import { useManufacturer } from '../manufacturers/useManufacturer';
// import { useManufacturer } from '../manufacturers/useManufacturer';
import { getColumns, mapProductsToTableData } from './helpers';
import { PageTitle } from './PageTitle';
import { useProducts } from './useProducts';

interface Filter {
    value: string;
    name: string;
    key: string;
}

const csvHeaders = [
    { label: 'Product ID', key: 'productId' },
    { label: 'Company Name', key: 'company_name' },
    { label: 'Type', key: 'type' },
    { label: 'Input Name', key: 'name' },
    { label: 'Quantity', key: 'quantity' },
    { label: 'Category', key: 'category' },
    { label: 'Price', key: 'price' },
    { label: 'Date Added', key: 'dateAdded' },
    { label: 'Status', key: 'status' },
    { label: 'DMA Price', key: 'dmaPrice' },
    { label: 'Rebates (%)', key: 'rebates' },
];

type TableData = {
    productId: string | undefined;
    company_name: string | undefined | null;
    type: number | string | null | undefined;
    inputName: string | null | undefined;
    quantity: number | string | null | undefined;
    category: number | string | null | undefined;
    price: number | string | undefined;
    dateAdded: string | null | undefined;
    status: string | null | undefined;
    dmaPrice: number | null | undefined;
    rebates: number | null | undefined;
};

type FilterType = {
    page: number;
    limit: number;
    q: string;
};

const statusOptions: Filter[] = [
    { value: '', name: 'Select status', key: 'select-status' },
    { value: 'Approved', name: 'Approved', key: 'Approved' },
    { value: 'Rejected', name: 'Rejected', key: 'Rejected' },
    {
        value: 'Pending',
        name: 'Pending',
        key: 'Pending',
    },
];

const Products: React.FC = () => {
    const [tableData, setTableData] = useState<TableData[]>([]);
    const [, setSelectedRow] = useState<Record<string, any>>();
    const [searchTerm, setSearchTerm] = useState('');
    // const [files, setFiles] = useState<File[]>([]);
    const [loading, setLoading] = useState(false);
    const [statusFilter, setStatusFilter] = useState('');
    const [inputTypeFilter, setInputTypeFilter] = useState('');
    const [manufacturerFilter, setManufacturerFilter] = useState('');
    const [manufacturerHubFilter, setManufacturerHubFilter] = useState('');
    const [selectedProductIds, setSelectedProductIds] = useState<any[]>([]);
    const [filters, setFilters] = useState<FilterType>({
        page: 1,
        limit: 10,
        q: '',
    });
    const DEFAULT_TABLE_SELECTOR_TYPE = 'checkbox';
    const SHOULD_HIDE_SELECT_ALL = true;
    // const [shouldShowUploadModal, setShouldShowUploadModal] = useState(false);
    // const [, setManufacturer] = useState(false);

    // const handleManufacturerChange = (e: React.ChangeEvent<any>) => {
    //     setManufacturer(e.target.value);
    // };

    const { fetchManufacturers, manufacturersData, fetchManufacturerHubs, manufacturerHubs } = useManufacturer();

    const columns = useMemo(
        () =>
            getColumns(
                () => {}, //eslint-disable-line
                (data) => {
                    return Swal.fire({
                        title: 'Delete Product',
                        text: `Are you sure you want to delete this product?`,
                        icon: 'info',
                        showCancelButton: true,
                    }).then(async (results: any) => {
                        if (results.value) {
                            await removeProduct(data);
                        }
                    });
                },
            ),
        [],
    );
    const { paginatedProducts, fetchPaginatedProducts, removeProduct, inputTypes } = useProducts();
    // const { fetchManufacturers, manufacturersData } = useManufacturer();

    useEffect(() => {
        const params = searchTerm ? { q: searchTerm } : { limit: filters.limit, page: 1, q: '' };

        fetchPaginatedProducts(params);
        fetchManufacturers(manufacturerFilter);
    }, [fetchPaginatedProducts]);

    useEffect(() => {
        if (paginatedProducts?.products) {
            setTableData(mapProductsToTableData(paginatedProducts?.products));
        }
    }, [paginatedProducts]);

    const handleOnRow = (row: any) => {
        return {
            onClick: () => setSelectedRow(row),
        };
    };

    const handleSearch = (value: string) => {
        setSearchTerm(value);
        if (!value) {
            return setTableData(mapProductsToTableData(paginatedProducts?.products ?? []));
        }
        fetchPaginatedProducts(retrieveSearchProductsParams(manufacturerFilter, manufacturerHubFilter, 1, value));
    };

    const handleOnManufacturerSearch = (manufacturerText: string) => {
        setManufacturerFilter(manufacturerText);
        if (!manufacturerText) return;
        debounce(async () => await fetchManufacturers(manufacturerText), 1000)();
    };

    const handleInputTypeChange = (type: string) => {
        setInputTypeFilter(type);
        fetchPaginatedProducts(
            retrieveSearchProductsParams(manufacturerFilter, manufacturerHubFilter, 1, searchTerm, type, statusFilter),
        );
    };

    const handleManufacturerChange = (manufacturerId: string) => {
        setManufacturerFilter(manufacturerId);
        setManufacturerHubFilter('');
        Promise.all([
            fetchManufacturerHubs(manufacturerId),
            fetchPaginatedProducts(
                retrieveSearchProductsParams(
                    manufacturerId,
                    manufacturerHubFilter,
                    1,
                    searchTerm,
                    inputTypeFilter,
                    statusFilter,
                ),
            ),
        ]);
    };

    const handlePageChange = (value: number) => {
        setFilters({ ...filters, page: value });
        fetchPaginatedProducts(
            retrieveSearchProductsParams(
                manufacturerFilter,
                manufacturerHubFilter,
                value,
                searchTerm,
                inputTypeFilter,
                statusFilter,
            ),
        );
    };

    const retrieveSearchProductsParams = (
        manufacturerId: string,
        manufacturerHubId: string,
        page = 1,
        searchTerm = '',
        type = '',
        status = '',
    ) => {
        const searchParams: SearchProductsAdminQueryVariables | null = searchTerm
            ? { q: searchTerm, manufacturerId, page }
            : { limit: filters.limit, page, q: searchTerm, manufacturerId };

        if (!!manufacturerHubId) {
            searchParams.hubId = [manufacturerHubId];
        }
        if (!!type) {
            searchParams.type = type;
        }
        if (!!status) {
            searchParams.status = status;
        }
        console.log('Searchparams', searchParams);
        return searchParams;
    };

    const handleManufacturerHubChange = (manufacturerHubId: string) => {
        setManufacturerHubFilter(manufacturerHubId);
        fetchPaginatedProducts(retrieveSearchProductsParams(manufacturerFilter, manufacturerHubId));
    };

    const handleStatusChange = (status: string) => {
        setStatusFilter(status);
        fetchPaginatedProducts(
            retrieveSearchProductsParams(
                manufacturerFilter,
                manufacturerHubFilter,
                1,
                searchTerm,
                inputTypeFilter,
                status,
            ),
        );
    };

    const { Option } = Select;

    const clearFilters = () => {
        setStatusFilter('');
        setInputTypeFilter('');
        setManufacturerFilter('');
        setManufacturerHubFilter('');
        fetchPaginatedProducts({ limit: filters.limit, page: 1, q: '' });
    };

    const handleApproveProducts = async () => {
        // handle approve products logic
        try {
            setLoading(true);
            const q = graphqlOperation(updateMultipleProducts, {
                productIds: selectedProductIds,
                status: 'Approved',
            });
            const { data } = (await API.graphql(q)) as any;
            const params = searchTerm ? { q: searchTerm } : { limit: 60, page: 1, q: '' };
            await fetchPaginatedProducts(params);
            if (paginatedProducts?.products) {
                setTableData(mapProductsToTableData(paginatedProducts?.products));
            }
            return { result: data?.updateMultipleProducts, message: 'update completed' };
        } catch (e: any) {
            return {
                result: null,
                message: 'Could not update',
            };
        } finally {
            setLoading(false);
        }
    };

    const handleRejectProducts = async () => {
        // handle reject products logic
        try {
            setLoading(true);
            const q = graphqlOperation(updateMultipleProducts, {
                productIds: selectedProductIds,
                status: 'Rejected',
            });
            const { data } = (await API.graphql(q)) as any;
            const params = searchTerm ? { q: searchTerm } : { limit: 60, page: 1, q: '' };

            await fetchPaginatedProducts(params);
            if (paginatedProducts?.products) {
                setTableData(mapProductsToTableData(paginatedProducts?.products));
            }
            return { result: data?.updateMultipleProducts, message: 'update completed' };
        } catch (e: any) {
            return {
                result: null,
                message: 'Could not update',
            };
        } finally {
            setLoading(false);
        }
    };

    const deduceRowCheckBoxStyle = (record: TableData) => {
        return {
            style: {
                display: record?.status === 'pending' ? 'flex' : 'none',
                paddingLeft: '1.3rem',
            },
        };
    };

    const handleRowSelection = (): TableRowSelection<TableData> => ({
        type: DEFAULT_TABLE_SELECTOR_TYPE,
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableData[]) => {
            const selectedProductIds = selectedRows.map((selectedRow) => selectedRow.productId);
            setSelectedProductIds(selectedProductIds);
        },
        hideSelectAll: SHOULD_HIDE_SELECT_ALL,
        getCheckboxProps: (record: TableData) => {
            return deduceRowCheckBoxStyle(record);
        },
    });

    // @ts-ignore
    // @ts-ignore
    return (
        <BlockUi blocking={loading}>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="">Products List</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                    <div className="row mt-4 mb-3">
                        <PageTitle title="Products List" />
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-end">
                            <div className="p-2 bd-highlight">
                                <div className="border-btn text-center" onClick={() => window.print()}>
                                    Print <PrinterOutlined style={{ color: '#2cb34a' }} />
                                </div>
                            </div>
                            <div className="p-2 bd-highlight">
                                {' '}
                                <CSVLink data={tableData} headers={csvHeaders} filename="Products-data">
                                    <div className="border-btn text-center">
                                        Export <DownloadOutlined style={{ color: '#2cb34a' }} />
                                    </div>
                                </CSVLink>
                            </div>
                            {/* <div className="p-2 bd-highlight">
                            <div
                                onClick={async () => {
                                    setShouldShowUploadModal(true);
                                    setLoading(true);
                                    await fetchManufacturers();
                                    setLoading(false);
                                }}
                                className="border-btn text-center"
                            >
                                Upload Product
                            </div>
                        </div> */}
                            <div className="p-2 bd-highlight">
                                <NavLink to={'/product/new'}>
                                    <div className="border-btn text-center">Add Product</div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 mb-3">
                        <div className="col-md-3 mb-2">
                            <Select
                                showSearch
                                className="w-100"
                                value={manufacturerFilter}
                                onSearch={handleOnManufacturerSearch}
                                onChange={handleManufacturerChange}
                            >
                                <Option value="" key="">
                                    Select Manufacturer
                                </Option>
                                {manufacturersData.map((manufacturer, index) => {
                                    return (
                                        <Option value={manufacturer.manufacturerId} key={index}>
                                            {manufacturer?.companyName}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>

                        <div className="col-md-3 mb-2">
                            <Select
                                className="w-100"
                                value={manufacturerHubFilter}
                                onChange={handleManufacturerHubChange}
                            >
                                <Option value="" key="">
                                    Select Manufacturer Hub
                                </Option>
                                {manufacturerHubs.map((manufacturerHub, index) => {
                                    return (
                                        <Option
                                            value={manufacturerHub.hubId}
                                            key={index}
                                            title={`${manufacturerHub?.hubName} - ${manufacturerHub?.hubId}`}
                                        >
                                            {manufacturerHub?.hubName} - {manufacturerHub?.hubId}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>
                    <div className="row mt-4 mb-3">
                        <div className="col-md-3 mb-2">
                            <Select className="w-100" value={inputTypeFilter} onChange={handleInputTypeChange}>
                                <Option value="" key="">
                                    Select Input Type
                                </Option>
                                {Array.from(inputTypes).map((inputType) => {
                                    return (
                                        <Option value={inputType} key={inputType}>
                                            {inputType}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="col-md-3 mb-2">
                            <Select className="w-100" value={statusFilter} onChange={handleStatusChange}>
                                {statusOptions.map((option: Filter) => (
                                    <Option key={option.key} value={option.value}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className="col-md-4">
                            <Search
                                iconColor={'#d7d7d7'}
                                placeholder="Search"
                                value={searchTerm}
                                onChange={(value) => handleSearch(value)}
                            />
                        </div>
                        <div className="col-md-2">
                            <div className="primary-color fw-500 p-3 pointer" onClick={clearFilters}>
                                Clear All Filters
                            </div>
                        </div>
                    </div>
                    {/* <div className="enhanced-modal">
                    <BlockUi blocking={loading}>
                        <Modal
                            show={shouldShowUploadModal}
                            onHide={() => setShouldShowUploadModal(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title style={{ fontWeight: 'bolder', color: 'black' }}>
                                    Upload an attachment
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Col
                                    md={6}
                                    className=""
                                    style={{ marginBottom: '15px', marginLeft: '5%', width: '90%' }}
                                >
                                    <Form.Label className="new-product-form-label">Select Manufacturer</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="manufacturerUserId"
                                        onChange={handleManufacturerChange}
                                    >
                                        {manufacturersData?.map((manufacturer, k) => {
                                            return (
                                                <option key={k} value={manufacturer.username}>
                                                    {manufacturer.name}
                                                </option>
                                            );
                                        })}
                                    </Form.Control>
                                </Col>
                                <ProductUpload files={files} setFiles={setFiles} />
                            </Modal.Body>
                        </Modal>
                    </BlockUi>
                </div> */}
                    {selectedProductIds.length > 0 && (
                        <div className="row mb-3">
                            <div className="d-flex justify-content-end">
                                <div className="p-2 bd-highlight">
                                    <div className="border-btn text-center" onClick={handleApproveProducts}>
                                        Approve
                                    </div>
                                </div>
                                <div className="p-2 bd-highlight">
                                    <div className="border-btn text-center" onClick={handleRejectProducts}>
                                        Reject
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div style={{ overflowX: 'auto' }}>
                        <Table
                            className="products-table"
                            dataSource={tableData}
                            columns={columns}
                            onRow={handleOnRow}
                            rowSelection={handleRowSelection()}
                            pagination={false}
                        />
                    </div>
                    <div className="pagination-wrapper">
                        <Pagination
                            current={filters.page || 1}
                            total={paginatedProducts?.total || 0}
                            pageSize={filters.limit}
                            showSizeChanger={false}
                            disabled={(paginatedProducts?.total || 0) < filters.limit}
                            onChange={handlePageChange}
                        />
                    </div>
                </div>
            </div>
        </BlockUi>
    );
};

export default Products;
