import { GraphQLResult } from '@aws-amplify/api';
import { message } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import axios from 'axios';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AdminGetManufacturerPresignedUrlQuery } from '../../API';
import { createManufacturerAndMainHub } from '../../graphql/mutations';
import { adminGetManufacturerPresignedUrl, searchManufacturer } from '../../graphql/queries';
import { formatNumber, onChange } from '../../utils/common';
// import { listDocUrl } from '../../utils/urls';
import { fieldsType } from './types';

const alert = message;
export const useManufacturer = () => {
    const staticFields: fieldsType = {
        company_name: '',
        phoneNumber: '',
        email: '',
        name: '',
        location: '',
        group: '',
    };
    const [creating, setCreating] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [values, setValues] = useState<fieldsType>(staticFields);
    const [files, setFiles] = useState<File[]>([]);
    const [bucketUrl, setBucketUrl] = useState('');
    const [bucketData, setBucketData] = useState({ fields: {} });
    const [message, setMessage] = useState('');
    const [key, setKey] = useState('info');
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e, values, setValues);
    };

    const history = useHistory();

    const handleCompanyInfoSubmit = async () => {
        const param = {
            company_name: companyName,
            phoneNumber: formatNumber(phoneNumber),
            email: email.trim().toLowerCase(),
            name,
            location,
        };
        try {
            const token = localStorage.getItem('jwt');

            if (!token) return;

            if (await doesManufacturerPreviouslyExist(param.email)) {
                setMessage('Manufacturer with this email already exists');
                return;
            }

            setCreating(true);

            //graphQl request

            const query = graphqlOperation(createManufacturerAndMainHub, { manufacturerInput: param });
            const { data } = (await API.graphql(query)) as GraphQLResult<any>;

            const createManufacturerResponse = data?.createManufacturerAndMainHub;

            if (createManufacturerResponse?.code && createManufacturerResponse?.code != 200) {
                setCreating(false);
                setMessage(createManufacturerResponse?.message as string);
                return;
            }

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const getManufacturerPresignedData = await getPresignedUrl(
                createManufacturerResponse?.data?.User?.Username,
            );

            setCreating(false);
            if (getManufacturerPresignedData && 'url' in getManufacturerPresignedData) {
                setBucketUrl(getManufacturerPresignedData.url as string);
                setBucketData(JSON.parse(getManufacturerPresignedData?.fields as string));
                setKey('kyc');
                setMessage('');
            }
        } catch (e: any) {
            console.log(e);
            setMessage(e?.response?.data?.message || 'An unexpected error occurred. Kindly contact support!');
            setCreating(false);
        }
    };

    const doesManufacturerPreviouslyExist = async (emailAddress: String) => {
        const query = graphqlOperation(searchManufacturer, { emailAddress });
        const { data } = (await API.graphql(query)) as GraphQLResult<any>;

        const mainResult = data?.searchManufacturer;
        const manufacturer = mainResult?.data?.result || [];
        return manufacturer.length > 0;
    };

    const handleKYCSubmit = async () => {
        const fd = new FormData();
        try {
            if (files.length === 0) {
                return setMessage('Upload Documents to Proceed');
            }
            const fields = bucketData;

            Object.entries(fields).forEach(([field, value]) => {
                fd.append(field, value as string);
            });

            files.map((file) => fd.append('file', file, file.name));
            delete axios.defaults.headers.common['Authorization'];
            const res = await axios.post(bucketUrl, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (res) {
                alert.success('Successfully Created Manufacturer');
                history.push('/manufacturers');
            }
        } catch (e: any) {
            setMessage(
                e.response.data.message ||
                    'An unexpected error occurred submitting KYC documents. Kindly contact support!',
            );
        }
    };

    const getPresignedUrl = async (username: string | null | undefined) => {
        if (!username) {
            return null;
        }
        const q = graphqlOperation(adminGetManufacturerPresignedUrl, { username });
        const { data } = (await API.graphql(q)) as GraphQLResult<AdminGetManufacturerPresignedUrlQuery>;

        const res = data?.adminGetManufacturerPresignedUrl;
        if (res) {
            return res;
        }
        return null;
    };

    return {
        values,
        handleChange,
        creating,
        companyName,
        setCompanyName,
        location,
        setLocation,
        phoneNumber,
        setPhoneNumber,
        email,
        setEmail,
        name,
        setName,
        handleCompanyInfoSubmit,
        key,
        setKey,
        files,
        setFiles,
        message,
        handleKYCSubmit,
    };
};
