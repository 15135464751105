import './wards.css';

import { Breadcrumb } from 'antd';
import React, { MouseEvent, useState } from 'react';
import BlockUi from 'react-block-ui';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { Input } from '../../components/inputs/Input';
import { useWards } from './useWards';

interface Iprops {
    setKey: (key: string) => void;
}

export const CreateWard: React.FC<Iprops> = () => {
    const [wardName, setWardName] = useState('');
    const [region, setRegion] = useState('');
    const [county, setCounty] = useState('');
    const [subCounty, setSubCounty] = useState('');
    const [creating, setCreating] = useState(false);

    const { addWards } = useWards();

    const saveWard = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        try {
            if (!wardName) {
                return toast.error('Ward Name is required!');
            }

            if (!region) {
                return toast.error('Ward Region is required!');
            }

            if (!county) {
                return toast.error('Ward County is required!');
            }

            const specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

            if (
                /\s/.test(wardName) ||
                /\s/.test(region) ||
                /\s/.test(county) ||
                specialChars.test(wardName) ||
                specialChars.test(region) ||
                specialChars.test(county)
            ) {
                return toast.error(
                    'Ward name or Ward region or Ward County cant contain spaces or specials characters.',
                );
            }

            setCreating(true);
            const results = await addWards({
                name: wardName,
                region,
                county,
                subCounty,
            });

            if (results.success) {
                setWardName('');
                setRegion('');
                setCounty('');
                setSubCounty('');
                // sessionStorage.setItem('createdRole', results.data.roleId);
                history.push('/wards');
            } else {
                console.log(results.message);
                return toast.error(results.message);
            }
        } catch (e: any) {
            return toast.error('Error creating role', e);
        } finally {
            setCreating(false);
        }
    };

    const history = useHistory();

    return (
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <BlockUi blocking={creating}>
                            <ToastContainer />
                            <div>
                                <Breadcrumb separator=">">
                                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item>Wards</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>

                            <h6 className="roles-heading">Create Ward</h6>

                            <div>
                                <Card className="roles-cardbody shadow-lg p-3 mb-5">
                                    <Card.Body>
                                        <Form>
                                            <div className="d-flex">
                                                <Col md={6} className="p-3">
                                                    <div className="form-group">
                                                        <label className="auth-label mb-2">Ward Name</label>
                                                        <Input
                                                            className=""
                                                            placeholder="Ward Name"
                                                            value={wardName}
                                                            type="text"
                                                            required
                                                            onChange={(e) => setWardName(e)}
                                                            name="role_name"
                                                            disabled={false}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6} className="p-3">
                                                    <div className="form-group">
                                                        <label className="auth-label mb-2">Ward region</label>
                                                        <Input
                                                            className=""
                                                            placeholder="Mombasa"
                                                            value={region}
                                                            type="text"
                                                            required
                                                            onChange={(val) => setRegion(val)}
                                                            name="ward_region"
                                                            disabled={false}
                                                        />
                                                    </div>
                                                </Col>
                                            </div>
                                            <div className="d-flex">
                                                <Col md={6} className="p-3">
                                                    <div className="form-group">
                                                        <label className="auth-label mb-2">Ward County</label>
                                                        <Input
                                                            className=""
                                                            placeholder="Mombasa"
                                                            value={county}
                                                            type="text"
                                                            required
                                                            onChange={(val) => setCounty(val)}
                                                            name="ward_county"
                                                            disabled={false}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6} className="p-3">
                                                    <div className="form-group">
                                                        <label className="auth-label mb-2">Ward Sub County</label>
                                                        <Input
                                                            className=""
                                                            placeholder="Mombasa"
                                                            value={subCounty}
                                                            type="text"
                                                            required
                                                            onChange={(val) => setSubCounty(val)}
                                                            name="ward_region"
                                                            disabled={false}
                                                        />
                                                    </div>
                                                </Col>
                                            </div>
                                            <div className="d-flex justify-content-flex-end fullwidth mt-5">
                                                <div
                                                    className="cancel-btn fw-500 m-3 btn-width pointer"
                                                    onClick={() => history.push('/wards')}
                                                >
                                                    Cancel
                                                </div>
                                                <button
                                                    onClick={(event) => saveWard(event)}
                                                    className="primary-btn fw-500 m-3 btn-width"
                                                    disabled={creating}
                                                >
                                                    {creating ? 'Loading...' : 'Save'}
                                                </button>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div>
        </div>
    );
};
