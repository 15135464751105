import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row, Tabs } from 'antd';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import { DMA } from '../../API';
import NoDataSVG from '../../assets/SVG/empty.svg';
import Empty from '../../assets/SVG/no-data.svg';
import { AccountInfoCard } from './components/AccountInfoCard';
import { CreditLimitCard } from './components/CreditLimitCard';
import { DigiFarmHubCard } from './components/DigiFarmHubCard';
import { Orders } from './components/Orders';
import { Profile } from './components/Profile';
import { SalesGraph } from './components/SalesGraph';
import { useDma } from './useDma';
const { TabPane } = Tabs;

interface ProfileProps {
    title: string;
    match: {
        params: {
            id: string;
        };
    };
}

const DmaProfile: React.FC<ProfileProps> = (props) => {
    const {
        getUserProfile,
        singleUser,
        isLoadingUser,
        updateDmaStatus,
        downloadURL,
        updatingStatus,
        getReadUrl,
        checkCanDeleteDmaFn,
        checkingCanDeleteDma,
        canDeleteDma,
        deleteDmaFn,
    } = useDma();
    const id = props.match.params.id;

    useEffect(() => {
        getUserProfile(id);
        checkCanDeleteDmaFn(id);
    }, []);

    if (isLoadingUser) {
        return (
            <div className="d-flex justify-content-center align-items-center height-100vh">
                <LoadingOutlined style={{ fontSize: 34 }} spin />
            </div>
        );
    }
    return (
        <>
            <div className="content-wrapper">
                <ToastContainer />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-between fullwidth ">
                                <Row className="fullwidth">
                                    <Col span={8} className="fullheight">
                                        <Profile
                                            downloadUrl={downloadURL}
                                            updatingStatus={updatingStatus}
                                            user={singleUser?.data}
                                            id={id}
                                            updateDmaStatus={updateDmaStatus}
                                            getReadUrl={getReadUrl}
                                            checkingCanDeleteDma={checkingCanDeleteDma}
                                            canDeleteDma={canDeleteDma}
                                            deleteDmaFn={deleteDmaFn}
                                        />
                                    </Col>
                                    <Col span={16} className="p-4">
                                        <Tabs type="card" size="large">
                                            <TabPane tab="Profile" key="1">
                                                <AccountInfoCard user={singleUser?.data as DMA} id={id} />
                                                <CreditLimitCard user={singleUser?.data as DMA} id={id} />
                                                <DigiFarmHubCard user={singleUser?.data as DMA} id={id} />
                                            </TabPane>
                                            <TabPane tab="Analytics" key="2">
                                                <SalesGraph
                                                    title={'DMA Sales Report'}
                                                    status={singleUser?.data?.kyc_flow_state}
                                                    user={singleUser?.data}
                                                    empty={Empty}
                                                    userId={id}
                                                    name={singleUser?.data?.businessName}
                                                />
                                                <div className="mt-4">
                                                    <Orders
                                                        name={singleUser?.data?.businessName}
                                                        user={singleUser?.data}
                                                        title={'DMA Orders'}
                                                        userId={id}
                                                        status={singleUser?.data?.kyc_flow_state}
                                                        empty={NoDataSVG}
                                                    />
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                    </Col>
                                </Row>

                                <div>{props.children}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DmaProfile;
