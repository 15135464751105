import { LeftPanelTypes } from '../../models';
export enum Views {
    PersonalInfo = 'personalInfo',
    AwaitApproval = 'await-approval',
    Verify = 'verify',
    Kyc = 'kyc',
    Login = 'login',
    ManufacturerResetPassword = 'manufacturer-reset-password',
    VerifyResetPassword = 'verify-password',
}

export const leftPanelCopies: Record<Views, LeftPanelTypes> = {
    [Views.PersonalInfo]: {
        image: `personal-bg-img`,
        activeText: 'order making process',
        subText:
            'DigiFarm offer access for easy ordering and distribution of available quality products from manufacturer to the distributors.',
        header: 'Empowered to ease',
        breakText: 'for inputs',
    },
    [Views.AwaitApproval]: {
        image: `kyc-bg-img`,
        activeText: 'purchasing',
        subText:
            'Input distribution where digifarm master agents will order inputs directly from manufacturer and have products distributed',
        header: 'Link directly to manufacturers when',
        breakText: 'inputs',
    },
    [Views.Verify]: {
        image: `personal-bg-img`,
        activeText: 'purchasing',
        subText:
            'Input distribution where digifarm master agents will order inputs directly from manufacturer and have products distributed',
        header: 'Link directly to manufacturers when',
        breakText: 'inputs',
    },
    [Views.Kyc]: {
        image: 'kyc-bg-img',
        activeText: 'purchasing',
        subText:
            'Input distribution where digifarm master agents will order inputs directly from manufacturer and have products distributed',
        header: 'Link directly to manufacturers when',
        breakText: 'inputs',
    },
    [Views.Login]: {
        image: 'login-bg-img',
        activeText: 'on stop',
        subText:
            'One stop access to variety of services including financing, high quality seed, fertilisers, animal feed and agricultural chemicals and stock management.',
        header: 'Easier Distribution and Access to Service',
        breakText: 'For Inputs',
    },
    [Views.ManufacturerResetPassword]: {
        image: 'login-bg-img',
        activeText: 'on stop',
        subText:
            'One stop access to variety of services including financing, high quality seed, fertilisers, animal feed and agricultural chemicals and stock management.',
        header: 'Easier Distribution and Access to Service',
        breakText: 'For Inputs',
    },
    [Views.VerifyResetPassword]: {
        image: 'login-bg-img',
        activeText: 'on stop',
        subText:
            'One stop access to variety of services including financing, high quality seed, fertilisers, animal feed and agricultural chemicals and stock management.',
        header: 'Easier Distribution and Access to Service',
        breakText: 'For Inputs',
    },
};
