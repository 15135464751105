import './advertise.scss';

import { Breadcrumb } from 'antd';
import { Storage } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import React, { ChangeEvent, useState } from 'react';
import BlockUi from 'react-block-ui';
import { Card, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import { FileUpload } from '../../components/fileUpload/FileUpload';
import FormTextarea from '../../components/inputs/FormTextarea';
import { Input } from '../../components/inputs/Input';
import { createAdvert } from '../../graphql/mutations';

const Advertise = () => {
    const [files, setFiles] = useState<File[]>([]);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const history = useHistory();

    const getPublicStorageUrl = (key: string) => {
        return `https://${process.env.REACT_APP_PRODUCT_IMAGE_STORAGE}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/public/${key}`;
    };

    const uploadImage = async (files: File[]) => {
        try {
            const fileKeys = `adverts/${files[0].name}`;
            await Promise.all(
                files.map((file) => {
                    return Storage.put(`adverts/${file.name}`, file, {
                        contentType: 'image/*',
                        // authorization: `Bearer ${localStorage.getItem('jwt')}`,
                    });
                }),
            );

            const images: Array<Object | string> = [];

            const url = getPublicStorageUrl(fileKeys);
            images.push(url);

            return images[0];
        } catch (e: any) {
            console.log(e);
            return toast.error(e);
        }
    };

    const handleSubmit = async () => {
        try {
            if (files.length === 0) {
                return toast.error('Select an image to upload');
            }

            if (!name) {
                return toast.error('Advert name is required');
            }
            setLoading(true);
            const image = await uploadImage(files);

            const query = graphqlOperation(createAdvert, {
                advert: {
                    advertId: uuid(),
                    name,
                    description,
                    image,
                },
            });
            await API.graphql(query);
            toast.success('Advert Added successfully');
        } catch (e: any) {
            return toast.error(e);
        } finally {
            setLoading(false);
            setName('');
            setDescription('');
        }
    };

    return (
        <div className="content-wrapper">
            <div className="container">
                <BlockUi blocking={loading}>
                    <ToastContainer />
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="">Advertise</Breadcrumb.Item>
                    </Breadcrumb>
                    <Card className="mt-5 col-md-6 offset-md-3 elevated-card">
                        <div className="grey-color p-4">
                            <Col md={12}>
                                <label className="auth-label mb-2">Advert Name</label>
                                <Input
                                    className=""
                                    placeholder="Advert Name"
                                    value={name}
                                    type="text"
                                    required
                                    onChange={(e) => setName(e)}
                                    name="advert_name"
                                    disabled={false}
                                />
                                <label className="auth-label mt-4 mb-2">Advert Description</label>
                                <FormTextarea
                                    className=""
                                    placeholder="Advert Description"
                                    value={description}
                                    type="text"
                                    required
                                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}
                                    name="advert_description"
                                    disabled={false}
                                />

                                <p className="mt-4 text-center ad-upload-header">Upload Advertise Image</p>
                                <FileUpload files={files} setFiles={setFiles} />
                                <button
                                    className="primary-btn col-12 fw-500 mt-5"
                                    type="submit"
                                    onClick={() => handleSubmit()}
                                >
                                    Upload
                                </button>
                            </Col>
                        </div>
                    </Card>
                </BlockUi>
            </div>
        </div>
    );
};

export default Advertise;
