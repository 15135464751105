import React from 'react';

import { getInitials } from '../../../../utils';

type ProfileInitialsType = {
    firstname: string;
    lastname?: string;
    id: string;
};

const ProfileInitials: React.FC<ProfileInitialsType> = ({ firstname, id, lastname = '' }) => {
    return (
        <div className="d-flex align-items-center gap-30 col-md-12">
            <div className={'d-flex mb-13'}>
                <div className={'user-name-abbr fw-500 fs-17 user-margin-right'}>
                    {getInitials(`${firstname} ${lastname}`)}
                </div>
                <div className="">
                    <div className="user-details-name fs-17 primary-color">{`${firstname} ${lastname}`}</div>
                    <div className="user-details-number mt-2 text-align-left">ID: {id || ''}</div>
                </div>
            </div>
        </div>
    );
};

export default ProfileInitials;
