import './manufacturer.scss';

import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import { KycForm } from './KYC';
import { PersonalInfo } from './PersonalInfo';
import { useManufacturer } from './useManufacturer';

const CreateManufacturer = () => {
    const {
        values,
        handleChange,
        creating,
        companyName,
        setCompanyName,
        location,
        setLocation,
        phoneNumber,
        setPhoneNumber,
        email,
        setEmail,
        name,
        setName,
        handleCompanyInfoSubmit,
        key,
        setKey,
        files,
        setFiles,
        message,
        handleKYCSubmit,
    } = useManufacturer();

    return (
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="manufacturer">
                            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k: any) => setKey(k)}>
                                <Tab
                                    eventKey="info"
                                    disabled={key === 'kyc'}
                                    tabClassName=" uppercase"
                                    title="Company Info"
                                >
                                    <PersonalInfo
                                        values={values}
                                        creating={creating}
                                        companyName={companyName}
                                        setCompanyName={setCompanyName}
                                        location={location}
                                        setLocation={setLocation}
                                        phoneNumber={phoneNumber}
                                        message={message}
                                        setPhoneNumber={setPhoneNumber}
                                        email={email}
                                        setEmail={setEmail}
                                        name={name}
                                        setName={setName}
                                        handleChange={handleChange}
                                        setKey={setKey}
                                        handleSubmit={handleCompanyInfoSubmit}
                                    />
                                </Tab>
                                <Tab
                                    eventKey="kyc"
                                    disabled={key !== 'kyc'}
                                    tabClassName="uppercase"
                                    title="KYC Documents"
                                >
                                    <KycForm
                                        setKey={setKey}
                                        message={message}
                                        handleSubmit={handleKYCSubmit}
                                        files={files}
                                        setFiles={setFiles}
                                    />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateManufacturer;
