import './SideNav.css';

import { Avatar, Dropdown, Menu } from 'antd';
import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
const getLoggedInUser = JSON.parse(localStorage.getItem('user') || '{}');
import { CaretDownOutlined } from '@ant-design/icons';

import logo from '../../assets/LOGO/drawable-xxhdpi/digifarm_logo_approved.png';
import { LogoutButton } from '../../components/LogoutButton';
import { routes } from '../../routes';
import { useNavItems } from '../useNavItems';
import { SideNavItem } from './SideNavItem';

export const SideNav = () => {
    const [links, selectLink] = useNavItems(routes);
    const [state_status, setStatus] = useState({ menuStatus: false });

    const menu = (
        <Menu>
            <Menu.Item key="0">
                <LogoutButton />
            </Menu.Item>
        </Menu>
    );

    function getData() {
        if (window.innerWidth <= 767) {
            setStatus({ menuStatus: !state_status.menuStatus });
            state_status.menuStatus = !state_status.menuStatus;
        }
    }
    return (
        <div>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light" style={{ padding: '1.21rem' }}>
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            onClick={() => getData()}
                            data-widget="pushmenu"
                            role="button"
                            id="nav-toggler"
                        >
                            <i className="fas fa-bars" />
                        </a>
                    </li>
                </ul>

                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#">
                            <i className="far fa-bell" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <span className="dropdown-item dropdown-header">15 Notifications</span>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item">
                                {' '}
                                <i className="fas fa-file mr-2" /> 3 new reports{' '}
                                <span className="float-right text-muted text-sm">2 days</span>{' '}
                            </a>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item dropdown-footer">
                                {' '}
                                See All Notifications{' '}
                            </a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <div className="mt-2">
                            <Avatar />
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
                            <h5 className="primary-color" style={{ fontSize: '80%' }}>
                                {getLoggedInUser.given_name}
                            </h5>
                            <h6 style={{ fontSize: '80%' }}>Admin</h6>
                        </a>
                    </li>
                    <li className="nav-item">
                        <div className="mt-2">
                            <Dropdown overlay={menu}>
                                <CaretDownOutlined style={{ color: 'black' }} />
                            </Dropdown>
                        </div>
                    </li>
                </ul>
            </nav>
            <div
                className={`bg-light vh-100 main-sidebar sidebar-light-primary ${
                    state_status.menuStatus ? 'increase-width' : ''
                }`}
                id="aside"
                style={{ borderRight: '1px solid #dee2e6', overflowY: 'auto' }}
            >
                <div>
                    <div className="side-nav-header d-flex justify-content-center border-bottom">
                        <img src={logo} alt="digifarm logo" />
                    </div>
                </div>
                <div className="full-height">
                    <Nav defaultActiveKey="/home" className="w-100">
                        {links.map((link) => (
                            <SideNavItem key={link.path} item={link} onSelect={selectLink} />
                        ))}
                    </Nav>
                </div>
            </div>
            <div id={`${state_status.menuStatus ? 'sidebar-overlay' : ''}`} onClick={() => getData()} />
        </div>
    );
};
