import { message } from 'antd';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';

import { Input } from '../../../components/inputs/Input';
import { OTPInputComponent } from '../../../components/inputs/otpInput/OtpInputContainer';
import { Views } from '../auth-constants';
import { AuthHeading } from '../components/AuthHeading';
import ResendOtp from './ResendOtp';

// import ResendOtp from "./resendOtp";

type VerifyUserProps = {
    deliveryMethod: string;
    setView: (view: Views) => void;
    resendCode?: () => void;
    username: string;
};

export const VerifyForgotPassword: React.FC<VerifyUserProps> = (props) => {
    const { deliveryMethod, setView, username } = props;
    const [otp, setOtp] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [msg, setMessage] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const validateCode = async () => {
        if (password !== confirmPassword) {
            return setMessage("Password doesn't match.");
        } else {
            setLoading(true);
            try {
                await Auth.forgotPasswordSubmit(username, otp, password);
                message.success('Successfully changed password.');
                setView(Views.Login);
            } catch (err: any) {
                console.log(err);
                setMessage(err.message);
                if (err.code === 'InvalidParameterException') {
                    setMessage('Your password is very weak');
                }
            }
        }
        setLoading(false);
    };

    const resendOtp = async () => {
        setMessage('');
        try {
            await Auth.forgotPassword(username);
        } catch (err: any) {
            console.log(err);
            setMessage(err.message);
        }
    };

    return (
        <div className="">
            {msg && <Alert variant="warning">{msg}</Alert>}
            <AuthHeading
                title="VERIFICATION"
                subTitle={` We have sent a password recover instructions to ${deliveryMethod}, kindly provide OTP and enter a new password.`}
            />

            <OTPInputComponent
                className="d-flex justify-content-center mt-5"
                disabled={loading}
                inputClassName="otp-input"
                autoFocus
                isNumberInput
                length={6}
                onChangeOTP={(otp) => setOtp(otp)}
                setDisabled={setDisabled}
            />

            <div className="mt-3 text-align-center d-flex justify-content-center align-items-center grey-color mt-5">
                <div> Didn’t receive a verification code? </div> &nbsp;
                <ResendOtp resendFunc={resendOtp} />
            </div>
            <div className="form-group  mt-5 mb-4">
                <label className="auth-label mb-2">New Password</label>
                <Input
                    onChange={setPassword}
                    className=""
                    name="name"
                    required
                    disabled={false}
                    placeholder="********"
                    type="password"
                    value={password}
                />
                <div className="form-group  mt-4 mb-4">
                    <label className="auth-label mb-2">Confirm Password</label>
                    <Input
                        onChange={setConfirmPassword}
                        className=""
                        name="name"
                        required
                        disabled={false}
                        placeholder="*********"
                        type="password"
                        value={confirmPassword}
                    />
                </div>
            </div>
            <button disabled={disabled} className="primary-btn col-12 fw-500 mt-5" onClick={() => validateCode()}>
                {loading ? 'Confirming password...' : 'Confirm reset'}
            </button>

            <div className="d-flex black-color mt-3 justify-content-center ">
                {/* <span className={`${!activateResend ? 'light-grey-color cursor-disabled' : "pointer"}`} onClick={()=> activateResend && resendCode()}>
          Resend code
        </span>{" "} */}
                {/* <ResendOtp resendFunc={resendCode}/> */}
            </div>
        </div>
    );
};
