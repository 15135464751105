import './IDProductUploadDetails.css';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';

// import { useHistory } from 'react-router-dom';
// import Swal from 'sweetalert2';
import { Product } from '../../API';
// import { useProducts } from '../../views/products/useProducts';
import { ProductImage } from '../ProductImage/ProductImage';
import { IDProductUploadImages } from './IDProductUploadImages';
import { IDProductUploadInfo } from './IDProductUploadInfo';

/**
 * In order to be highly reusable, this component assumes its
 * parent's size
 */
export const IDProductUploadDetails: FC<{
    details: Product;
    setView: React.Dispatch<React.SetStateAction<string>>;
    deleteProduct: () => Promise<void>;
}> = ({ details, deleteProduct, setView }) => {
    const { image, ...info } = details;
    const images = Array.isArray(image) ? image : [image, image, image];

    const approvedProductMenu = (
        <Menu>
            <Menu.Item onClick={() => setView('uploadEdit')} key="1">
                <p onClick={() => setView('uploadEdit')} className="status-approval">
                    {' '}
                    <EditOutlined /> Edit
                </p>
            </Menu.Item>
            <Menu.Item onClick={deleteProduct} key="2">
                <p onClick={deleteProduct} className="status-approval">
                    {' '}
                    <DeleteOutlined /> Delete
                </p>
            </Menu.Item>
        </Menu>
    );

    return (
        <Row className="id-product-details">
            <Dropdown.Button overlay={approvedProductMenu}></Dropdown.Button>
            <Col>
                <div className="main-image-wrapper">
                    <ProductImage imgUrl={images[0] || ''} />
                </div>
                <div className="mt-3">
                    <IDProductUploadImages images={images} />
                </div>
            </Col>
            <Col>
                <IDProductUploadInfo info={info} />
            </Col>
        </Row>
    );
};
