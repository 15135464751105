import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';
import { useState } from 'react';

import {
    DmaSalesStatisticsQuery,
    DmaStatisticsQuery,
    ManufacturerSalesStatisticsQuery,
    ManufacturerStatisticsQuery,
    SearchOrdersAdminQuery,
} from '../../API';
import {
    dmaSalesStatistics,
    dmaStatistics,
    manufacturerSalesStatistics,
    manufacturerStatistics,
    searchOrdersAdmin,
} from '../../graphql/queries';

export type DashboardStats = { dma: number; manufacturer: number; manufacturersales: number; dmasales: number };

export const useDashboardData = () => {
    const [manufactureSalesStats, setManufactureSalesStats] = useState<any>();
    const [dmaSalesStats, setDmaSalesStats] = useState<any>();
    const [dmaStats, setDmaStats] = useState<any>();
    const [manufactureStats, setManufactureStats] = useState<any>();

    const [orders, setOrders] = useState<any>([]);

    const fetchLatestOrdersData = async () => {
        try {
            const q = graphqlOperation(searchOrdersAdmin, { limit: 5 });
            const data = (await API.graphql(q)) as GraphQLResult<SearchOrdersAdminQuery>;
            const latestOrders = data?.data?.searchOrdersAdmin?.result;
            setOrders(latestOrders);
        } catch (err: any) {
            console.log(err);
        }
    };

    const fetchDmaSalesStatistics = async () => {
        try {
            const q = graphqlOperation(dmaSalesStatistics);
            const { data } = (await API.graphql(q)) as GraphQLResult<DmaSalesStatisticsQuery>;
            const dmaData = data?.dmaSalesStatistics;
            setDmaSalesStats(dmaData);
        } catch (err: any) {
            console.log(err);
        }
    };

    const fetchDmaStatistics = async () => {
        try {
            const q = graphqlOperation(dmaStatistics);
            const { data } = (await API.graphql(q)) as GraphQLResult<DmaStatisticsQuery>;
            const dmaData = data?.dmaStatistics;
            setDmaStats(dmaData);
        } catch (err: any) {
            console.log(err);
        }
    };

    const fetchManufacturerStatistics = async () => {
        try {
            const q = graphqlOperation(manufacturerStatistics);
            const { data } = (await API.graphql(q)) as GraphQLResult<ManufacturerStatisticsQuery>;
            const manufacturerStats = data?.manufacturerStatistics;
            setManufactureStats(manufacturerStats);
        } catch (err: any) {
            console.log(err);
        }
    };

    const fetchManufacturerSalesStatistics = async () => {
        try {
            const q = graphqlOperation(manufacturerSalesStatistics);
            const { data } = (await API.graphql(q)) as GraphQLResult<ManufacturerSalesStatisticsQuery>;
            const manufacturerSalesData = data?.manufacturerSalesStatistics;
            setManufactureSalesStats(manufacturerSalesData);
        } catch (err: any) {
            console.log(err);
        }
    };

    return {
        orders,
        fetchDmaSalesStatistics,
        fetchManufacturerSalesStatistics,
        fetchManufacturerStatistics,
        fetchDmaStatistics,
        manufactureSalesStats,
        fetchLatestOrdersData,
        dmaSalesStats,
        dmaStats,
        manufactureStats,
    };
};
