import { Card, Col, Divider, Row } from 'antd';
import React, { useState } from 'react';
import BlockUi from 'react-block-ui';

import { DMA } from '../../../API';

interface ProfileType {
    id: string;
    user: DMA;
}
export const AccountInfoCard: React.FC<ProfileType> = ({ user }) => {
    const [accountInfo] = useState({
        ...user?.paymentAccounts,
    });

    return (
        <BlockUi>
            <Row>
                <Col span={12}>
                    <div className="primary-color fs-20 fw-500 mb-4">My Payment Accounts</div>
                </Col>
            </Row>
            <div className="d-flex justify-content-between fullwidth">
                <Card className="profile-card fullwidth mb-5">
                    <div className="flex-column fullwidth d-flex fw-500">Payment Accounts</div>
                    <hr />
                    <div className="mb-3">
                        <Row>
                            <Col span={12}>Account Number: {accountInfo?.accountNumber || 'N/A'}</Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={12}>Paybill Number: {accountInfo?.payBillNumber || 'N/A'}</Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={12}>Till Number: {accountInfo?.tillNumber || 'N/A'}</Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={12}>MPesa Number: {accountInfo?.mpesaNumber || 'N/A'}</Col>
                        </Row>
                    </div>
                </Card>
            </div>
        </BlockUi>
    );
};
