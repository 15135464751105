import { CSSProperties } from 'react';

export interface CardType {
    style?: CSSProperties; // Style for container
    className?: string; // Class for container

    inputStyle?: CSSProperties; // Style for child
    inputClassName?: string; // Class for child
}

export interface LeftPanelTypes {
    image?: string;
    header: string;
    activeText: string;
    subText?: string;
    breakText?: string;

    headerStyle?: CSSProperties;
    headerClassName?: string;

    subTextStyle?: CSSProperties;
    subTextClassName?: string;
}

export enum Status {
    Pending = 'PENDING',
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
    noDocument = 'NOT_UPLOADED',
    approvalPending = 'APPROVAL_PENDING',
}
