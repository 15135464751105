import './userdetails.css';

import { EditOutlined, MoreOutlined, UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { Popover, Select } from 'antd';
import { Breadcrumb, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useRouteMatch } from 'react-router-dom';
import Swal from 'sweetalert2';

import { useCreateUser } from '../createUser/useCreate';
import { PageTitle } from '../PageTitle';
import { useAdmin } from '../useAdmin';
import ProfileInitials from './components/ProfileInitials';

const { Option } = Select;

type UserPersonalDetailType = {
    firstname: string;
    id: string;
    enabled: boolean;
    email: string;
    phoneNumber: string;
    status: string;
    lastname?: string;
    activateUser: (id: string) => void;
    deActivateUser: (id: string) => void;
    loading?: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    handleSelectRole: (e: string) => void;
    selectedRole: string;
    editUserRole: (id: string) => void;
    isCreating: boolean;
    showModal: () => void;
    isModalVisible: boolean;
    handleOk: () => void;
    setIsModalVisible: (visible: boolean) => void;
};

type RolesAndPermissionType = {
    roleType: string[];
    permissions: Permissions[];
};

type Permissions = {
    alias: string;
    description: string;
    fieldName: string;
    permissionId: string;
};

const UserDetails = () => {
    const { params } = useRouteMatch();
    const { id } = params as any;
    const [loading, setLoading] = useState(true);

    const {
        getAdmin,
        adminData,
        activateUser,
        deActivateUser,
        handleSelectRole,
        selectedRole,
        editUserRole,
        isCreating,
        showModal,
        isModalVisible,
        handleOk,
        setIsModalVisible,
    } = useAdmin();

    useEffect(() => {
        const getData = async () => {
            await getAdmin(id);
            setLoading(false);
        };
        getData();
    }, [id]);

    useEffect(() => {
        const getData = async () => {
            await getAdmin(id);
            setLoading(false);
        };
        getData();
    }, [id]);

    return (
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <BlockUi blocking={loading}>
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="/users">Users</Breadcrumb.Item>
                                <Breadcrumb.Item>View More</Breadcrumb.Item>
                            </Breadcrumb>
                            <PageTitle title={'User Details'} />
                            <UserPersonalDetails
                                email={adminData.email || 'N/A'}
                                id={adminData.username || 'N/A'}
                                phoneNumber={adminData.phone_number || 'N/A'}
                                status={adminData.enabled ? 'Active' : 'Inactive'}
                                firstname={adminData.given_name || 'N/A'}
                                enabled={adminData.enabled || false}
                                deActivateUser={deActivateUser}
                                activateUser={activateUser}
                                loading={loading}
                                setLoading={setLoading}
                                handleSelectRole={handleSelectRole}
                                selectedRole={selectedRole}
                                editUserRole={editUserRole}
                                isCreating={isCreating}
                                showModal={showModal}
                                isModalVisible={isModalVisible}
                                handleOk={handleOk}
                                setIsModalVisible={setIsModalVisible}
                            />
                            <PageTitle title={'Roles & Permissions'} />
                            <div className={'user-permission'}>
                                <RolesAndPermission
                                    roleType={adminData.roles || []}
                                    permissions={adminData.permissions || []}
                                />
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div>
        </div>
    );
};

const UserPersonalDetails: React.FC<UserPersonalDetailType> = ({
    email,
    phoneNumber,
    status,
    firstname,
    id,
    enabled,
    activateUser,
    deActivateUser,
    setLoading,
    handleSelectRole,
    selectedRole,
    editUserRole,
    isCreating,
    showModal,
    isModalVisible,
    handleOk,
    setIsModalVisible,
}) => {
    const { roles, getUserRoles } = useCreateUser();
    // const {
    //     handleSelectRole,
    //     selectedRole,
    //     editUserRole,
    //     isCreating,
    //     showModal,
    //     handleOk,
    //     isModalVisible,
    //     setIsModalVisible,
    // } = useUserDetails();

    const DeactivateUser = (id: string) => {
        return Swal.fire({
            title: 'Deactivate User',
            text: `Are you sure you want to deactivate this user?`,
            confirmButtonColor: '#f01d1d',
            icon: 'error',
            showCancelButton: true,
        }).then(async (results: any) => {
            if (results.value) {
                setLoading(true);
                await deActivateUser(id);
                setLoading(false);
            }
        });
    };

    //useEffect hook to load roles
    useEffect(() => {
        getUserRoles();
    }, []);

    const ActivateUser = (id: string) => {
        return Swal.fire({
            title: 'Activate User',
            text: `Are you sure you want to activate this user?`,
            confirmButtonColor: '#2cb34a',
            icon: 'success',
            showCancelButton: true,
        }).then(async (results: any) => {
            if (results.value) {
                setLoading(true);
                await activateUser(id);
                setLoading(false);
            }
        });
    };

    const content = (
        <div>
            <>
                {enabled ? (
                    <p onClick={() => DeactivateUser(id)} className="pointer-emphasized">
                        <UserDeleteOutlined /> Deactivate User
                    </p>
                ) : (
                    <p onClick={() => ActivateUser(id)} className="pointer-emphasized">
                        <UserAddOutlined /> Activate User
                    </p>
                )}
            </>
            <div className="d-flex align-items-center" onClick={showModal}>
                <EditOutlined /> &nbsp; Edit Role
            </div>
        </div>
    );
    return (
        <div className={'card__base'}>
            <Row className={'flex justify-content-between'}>
                <Col md={6} sm={6}>
                    <ProfileInitials firstname={firstname} id={id} />
                </Col>
                <Col md={6} sm={6}>
                    <Popover className={'pull-right'} placement="leftTop" content={content} trigger="click">
                        <MoreOutlined />
                    </Popover>
                </Col>
            </Row>
            <Row className={'flex justify-content-between'}>
                <Col md={3}>
                    <div className={'user__label'}>Email Address</div>
                    <div className={'user__label__value'}>{email}</div>
                </Col>
                <Col md={3}>
                    <div className={'user__label'}>Phone number</div>
                    <div className={'user__label__value'}>{phoneNumber}</div>
                </Col>
                <Col md={3}>
                    <div className={'user__label'}>Status</div>
                    <div>
                        <div className={`user__label__${status}`}>{status}</div>
                    </div>
                </Col>
                <Col md={3} />
            </Row>
            <Modal visible={isModalVisible} footer={null} onCancel={() => setIsModalVisible(false)} onOk={handleOk}>
                <div className="text-align-center mb-4">Edit User Role.</div>
                <Row className={'mb-4'}>
                    <Col md={12} sm={12} xs={12}>
                        <div className="form-group">
                            <label className="auth-label mb-2">Role</label>
                            <Select
                                value={selectedRole}
                                style={{ width: '100%' }}
                                className="custom-select"
                                onChange={handleSelectRole}
                            >
                                <Option value="disabled" disabled>
                                    Select a Role
                                </Option>
                                {roles?.map((role, index) => (
                                    <Option key={role?.rolesId || index} value={role?.rolename || ''}>
                                        {role?.rolename}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </Col>
                </Row>
                <div className="d-flex justify-content-flex-end fullwidth uppercase">
                    <div className="cancel-btn fw-500 btn-width pointer" onClick={() => setIsModalVisible(false)}>
                        Cancel
                    </div>
                    <button
                        className="primary-btn fw-500 ml-3 btn-width uppercase "
                        onClick={() => editUserRole(id)}
                        disabled={isCreating}
                    >
                        {isCreating ? 'Editing role...' : 'Edit Role'}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

const RolesAndPermission: React.FC<RolesAndPermissionType> = ({ roleType, permissions }) => {
    return (
        <div className={''}>
            <div className={'d-flex align-items-center mb-4'}>
                <div className={'user__role user-margin-right'}>Role Type: </div>
                <div className={'user-details-name primary-color'}>
                    {roleType.length > 0 ? roleType.join(', ') : 'N/A'}
                </div>
            </div>
            <Form.Group className="roles-permission-checkbox-group" controlId="formBasicCheckbox">
                {permissions.map((p) => {
                    return (
                        <Form.Check
                            disabled
                            className="permissionsCheck"
                            checked
                            key={p.permissionId}
                            type="checkbox"
                            label={`${p.alias} (${p.description})`}
                        />
                    );
                })}
            </Form.Group>
        </div>
    );
};

export default UserDetails;
