/* eslint-disable react/jsx-key */
import React from 'react';
import { Table } from 'react-bootstrap';
import { useTable } from 'react-table';

type IProps = { data: any[]; columns: any[] };

const MyTable = ({ columns, data }: IProps) => {
    const { getTableProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
    });
    return (
        <Table striped responsive hover size="sm" {...getTableProps()}>
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr className="table-success" {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

export default MyTable;
