import { GraphQLResult } from '@aws-amplify/api';
import { message } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import axios from 'axios';
import _ from 'lodash';
import { useCallback, useState } from 'react';

import {
    AdminGetDmaReadPresignedUrlQuery,
    DeleteDmaMutation,
    ExportDmaAdminQuery,
    GetDmaByDmaIDQuery,
    SearchDmaAdminQuery,
    SearchFarmerOrdersAdminQuery,
} from '../../API';
import { GetBranchesQuery } from '../../API';
import { deleteDma, updateKycStatus } from '../../graphql/mutations';
import {
    adminGetDmaReadPresignedUrl,
    disableUser,
    enableUser,
    exportDmaAdmin,
    getBranches,
    getDmaByDmaID,
    getDmaUserDetails,
    getWards,
    listUsersInGroup,
    searchDmaAdmin,
    searchFarmerOrdersAdmin,
} from '../../graphql/queries';
import { reduceDmaList } from '../../utils';

const alert = message;

export const useDma = () => {
    const defaultFilters = {
        group: 'DMA',
        limit: 10,
        location: 'Select Location (Ward)',
        search_text: '',
        kyc_flow_state: 'Select Status',
        regions: '',
        counties: '',
        subCounties: '',
    };

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [data, setData] = useState<any>([]);
    const [dma, setDMA] = useState<any>([]);
    const [dmaExportData, setDmaExportData] = useState<any>([]);
    const [initialData, setInitialData] = useState<any>([]);
    const [downloadURL, setDownloadURL] = useState('');
    const [locations, setLocations] = useState([]);
    const [wards, setWards] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [singleUser, setSingleUser] = useState({} as GetDmaByDmaIDQuery['getDmaByDmaID']);
    const [isLoadingUser, setIsLoadingUser] = useState(true);
    const [updatingStatus, setUpdatingStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [type, setType] = useState('success');
    const [filters, setFilters] = useState(defaultFilters);
    const [popOverVisible, setPopoverVisible] = useState(false);
    const [checkingCanDeleteDma, setCheckingCanDeleteDma] = useState(false);
    const [canDeleteDma, setCanDeleteDma] = useState(false);
    const [aggregations, setAggregations] = useState<any>([]);

    const checkCanDeleteDmaFn = async (dmaId: string) => {
        if (!checkingCanDeleteDma) {
            setCheckingCanDeleteDma(true);
        }
        const query = graphqlOperation(searchFarmerOrdersAdmin, { dmaId });
        try {
            const { data } = (await API.graphql(query)) as GraphQLResult<SearchFarmerOrdersAdminQuery>;
            const canDeleteDma = (data?.searchFarmerOrdersAdmin?.result?.length ?? 0) == 0;
            setCanDeleteDma(canDeleteDma);
        } catch (e) {
            setCanDeleteDma(false);
        } finally {
            setCheckingCanDeleteDma(false);
        }
    };

    const deleteDmaFn = async (dmaId: string): Promise<DeleteDmaMutation | undefined> => {
        const query = graphqlOperation(deleteDma, { dmaId });
        const { data } = (await API.graphql(query)) as GraphQLResult<DeleteDmaMutation>;
        return data;
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleGetWards = async () => {
        try {
            const query = graphqlOperation(getWards);
            const { data } = (await API.graphql(query)) as GraphQLResult<any>;
            const wards = data?.getWards.map((p: any) => p?.name);
            wards?.sort((a: any, b: any) => a?.toLowerCase().localeCompare(b?.toLowerCase()));
            setWards(wards);
        } catch (error) {
            console.log(error);
        }
    };

    //fetch all dmas
    const fetchUsers = useCallback(async () => {
        const params: Record<string, any> = {};
        Object.entries(filters).forEach(([key, val]) => {
            if (val !== '' && !val.toString().includes('Select')) {
                params[key] = val;
            }
        });
        try {
            const query = graphqlOperation(listUsersInGroup, params);
            const { data }: any = await API.graphql(query);
            const users = data.listUsersInGroup;

            setLocations(users?.Locations);
            const mapped = users?.Users.map((user: Record<string, any>) => {
                const { Attributes: attributes } = user;

                // TODO: Please leave this type def for now. I'll declare what a manufacturer looks like later
                type DMA = Record<string, any>;
                const userAttr: DMA = reduceDmaList(attributes);

                userAttr.key = user.Username;
                userAttr.account_status = user.Enabled;
                userAttr.created_at = user.UserCreateDate;
                return userAttr;
            });
            setIsLoading(false);
            const sortedData = _.orderBy(mapped, 'created_at', ['asc']);
            setData(sortedData);
            setInitialData(mapped);
        } catch (e: any) {
            setIsLoading(false);
            console.log(e);
        }
    }, [filters]);

    const buildDmaAdminSearchParams = (queryParams: any, regions = '', counties = '', subCounties = '') => {
        if (regions) {
            queryParams.regions = regions;
        }
        if (counties) {
            queryParams.counties = counties;
        }
        if (subCounties) {
            queryParams.subCounties = subCounties;
        }
        return queryParams;
    };

    const getDMAAdmin = async (
        page = 1,
        limit = 10,
        wards = '',
        kyc_flow_state = '',
        q = '',
        exportData = false,
        regions = '',
        counties = '',
        subCounties = '',
    ) => {
        try {
            if (wards === 'Select Location (Ward)') wards = '';
            if (kyc_flow_state === 'Select Status') kyc_flow_state = '';

            const query = graphqlOperation(
                searchDmaAdmin,
                buildDmaAdminSearchParams({ page, limit, wards, kyc_flow_state, q }, regions, counties, subCounties),
            );
            const { data } = (await API.graphql(query)) as GraphQLResult<SearchDmaAdminQuery>;
            const dmaData = data?.searchDmaAdmin?.result;
            setDMA(dmaData);
            setTotalRows(data?.searchDmaAdmin?.totalCount as number);
            if (exportData) {
                await getDmaExportData(data?.searchDmaAdmin?.totalCount as number);
            }
            setInitialData(_.orderBy(dmaData, 'created_at', ['asc']));
            const agg = JSON.parse(data?.searchDmaAdmin?.aggregations || '{}');
            if (aggregations.length === 0) {
                setAggregations(agg);
            }
            console.log(agg);
            setIsLoading(false);
            return dmaData;
        } catch (e) {
            setIsLoading(false);
            console.log(e);
        }
    };

    const getDmaExportData = async (limit: Number) => {
        try {
            const query = graphqlOperation(searchDmaAdmin, {
                page: 1,
                limit,
                ward: '',
                kyc_flow_state: '',
                businessName: '',
            });
            const { data } = (await API.graphql(query)) as GraphQLResult<SearchDmaAdminQuery>;
            setDmaExportData(data?.searchDmaAdmin?.result);
        } catch (e) {
            console.log(e);
        }
    };

    const getReadUrl = async (id: string, download = false) => {
        const q = graphqlOperation(adminGetDmaReadPresignedUrl, { file_key: id });
        const { data } = (await API.graphql(q)) as GraphQLResult<AdminGetDmaReadPresignedUrlQuery>;

        const res = data?.adminGetDmaReadPresignedUrl;

        let documentUrl;
        if (res) documentUrl = JSON.parse(res);

        if (documentUrl?.url) {
            if (download) {
                await axios.get(documentUrl.url);
            }
            setDownloadURL(documentUrl.url);
        }
        setIsLoadingUser(false);
    };

    //fetch a single dma profile
    const getUserProfile = async (id: string, downloadKyc = true) => {
        const params = {
            dmaId: id,
        };
        setIsLoadingUser(true);
        try {
            const query = graphqlOperation(getDmaByDmaID, params);
            const { data } = (await API.graphql(query)) as GraphQLResult<GetDmaByDmaIDQuery>;
            const account = data?.getDmaByDmaID;
            setSingleUser(account);
            setIsLoadingUser(false);
            if (downloadKyc) {
                await getReadUrl(id);
            }
        } catch (e: any) {
            console.log(e);
            setIsLoadingUser(false);
        }
    };

    const getDMADetails = async (userId: string) => {
        try {
            const q = graphqlOperation(getDmaUserDetails, {
                userId,
            });
            const { data } = (await API.graphql(q)) as GraphQLResult<any>;
            return data?.getDmaUserDetails;
        } catch (e) {
            return {
                result: null,
                message: 'No data found',
            };
        }
    };

    const getDMABranches = async (dmaId: string) => {
        try {
            const q = graphqlOperation(getBranches, {
                dmaId,
            });
            const { data } = (await API.graphql(q)) as GraphQLResult<GetBranchesQuery>;
            return data?.getBranches?.data;
        } catch (e) {
            return null;
        }
    };

    const handleLocationChange = (value: string) => {
        setFilters({ ...filters, location: value, kyc_flow_state: 'Select Status' });
        getDMAAdmin(
            1,
            10,
            value,
            defaultFilters.kyc_flow_state,
            filters.search_text,
            false,
            filters.regions,
            filters.counties,
            filters.subCounties,
        );
    };

    const onChangeStatusFilter = (value: string) => {
        setFilters({ ...filters, kyc_flow_state: value, location: 'Select Location (Ward)' });
        getDMAAdmin(
            1,
            10,
            defaultFilters.location,
            value,
            filters.search_text,
            false,
            filters.regions,
            filters.counties,
            filters.subCounties,
        );
    };

    const onChangeWardRegionFilter = (value: string) => {
        setFilters({ ...filters, regions: value, location: 'Select Location (Ward)' });
        getDMAAdmin(
            1,
            10,
            filters.location,
            filters.kyc_flow_state,
            filters.search_text,
            false,
            value,
            filters.counties,
            filters.subCounties,
        );
    };

    const onChangeCountiesFilter = (value: string) => {
        setFilters({ ...filters, counties: value, location: 'Select Location (Ward)' });
        getDMAAdmin(
            1,
            10,
            filters.location,
            filters.kyc_flow_state,
            filters.search_text,
            false,
            filters.regions,
            value,
            filters.subCounties,
        );
    };

    const onChangeSubCountiesFilter = (value: string) => {
        setFilters({ ...filters, subCounties: value, location: 'Select Location (Ward)' });
        getDMAAdmin(
            1,
            10,
            defaultFilters.location,
            filters.kyc_flow_state,
            filters.search_text,
            false,
            filters.regions,
            filters.counties,
            value,
        );
    };

    const updateDmaStatus = async (username: string, action: string) => {
        try {
            setUpdatingStatus(true);
            if (action === 'enable') {
                const q = graphqlOperation(enableUser, { username });
                await API.graphql(q);
            } else if (action === 'disable') {
                const q = graphqlOperation(disableUser, { username });
                await API.graphql(q);
            } else {
                await API.graphql(graphqlOperation(updateKycStatus, { dmaId: username, status: action }));
                // const mutation = ;
                // await API.graphql(mutation);
            }

            setUpdatingStatus(false);
            await getUserProfile(username);
            alert.success('Successfully Updated');
        } catch (e: any) {
            //TODO: display dynamic error
            alert.error('Could not complete this request.');
            setUpdatingStatus(false);
            setType('error');
            console.log(e);
        }
    };

    const handleExportClick = async (dateRange: { startDate: Date; endDate: Date }, csvLinkRef: any) => {
        try {
            setPopoverVisible(false);
            const gte = new Date(dateRange.startDate.getTime());
            gte.setHours(0, 0, 0, 0);
            const query = graphqlOperation(exportDmaAdmin, { gte, lte: dateRange.endDate });
            const { data } = (await API.graphql(query)) as GraphQLResult<ExportDmaAdminQuery>;
            setDmaExportData(data?.exportDmaAdmin?.result || []);
            csvLinkRef?.current?.link.click();
        } catch (err: any) {
            console.log(err);
        }
    };

    const handlePopoverVisibleChange = (visible: boolean) => setPopoverVisible(visible);

    return {
        showModal,
        handleCancel,
        handleOk,
        setIsModalVisible,
        isModalVisible,
        fetchUsers,
        data,
        isLoading,
        setIsLoading,
        getUserProfile,
        getDMADetails,
        getDMABranches,
        singleUser,
        isLoadingUser,
        updateDmaStatus,
        type,
        updatingStatus,
        locations,
        filters,
        initialData,
        handleLocationChange,
        setFilters,
        onChangeStatusFilter,
        downloadURL,
        defaultFilters,
        wards,
        getDMAAdmin,
        totalRows,
        handleGetWards,
        dma,
        dmaExportData,
        handlePopoverVisibleChange,
        popOverVisible,
        handleExportClick,
        getReadUrl,
        checkCanDeleteDmaFn,
        checkingCanDeleteDma,
        canDeleteDma,
        deleteDmaFn,
        aggregations,
        onChangeWardRegionFilter,
        onChangeCountiesFilter,
        onChangeSubCountiesFilter,
    };
};
