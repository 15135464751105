import './ProductDetails.css';

import { Breadcrumb } from 'antd';
import { FC } from 'react';
import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { Col, Row } from 'react-bootstrap';

// import { useRouteMatch } from 'react-router-dom';
import { IDProductUploadDetails } from '../../../components/IDProductUploadDetails';
import { PageTitle } from '../../../components/PageTitle';
import { useProducts } from '../useProducts';

type ProductUploadDetailsProps = {
    productDetailsId: string | null;
    setView: React.Dispatch<React.SetStateAction<string>>;
    deleteProduct: () => Promise<void>;
};

const ProductUploadDetails: FC<ProductUploadDetailsProps> = ({ productDetailsId, setView, deleteProduct }) => {
    // const { params } = useRouteMatch();
    // const { id } = params as any;
    const { product, getSingleProduct } = useProducts();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getSingleProduct(productDetailsId || '');
        setLoading(false);
    }, [getSingleProduct, productDetailsId]);

    return (
        <BlockUi blocking={loading}>
            <Breadcrumb className="detailsBreadCrumbs" separator=">">
                <Breadcrumb.Item
                    href=""
                    onClick={(e) => {
                        e.preventDefault();
                        setView('uploadList');
                    }}
                >
                    Products Upload List
                </Breadcrumb.Item>
                <Breadcrumb.Item>Product Upload View Details</Breadcrumb.Item>
            </Breadcrumb>
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div>
                                <PageTitle title={'Product Upload View Details'} />
                                <Row style={{ marginTop: -30 }}>
                                    <Col className="mt-1">
                                        {product && (
                                            <IDProductUploadDetails
                                                setView={setView}
                                                deleteProduct={deleteProduct}
                                                details={product}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BlockUi>
    );
};

export default ProductUploadDetails;
