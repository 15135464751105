import { useHistory } from 'react-router-dom';

import { getCookieValue, setCookieData } from './cookies';
const USERNAME_KEY = 'hdsgfhsjur634765437isdfisdyfstydfds';
const LOGIN_ID = 'gdafjsdgf46738465843sjfgsdhjfg';
export const setUsername = (value: string) => setCookieData(USERNAME_KEY, value);
export const setLoggedIn = (situation: boolean) => setCookieData(LOGIN_ID, situation ? 'ayie' : 'atamora');
export const isLoggedIn = () => getCookieValue(LOGIN_ID) === 'ayie';
export const getUsername = () => getCookieValue(USERNAME_KEY);

export const authListener = (data: any) => {
    const history = useHistory();
    console.log(data);
    switch (data.payload.event) {
        case 'signIn':
            console.info('user signed in');
            break;
        case 'signUp':
            console.info('user signed up');
            break;
        case 'signOut':
            console.info('user signed out');
            history.push('/auth/login');
            break;
        case 'signIn_failure':
            console.error('user sign in failed');
            break;
        case 'tokenRefresh':
            console.info('token refresh succeeded');
            break;
        case 'tokenRefresh_failure':
            console.error('token refresh failed');
            break;
        case 'configured':
            console.info('the Auth module is configured');
    }
};
export const getFullName = (fn = '', mn = '', ln = '') => {
    return `${fn} ${mn} ${ln}`;
};
