import { GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';

import { ListUsersInGroupQuery, ManufacturerDetailResult } from '../../API';
import { disableUser, enableUser, searchManufacturer } from '../../graphql/queries';
import { getAccount, listUsersInGroup } from '../../graphql/queries';
import { Manufacturer } from '../../shared-types/manufacturer.model';
import { mapAttributesToManufacturer } from '../../utils/common';
import { DisableUserQuery, EnableUserQuery, GetAccountQuery } from './../../API';
import {
    ActionDispatch,
    GET_ALL_MANUFACTURERS,
    GET_ALL_MANUFACTURERS_ERROR,
    GET_ALL_MANUFACTURERS_SUCCESS,
    GET_MANUFACTURER,
    GET_MANUFACTURER_ERROR,
    RETRIEVE_MANUFACTURER,
    RETRIEVE_MANUFACTURER_ERROR,
    TOGGLE_MANUFACTURER,
    TOGGLE_MANUFACTURER_ERROR,
} from './../actionTypes';
import { AppState } from './../reducers/index';

export const fetchManufacturers = (filters?: any) => async (dispatch: ActionDispatch) => {
    try {
        dispatch({ type: GET_ALL_MANUFACTURERS });

        const query = graphqlOperation(listUsersInGroup, {
            group: 'Manufacturers',
            ...(filters || {}),
        });
        const { data } = (await API.graphql(query)) as GraphQLResult<ListUsersInGroupQuery>;

        const users = data?.listUsersInGroup?.Users || [];

        if (!users.length) {
            throw new Error('No users found');
        }

        const mapped = users.map(mapAttributesToManufacturer);

        dispatch({ type: GET_ALL_MANUFACTURERS_SUCCESS, payload: mapped });
    } catch (e: any) {
        dispatch({ type: GET_ALL_MANUFACTURERS_ERROR });
    }
};

export const toggleManufacturerActiveState =
    (name: string, isActivation?: boolean) => async (dispatch: ActionDispatch, getState: () => AppState) => {
        const { manufacturers: manState } = getState();
        const schema = isActivation ? enableUser : disableUser;

        try {
            const query = graphqlOperation(schema, { username: manState.manufacturer?.username });

            (await API.graphql(query)) as GraphQLResult<EnableUserQuery | DisableUserQuery>;

            let man;

            if (manState.manufacturer) {
                man = {
                    ...manState.manufacturer,
                    isActive: isActivation,
                    enabled: isActivation,
                };
            }

            const manufacturers = manState.manufacturers.map((manufacturer) =>
                manufacturer.name === name ? { ...manufacturer, isActive: isActivation } : manufacturer,
            );

            dispatch({
                type: TOGGLE_MANUFACTURER,
                payload: { manufacturers, manufacturer: man },
            });
        } catch (e: any) {
            dispatch({ type: TOGGLE_MANUFACTURER_ERROR });
        }
    };

export const getManufacturer = (username: string) => async (dispatch: ActionDispatch, getState: () => AppState) => {
    const { manufacturers: manState } = getState();

    try {
        let manufacturer: Partial<Manufacturer> | undefined = manState.manufacturers.find(
            (manufacturer) => manufacturer.username === username,
        );

        if (manufacturer) {
            dispatch({
                type: GET_MANUFACTURER,
                payload: manufacturer,
            });

            return manufacturer;
        }

        const query = graphqlOperation(getAccount, { username });
        const { data } = (await API.graphql(query)) as GraphQLResult<GetAccountQuery>;

        manufacturer = mapAttributesToManufacturer({
            ...(data?.getAccount || {}),
            Attributes: data?.getAccount?.UserAttributes,
        });

        dispatch({
            type: GET_MANUFACTURER,
            payload: manufacturer,
        });

        return manufacturer;
    } catch (e: any) {
        dispatch({ type: GET_MANUFACTURER_ERROR });
    }
};

export const retrieveManufacturer =
    (manufacturerId: string) => async (dispatch: ActionDispatch, getState: () => AppState) => {
        const { manufacturersList: manState } = getState();

        try {
            let manufacturer: Partial<ManufacturerDetailResult> | undefined | null = manState.manufacturers.find(
                (manufacturer) => manufacturer.manufacturerId === manufacturerId,
            );

            if (manufacturer) {
                dispatch({
                    type: RETRIEVE_MANUFACTURER,
                    payload: manufacturer,
                });

                return manufacturer;
            }

            const query = graphqlOperation(searchManufacturer, { manufacturerId });
            const { data } = (await API.graphql(query)) as GraphQLResult<any>;

            const mainResult = data?.searchManufacturer;
            let manufacturers = mainResult?.data?.result || [];

            if (mainResult?.code !== 200) {
                manufacturers = [];
            }
            manufacturer = manufacturers[0];
            dispatch({
                type: RETRIEVE_MANUFACTURER,
                payload: manufacturer,
            });

            return manufacturer;
        } catch (e: any) {
            dispatch({ type: RETRIEVE_MANUFACTURER_ERROR });
        }
    };
