import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { Alert } from 'react-bootstrap';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import logo from '../../../assets/LOGO/drawable-hdpi/digifarm_logo_approved.png';
import {
    changeNewManufacturerPassword,
    loginManufacturer,
    sendResetPasswordInstruction,
} from '../../../redux/actions/authActions';
import { RootState } from '../../../redux/store';
import { Views } from '../auth-constants';
import { AuthContentWrapper } from '../components/AuthContentWrapper';
import { LeftPanel } from '../components/LeftPanel';
import { LeftPanelWrapper } from '../components/LeftPanelWrapper';
import useLeftPanelCopy from '../hooks/useLeftPanelCopy';
import { ChangePasswordForm, ChangePasswordPayload } from './ChangePasswordForm';
import { ForgotPassword } from './ForgotPassword';
import { SignInForm } from './SignInForm';
import { VerifyForgotPassword } from './VerifyForgotPassword';

const SignIn: React.FC<IProps> = ({ auth }) => {
    const [copy, setCopy, view] = useLeftPanelCopy(Views.Login);
    const [isResettingPassword, setIsResettingPassword] = useState(false);
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [deliveryMethod, setDeliveryMethod] = useState('');
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!auth) {
            return;
        }
        if (auth) {
            if ('error' in auth) {
                setError(auth.error);
            }
            if (
                (!('session' in auth) &&
                    'user' in auth &&
                    !('email_verified' in auth?.user && 'phone_number_verified' in auth?.user)) ||
                auth.isNewUser
            ) {
                setIsResettingPassword(true);
            } else {
                setIsResettingPassword(false);
            }
        }
    }, [auth]);

    const handleSignIn = (userId: string, password: string) => {
        setIsSubmitting(true);
        dispatch(loginManufacturer(userId, password));
    };

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError('');
            }, 3000);
        }
        setIsSubmitting(false);
    }, [error]);
    const history = useHistory();

    useEffect(() => {
        if (auth.isAuthenticated && !auth.isNewUser) {
            history.push('/');
            return;
        }
    }, [auth.isAuthenticated]);

    const handleChangePassword = ({ currentPassword, newPassword, givenName }: ChangePasswordPayload) => {
        dispatch(
            changeNewManufacturerPassword(
                currentPassword,
                newPassword,
                auth?.user?.username,
                givenName,
                setIsResettingPassword,
                setLoading,
            ),
        );
    };

    const gotoResetPasswordForm = () => {
        setCopy(Views.ManufacturerResetPassword);
    };

    const sendResetPasswordEmail = (email: string) => {
        dispatch(sendResetPasswordInstruction(email));
    };

    return (
        <BlockUi blocking={loading}>
            <div className="d-flex height-100vh position-relative justify-content-center">
                <LeftPanelWrapper>
                    <LeftPanel {...copy} />
                </LeftPanelWrapper>
                <AuthContentWrapper>
                    {error && (
                        <Alert key="alert-key" variant="warning">
                            {error}
                        </Alert>
                    )}
                    <div className="text-align-center m-display">
                        <img alt="logo" src={logo} className={'mb-2'} width={70} />
                    </div>

                    <ChangePasswordForm
                        onChangePassword={handleChangePassword}
                        show={isResettingPassword}
                        setLoading={setLoading}
                        close={() => setIsResettingPassword(false)}
                    />
                    {view === Views.ManufacturerResetPassword ? (
                        <ForgotPassword
                            setDeliveryMethod={setDeliveryMethod}
                            setView={setCopy}
                            setUsername={setUsername}
                            sendResetInfo={sendResetPasswordEmail}
                        />
                    ) : view === Views.VerifyResetPassword ? (
                        <VerifyForgotPassword deliveryMethod={deliveryMethod} setView={setCopy} username={username} />
                    ) : (
                        <SignInForm
                            signIn={handleSignIn}
                            resetPassword={() => gotoResetPasswordForm()}
                            isSubmitting={isSubmitting}
                        />
                    )}
                </AuthContentWrapper>
            </div>
        </BlockUi>
    );
};
const mapStateToProps = ({ auth }: RootState) => ({
    auth,
});
const connector = connect(mapStateToProps);
type IProps = ConnectedProps<typeof connector>;
export default connector(SignIn);
