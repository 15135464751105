import { DeleteOutlined, EyeOutlined, MoreOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import _ from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { NestedProducts } from '../../API';

export const capitalizeFirstLetter = (string: string | null | undefined) => {
    if (!string) return;
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getApprovedProductsColumns = () => [
    // {
    //     title: '',
    //     dataIndex: 'checked',
    //     key: 'checked',
    //     render: () => {
    //         return <input type="checkbox" checked />;
    //     },
    // },
    {
        title: 'ID',
        dataIndex: 'productId',
        key: 'productId',
    },
    {
        title: 'Manufacturer Name',
        dataIndex: 'company_name',
        key: 'company_name',
    },
    {
        title: 'Input Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Input Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
    },

    {
        title: 'Total Price (Ksh)',
        dataIndex: 'price',
        key: 'price',
        render: (_: unknown, data?: any) => {
            return <div>{data.quantity * data.price || 'N/A'}</div>;
        },
    },

    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_: unknown, data?: any) => {
            return <div className={data.status}>{capitalizeFirstLetter(data.status)}</div>;
        },
    },
];

export const getInvalidProductsColumns = () => [
    {
        title: 'ID',
        dataIndex: 'productId',
        key: 'productId',
    },
    {
        title: 'Manufacturer Name',
        dataIndex: 'company_name',
        key: 'company_name',
    },
    {
        title: 'Input Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Input Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
    },

    {
        title: 'Total Price (Ksh)',
        dataIndex: 'price',
        key: 'price',
        render: (_: unknown, data?: any) => {
            return <div>{data.quantity * data.price || 'N/A'}</div>;
        },
    },

    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_: unknown, data?: any) => {
            return <div className={data.status}>{capitalizeFirstLetter(data.status)}</div>;
        },
    },
];

export const getUploadColumns = (
    setView: React.Dispatch<React.SetStateAction<string>>,
    setProductDetailsId: React.Dispatch<React.SetStateAction<string | null>>,
) => [
    {
        title: 'ID',
        dataIndex: 'productId',
        key: 'productId',
    },
    {
        title: 'Manufacturer Name',
        dataIndex: 'company_name',
        key: 'company_name',
    },
    {
        title: 'Input Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Input Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
    },

    {
        title: 'Total Price (Ksh)',
        dataIndex: 'price',
        key: 'price',
        render: (_: unknown, data?: any) => {
            return <div>{data.quantity * data.price}</div>;
        },
    },

    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_: unknown, data?: any) => {
            return <div className={data.status}>{capitalizeFirstLetter(data.status)}</div>;
        },
    },
    {
        dataIndex: 'action',
        key: 'action',
        render: (action: any, data: any) => {
            const content = (
                <>
                    {/* <div style={{ cursor: 'pointer' }} onClick={() => setView('uploadEdit')}>
                        <EditOutlined /> &nbsp; Edit
                    </div>

                    <div style={{ cursor: 'pointer' }} onClick={deleteProduct}>
                        <DeleteOutlined /> &nbsp; Delete
                    </div> */}
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setProductDetailsId(data.productId);
                            setView('uploadDetails');
                        }}
                    >
                        <EyeOutlined /> &nbsp; View More
                    </div>
                </>
            );
            return (
                <Popover placement="leftTop" content={content} trigger="click">
                    <MoreOutlined />
                </Popover>
            );
        },
    },
];

export const getColumns = (onMoreClicked: (...args: any[]) => void, onDelete?: (data: any) => unknown) => [
    {
        title: '',
        dataIndex: '',
        key: '',
    },
    {
        title: '',
        dataIndex: 'checkbox',
        key: 'productId',
    },
    {
        title: 'ID',
        dataIndex: 'productId',
        key: 'productId',
    },
    {
        title: 'Hub ID',
        dataIndex: 'hubId',
        key: 'hubId',
    },
    {
        title: 'Manufacturer Name',
        dataIndex: 'company_name',
        key: 'company_name',
    },
    {
        title: 'Input Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Input Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
    },

    {
        title: 'Total Price (Ksh)',
        dataIndex: 'price',
        key: 'price',
        render: (_: unknown, data?: any) => {
            return <div>{data.quantity * data.price}</div>;
        },
    },

    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_: unknown, data?: any) => {
            return <div className={data.status}>{capitalizeFirstLetter(data.status)}</div>;
        },
    },
    {
        dataIndex: 'action',
        key: 'action',
        render: (action: any, data: any) => {
            const content = (
                <>
                    <NavLink to={`/products/${data.productId}`}>
                        <EyeOutlined /> &nbsp; View More
                    </NavLink>
                    <div onClick={() => onDelete && onDelete(data.productId)}>
                        <DeleteOutlined /> &nbsp; Delete
                    </div>
                </>
            );
            return (
                <Popover placement="leftTop" content={content} trigger="click">
                    <MoreOutlined />
                </Popover>
            );
        },
    },
];

export const mapProductsToTableData = (products: Array<NestedProducts | null>) => {
    let mappedProducts = products.map((product) => {
        return {
            productId: product?.productId,
            company_name: product?.company_name,
            dmaPrice: product?.dmaPrice,
            rebates: product?.rebates,
            inputName: product?.name,
            quantity: product?.quantity,
            type: product?.type,
            name: product?.name,
            category: product?.category,
            price: product?.price,
            dateAdded: product?.creationDate,
            status: product?.status,
            image: product?.image,
            key: product?.productId,
            hubId: product?.hubId,
        };
    });
    mappedProducts = _.orderBy(mappedProducts, ['dateAdded'], ['desc']);
    return mappedProducts;
};
