import React from 'react';

interface titleProps {
    title: string;
}

export const PageTitle: React.FC<titleProps> = (props) => {
    const { title } = props;
    return (
        <div className="d-flex justify-content-between fullwidth">
            <div className="fs-20 primary-color fw-500">{title}</div>
            <div>{props.children}</div>
        </div>
    );
};
