import { GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';

import { GetCategoriesQuery } from '../../API';
import { getCategories } from '../../graphql/queries';
import { ActionDispatch, GET_CATEGORIES, GET_CATEGORIES_ERROR, GET_CATEGORIES_SUCCESS } from '../actionTypes';

export const fetchCategories = (filters?: any) => async (dispatch: ActionDispatch) => {
    try {
        dispatch({ type: GET_CATEGORIES });

        const query = graphqlOperation(getCategories, {
            ...(filters || {}),
        });
        const { data } = (await API.graphql(query)) as GraphQLResult<GetCategoriesQuery>;

        const items = data?.getCategories;
        dispatch({ type: GET_CATEGORIES_SUCCESS, payload: items });
    } catch (e) {
        dispatch({ type: GET_CATEGORIES_ERROR });
    }
};
