import './demand.scss';

import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { Breadcrumb, DatePicker, Select } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import { get } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import DataTable, { TableColumn } from 'react-data-table-component';

// import { NavLink } from 'react-router-dom';
import { USSDFarmerOrderData, USSDFarmerOrderResponse } from '../../API';
import { Search } from '../../components/Search';
import { getUSSDFarmerOrder } from '../../graphql/queries';
import { formatAmountWithCurrency } from '../../utils';
import { PageTitle } from '../products/PageTitle';
import OrderInformation from './OrderInformation';

const { Option } = Select;

const csvHeaders = [
    { label: 'Id', key: 'orderId' },
    { label: 'Date of Order', key: 'createdDateTime' },
    { label: 'Date of Delivery', key: 'preOrderDeliveryDate' },
    { label: 'Farmer Name', key: 'farmerName' },
    { label: 'DMA Assigned', key: 'dmaName' },
    { label: 'Amount', key: 'orderTotalCost' },
    { label: 'Status', key: 'orderPaymentStatus' },
];

const mappedTableData = (tableData: any[]) => {
    return tableData.map((data) => {
        return {
            orderId: data.orderId,
            createdDateTime: data.createdDateTime,
            preOrderDeliveryDate: data.preOrderDeliveryDate,
            farmerName: `${data.farmerModel?.firstName} ${data.farmerModel?.lastName}`,
            dmaName: `${data.dmaFirstName} ${data.dmaLastName}`,
            orderTotalCost: data.orderTotalCost,
            orderPaymentStatus: data.orderPaymentStatus,
        };
    });
};

const columns: TableColumn<USSDFarmerOrderData>[] = [
    {
        name: 'ID',
        selector: (i: USSDFarmerOrderData) => i?.orderId?.slice(-6).toUpperCase() as any,
    },
    {
        name: 'Date of Order',
        selector: (i: USSDFarmerOrderData) => i?.createdDateTime as any,
    },
    {
        name: 'Date of Delivery',
        selector: (i: USSDFarmerOrderData) => i?.preOrderDeliveryDate as any,
    },
    {
        name: 'Farmer Name',
        selector: (i: USSDFarmerOrderData) =>
            `${i?.farmerModel?.firstName} ${i?.farmerModel?.lastName}`.toUpperCase() as any,
    },
    {
        name: 'DMA Assigned',
        selector: (i: USSDFarmerOrderData) => `${i?.dmaFirstName} ${i?.dmaLastName}`.toUpperCase() as any,
    },
    {
        name: 'Amount',
        selector: (i: USSDFarmerOrderData) => formatAmountWithCurrency(i?.orderTotalCost) as any,
    },
    {
        name: 'Status',
        selector: (i: USSDFarmerOrderData) => i?.orderPaymentStatus?.toUpperCase() ?? ('PENDING' as any),
        conditionalCellStyles: [
            {
                when: (row: USSDFarmerOrderData) => row?.orderPaymentStatus?.toUpperCase() === ('FAILED' as any),
                style: {
                    backgroundColor: 'rgba(239, 55, 62, 0.4)',
                    color: '#ef373e',
                },
            },
            {
                when: (row: USSDFarmerOrderData) => row?.orderPaymentStatus?.toUpperCase() === ('COMPLETED' as any),
                style: {
                    backgroundColor: 'rgba(59, 184, 77, 0.4)',
                    color: '#3bb84d',
                },
            },
        ],
    },
];
const customStyles = {
    headCells: {
        style: {
            backgroundColor: '#2cb34a', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
};
const FarmerRequests = () => {
    const [tableData, setTableData] = useState<USSDFarmerOrderData[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [orderTypeFilter, setOrderTypeFilter] = useState('ORDER');
    const [startDateFilter, setStartDateFilter] = useState<Moment | null>(null);
    const [endDateFilter, setEndDateFilter] = useState<Moment | null>(null);

    const orderTypes = ['ORDER', 'PREORDER'];

    const fetchFarmerRequests = async (
        page: number,
        size = perPage,
        type = orderTypeFilter,
        startDate = startDateFilter,
        endDate = endDateFilter,
    ) => {
        try {
            setLoading(true);

            const q = graphqlOperation(getUSSDFarmerOrder, {
                page,
                size,
                orderType: type,
                startDate: startDate && moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
                endDate: endDate && moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
            });
            const data = await API.graphql(q);
            const fResponse: USSDFarmerOrderResponse = get(data, 'data.getUSSDFarmerOrder');
            setTableData((fResponse?.body?.data as any[]) ?? []);
            setTotalRows(fResponse?.body?.data?.length || 0);
        } catch (err: any) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            fetchFarmerRequests(currentPage);
        })();
    }, []);

    const handlePageChange = (page: number) => {
        fetchFarmerRequests(page);
        setCurrentPage(page);
    };

    const handleStartDateChange = async (date: any) => {
        setStartDateFilter(date);
        await fetchFarmerRequests(currentPage, perPage, orderTypeFilter, date);
    };

    const handleEndDateChange = async (date: any) => {
        setEndDateFilter(date);
        await fetchFarmerRequests(currentPage, perPage, orderTypeFilter, startDateFilter, date);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        fetchFarmerRequests(page, newPerPage);
        setPerPage(newPerPage);
    };

    const handleOrderTypeChange = async (type: string) => {
        setOrderTypeFilter(type);
        await fetchFarmerRequests(currentPage, perPage, type);
    };

    const clearAllFilters = async () => {
        setOrderTypeFilter('ORDER');
        setStartDateFilter(null);
        setEndDateFilter(null);
        await fetchFarmerRequests(currentPage, perPage, 'ORDER', null, null);
    };

    return (
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="demand">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="">Demand Requests</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                    <div className="row mt-4 mb-3">
                        <PageTitle title="Farmer Demand Requests" />
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-end">
                            <div className="p-2 bd-highlight">
                                <div className="border-btn text-center" onClick={() => window.print()}>
                                    Print <PrinterOutlined style={{ color: '#2cb34a' }} />
                                </div>
                            </div>
                            <div className="p-2 bd-highlight">
                                {' '}
                                <CSVLink
                                    data={mappedTableData(tableData)}
                                    headers={csvHeaders}
                                    filename="Farmer-Requests-data"
                                >
                                    <div className="border-btn text-center">
                                        Export <DownloadOutlined style={{ color: '#2cb34a' }} />
                                    </div>
                                </CSVLink>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-md-2 mb-2">
                            <Select className="w-100" value={orderTypeFilter} onChange={handleOrderTypeChange}>
                                {orderTypes.map((orderType) => {
                                    return (
                                        <Option value={orderType} key={orderType}>
                                            {orderType}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="col-md-2 col-sm-6 mb-2">
                            <DatePicker
                                placeholder="Start Date"
                                className="farmerRequestDate w-100"
                                onChange={handleStartDateChange}
                                value={startDateFilter}
                            />
                        </div>
                        <div className="col-md-2 col-sm-6 mb-2">
                            <DatePicker
                                placeholder="End Date"
                                className="farmerRequestDate w-100"
                                onChange={handleEndDateChange}
                                value={endDateFilter}
                            />
                        </div>
                        <div className="col-md-4">
                            <Search
                                iconColor={'#d7d7d7'}
                                placeholder="Search"
                                value={searchTerm}
                                onChange={(value) => setSearchTerm(value)}
                            />
                        </div>
                        <div className="col-md-2">
                            <div className="primary-color fw-500 p-3 pointer" onClick={clearAllFilters}>
                                Clear All Filters
                            </div>
                        </div>
                    </div>

                    <div style={{ overflowX: 'auto' }}>
                        <DataTable<USSDFarmerOrderData>
                            className="dma-header products-table"
                            data={tableData}
                            columns={columns}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            progressPending={loading}
                            striped
                            customStyles={customStyles}
                            expandableRows
                            expandableRowsComponent={OrderInformation}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FarmerRequests;
