import '../../roles/roles.css';

import { GraphQLResult } from '@aws-amplify/api';
import { Breadcrumb } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import React, { ChangeEvent, MouseEvent, useState } from 'react';
import BlockUi from 'react-block-ui';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import FormTextarea from '../../../components/inputs/FormTextarea';
import { Input } from '../../../components/inputs/Input';
import { createDMARole } from '../../../graphql/mutations';

interface Iprops {
    setKey: (key: string) => void;
}

const CreateDMARoles: React.FC<Iprops> = () => {
    const [rolename, setRolename] = useState('');
    const [alias, setAlias] = useState('');
    const [description, setDescription] = useState('');
    const [creating, setCreating] = useState(false);

    const saveRole = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        try {
            if (!rolename || !alias || !description) {
                return toast.error('All inputs are required!');
            }

            const specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

            if (/\s/.test(rolename) || /\s/.test(alias) || specialChars.test(rolename) || specialChars.test(alias)) {
                return toast.error('Role name and Role alias cant contain spaces or specials characters.');
            }

            setCreating(true);
            const query = graphqlOperation(createDMARole, {
                role: {
                    rolename,
                    description,
                    alias,
                },
            });
            const res = (await API.graphql(query)) as GraphQLResult<any>;
            const results = JSON.parse(res.data.createDMARole);

            if (results.status === 'SUCCESSED') {
                setRolename('');
                setAlias('');
                setDescription('');
                sessionStorage.setItem('createdDMARole', results.data.roleId);
                history.push('/dma-roles/edit');
            } else {
                console.log(results.data.message);
                return toast.error(results.data.message);
            }
        } catch (e: any) {
            return toast.error('Error creating role', e);
        } finally {
            setCreating(false);
        }
    };

    const history = useHistory();

    return (
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <BlockUi blocking={creating}>
                            <ToastContainer />
                            <div>
                                <Breadcrumb separator=">">
                                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item>DMA Roles and Permissions</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>

                            <h6 className="roles-heading">Create Role</h6>

                            <div>
                                <Card className="roles-cardbody shadow-lg p-3 mb-5">
                                    <Card.Body>
                                        <Form>
                                            <div className="d-flex">
                                                <Col md={6} className="p-3">
                                                    <div className="form-group">
                                                        <label className="auth-label mb-2">DMA Role Name</label>
                                                        <Input
                                                            className=""
                                                            placeholder="Rolename"
                                                            value={rolename}
                                                            type="text"
                                                            required
                                                            onChange={(e) => setRolename(e)}
                                                            name="role_name"
                                                            disabled={false}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6} className="p-3">
                                                    <div className="form-group">
                                                        <label className="auth-label mb-2">DMA Role Alias</label>
                                                        <Input
                                                            className=""
                                                            placeholder="Alias"
                                                            value={alias}
                                                            type="text"
                                                            required
                                                            onChange={(e) => setAlias(e)}
                                                            name="role_alias"
                                                            disabled={false}
                                                        />
                                                    </div>
                                                </Col>
                                            </div>
                                            <div className="d-flex">
                                                <Col md={6} className="p-3">
                                                    <div className="form-group">
                                                        <label className="auth-label mb-2">DMA Role Description</label>
                                                        <FormTextarea
                                                            className=""
                                                            placeholder="Description"
                                                            value={description}
                                                            type="text"
                                                            required
                                                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                                                                setDescription(e.target.value)
                                                            }
                                                            name="role_description"
                                                            disabled={false}
                                                        />
                                                    </div>
                                                </Col>
                                            </div>
                                            <div className="d-flex justify-content-flex-end fullwidth mt-5">
                                                <div
                                                    className="cancel-btn fw-500 m-3 btn-width pointer"
                                                    onClick={() => history.push('/dma-roles')}
                                                >
                                                    Cancel
                                                </div>
                                                <button
                                                    onClick={(e) => saveRole(e)}
                                                    className="primary-btn fw-500 m-3 btn-width"
                                                    disabled={creating}
                                                >
                                                    {creating ? 'Loading...' : 'Save'}
                                                </button>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateDMARoles;
