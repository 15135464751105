import { GraphQLResult } from '@aws-amplify/api-graphql';
import { Breadcrumb } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import { FormikHelpers, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { Button, Card, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { createHub } from '../../../graphql/mutations';
import { formatNumber } from '../../../utils';
import { createHubSchema } from './schema';

const CreateHub: React.FC<{}> = () => {
    const [manufacturerId, setManufacturerId] = useState('');
    const { params } = useRouteMatch();
    const { id } = params as any;

    useEffect(() => {
        if (id) {
            setManufacturerId(id);
        }
    }, [params]);
    const saveHub = async (values: any, { setSubmitting, resetForm }: FormikHelpers<any>) => {
        try {
            if (!values.location || !values.emailAddress || !values.location || !values.name) {
                console.log('not valid');
                return toast.error('Kindly fill all fields to add a product');
            }
            const query = graphqlOperation(createHub, {
                hubInput: {
                    ...values,
                    phoneNumber: formatNumber(values.phoneNumber),
                    manufacturerId,
                },
            });
            const { data } = (await API.graphql(query)) as GraphQLResult<any>;
            const createHubResponse = data?.createHub;
            if (createHubResponse?.code && createHubResponse?.code !== 200) {
                setSubmitting(false);
                return toast.error(createHubResponse?.message || 'An error occurred');
            }
            setSubmitting(false);
            resetForm();
            toast.success('Hub created successfully');
            history.push(`/manufacturer/profile/${manufacturerId}`);
            return;
        } catch (e: any) {
            console.log(e);
            return toast.error(e);
        }
    };

    const history = useHistory();

    const cancelAddHub = () => {
        history.push(`/manufacturer/profile/${manufacturerId}`);
    };

    const formik = useFormik<any>({
        initialValues: {
            phoneNumber: '',
            manufacturerId: '',
            name: '',
            location: '',
            emailAddress: '',
            address: '',
        },
        onSubmit: saveHub,
        validationSchema: createHubSchema,
    });

    return (
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <BlockUi blocking={formik.isSubmitting}>
                            <ToastContainer />
                            <div>
                                <Breadcrumb separator=">">
                                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item>Hubs</Breadcrumb.Item>
                                    <Breadcrumb.Item href="">Add Hub</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>

                            <div>
                                <h6 className="products-heading">Create Company Hub</h6>
                                <Card className="products-cardbody shadow-lg p-3 mb-5">
                                    <Card.Body>
                                        <Form
                                            onSubmit={(e) => {
                                                console.log(formik.errors);
                                                formik.handleSubmit(e);
                                            }}
                                        >
                                            <Form.Group controlId="formBasicName">
                                                <Row>
                                                    <div className="col-md-6 mb-2">
                                                        <Form.Label className="new-product-form-label">
                                                            Hub Name
                                                        </Form.Label>
                                                        <Form.Control
                                                            placeholder="Enter Hub Name"
                                                            isInvalid={Boolean(
                                                                formik.touched.name && formik.errors.name,
                                                            )}
                                                            {...formik.getFieldProps('name')}
                                                            value={formik.values.name}
                                                        />
                                                        {formik.touched.name && formik.errors.name ? (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.name}
                                                            </Form.Control.Feedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="col-md-6 mb-2">
                                                        <Form.Label className="new-product-form-label">
                                                            Location
                                                        </Form.Label>
                                                        <Form.Control
                                                            placeholder="Enter Location"
                                                            isInvalid={Boolean(
                                                                formik.touched.location && formik.errors.location,
                                                            )}
                                                            {...formik.getFieldProps('location')}
                                                            value={formik.values.location}
                                                        />
                                                        {formik.touched.location && formik.errors.location ? (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.location}
                                                            </Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group className="new-product-form-group" controlId="formBasicInputs">
                                                <Row>
                                                    <div className="col-md-6">
                                                        <Form.Label className="new-product-form-label">
                                                            Email Address
                                                        </Form.Label>
                                                        <Form.Control
                                                            placeholder="Enter email address"
                                                            isInvalid={Boolean(
                                                                formik.touched.emailAddress &&
                                                                    formik.errors.emailAddress,
                                                            )}
                                                            {...formik.getFieldProps('emailAddress')}
                                                            value={formik.values.emailAddress}
                                                        />
                                                        {formik.touched.emailAddress && formik.errors.emailAddress ? (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.emailAddress}
                                                            </Form.Control.Feedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="col-md-6">
                                                        <Form.Label className="new-product-form-label">
                                                            Phone Number
                                                        </Form.Label>
                                                        <Form.Control
                                                            placeholder="Enter Phone Number"
                                                            isInvalid={Boolean(
                                                                formik.touched.phoneNumber && formik.errors.phoneNumber,
                                                            )}
                                                            {...formik.getFieldProps('phoneNumber')}
                                                            value={formik.values.phoneNumber}
                                                        />
                                                        {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.phoneNumber}
                                                            </Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group className="new-product-form-group" controlId="formBasicInputs">
                                                <Row>
                                                    <div className="col-md-6">
                                                        <Form.Label className="new-product-form-label">
                                                            Address
                                                        </Form.Label>
                                                        <Form.Control
                                                            placeholder="Enter hub address"
                                                            isInvalid={Boolean(
                                                                formik.touched.address && formik.errors.address,
                                                            )}
                                                            {...formik.getFieldProps('address')}
                                                            value={formik.values.address}
                                                        />
                                                        {formik.touched.address && formik.errors.address ? (
                                                            <Form.Control.Feedback type="invalid">
                                                                {formik.errors.address}
                                                            </Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                </Row>
                                            </Form.Group>
                                            <div className="new-product-action-buttons">
                                                <Button type="submit" className="new-product-action-buttons-create">
                                                    Create
                                                </Button>
                                                <Button
                                                    onClick={cancelAddHub}
                                                    className="new-product-action-buttons-cancel"
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(null)(CreateHub);
