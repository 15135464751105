export const formatTick = (value: any) => {
    value = value + '';
    const len = value.length;
    if (len > 6) {
        return formatToUnit(value, 'M', len - 6);
    } else if (len > 3) {
        return formatToUnit(value, 'K', len - 3);
    }
    return value;
};

const formatToUnit = (value: string, suffix: 'K' | 'M', decimalPlace: number) => {
    const valueSubstr = value.slice(0, 3);

    if (decimalPlace == 3) {
        return valueSubstr + suffix;
    } else if (decimalPlace == 2) {
        const displayValue = valueSubstr.replace(/0$/g, suffix);
        return displayValue.includes(suffix) ? displayValue : addDecimalPlace(displayValue, suffix, decimalPlace);
    }

    const displayValue = valueSubstr.replace(/0+$/g, suffix);
    if (displayValue.length == 2 && displayValue.includes(suffix)) {
        return displayValue;
    }
    return addDecimalPlace(valueSubstr, suffix, decimalPlace);
};

const addDecimalPlace = (value: string, suffix: 'K' | 'M', decimalPlace: number) => {
    return value.slice(0, decimalPlace) + '.' + value.slice(decimalPlace).replace(/0+$/g, '') + suffix;
};
