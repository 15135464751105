import React, { SyntheticEvent, useEffect, useState } from 'react';

type IProps = {
    initialMinute?: number;
    initialSeconds?: number;
    resendFunc(): void;
};
const ResendOtp: React.FC<IProps> = (props) => {
    const { initialMinute = 2, initialSeconds = 0 } = props;
    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(initialSeconds);

    const onClickResend = async (e: SyntheticEvent) => {
        e.preventDefault();
        await props.resendFunc();
        setMinutes(initialMinute);
        setSeconds(initialSeconds);
    };
    useEffect(() => {
        const myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    });

    return (
        <div
            onClick={(e) => (minutes === 0 && seconds === 0 ? onClickResend(e) : null)}
            className="d-flex otp-resend-container ml-2"
        >
            <div className={minutes !== 0 || seconds != 0 ? 'disabled' : 'primary-color pointer'}>Resend</div>
            <span className="d-flex">
                {minutes === 0 && seconds === 0 ? null : (
                    <>
                        <span className="m-display-none">&nbsp;</span>
                        <span className="disabled">OTP in:</span> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </>
                )}
            </span>
        </div>
    );
};

export default ResendOtp;
