import React, { Component, ErrorInfo } from 'react';

export default class RootErrorBoundary extends Component {
    state = {
        hasError: false,
    };

    /**
     * Catches errors thrown by children
     * @param {Object} error Error thrown
     * @param {Object} info Information of the error Thrown
     * */
    componentDidCatch(error: Error, info: ErrorInfo) {
        this.setState({
            hasError: true,
        });

        // TODO log these to external service

        // eslint-disable-next-line no-console
        console.log('Error Caught Info', JSON.stringify(info));

        // eslint-disable-next-line no-console
        console.log('Error Caught, Error', JSON.stringify(error));
    }

    render() {
        if (this.state.hasError) {
            <div className="container">
                <h3>Something went wrong. Unable to render this part.</h3>
            </div>;
        }

        // render children if there is no error
        // eslint-disable-next-line react/prop-types
        return this.props.children;
    }
}
