import { GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AdminManufacturerMonthlyStatisticsQuery, SearchOrdersAdminQuery } from '../../API';
import {
    adminManufacturerMonthlyStatistics,
    getManufacturerHubDetails,
    getManufacturerHubs,
    searchManufacturer,
    searchOrdersAdmin,
} from '../../graphql/queries';

export const useManufacturer = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [manufacturerStats, setManufacturerStats] = useState<any>({});
    const [manufacturersData, setManufacturersData] = useState<any[]>([]);
    const [initialData, setInitialData] = useState<any[]>([]);
    const [fetching, setFetching] = useState(false);
    const [orders, setOrders] = useState<SearchOrdersAdminQuery['searchOrdersAdmin']>(
        {} as SearchOrdersAdminQuery['searchOrdersAdmin'],
    );
    const [manufacturerHubs, setManufacturerHubs] = useState<any[]>([]);
    const [manufacturerHubDetail, setManufacturerHubDetail] = useState<any>(null);
    const [isLocationsSet, setIsLocationsSet] = useState(false);
    const [locations, setLocations] = useState<string[]>([]);

    const history = useHistory();

    const getManufacturerStats = async (manufacturerId: string) => {
        try {
            setFetching(true);

            //graphQl request

            const query = graphqlOperation(adminManufacturerMonthlyStatistics, { manufacturerId });
            const { data } = (await API.graphql(query)) as GraphQLResult<AdminManufacturerMonthlyStatisticsQuery>;

            setManufacturerStats(data?.adminManufacturerMonthlyStatistics);
            setFetching(false);
        } catch (e) {
            setFetching(false);
        }
    };

    const handleCompanyInfoSubmit = async (manufacturerId: string) => {
        try {
            setFetching(true);

            //graphQl request

            const query = graphqlOperation(searchOrdersAdmin, { manufacturerId });
            const { data } = (await API.graphql(query)) as GraphQLResult<SearchOrdersAdminQuery>;

            setOrders(data?.searchOrdersAdmin || ({} as SearchOrdersAdminQuery['searchOrdersAdmin']));
            setFetching(false);
        } catch (e) {
            setFetching(false);
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = (cb?: () => void) => {
        if (cb) {
            cb();
        }
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const fetchManufacturerHubs = async (manufacturerId: string) => {
        try {
            setFetching(true);

            //graphQl request

            const query = graphqlOperation(getManufacturerHubs, { manufacturerId });
            const { data } = (await API.graphql(query)) as GraphQLResult<any>;

            const manufacturerHubs = data?.getManufacturerHubs?.data || [];
            setManufacturerHubs(manufacturerHubs);
            setFetching(false);
        } catch (e) {
            setFetching(false);
        }
    };

    const fetchManufacturers = async (searchText = '') => {
        try {
            const query = graphqlOperation(searchManufacturer, {
                page: 1,
                limit: 10,
                q: searchText,
            });
            const { data } = (await API.graphql(query)) as GraphQLResult<any>;

            const mainResult = data?.searchManufacturer;
            const manufacturers = mainResult?.data?.result || [];

            if (!manufacturers.length || mainResult?.code !== 200) {
                throw new Error('No users found');
            }
            const aggregations = JSON.parse(mainResult?.data?.aggregations);
            populateLocationsWithAggregateData(aggregations);
            setManufacturersData(manufacturers);
            setInitialData(manufacturers);

            return manufacturers;
        } catch (e: any) {}
    };

    const populateLocationsWithAggregateData = (aggregations: any) => {
        if (aggregations && aggregations?.location?.buckets) {
            const aggregateLocations = aggregations?.location?.buckets;
            const retrievedLocations = aggregateLocations.map((bucket: any) => bucket?.key);
            setLocations(Array.from(new Set([...locations, ...retrievedLocations])));
        }
    };

    const fetchManufacturerHubDetail = async (manufacturerId: string, hubId: string) => {
        try {
            setFetching(true);

            //graphQl request

            const query = graphqlOperation(getManufacturerHubDetails, { manufacturerId, hubId });
            const { data } = (await API.graphql(query)) as GraphQLResult<any>;

            const mainResult = data?.getManufacturerHubDetails;
            if (mainResult?.code !== 200) {
                return history.push(`/manufacturers`);
            }
            const manufacturerHubDetail = mainResult?.data || {};

            setManufacturerHubDetail(manufacturerHubDetail);
            setFetching(false);
        } catch (e) {
            setFetching(false);
        }
    };

    return {
        showModal,
        handleCancel,
        fetchManufacturers,
        setManufacturersData,
        handleOk,
        initialData,
        manufacturersData,
        setIsModalVisible,
        isModalVisible,
        handleCompanyInfoSubmit,
        fetching,
        orders,
        getManufacturerStats,
        manufacturerStats,
        manufacturerHubs,
        fetchManufacturerHubs,
        isLocationsSet,
        setIsLocationsSet,
        locations,
        setLocations,
        fetchManufacturerHubDetail,
        manufacturerHubDetail,
    };
};
