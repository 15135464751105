import { Breadcrumb } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { StockTable } from './components/StockTable';
import { PageTitle } from './PageTitle';

type DmaStockType = {
    match: {
        params: {
            id: string;
        };
    };
};
const DmaStock: React.FC<DmaStockType> = (props) => {
    const history = useHistory();
    const id = props.match.params.id;
    return (
        <div className="content-wrapper p-4">
            <Breadcrumb separator=">">
                <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => history.push('/dma')}>Digital Master Agents</Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => history.goBack()}>View Details</Breadcrumb.Item>
                <Breadcrumb.Item href="">View Stock</Breadcrumb.Item>
            </Breadcrumb>
            <div className="row mt-3 ">
                <PageTitle title="DigiFarm Master Agent Uploaded Stock" />
            </div>

            <StockTable userId={id} />
        </div>
    );
};
export default DmaStock;
