import { useState } from 'react';

import { NavLink } from '../routes';

type SelectLinkFn = (link: NavLink) => void;
type HookReturnType = [NavLink[], SelectLinkFn];

type UseNavItems = (defaultLinks: NavLink[]) => HookReturnType;
export const useNavItems: UseNavItems = (defaultLinks) => {
    const [links, setLinks] = useState(defaultLinks);

    const selectLink = (selectedLink: NavLink) => {
        setLinks((lnks) =>
            lnks.map((link) =>
                selectedLink.key === link.key ? { ...link, isSelected: true } : { ...link, isSelected: false },
            ),
        );
    };
    return [links, selectLink];
};
