import { GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { useState } from 'react';

import { CreateWardMutation, DeleteMultipleWardsMutation, GetWardsQuery } from '../../API';
import { createWard, deleteMultipleWards } from '../../graphql/mutations';
import { getWards } from '../../graphql/queries';

export const useWards = () => {
    const [wardsData, setWardsData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const getAllWards = async () => {
        try {
            setLoading(true);
            const query = graphqlOperation(getWards);
            const { data } = (await API.graphql(query)) as GraphQLResult<GetWardsQuery>;

            const wards = data?.getWards;

            if (wards) {
                setWardsData(wards);
            }
            setLoading(false);
            return wards;
        } catch (e: any) {
            console.log(e);
        }
    };

    const addWards = async (payload: any) => {
        try {
            const mutation = graphqlOperation(createWard, {
                ward: payload,
            });
            const { data } = (await API.graphql(mutation)) as GraphQLResult<CreateWardMutation>;
            const response = JSON.parse(data?.createWard || '{}');
            if (Number(response.code) === 200) {
                return {
                    message: 'Ward was added successfully',
                    code: Number(response.code),
                    success: true,
                };
            }
            return {
                message: response.message || 'Failed to create ward',
                code: Number(response.code),
                success: false,
            };
        } catch (e: any) {
            console.log(e);
            return {
                message: 'An error occurred while creating ward, kindly try again',
                code: 500,
                success: false,
            };
        }
    };

    const deleteWardById = async (wardIds: string[]) => {
        try {
            const mutation = graphqlOperation(deleteMultipleWards, {
                wardIds,
            });
            const { data } = (await API.graphql(mutation)) as GraphQLResult<DeleteMultipleWardsMutation>;
            const response = JSON.parse(data?.deleteMultipleWards || '{}');
            if (Number(response.code) === 200) {
                return {
                    message: 'Ward was deleted successfully',
                    code: Number(response.code),
                    success: true,
                };
            }
            return {
                message: 'Failed to delete ward',
                code: Number(response.code),
                success: false,
            };
        } catch (e: any) {
            return {
                message: 'An error occurred while creating ward, kindly try again',
                code: 500,
                success: false,
            };
        }
    };

    return {
        wardsData,
        getAllWards,
        addWards,
        deleteWardById,
        loading,
    };
};
