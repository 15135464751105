import './categories.css';

import { GraphQLResult } from '@aws-amplify/api';
import { Breadcrumb } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { Input } from '../../components/inputs/Input';
import { createSubCategory } from '../../graphql/mutations';
import { useCategories } from './useCategories';

function AddSubCategory() {
    const [name, setName] = useState('');
    const [creating, setCreating] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState(localStorage.getItem('categoryId'));
    const history = useHistory();

    const { categories, getAllCategories } = useCategories();

    useEffect(() => {
        (async function () {
            await getAllCategories();
            setCreating(false);
        })();
    }, []);

    const saveSubCategory = async (e: any) => {
        e.preventDefault();
        if (!name) {
            return toast.error('Please specify a SubCategory name!');
        }
        if (!selectedCategory) {
            return toast.error('Please select a Category!');
        }
        setCreating(true);
        const query = graphqlOperation(createSubCategory, {
            subCategory: {
                categoryId: selectedCategory,
                name,
            },
        });
        const res = (await API.graphql(query)) as GraphQLResult<any>;
        if (res.data.createSubCategory.status === 'SUCCESS') {
            return history.push(`/categories/${localStorage.getItem('categoryId')}/details`);
        }
        toast.error(res.data.createSubCategory.message);
    };

    return (
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <BlockUi blocking={creating}>
                            <ToastContainer />
                            <div>
                                <Breadcrumb separator=">">
                                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/categories">Categories</Breadcrumb.Item>
                                    <Breadcrumb.Item>Add Sub category</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>

                            <h6 className="categories-heading">Create Sub Category</h6>

                            <div>
                                <Card className="categories-cardbody shadow-lg p-3 mb-5">
                                    <Card.Body>
                                        <Form>
                                            <div className="d-flex">
                                                <Col md={12} className="p-3">
                                                    <div className="form-group">
                                                        <label className="auth-label mb-2">Category</label>
                                                        <Form.Control
                                                            onChange={(e) => setSelectedCategory(e.target.value)}
                                                            as="select"
                                                            className="w-100 mb-4"
                                                        >
                                                            {categories.map((category) => {
                                                                if (category.categoryId === selectedCategory) {
                                                                    return (
                                                                        <option
                                                                            selected={true}
                                                                            value={category.categoryId}
                                                                            key={category.categoryId}
                                                                        >
                                                                            {category.name}
                                                                        </option>
                                                                    );
                                                                }
                                                                return (
                                                                    <option
                                                                        value={category.categoryId}
                                                                        key={category.categoryId}
                                                                    >
                                                                        {category.name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </div>
                                                </Col>
                                            </div>
                                            <div className="d-flex">
                                                <Col md={12} className="p-3">
                                                    <div className="form-group">
                                                        <label className="auth-label mb-2">SubCategory Name</label>
                                                        <Input
                                                            className=""
                                                            placeholder="SubCategory Name"
                                                            value={name}
                                                            type="text"
                                                            required
                                                            onChange={(e) => setName(e)}
                                                            name="subcategory_name"
                                                            disabled={false}
                                                        />
                                                    </div>
                                                </Col>
                                            </div>

                                            <div className="d-flex justify-content-flex-end fullwidth mt-5">
                                                <div
                                                    className="cancel-btn fw-500 m-3 btn-width pointer"
                                                    onClick={() => history.push('/categories')}
                                                >
                                                    Cancel
                                                </div>
                                                <button
                                                    onClick={(e) => saveSubCategory(e)}
                                                    className="primary-btn fw-500 m-3 btn-width"
                                                    disabled={creating}
                                                >
                                                    {creating ? 'Loading...' : 'Add Sub Category'}
                                                </button>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddSubCategory;
