import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { Bar, BarChart, CartesianGrid, Cell, Label, Legend, Pie, PieChart, Tooltip, XAxis, YAxis } from 'recharts';
import { _DeepReadonlyObject } from 'utility-types/dist/mapped-types';

import {
    BestSellingProducts,
    ManufacturerDetailResult,
    ManufacturerMonthlyStatisticsQuery,
    MonthlySales,
} from '../../API';
import Empty from '../../assets/SVG/no-data.svg';
import { ActionModal } from '../../components/ActionModal';
import { manufacturerHubColumns } from '../../pages/manufacturers/tables-constant';
import { retrieveManufacturer, toggleManufacturerActiveState } from '../../redux/actions/manufacturersActions';
import { AppState } from '../../redux/reducers';
import { EmptyUserData } from './components/EmptyUserData';
import { Profile } from './components/Profile';
import { companyPermsColumns, salesColumns } from './tables-constant';
import { useManufacturer } from './useManufacturer';

interface ProfileProps {
    title: string;
    manufacturer?: _DeepReadonlyObject<ManufacturerDetailResult>;
}

const ManufacturerProfile: React.FC<ProfileProps> = ({ manufacturer, children }) => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const {
        orders,
        handleCompanyInfoSubmit,
        getManufacturerStats,
        manufacturerStats,
        fetchManufacturerHubs,
        manufacturerHubs,
    } = useManufacturer();

    const [permissions, setPermissions] = useState<any[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [monthlyStats] = useState<ManufacturerMonthlyStatisticsQuery | null | undefined>(null);
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#F333FF', '#FF0000'];

    const history = useHistory();

    useEffect(() => {
        dispatch(retrieveManufacturer(id));
        handleCompanyInfoSubmit(id);
        getManufacturerStats(id);
        fetchManufacturerHubs(id);
    }, [id, dispatch]);

    const mapMonthlySalesToGraph = (data: MonthlySales[] | null, manufacturer: any) => {
        const results = data?.map((p) => {
            return {
                name: p.monthShort,
                [manufacturer?.name]: p.monthSales,
            };
        });
        return results;
    };

    const mapBestSellingProductsData = (data: BestSellingProducts[] | null) => {
        return data?.map((p) => {
            return {
                name: p.key,
                value: p.doc_count,
            };
        });
    };

    // Todo: This is temporary till we support multiple users per manufacturers
    useEffect(() => {
        if (manufacturer) {
            setPermissions((prev) => [
                {
                    ...prev,
                    fullName: manufacturer?.name,
                    id: 'A0',
                    email: manufacturer?.email,
                    phoneNumber: manufacturer?.phoneNumber,
                    rolePermission: 'Admin',
                },
            ]);
        }
    }, [manufacturer]);

    const data: any = orders?.result;
    const isManufacturerActive = (status: String | undefined | null): boolean => {
        return !!status && status.toLowerCase() === 'active';
    };

    if (!manufacturer) {
        return (
            <div className="d-flex justify-content-center align-items-center height-100vh">
                <LoadingOutlined style={{ fontSize: 34 }} spin />
            </div>
        );
    }
    return (
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between fullwidth">
                            <Row className="fullwidth">
                                <Col className="fullwidth">
                                    <Profile
                                        profile={manufacturer}
                                        isManufacturerActive={isManufacturerActive}
                                        onToggleManufacturer={() => {
                                            setIsModalVisible(!isModalVisible);
                                        }}
                                    />
                                </Col>
                                <Col className="fullwidth mx-auto p-4">
                                    <div className="d-flex flex-column justify-content-between fullwidth">
                                        {isManufacturerActive(manufacturer?.status) ? (
                                            <>
                                                <div className="d-flex justify-content-between mb-3">
                                                    <div className="primary-color mb-2 fw-500 fs-18">Company Hubs</div>
                                                    <div
                                                        className="border-btn text-center"
                                                        onClick={() =>
                                                            history.push(
                                                                `/manufacturer/${manufacturer?.manufacturerId}/hub/new`,
                                                            )
                                                        }
                                                    >
                                                        Add Hub
                                                    </div>
                                                </div>
                                                <Table
                                                    className={'mb-5 fullwidth'}
                                                    dataSource={manufacturerHubs}
                                                    columns={manufacturerHubColumns(manufacturer?.manufacturerId)}
                                                    pagination={false}
                                                />
                                            </>
                                        ) : (
                                            <EmptyUserData
                                                name={manufacturer?.name || ''}
                                                empty={Empty}
                                                message="has no allocated hubs"
                                            />
                                        )}
                                    </div>
                                </Col>
                                <Col className="fullwidth mx-auto p-4">
                                    <div className="d-flex flex-column justify-content-between fullwidth">
                                        {isManufacturerActive(manufacturer?.status) ? (
                                            <>
                                                <div className="primary-color mb-2 fw-500 fs-18">Company Users</div>
                                                <Table
                                                    className={'mb-5 fullwidth'}
                                                    dataSource={permissions}
                                                    columns={companyPermsColumns()}
                                                    pagination={false}
                                                />
                                            </>
                                        ) : (
                                            <EmptyUserData
                                                name={manufacturer?.name || ''}
                                                empty={Empty}
                                                message="has no company permissions allocated to anyone"
                                            />
                                        )}

                                        {isManufacturerActive(manufacturer?.status) ? (
                                            <>
                                                <div className="primary-color mb-2 mt-3 fw-500 fs-18">
                                                    Company Orders
                                                </div>

                                                <Table
                                                    className={'fullwidth'}
                                                    dataSource={data}
                                                    columns={salesColumns()}
                                                    pagination={false}
                                                />
                                            </>
                                        ) : (
                                            <EmptyUserData
                                                name={manufacturer?.name || ''}
                                                empty={Empty}
                                                message="has no company orders"
                                            />
                                        )}
                                    </div>
                                </Col>
                                <Row className="fullwidth" style={{ marginBottom: '30x' }}>
                                    <Col className="mx-auto p-4 col-md-6">
                                        <p style={{ marginBottom: '15px', fontWeight: 'bold', color: '#39b54a' }}>
                                            Company Analysis
                                        </p>
                                        <Card className="elevated-card">
                                            <CardBody>
                                                <CardTitle className="text-center" tag="h6">
                                                    Monthly Manufacturers Sales
                                                </CardTitle>
                                                <BarChart
                                                    width={500}
                                                    height={380}
                                                    data={mapMonthlySalesToGraph(
                                                        manufacturerStats?.monthlySales as any,
                                                        manufacturer,
                                                    )}
                                                    margin={{
                                                        top: 5,
                                                        right: 30,
                                                        left: 20,
                                                        bottom: 15,
                                                    }}
                                                >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="name" />
                                                    <YAxis>
                                                        <Label
                                                            angle={-90}
                                                            value="Amount (Ksh)"
                                                            position="insideLeft"
                                                            style={{
                                                                textAnchor: 'middle',
                                                                paddingRight: '15px',
                                                                marginRight: '15px',
                                                            }}
                                                        />
                                                    </YAxis>
                                                    <Tooltip />

                                                    <Legend
                                                        verticalAlign="bottom"
                                                        layout="vertical"
                                                        align="center"
                                                        wrapperStyle={{
                                                            paddingLeft: '10px',
                                                            marginBottom: '-15px',
                                                        }}
                                                    />
                                                    <Bar dataKey={manufacturer?.name as string} fill="#8884d8" />
                                                </BarChart>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col className="col-md-6 ml-2 mx-auto p-4">
                                        <p style={{ marginBottom: '15px', fontWeight: 'bold', color: '#39b54a' }}>
                                            Company Top Sellers
                                        </p>
                                        <Card className="elevated-card">
                                            <CardBody>
                                                <CardTitle className="text-center" tag="h6">
                                                    Best Selling Products
                                                </CardTitle>
                                                <PieChart width={500} height={380}>
                                                    <Legend layout="horizontal" verticalAlign="bottom" align="center" />
                                                    <Pie
                                                        data={mapBestSellingProductsData(
                                                            manufacturerStats?.bestSellingProducts as any,
                                                        )}
                                                        cx="50%"
                                                        cy="50%"
                                                        isAnimationActive={false}
                                                        label
                                                        outerRadius={80}
                                                        fill="#8884d8"
                                                        dataKey="value"
                                                    >
                                                        {monthlyStats?.manufacturerMonthlyStatistics?.bestSellingProducts?.map(
                                                            (entry, index) => (
                                                                <Cell
                                                                    key={`cell-${index}`}
                                                                    fill={COLORS[index % COLORS.length]}
                                                                />
                                                            ),
                                                        )}
                                                    </Pie>
                                                </PieChart>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Row>

                            <div>{children}</div>
                            {isModalVisible ? (
                                <ActionModal
                                    isActivating={!isManufacturerActive(manufacturer?.status)}
                                    handleCancel={() => setIsModalVisible(false)}
                                    handleOk={() => {
                                        setIsModalVisible(false);
                                        dispatch(
                                            toggleManufacturerActiveState(
                                                manufacturer?.name || '',
                                                !isManufacturerActive(manufacturer?.status),
                                            ),
                                        );
                                    }}
                                    isModalVisible={isModalVisible}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ manufacturersList }: AppState) => ({
    manufacturer: manufacturersList.manufacturer,
});

export default connect(mapStateToProps)(ManufacturerProfile);
