import React from 'react';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { formatTick } from './util';

interface DataPoints {
    label: string;
    sales: string | number;
}

type Props = {
    salesChart: DataPoints[];
};

const SalesChart: React.FC<Props> = ({ salesChart }) => {
    return (
        <ResponsiveContainer width="100%" height="100%" minHeight="300px">
            <LineChart width={150} height={120} data={salesChart}>
                <XAxis dataKey="label" />
                <YAxis tickFormatter={formatTick} />
                <Tooltip />
                <Line
                    type="monotone"
                    name="DMA Sales"
                    dataKey="sales"
                    stroke="#1f06fe"
                    strokeWidth={2}
                    legendType="none"
                />
                <Legend />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default SalesChart;
