import React from 'react';

import { TopDmaSeller } from '../../API';
import MyTable from './components/MyTable';

const TopDmaSellers = ({ topDmaSellers }: { topDmaSellers: TopDmaSeller[] }) => {
    const columns = [
        {
            Header: 'Id',
            accessor: (o: TopDmaSeller) => o.dmaId?.slice(-6)?.toUpperCase(),
        },

        {
            Header: 'DMA Name',
            accessor: 'dmaName',
        },
        {
            Header: 'Total Sales(Ksh)',
            accessor: 'totalSalesAmount',
        },
    ];

    return (
        <div style={{ overflowX: 'auto' }}>
            <MyTable columns={columns} data={topDmaSellers} />
        </div>
    );
};

export default TopDmaSellers;
