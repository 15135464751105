import { Breadcrumb } from 'antd';
import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import DashboardStats from './components/DashboardStats';
import GrowthChart from './GrowthChart';
import ManufactureSales from './ManufactureSales';
import SalesChart from './SalesChart';
import TopDmaSellers from './TopDmaSellers';
import { useDashboardData } from './useDashboardQueries';

const Dashboard = () => {
    const {
        manufactureSalesStats,
        dmaSalesStats,
        dmaStats,
        manufactureStats,
        fetchManufacturerStatistics,
        fetchDmaSalesStatistics,
        fetchDmaStatistics,
        fetchManufacturerSalesStatistics,
        fetchLatestOrdersData,
    } = useDashboardData();

    const stats = {
        dma: dmaStats?.dmaStats || 0,
        manufacturer: manufactureStats?.manToday || 0,
        manufacturersales: manufactureSalesStats?.manSales || 0,
        dmasales: dmaSalesStats?.dmaSales || 0,
    };

    const history = useHistory();

    useEffect(() => {
        fetchManufacturerStatistics();
        fetchLatestOrdersData();
        fetchManufacturerSalesStatistics();
        fetchDmaStatistics();
        fetchDmaSalesStatistics();
    }, []);

    const dmaManufacturerGrowth = (dmaStats: any, manufactureStats: any) => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        if (dmaStats && manufactureStats) {
            return months.map((p) => ({
                name: p,
                dma: dmaStats?.monthlyDmaStats.find((a: any) => a.monthShort === p).monthStats || 0,
                manufacturer: manufactureStats?.manMonthly.find((a: any) => a.monthShort === p).monthStats || 0,
            }));
        }
        return [];
    };

    const dmaSalesChart = (dmaSalesStats: any) => {
        return dmaSalesStats?.monthlyDmaSales.map((p: any) => ({
            label: p.monthShort,
            sales: p.monthSales,
        }));
    };

    const manufacturerSalesChart = (manufactureSalesStats: any) => {
        const results = manufactureSalesStats?.monthlyManufacturerSales.map((p: any) => ({
            label: p.monthShort,
            sales: p.monthSales,
        }));
        return results || [];
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="container-fluid mt-5 mb-5">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="mt-3 mb-6" />
                    <DashboardStats stats={stats} />
                    <Row>
                        <Col md="6" sm="12" className="">
                            <Card className="shadow-lg p-3 mb-5" body>
                                <Card.Title className="text-center">Monthly DMA Sales</Card.Title>
                                <SalesChart salesChart={dmaSalesChart(dmaSalesStats)} />
                            </Card>
                        </Col>
                        <Col md="6" sm="12" className="">
                            <Card className="shadow-lg p-3 mb-5" body>
                                <Card.Title className="text-center">Monthly Manufacturer Sales</Card.Title>
                                <ManufactureSales manufactureSales={manufacturerSalesChart(manufactureSalesStats)} />
                            </Card>
                        </Col>
                        <Col md="6" sm="12">
                            <Card className="shadow-lg p-3 mb-5" body style={{ minHeight: '300px' }}>
                                <Card.Title className="text-success">Top DMA Sellers</Card.Title>
                                <TopDmaSellers topDmaSellers={dmaSalesStats?.topDmaSellers || []} />
                            </Card>
                        </Col>
                        <Col md="6" sm="12">
                            <Card className="shadow-lg p-3 mb-5" body style={{ minHeight: '300px' }}>
                                <Card.Title className="text-center">DMA and Manufacturer Growth</Card.Title>
                                <GrowthChart dmaGrowth={dmaManufacturerGrowth(dmaStats, manufactureStats)} />
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
