import React, { CSSProperties, FC, useMemo } from 'react';

import useBreakpoint from '../hooks/useBreakpoint';

export const AuthContentWrapper: FC = ({ children }) => {
    const bp = useBreakpoint();
    const isSmallScreen = bp === 'xs' || bp === 'sm';

    const style: CSSProperties = useMemo(() => {
        return isSmallScreen
            ? {
                  position: 'absolute',
                  zIndex: 100,
                  top: 15,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  height: 'fit-content',
              }
            : {};
    }, [isSmallScreen]);

    return (
        <div
            className={`col-md-5 p-5 col-11 bg-white d-flex flex-column justify-space-between ${
                isSmallScreen ? 'card' : ''
            }`}
            style={{ ...style }}
        >
            {children}
        </div>
    );
};
